<div class="filters-container">
  <div class="accordion-container">
    <div class="search-container">
      <div class="filter-row" id="first-row">
        <div class="single-filter-container w-50" (focusout)="onAapLeave()" (mouseleave)="onAapLeave()">
          <label class="ds-input-group__label filter-label"> Appel à projet </label>
          <lib-ds-multiselect
            class="filter"
            #aapComponent
            [dataList]="aapFilterList"
            [selectOptions]="{ placeholder: 'Appel à projet' }"
            [name]="'aap-filter'"
          >
          </lib-ds-multiselect>
        </div>

        <div class="single-filter-container w-50">
          <label class="ds-input-group__label filter-label"> Dates de relèves </label>
          <lib-ds-multiselect
            class="filter"
            #dateReleveComponent
            [dataList]="filteredDateReleveFilterList"
            [selectOptions]="{ placeholder: 'Dates de relèves' }"
            [name]="'comite-date-releves-filter'"
          >
          </lib-ds-multiselect>
        </div>
      </div>

      <div class="filter-row" id="second-row" [ngClass]="{ 'hidden-row': !accordionOpen }">
        <div id="date-picker-fermeture" class="date-time__date ds-input-group ds-input-group--date single-filter-container w-50">
          <label class="ds-input-group__label">Date de comité</label>
          <span class="ds-input-group__input">
            <input
              matInput
              [matDatepicker]="closeDatePicker"
              [(ngModel)]="dateTenue"
              class="ds-input-group__input"
              placeholder="JJ/MM/AAAA"
            />
            <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #closeDatePicker></mat-datepicker>
          </span>
        </div>

        <div class="single-filter-container w-50">
          <label for="comite-creator" class="ds-input-group__label filter-label">Créateur du comité</label>
          <div class="ds-input-group__select">
            <select name="comite-creator" [(ngModel)]="comiteCreator">
              <option [ngValue]="null" selected disabled hidden>Sélectionnez un créateur de comité</option>
              <option *ngFor="let item of comiteCreatorFilterList" [value]="item.id">{{ item.value }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="search-buttons-container">
        <button class="ds-btn ds-btn--tertiary" (click)="toggleFilters()">
          {{ accordionOpen ? 'Moins de critères' : 'Plus de critères' }}
        </button>
        <button type="button" class="ds-btn ds-btn--secondary" (click)="resetAllFilters()">
          <span class="ds-icon ds-icon-left ds-float--left" aria-hidden="true">refresh</span>
          Réinitialiser
        </button>
        <button id="actions-btn" class="ds-btn ds-btn--primary" (click)="submitSearch()">Rechercher</button>
      </div>
    </div>
  </div>
</div>
