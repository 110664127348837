export class ArrayUtils {
  static arrayEquals(array1: any[], array2: any[]): boolean {
    return (
      Array.isArray(array1) &&
      Array.isArray(array2) &&
      array1.length === array2.length &&
      array1.every((val, index) => val === array2[index])
    );
  }
}
