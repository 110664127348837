import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  EnumRoleIntervenantDC,
  IntervenantsDc,
  ReferentielHttpService,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';

@Component({
  selector: 'pxl-intervenants-role',
  templateUrl: './intervenants-role.component.html',
  styleUrls: ['./intervenants-role.component.scss'],
})
export class IntervenantsRoleComponent extends SubscriptionDestroyerComponent {
  // @Input() dataSource: MatTableDataSource<any>;
  @Input()
  set dataSource(data: MatTableDataSource<IntervenantsDc>) {
    this._dataSource = data;
    if (data) {
      data.sort = this.sort;
      data.sortingDataAccessor = (item: any, property: string) => {
        return item[property];
      };
    }
  }
  get dataSource() {
    return this._dataSource;
  }
  @Input() modificationPath: string;

  private _dataSource: MatTableDataSource<IntervenantsDc> | undefined;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['role', 'nom', 'prenom', 'matricule', 'email', 'telephone', 'actions'];

  constructor(private referentielService: ReferentielHttpService, private showToastrService: ShowToastrService) {
    super();
  }

  deleteIntervenant(intervenant: IntervenantsDc): void {
    this.referentielService
      .deleteIntervenatsDC(intervenant.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.showToastrService.success("L'intervenant a bien été supprimé)");
          this.dataSource.data = this.dataSource.data.filter(f => f.id !== intervenant.id);
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  protected readonly EnumRoleIntervenantDC = EnumRoleIntervenantDC;
}
