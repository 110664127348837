import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumDecisionComite } from '@shared-ui';

export interface ModalData {
  title: string;
  subtitle: string;
  textReturnButton: string;
  textGoButton: string;
}

@Component({
  selector: 'pxl-comite-decision-modal',
  templateUrl: './comite-decision-modal.component.html',
  styleUrls: ['./comite-decision-modal.component.scss'],
})
export class ComiteDecisionModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();
  decisionComiteForm: UntypedFormGroup;

  get formValidationPropositionComite(): UntypedFormControl {
    return this.decisionComiteForm.get('validationPropositionComite') as UntypedFormControl;
  }

  get formDateDecisionEtat(): UntypedFormControl {
    return this.decisionComiteForm.get('dateDecisionEtat') as UntypedFormControl;
  }

  get formAvisEtat(): UntypedFormControl {
    return this.decisionComiteForm.get('avisEtat') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<ComiteDecisionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.decisionComiteForm = this.formBuilder.group({
      validationPropositionComite: [null, [Validators.required]],
      dateDecisionEtat: [null, [Validators.required]],
      avisEtat: [null, null],
    });
  }

  confirmerDate(): void {
    this.decisionComiteForm.markAllAsTouched();
    if (this.decisionComiteForm.valid) {
      this.dialogRef.close({ data: this.decisionComiteForm.value });
    }
  }

  cancelModal() {
    if (this.decisionComiteForm.touched) {
      const cn = confirm('La saisie ne sera peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  protected readonly EnumDecisionComite = EnumDecisionComite;
}
