import { Component, Input } from '@angular/core';
import { EnumNotationEtape, EnumNotationTypeNote, Notation } from '@shared-ui';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';

@Component({
  template: '',
  selector: 'pxl-projet-synthese-notation-base',
})
export class ProjetSyntheseNotationBaseComponent {
  readonly GREEN_NOTES = ['A', 'Favorable', '4', 'A retenir'];
  readonly ORANGE_NOTES = ['B', '2', '3'];
  readonly RED_NOTES = ['C', 'Défavorable', '1', 'Sans suite'];
  readonly BLUE_NOTES = ['Réorientation', 'Réservé', 'Ajournement'];

  @Input() notation: Notation;

  constructor(public sharedFunction: SharedFunction) {}

  isTextLong(textLength: number) {
    return textLength > 250;
  }

  getEvaluateurNomPrenom(notation: Notation): string {
    return notation?.evaluateur?.nom ? notation?.evaluateur?.nom + ' ' + notation?.evaluateur?.prenom : notation?.evaluateur?.email;
  }

  getEvaluateurEmail(notation: Notation): string {
    return notation?.evaluateur?.email ?? ' ';
  }

  getEvaluateurAbsentMessage(): string {
    return this.sharedFunction.isSiteAdmin()
      ? 'Cet évaluateur ne s’est jamais connecté sur PICXEL, il n’est actuellement pas possible de lui associer une notation.'
      : ' ';
  }

  showMoreButtonText(readMore: boolean): string {
    return readMore ? 'Voir moins' : 'Voir plus';
  }

  isNoteGreen(): boolean {
    return this.GREEN_NOTES.includes(this.notation.avis ?? this.notation.avisExpert.avis);
  }

  isNoteOrange(): boolean {
    return this.ORANGE_NOTES.includes(this.notation.avis ?? this.notation.avisExpert.avis);
  }

  isNoteRed(): boolean {
    return this.RED_NOTES.includes(this.notation.avis ?? this.notation.avisExpert.avis);
  }

  isNoteBlue(): boolean {
    return this.BLUE_NOTES.includes(this.notation.avis ?? this.notation.avisExpert.avis);
  }

  protected readonly EnumNotationEtape = EnumNotationEtape;
  protected readonly EnumNotationTypeNote = EnumNotationTypeNote;
}
