export enum EnumTypeOrganisme {
  SDE = 'Département' as any,
  ADEME = 'Thématique' as any,
  CABINET_EXTERNE = 'Tout' as any,
}

export enum EnumTypeOrganismeTech {
  SDE = 'Département',
  ADEME = 'Thématique',
  CABINET_EXTERNE = 'Tout',
}