<div class="tableau-title">
  <div>Référentiel des rôles</div>
</div>

<div class="table">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="label"
    matSortDirection="asc"
    matSortDisableClear
    aria-describedby="tableau de référentiel des rôles"
  >
    <ng-container matColumnDef="role" class="">
      <th id="intervenant-role" mat-header-cell *matHeaderCellDef mat-sort-header>Rôle</th>
      <td id="role" mat-cell *matCellDef="let element">
        <span>{{ EnumRoleIntervenantDC.toString(element.role) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="nom" class="">
      <th id="intervenant-nom" mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td id="nom" mat-cell *matCellDef="let element">
        <span>{{ element.nom }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="prenom" class="">
      <th id="intervenant-prenom" mat-header-cell *matHeaderCellDef mat-sort-header>Prenom</th>
      <td id="prenom" mat-cell *matCellDef="let element">
        <span>{{ element.prenom }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="matricule" class="">
      <th id="intervenant-matricule" mat-header-cell *matHeaderCellDef mat-sort-header>Matricule</th>
      <td id="matricule" mat-cell *matCellDef="let element">
        <span>{{ element.matricule }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="email" class="">
      <th id="intervenant-email" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td id="email" mat-cell *matCellDef="let element">
        <span>{{ element.email }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="telephone" class="">
      <th id="intervenant-telephone" mat-header-cell *matHeaderCellDef mat-sort-header>Téléphone</th>
      <td id="telephone" mat-cell *matCellDef="let element">
        <span>{{ element.telephone }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th id="intervenant-actions" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button id="delete-intervenant" class="table__button" matTooltip="Supprimer Intervenants" (click)="deleteIntervenant(element)">
          <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
  </table>
</div>
<div class="no-result" *ngIf="dataSource?.data?.length === 0">
  <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
  <span id="large-span-result">Il n'y a rien ici...</span>
  <span id="small-span-result">0 intervenant répertorié.</span>
</div>
