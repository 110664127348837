import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MultiselectComponent } from './multiselect.component';

@NgModule({
  declarations: [MultiselectComponent],
  imports: [CommonModule, MatProgressSpinnerModule, FormsModule, SharedModule],
  exports: [MultiselectComponent],
})
export class CustomMultiSelectModule {}
