<div class="page-content list-page">
  <section class="page-section" [libMyRemoveIfFeatureOff]="'grille-impacts'">
    <lib-card-title title="Paramétrer le référentiel de la Grille d’impacts"></lib-card-title>
    <div class="card--blue">
      <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
      <p class="card--info--text">
        Si vous importez de nouveau un KPI déjà présent dans le référentiel et utilisé dans un AAP, l'ensemble des valeurs seront mises à
        jour à l'exception des attributs suivants : Scope de la catégorie / Type structure de la catégorie lorsque le scope concerne les
        structures / Ordre de la catégorie / Type de réponse / Format de la réponse / Liste des valeurs possibles pour la réponse.
      </p>
    </div>
    <div class="card card--file">
      <div class="card__title">
        <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
        <div>
          <div>Importer un CSV</div>
          <div class="card--message">Fichier qui permet de paramétrer le référentiel des KPIs de la grille d’impacts</div>
        </div>
      </div>
      <div></div>
      <button type="button" class="ds-btn ds-btn--secondary" (click)="fileInput.click()">
        <lib-pxl-icon class="icon" icon="icon-action-add" aria-hidden="true"></lib-pxl-icon>
        Importer un CSV
      </button>
      <div class="input-file">
        <input type="file" id="files" (change)="onSelectDoc($event)" [accept]="SUPPORTED_FILE_EXTENSION" #fileInput />
      </div>
    </div>
  </section>

  <!-- Section paramétrer le référentiel des critères d'évaluation -->
  <section class="page-section" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.CRITERE_EVAL_INDICATEUR_ELIGIBILITE">
    <lib-card-title title="Paramétrer le référentiel des critères d'évaluation"></lib-card-title>

    <lib-card-text
      (click)="goToCritereEvaluation()"
      inputText="Enrichir le référentiel des critères d'évaluation"
      descriptionText="Vous pouvez  ajouter / modifier les critères d'évaluation ici"
      [isSmallCard]="true"
    ></lib-card-text>
  </section>
  <!-- Fin section paramétrer le référentiel des critères d'évaluation -->

  <!-- Section paramétrer le référentiel des indicateurs d’éligibilité -->
  <section class="page-section" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.CRITERE_EVAL_INDICATEUR_ELIGIBILITE">
    <lib-card-title title="Paramétrer le référentiel des indicateurs d’éligibilité"></lib-card-title>

    <lib-card-text
      (click)="goToIndicateursEligibilite()"
      inputText="Enrichir le référentiel des indicateurs d’éligibilité"
      descriptionText="Vous pouvez  ajouter / modifier les indicateurs d’éligibilité ici"
      [isSmallCard]="true"
    ></lib-card-text>
  </section>
  <!-- Fin section paramétrer le référentiel des indicateurs d’éligibilité -->

  <!-- Section paramétrer le référentiel des stratégies d'accélération (dispositifs) -->
  <section class="page-section" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.STRATEGIE_ACCELERATION">
    <lib-card-title title="Paramétrer le référentiel des stratégies d'accélération (dispositifs)"></lib-card-title>

    <lib-card-text
      (click)="goToIndicateursStrategiesAcceleration()"
      inputText="Enrichir le référentiel des stratégies d'accélération"
      descriptionText="Vous pouvez  ajouter / modifier les stratégies d'accélération ici"
      [isSmallCard]="true"
    ></lib-card-text>
  </section>
  <!-- Fin section paramétrer le référentiel des stratégies d'accélération (dispositifs) -->

  <!-- Section paramétrer la liste des rôles -->
  <section class="page-section" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.ROLE_CREATION_DC">
    <lib-card-title title="Paramétrer la liste des rôles"></lib-card-title>

    <lib-card-text
      (click)="goToRoleDC()"
      inputText="Enrichir le référentiel des rôles"
      descriptionText="Vous pouvez ajouter / modifier les rôles ici"
      [isSmallCard]="true"
    ></lib-card-text>
  </section>
  <!-- Fin section la liste des rôles pour la Creation DC -->

  <!-- Section paramétrer le réferentiel des documents AAP -->
  <section class="page-section" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.AAP_CONFIGURATION_DOCUMENT">
    <lib-card-title title="Parametrer le référentiel des documents AAP"></lib-card-title>

    <lib-card-text
      (click)="goToDocumentAAP()"
      inputText="Enrichir le référentiel des documents appels à projets"
      descriptionText="Vous pouvez ajouter / modifier les documents appels à projets"
      [isSmallCard]="true"
    ></lib-card-text>
  </section>
  <!-- Fin section réferentiel des documents AAP -->

  <!-- Section paramétrer le réferentiel des dépenses projets -->
  <section class="page-section">
    <lib-card-title title="Parametrer le référentiel des dépenses projets"></lib-card-title>

    <lib-card-text
      (click)="goToDepensesProjets()"
      inputText="Enrichir le référentiel des documents appels à projets"
      descriptionText="Vous pouvez ajouter / modifier les dépenses projets"
      [isSmallCard]="true"
    ></lib-card-text>
  </section>
  <!-- Fin section réferentiel des dépenses projets -->
</div>
