<h2 class="modal__title">Ajouter la date d'envoi pour validation par l'Etat</h2>

<div class="modal__content">
  <span>La date choisie s'appliquera à l'ensemble des projets sélectionnés</span>

  <form [formGroup]="form">
    <div
      class="date-time__date ds-input-group ds-input-group--date mt-2"
      [class.ds-input-group--error]="form.controls.dateEnvoi?.invalid && form.controls.dateEnvoi?.touched"
    >
      <label class="ds-input-group__label" for="date-envoi">Date d'envoi pour validation par l'Etat</label><lib-asterisk />
      <span class="ds-input-group__input">
        <input
          id="date-envoi"
          matInput
          [matDatepicker]="dateEnvoiDatePicker"
          formControlName="dateEnvoi"
          class="ds-input-group__input"
          placeholder="Date d'envoi pour validation par l'Etat"
        />
        <mat-datepicker-toggle matSuffix [for]="dateEnvoiDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dateEnvoiDatePicker></mat-datepicker>
      </span>
    </div>

    <div class="ds-input-group mt-2" [class.ds-radio-classic--error]="form.controls.phase.invalid && form.controls.phase.touched">
      <label class="ds-radio-classic" for="preselection-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="preselection-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.EVALUATION"
        />
        <span class="ds-radio__label"><span>Présélection</span></span>
      </label>

      <label class="ds-radio-classic" for="audition-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="audition-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.AUDITION"
        />
        <span class="ds-radio__label"><span>Audition</span></span>
      </label>

      <label class="ds-radio-classic" for="selection-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="selection-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.SELECTION"
        />
        <span class="ds-radio__label"><span>Sélection</span></span>
      </label>
    </div>
  </form>
</div>

<div class="modal__actions">
  <button id="cancel" class="ds-btn ds-btn--secondary" (click)="closeDialog()">Annuler</button>
  <button id="go-action" class="ds-btn ds-btn--primary" (click)="submit()" cdkFocusInitial>Confirmer</button>
</div>
