import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { GeneralInformationsComponent } from './general.informations.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedUiModule } from '../../../../../../../../libs/shared-ui/src/lib/shared-ui.module';

@NgModule({
  declarations: [GeneralInformationsComponent],
  exports: [GeneralInformationsComponent],
  providers: [],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
    SharedUiModule,
  ],
})
export class GeneralsInformationsModule {}
