import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ModalData {
  title: string;
  label: string;
  placeholder: string;
  subtitle: string;
  textReturnButton: string;
  textGoButton: string;
  description: string;
  labelWeight: string;
  optionalDate: boolean;
}

@Component({
  selector: 'pxl-modal',
  templateUrl: './date-select-modal.component.html',
  styleUrls: ['./date-select-modal.component.scss'],
})
export class DateSelectModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();
  dateForm: UntypedFormGroup;

  get formDate(): UntypedFormControl {
    return this.dateForm.get('date') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<DateSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.dateForm = this.formBuilder.group({
      date: [null, [Validators.required]],
    });
  }

  confirmerDate(): void {
    this.dateForm.markAllAsTouched();
    if (this.dateForm.valid || this.modalData.optionalDate) {
      this.dialogRef.close({ date: this.formDate.value });
    }
  }

  cancelModal(): void {
    if (this.dateForm.touched) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
}
