<h2 class="modal__title">Associer à un comité</h2>

<div class="modal__content">
  <span
    >Vous êtes sur le point d’affecter {{ projetsIds.length > 1 ? 'plusieurs' : 'un' }} projet{{ projetsIds.length > 1 ? 's' : '' }} de
    l'AAP {{ modalData.codeAap }} à un comité. Veuillez sélectionner le comité en question :</span
  >

  <form [formGroup]="form">
    <div
      class="ds-input-group"
      [class.ds-input-group--error]="form.controls.selectedComiteId.invalid && form.controls.selectedComiteId.touched"
    >
      <div class="ds-input-group__select ds-input-group__select--with-icon single-field-container mt-2">
        <div class="toggle-title-container">
          <label for="select__heure-tenue" class="ds-input-group__label">Nom du comité</label>
          <lib-asterisk />
        </div>
        <select id="select__heure-tenue" name="select__heure-tenue" formControlName="selectedComiteId">
          <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
          <option *ngFor="let comite of comites" [value]="comite.id">
            {{ comite.nom }} ({{ comite.dateTenue | date : 'dd/MM/yyyy' }})
          </option>
        </select>
      </div>
    </div>

    <div class="ds-input-group mt-2" [class.ds-radio-classic--error]="form.controls.phase.invalid && form.controls.phase.touched">
      <label class="ds-radio-classic" for="preselection-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="preselection-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.EVALUATION"
        />
        <span class="ds-radio__label"><span>Présélection</span></span>
      </label>

      <label class="ds-radio-classic" for="audition-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="audition-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.AUDITION"
        />
        <span class="ds-radio__label"><span>Audition</span></span>
      </label>

      <label class="ds-radio-classic" for="selection-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="selection-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.SELECTION"
        />
        <span class="ds-radio__label"><span>Sélection</span></span>
      </label>
    </div>
  </form>
</div>

<div class="modal__actions">
  <button id="cancel" class="ds-btn ds-btn--secondary" (click)="closeDialog()">Annuler</button>
  <button id="go-action" class="ds-btn ds-btn--primary" (click)="submit()" cdkFocusInitial>Confirmer</button>
</div>
