import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AapService } from '@services-evaluateur/aap.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { ProjetUtils } from '@shared-evaluateur/utils/projet-utils';
import {
  Aap,
  Comite,
  ComiteProjet,
  EnumPhaseComite,
  FilterDataInterface,
  Program,
  ProjetLight,
  ProjetTableElement,
  SearchObjectComites,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';

@Component({
  selector: 'pxl-project-search-block-comites',
  templateUrl: './project-search-block-comites.component.html',
  styleUrls: ['./project-search-block-comites.component.scss'],
})
export class ProjectSearchBlockComitesComponent extends SubscriptionDestroyerComponent implements OnInit {
  showSearchBar = false;

  @Input() selection = new SelectionModel<ProjetTableElement>(true, []);
  @Output() selectionChange = new EventEmitter<ProjetTableElement>();

  @Input() comite: Comite;
  @Input() phase: EnumPhaseComite;
  @Input() displayedColumns: string[] = [];
  @Input() programmes: Program[] = [];

  projets: ComiteProjet[];
  programmesFilter: FilterDataInterface[] = [];
  projetTableData: ProjetTableElement[] = [];

  constructor(
    public aapService: AapService,
    public showToastrService: ShowToastrService,
    public projetService: ProjetService,
    public router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.programmesFilter = this.programmes.map(program => {
      return {
        id: program.id,
        value: program.nom,
        isSelected: false,
      };
    });

    this.showSearchBar = true;
    this.initProjetsInTable();
  }

  goToComiteProjet(projet: ProjetLight): void {
    this.aapService
      .getAapById(projet.aapId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<Aap>) => this.redirectToNotation(projet.id, response.body.ilab),
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  redirectToNotation(projetId: string, ilab: boolean) {
    const ilabPath = ilab ? '-ilab' : '';
    const phaseToString = EnumPhaseComite.toString(this.phase).toLowerCase();
    const url = ['projets', projetId];

    if (this.phase === EnumPhaseComite.SELECTION) {
      url.push('projet-instruction', 'selection');
    } else {
      url.push(`projet-notation-${phaseToString}${ilabPath}`);
    }

    if (url.length > 0) {
      window.open(this.router.serializeUrl(this.router.createUrlTree(url)), '_blank');
    }
  }

  loadProjects(searchObject: SearchObjectComites): void {
    this.selection?.clear();
    searchObject.comiteId = this.comite?.id;
    searchObject.phase = this.phase;
    this.projetService
      .getProjetsComite(searchObject)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: projets => {
          this.setProjetsInTable(projets);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  private initProjetsInTable(): void {
    const projetsOfComiteInCurrentPhase = this.comite?.projetComites?.filter(p => p.phase === this.phase);
    if (!projetsOfComiteInCurrentPhase) {
      return;
    }
    this.setProjetsInTable(projetsOfComiteInCurrentPhase);
    this.projetTableData.forEach(p => this.selection.select(p));
  }

  setProjetsInTable(projets: ComiteProjet[]): void {
    this.projets = projets;
    this.projetTableData = ProjetUtils.convertProjetComiteToTableElement(this.projets);
  }
}
