import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferentielDocumentsAap, ReferentielHttpService, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';

@Component({
  selector: 'app-parametrage-documents-aap',
  templateUrl: './parametrage-documents-aap.component.html',
  styleUrls: ['./parametrage-documents-aap.component.scss'],
})
export class ParametrageDocumentsAapComponent extends SubscriptionDestroyerComponent implements OnInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  documentsAAP: ReferentielDocumentsAap[];

  modificationPath: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    private referentielService: ReferentielHttpService
  ) {
    super();
    this.modificationPath = this.route.snapshot.data.modificationPath;
  }

  ngOnInit(): void {
    this.loadDocumentsAAP();
  }

  loadDocumentsAAP(): void {
    this.referentielService
      .getReferentielDocumentsAAP()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<ReferentielDocumentsAap[]>) => {
          if (response) {
            this.documentsAAP = response.body;
            this.dataSource = new MatTableDataSource<ReferentielDocumentsAap>(this.documentsAAP);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  goToCreation(): void {
    this.router.navigate(['../' + this.route.snapshot.data.creationPath], { relativeTo: this.route });
  }

  goToReferentiels(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
