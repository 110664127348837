import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DepensesProjetConfig, SubscriptionDestroyerComponent } from '@shared-ui';

@Component({
  selector: 'pxl-depenses-projet-config',
  templateUrl: './depenses-projet-config.component.html',
  styleUrls: ['./depenses-projet-config.component.scss'],
})
export class DepensesProjetConfigComponent extends SubscriptionDestroyerComponent {
  @Input()
  set dataSource(data: MatTableDataSource<DepensesProjetConfig[]>) {
    this._dataSource = new MatTableDataSource(data.data);
  }
  get dataSource() {
    return this._dataSource;
  }

  @Input() modificationPathDepensesProjet: string | undefined;

  private _dataSource: MatTableDataSource<DepensesProjetConfig[]> | undefined;

  displayedColumnsTauxVersement: string[] = ['pourcentage', 'taux', 'versement', 'remboursement'];
  displayedColumnsAidePremierVersement: string[] = ['petite', 'moyenne', 'grande', 'labo'];

  constructor() {
    super();
  }
}
