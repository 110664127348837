import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoutingOriginService {
  isFromTransverse = false;

  setIsFromTransverse(value: boolean): void {
    this.isFromTransverse = value;
  }

  getIsFromTransverse(): boolean {
    return this.isFromTransverse;
  }
}
