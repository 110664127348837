import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { ParametrageDocumentsAapComponent } from './parametrage-documents-aap.component';
import { ListeDocumentsAapModule } from './liste-documents-aap/liste-documents-aap.module';

@NgModule({
  declarations: [ParametrageDocumentsAapComponent],
  imports: [CommonModule, SharedModule, SharedUiModule, MatTableModule, ListeDocumentsAapModule],
  exports: [ParametrageDocumentsAapComponent],
})
export class ParametrageDocumentsAapModule {}
