import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@shared-ui';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntervenantsRoleComponent } from './intervenants-role.component';

@NgModule({
  declarations: [IntervenantsRoleComponent],
  imports: [CommonModule, SharedUiModule, SharedModule, MatTableModule, MatSortModule, MatTooltipModule],
  exports: [IntervenantsRoleComponent],
})
export class IntervenantsRoleModule {}
