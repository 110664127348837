import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjetService } from '@services-evaluateur/projet.service';
import { DateSelectModalComponent } from '@shared-evaluateur/components/modals/date-select-modal/date-select-modal.component';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Aap,
  DemandeInterventionPI,
  EnumStatutInterventionPI,
  Projet,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';

@Component({
  selector: 'app-projet-notation-intervention-pi-tags',
  templateUrl: './projet-notation-intervention-pi-tags.component.html',
  styleUrls: ['./projet-notation-intervention-pi-tags.component.scss'],
})
export class ProjetNotationInterventionPiTagsComponent extends SubscriptionDestroyerComponent implements OnInit {
  showIntervention = false;
  interventionPiStatutColor: string;
  @Input() projet!: Projet;
  @Input() aap!: Aap;

  constructor(
    public sharedFunction: SharedFunction,
    public matDialog: MatDialog,
    public projetService: ProjetService,
    public showToastrService: ShowToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInterventionPiStatutColor();
  }

  setInterventionPiStatutColor(): void {
    if (this.projet?.interventionPI) {
      if (this.projet.interventionPI.statut === EnumStatutInterventionPI.ACCEPTEE) {
        this.interventionPiStatutColor = 'full-green';
      } else if (this.projet.interventionPI.statut === EnumStatutInterventionPI.BROUILLON) {
        this.interventionPiStatutColor = 'red';
      } else if (this.projet.interventionPI.statut === EnumStatutInterventionPI.MODIFICATION_DEMANDEE) {
        this.interventionPiStatutColor = 'orange';
      } else if (this.projet.interventionPI.statut === EnumStatutInterventionPI.PUBLIEE) {
        this.interventionPiStatutColor = 'green';
      }
    } else {
      this.interventionPiStatutColor = 'green';
    }
  }

  openInterventionModal() {
    const dialogRef = this.matDialog.open(DateSelectModalComponent, {
      data: {
        title: 'Intervention Juridique et Propriété Intellectuelle',
        placeholder: 'JJ/MM/AAAA', // Add this
        label: 'Date de rendu',
        description: `La DJPI sera notifiée par mail de votre demande d’expertise juridique et/ou PI. Veuillez sélectionner la date de rendu des recommandations souhaitée.`,
        textGoButton: 'Demander',
        textReturnButton: 'Annuler',
        labelWeight: '500',
        optionalDate: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      const demandeInterventionPI = new DemandeInterventionPI();
      if (result.date) {
        demandeInterventionPI.dateRendu = new Date(result.date);
      }
      this.updateDemandeInterventionPI(demandeInterventionPI);
    });
  }

  private updateDemandeInterventionPI(demandeInterventionPI: DemandeInterventionPI): void {
    this.projetService
      .updateDemandeInterventionPI(this.projet.id, demandeInterventionPI)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.getProjet();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  private getProjet(): void {
    this.projetService
      .getProjetById(this.projet.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: projetResult => {
          this.projet = projetResult.body;
          this.projetService.setProjetObservable(projetResult.body);
          this.showToastrService.success("La demande d'intervention a été envoyée avec succès");
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  protected readonly EnumStatutInterventionPI = EnumStatutInterventionPI;
}
