import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTimeUtils, EnumDateType } from '@shared-ui';

export interface ModalData {
  title: string;
  data: any;
  textReturnButton: string;
  textGoButton: string;
}

@Component({
  selector: 'pxl-modification-dates-modal',
  templateUrl: './modification-dates-modal.component.html',
  styleUrls: ['./modification-dates-modal.component.scss'],
})
export class ModificationDatesModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();
  modificationDateForm: UntypedFormGroup;

  get formValidationType(): UntypedFormControl {
    return this.modificationDateForm.get('type') as UntypedFormControl;
  }

  get formValidationNom(): UntypedFormControl {
    return this.modificationDateForm.get('nom') as UntypedFormControl;
  }

  get addDateForm(): UntypedFormControl {
    return this.modificationDateForm.get('date') as UntypedFormControl;
  }

  dayHours = DateTimeUtils.generateTimes(0, 24, 60);

  constructor(
    public dialogRef: MatDialogRef<ModificationDatesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.modificationDateForm = this.formBuilder.group({
      nom: [this.modalData.data.nom, Validators.required],
      type: [EnumDateType.RELEVE, Validators.required],
      date: [new Date(this.modalData.data.valeur), Validators.required],
      heure: [new Date(this.modalData.data.valeur).toLocaleTimeString().slice(0, 5), Validators.required],
    });
  }

  confirmerDate(formDirective: FormGroupDirective): void {
    this.modificationDateForm.markAllAsTouched();
    if (this.modificationDateForm.valid) {
      this.dialogRef.close({ dataForm: formDirective, data: this.modificationDateForm.value });
    }
  }

  cancelModal() {
    if (this.modificationDateForm.touched) {
      const cn = confirm('La saisie ne sera peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  protected readonly EnumDateType = EnumDateType;
}
