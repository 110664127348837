import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffectationIntervenantModalComponent } from './affectation-intervenant-modal.component';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { CustomToastModule } from '@shared-evaluateur/components/toastr/custom-toastr.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [AffectationIntervenantModalComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedModule, SharedUiModule, CustomToastModule],
})
export class AffectationIntervenantModalModule {}
