import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { IntervenantsDc } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable()
export class IntervenantsDcService {
  intervenantsDcUrl = environment.apiAdminUrl + 'ref/intervenant-dossier-commercial';

  constructor(private httpClient: HttpClient) {}

  getIntervenantsDC(): Observable<HttpResponse<IntervenantsDc[]>> {
    return this.httpClient.get<IntervenantsDc[]>(this.intervenantsDcUrl, { observe: 'response' });
  }
}
