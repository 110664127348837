import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EnumScope, Kpi } from '@shared-ui';

@Component({
  selector: 'pxl-grille-impact-tableau',
  templateUrl: './grille-impact-tableau.component.html',
  styleUrls: ['./grille-impact-tableau.component.scss'],
})
export class GrilleImpactTableauComponent implements OnChanges {
  @Input() dataSource: MatTableDataSource<Kpi>;
  @Input() selection = new SelectionModel<Kpi>(true, []);
  @Output() selectionChange = new EventEmitter<SelectionModel<Kpi>>();

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['select', 'titre', 'categorie', 'scope', 'clef'];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] && this.sort && this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'categorie':
            return item?.categorie?.titre;
          case 'scope':
            return item?.categorie?.scope;
          case 'clef': {
            const value = item[property as keyof Kpi];
            return parseInt((value as string).match(/\d+/g)[0], 10);
          }
          default: {
            const value = item[property as keyof Kpi];
            return typeof value === 'string' ? value?.toLocaleLowerCase().trim() : value.toString();
          }
        }
      };
      this.dataSource.sort = this.sort;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  protected readonly EnumScope = EnumScope;
}
