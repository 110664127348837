<div class="page-content">
  <a class="page-header__back" (click)="goToReferentiel()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Référentiel
  </a>
  <lib-card-title [title]="'Paramétrer le référentiel des ' + (isEvaluation ? 'critères d\'évaluation' : 'indicateurs d\'éligibilité')">
  </lib-card-title>
  <section class="page-section">
    <!-- Contenu de la page -->
    <div class="form-step" *ngIf="form">
      <div class="mb-4">
        <h4>{{ isModification ? "Modification d'un indicateur" : "Création d'un nouvel indicateur" }}</h4>
        Saisir tous les champs suivants pour créer votre critère. les champs avec
        <span class="ds-input-group__label-asterisk"> *</span> sont obligatoire.
      </div>

      <form class="form" [formGroup]="form" (ngSubmit)="saveReferentiel()">
        <div class="d-flex flex-wrap">
          <!-- Section label -->

          <div class="ds-input-group w-100" [class.ds-input-group--error]="!form.controls.label.valid && form.controls.label.touched">
            <label for="label" class="ds-input-group__label">
              {{ referentielName }} label
              <span class="ds-input-group__label-asterisk"> *</span>
            </label>
            <span class="ds-input-group__input">
              <input
                id="label"
                name="label"
                type="text"
                class="ds-input-group__input"
                formControlName="label"
                [placeholder]="referentielName + ' label'"
              />
            </span>
          </div>

          <!-- Fin section label -->

          <!-- Section aide visible -->

          <lib-radio-buttons
            class="w-50"
            [text]="referentielName + ' aide visible'"
            [isMandatory]="true"
            [group]="form"
            [options]="OPTIONS_YES_NO"
            controlName="aideVisible"
          >
          </lib-radio-buttons>

          <div class="ds-input-group w-50" [class.ds-input-group--error]="!form.controls.aide.valid && form.controls.aide.touched">
            <label for="aide" class="ds-input-group__label"> {{ referentielName }} aide (à renseigner si visible)</label>
            <span class="ds-input-group__input">
              <input
                id="aide"
                name="aide"
                type="text"
                class="ds-input-group__input"
                formControlName="aide"
                [placeholder]="referentielName + ' aide'"
              />
            </span>
          </div>

          <!-- Fin section aide visible -->

          <!-- Section note obligatoire -->

          <lib-radio-buttons
            class="w-100"
            [text]="referentielName + ' note obligatoire'"
            [isMandatory]="true"
            [group]="form"
            [options]="OPTIONS_YES_NO"
            controlName="noteObligatoire"
          >
          </lib-radio-buttons>

          <!-- Fin section note obligatoire -->

          <!-- Section aide visible -->

          <lib-radio-buttons
            class="w-50"
            [text]="referentielName + ' note aide visible'"
            [isMandatory]="true"
            [group]="form"
            [options]="OPTIONS_YES_NO"
            controlName="noteAideVisible"
          >
          </lib-radio-buttons>

          <div class="ds-input-group w-50" [class.ds-input-group--error]="!form.controls.noteAide.valid && form.controls.noteAide.touched">
            <label for="noteAide" class="ds-input-group__label"> {{ referentielName }} note aide (à renseigner si visible)</label>
            <span class="ds-input-group__input">
              <input
                id="noteAide"
                name="noteAide"
                type="text"
                class="ds-input-group__input"
                formControlName="noteAide"
                [placeholder]="referentielName + ' note aide'"
              />
            </span>
          </div>

          <!-- Fin section aide visible -->

          <!-- Section note commentaire -->

          <lib-radio-buttons
            class="w-50"
            [text]="referentielName + ' note commentaire'"
            [isMandatory]="true"
            [group]="form"
            [options]="OPTIONS_YES_NO"
            controlName="noteCommentaire"
          >
          </lib-radio-buttons>

          <div
            class="ds-input-group w-50"
            [class.ds-input-group--error]="
              !form.controls.noteCommentairePlaceholder.valid && form.controls.noteCommentairePlaceholder.touched
            "
          >
            <label for="noteCommentairePlaceholder" class="ds-input-group__label">
              {{ referentielName }} note commentaire placeholder (à renseigner si commentaire visible)
            </label>
            <span class="ds-input-group__input">
              <input
                id="noteCommentairePlaceholder"
                name="noteCommentairePlaceholder"
                type="text"
                class="ds-input-group__input"
                formControlName="noteCommentairePlaceholder"
                [placeholder]="referentielName + ' note commentaire placeholder'"
              />
            </span>
          </div>

          <!-- Fin section note commentaire -->
          <div class="w-100 d-flex">
            <ng-container *ngIf="!form.valid && submitClicked">
              <div class="ds-input-group ds-input-group--error w-100">
                <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
              </div>
            </ng-container>

            <div class="w-100 d-flex justify-content-end">
              <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="goToReferentiel()">
                <span>Annuler</span>
              </button>
              <button type="submit" class="ds-btn ds-btn--primary">
                <span>Enregistrer</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- FIN de Contenu de la page -->
  </section>
</div>
