<div id="page-container">
  <a class="page-header__back" (click)="goToEspace()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Retourner sur le board
  </a>
  <div class="container">
    <h1>
      <lib-pxl-icon class="icon" icon="icon-action-check-circle-outline"></lib-pxl-icon>
      Confirmation de votre connexion
    </h1>
    <ng-container *ngIf="requestSent; else requestNotSent">
      <p>
        Veuillez confirmer votre connexion à la plateforme en cliquant sur le lien vous ayant été envoyé par mail à l'adresse
        <span class="bold-text">{{ userEmail }}</span>
      </p>
    </ng-container>
    <ng-template #requestNotSent>
      <p>
        Vous devez valider votre email de connexion à la plateforme PICXEL. Veuillez cliquer le bouton ci-dessous pour recevoir un mail de
        vérification.
      </p>
    </ng-template>
  </div>

  <div class="center container">
    <div class="container span-container">
      <span *ngIf="requestSent">Pas de mail reçu ?</span>
    </div>
    <button class="ds-btn ds-btn--primary" [disabled]="!sendEmailButtonActive" (click)="sendConfirmation()">
      {{ requestSent ? 'Renvoyer le mail' : 'Vérifier mon compte' }}<lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
    </button>
    <div class="container span-container">
      <span *ngIf="!sendEmailButtonActive">Réessayez dans {{ secondsLeft }}</span>
    </div>
  </div>
</div>
