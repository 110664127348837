<div class="page-content">
  <a class="page-header__back" (click)="goToReferentiel()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Référentiel
  </a>
  <lib-card-title title="Paramétrer le référentiel des stratégies d'accélération (dispositifs)"> </lib-card-title>
  <section class="page-section">
    <!-- Contenu de la page -->
    <div class="form-step" *ngIf="form">
      <div class="mb-4">
        <h4>{{ isModification ? "Modification d'un dispositif" : "Création d'un nouveau dispositif" }}</h4>
        Saisir tous les champs suivants pour créer votre dispositif, les champs avec
        <span class="ds-input-group__label-asterisk"> *</span> sont obligatoires.
      </div>
      <form class="form" [formGroup]="form" (ngSubmit)="saveDispositif()">
        <div class="d-flex flex-wrap">
          <div class="ds-input-group w-50 p-2" [class.ds-input-group--error]="!form.controls.nom?.valid && form.controls.nom?.touched">
            <label for="nom" class="ds-input-group__label">Nom du dispositif</label>
            <span class="ds-input-group__input">
              <input
                id="nom"
                name="nom"
                type="text"
                class="ds-input-group__input"
                [maxlength]="MAXLENGTH"
                formControlName="nom"
                placeholder="Nom du dispositif"
                #nomDispositif
              />
            </span>
            <div class="form-input__length-hint" [class.form-input__length-hint--full]="nomDispositif.value?.length === 250">
              <span>{{ nomDispositif.value?.length || 0 }}/{{ MAXLENGTH }}</span>
            </div>
            <ng-container id="error-nom" *ngIf="!form.controls.nom?.valid && form.controls.nom?.touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom</div>
            </ng-container>
          </div>

          <div class="ds-input-group w-50 p-2" [class.ds-input-group--error]="!form.controls.code?.valid && form.controls.code?.touched">
            <label for="code" class="ds-input-group__label">Code du dispositif</label><span class="ds-input-group__label-asterisk"> *</span>
            <span class="ds-input-group__input">
              <input
                id="code"
                name="code"
                type="text"
                class="ds-input-group__input"
                [maxlength]="MAXLENGTH"
                formControlName="code"
                placeholder="Code du dispositif"
                #codeDispositif
              />
            </span>
            <div class="form-input__length-hint" [class.form-input__length-hint--full]="codeDispositif.value?.length === 250">
              <span>{{ codeDispositif.value?.length || 0 }}/{{ MAXLENGTH }}</span>
            </div>
            <ng-container id="error-code" *ngIf="!form.controls.code?.valid && form.controls.code?.touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un code</div>
            </ng-container>
          </div>

          <div
            class="date-time__date ds-input-group ds-input-group--date single-field-container w-50 p-2"
            [class.ds-input-group--error]="!form.controls.dateDebut?.valid && form.controls.dateDebut?.touched"
          >
            <label class="ds-input-group__label">Date de début</label> <span class="ds-input-group__label-asterisk"> *</span>
            <span class="ds-input-group__input">
              <input
                matInput
                [matDatepicker]="dateDebutDatePicker"
                formControlName="dateDebut"
                class="ds-input-group__input"
                placeholder="Date de début"
              />
              <mat-datepicker-toggle matSuffix [for]="dateDebutDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #dateDebutDatePicker></mat-datepicker>
            </span>
            <ng-container id="error-code" *ngIf="!form.controls.dateDebut?.valid && form.controls.dateDebut?.touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une date de début</div>
            </ng-container>
          </div>

          <div
            class="date-time__date ds-input-group ds-input-group--date single-field-container w-50 p-2"
            [class.ds-input-group--error]="!form.controls.dateFin?.valid && form.controls.dateFin?.touched"
          >
            <label class="ds-input-group__label">Date de fin</label> <span class="ds-input-group__label-asterisk"> *</span>
            <span class="ds-input-group__input">
              <input
                matInput
                [matDatepicker]="dateFinDatePicker"
                formControlName="dateFin"
                class="ds-input-group__input"
                placeholder="Date de fin"
              />
              <mat-datepicker-toggle matSuffix [for]="dateFinDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #dateFinDatePicker></mat-datepicker>
            </span>
            <ng-container id="error-code" *ngIf="!form.controls.dateFin?.valid && form.controls.dateFin?.touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une date de fin</div>
            </ng-container>
          </div>

          <lib-radio-buttons
            class="w-100"
            text="Statut du dispositif"
            [isMandatory]="true"
            [group]="form"
            [options]="OPTIONS_YES_NO"
            controlName="actif"
          ></lib-radio-buttons>

          <div class="w-100 d-flex">
            <ng-container *ngIf="!form.valid && submitClicked">
              <div class="ds-input-group ds-input-group--error w-100">
                <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
              </div>
            </ng-container>

            <div class="w-100 d-flex justify-content-end">
              <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="goToReferentiel()">
                <span>Annuler</span>
              </button>
              <button type="submit" class="ds-btn ds-btn--primary">
                <span>Enregistrer</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
