import { enumKeys } from '../../utils/enum-utils';

export enum EnumConvention {
  DEPLOIEMENT = 'DEPLOIEMENT',
  MATURATION = 'MATURATION',
  DEMONSTRATION = 'DEMONSTRATION',
  INDUSTRIALISATION = 'INDUSTRIALISATION',
  STRUCTUREL = 'STRUCTUREL',
  GRANDS_DEFIS = 'GRANDS_DEFIS',
}

export namespace EnumConvention {
  export function toString(convention: EnumConvention): string {
    switch (convention) {
      case EnumConvention.DEPLOIEMENT:
        return 'Déploiement';
      case EnumConvention.MATURATION:
        return 'Maturation';
      case EnumConvention.DEMONSTRATION:
        return 'Démonstration';
      case EnumConvention.INDUSTRIALISATION:
        return 'Industrialisation';
      case EnumConvention.STRUCTUREL:
        return 'Structurel';
      case EnumConvention.GRANDS_DEFIS:
        return 'Grands Défis';
      default:
        return '';
    }
  }

  export function all(): EnumConvention[] {
    return enumKeys(EnumConvention) as unknown as EnumConvention[];
  }
}
