import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@shared-ui';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ListeDocumentsAapComponent } from './liste-documents-aap.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [ListeDocumentsAapComponent],
  imports: [
    CommonModule,
    SharedUiModule,
    SharedModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  exports: [ListeDocumentsAapComponent],
})
export class ListeDocumentsAapModule {}
