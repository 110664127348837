import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EnumTypePartenaire } from '@shared-ui';

@Component({
  selector: 'project-structure',
  templateUrl: './project.structure.component.html',
  styleUrls: ['./project.structure.component.scss'],
})
export class ProjectStructureComponent {
  @Input() partenaireForm: UntypedFormGroup;
  @Input() stepNumber: number;

  protected readonly EnumTypePartenaire = EnumTypePartenaire;
}
