import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CentreMesure } from '@shared-ui';

export interface ModalData {
  title: string;
  drCalculated: CentreMesure;
  drdlList: CentreMesure[];
  drdlProject: CentreMesure;
  textReturnButton: string;
  textGoButton: string;
}

@Component({
  selector: 'pxl-modal',
  templateUrl: './direction-regionale-select-modal.component.html',
  styleUrls: ['./direction-regionale-select-modal.component.scss'],
})
export class DirectionRegionSelectModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();

  correctionForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DirectionRegionSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.correctionForm = this.formBuilder.group({
      drdlSelected: [this.modalData.drdlProject?.codeCentreMesure],
    });
    this.modalData.drdlList.sort((a, b) => (a.libCentreMesure > b.libCentreMesure ? 1 : -1));
  }

  sendUpdateDldr(): void {
    this.dialogRef.close(this.modalData.drdlList.filter(drdl => drdl.codeCentreMesure === this.correctionForm.value.drdlSelected)[0]);
  }
}
