import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ComitesDateReleve,
  DsMultiselectComponent,
  FilterDataInterface,
  FilterDatesReleves,
  FilterRsSiretInterface,
  SearchObject,
  SearchObjectComites,
  ShowToastrService,
} from '@shared-ui';
import { ProjetService } from '@services-evaluateur/projet.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MultiselectComponent } from '@shared-evaluateur/components/multiselect/multiselect.component';

@Component({
  selector: 'app-comite-projets-list-filter',
  templateUrl: './comite-projets-list-filter.component.html',
  styleUrls: ['./comite-projets-list-filter.component.scss'],
})
export class ComiteProjetsListFilterComponent {
  readonly ACRONYME = 'acronymes';
  readonly RS_SIRET = 'sirets';

  @Input() comiteId: string;
  @Input() comiteAaps: string[] = [];
  @Input() thematiqueDataList: FilterDataInterface[] = [];
  @Input() etapeDataList: FilterDataInterface[] = [];
  @Input() statutDataList: FilterDataInterface[] = [];
  @Input() centreMesureDataList: FilterDataInterface[] = [];
  @Input() regionDataList: FilterDataInterface[] = [];
  @Input() dateReleveDataList: FilterDataInterface[] = [];

  @ViewChild('acronymeComponent') acronymeComponent: MultiselectComponent;
  @ViewChild('rsSiretComponent') rsSiretComponent: MultiselectComponent;
  @ViewChild('thematiqueComponent') thematiqueComponent: DsMultiselectComponent;
  @ViewChild('etapeComponent') etapeComponent: DsMultiselectComponent;
  @ViewChild('statutComponent') statutComponent: DsMultiselectComponent;
  @ViewChild('regionAdministrativeComponent') regionAdministrativeComponent: DsMultiselectComponent;
  @ViewChild('drDlComponent') drDlComponent: DsMultiselectComponent;
  @ViewChild('dateReleveComponent') dateReleveComponent: DsMultiselectComponent;

  @Output() searchEventEmitter = new EventEmitter<SearchObjectComites>();

  acronymesSelectedList: FilterDataInterface[] = [];
  rsSiretSelectedList: FilterDataInterface[] = [];
  acronymesDataList: FilterDataInterface[] = [];
  rsSiretDataList: FilterDataInterface[] = [];

  loadingMap = new Map();
  accordionOpen = false;

  constructor(public projetService: ProjetService, public toastrService: ShowToastrService) {
    this.loadingMap.set(this.ACRONYME, false);
    this.loadingMap.set(this.RS_SIRET, false);
  }

  /**
   * Récupère l'input du composant fils, lance la recherche d'autocomplete, ensuite actualise la liste des 10 options
   * @param event Event envoyé par le composant fils de filtre, soit le texte écrit dans l'input
   * @param source Le nom du composant fils qui a emit cet event
   */
  autocompleteEvent(event: string, source: string): void {
    this.loadingMap.set(source, true);
    const searchObject = this.getAutocompleteFilter(source, event);

    this.projetService.getAutocomplete(source, searchObject).subscribe({
      next: resultAutocomplete => {
        switch (source) {
          case this.ACRONYME:
            this.acronymesDataList = (resultAutocomplete.body as string[]).map(acronyme => ({ id: acronyme, value: acronyme }));
            break;
          case this.RS_SIRET:
            this.rsSiretDataList = (resultAutocomplete.body as FilterRsSiretInterface[]).map(rsSiret => ({
              id: rsSiret,
              value: (rsSiret.siret != null && rsSiret.siret?.trim() !== '' ? rsSiret.siret + ' - ' : '') + rsSiret.raisonSocial,
            }));
            break;
        }
        this.loadingMap.set(source, false);
      },
      error: (err: HttpErrorResponse) => {
        this.toastrService.checkCodeError(err?.error);
        this.loadingMap.set(source, false);
      },
    });
  }

  selectedListChange(event: FilterDataInterface[], source: string): void {
    switch (source) {
      case this.ACRONYME:
        this.acronymesSelectedList = event;
        break;
      case this.RS_SIRET:
        this.rsSiretSelectedList = event;
        break;
    }
  }

  getAutocompleteFilter(fieldSource: string, autoCompleteValue: string): SearchObject {
    const autocompleteFilter = new SearchObject();
    autocompleteFilter.comiteId = this.comiteId;
    switch (fieldSource) {
      case this.ACRONYME:
        autocompleteFilter.inputAcronyme = autoCompleteValue;
        break;
      case this.RS_SIRET:
        autocompleteFilter.idAAPs = this.comiteAaps;
        autocompleteFilter.inputSiretRS = autoCompleteValue;
        break;
    }

    return autocompleteFilter;
  }

  get comiteFilterObject(): SearchObjectComites {
    const searchObjectComites = new SearchObjectComites();
    searchObjectComites.comiteId = this.comiteId;
    searchObjectComites.acronymes = this.acronymesSelectedList.map(acronyme => acronyme.id as string);
    searchObjectComites.siretRaisonSociale = this.rsSiretSelectedList.map(rs => ({
      siret: (rs.id as FilterRsSiretInterface).siret,
      raisonSocial: (rs.id as FilterRsSiretInterface).raisonSocial,
    }));
    searchObjectComites.thematiques = this.thematiqueComponent.getSelectedValues().map(thematique => thematique.value);
    searchObjectComites.filtreRelevesAap = this.selectedDatesReleves;
    searchObjectComites.etapes = this.etapeComponent.getSelectedValues().map(etape => etape.id as string);
    searchObjectComites.statuts = this.statutComponent.getSelectedValues().map(statut => statut.id as string);
    searchObjectComites.regionsBpifrance = this.regionAdministrativeComponent.getSelectedValues().map(region => region.value);
    searchObjectComites.directionsRegionales = this.drDlComponent.getSelectedValues().map(drDl => drDl.value);

    return searchObjectComites;
  }

  submitSearch(): void {
    this.searchEventEmitter.emit(this.comiteFilterObject);
  }

  resetAllFilters(): void {
    this.acronymeComponent.reset();
    this.rsSiretComponent.reset();
    this.thematiqueComponent.reset();
    this.dateReleveComponent.reset();
    this.etapeComponent.reset();
    this.statutComponent.reset();
    this.drDlComponent.reset();
    this.regionAdministrativeComponent.reset();
  }

  get selectedDatesReleves(): ComitesDateReleve[] {
    return (
      this.dateReleveComponent
        ?.getSelectedValues()
        .map(filter => <FilterDatesReleves>filter.id)
        .map(filterDatesReleves => {
          return filterDatesReleves.datesReleves.map(dateReleve => {
            return <ComitesDateReleve>{
              dateReleve: dateReleve,
              idAap: filterDatesReleves.idAap,
            };
          });
        })
        .reduce((accumulator, currentArray) => {
          return accumulator.concat(currentArray);
        }, []) ?? []
    );
  }

  toggleFilters(): void {
    this.accordionOpen = !this.accordionOpen;
  }
}
