import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParcoursCreationComponent } from './parcours-creation.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { StepTypePartenaireModule } from './steps/step-type-partenaire/step-type-partenaire.module';
import { StepContactModule } from './steps/step-contact/step-contact.module';
import { SharedUiModule } from '@shared-ui';
import { StepChoixRoleModule } from './steps/step-choix-role/step-choix-role.module';
import { StepIdentificationStructureModule } from '@features-evaluateur/projet-parcours/component/parcours-creation/steps/step-identification-structure/step-identification-structure.module';
import { StepInformationStructureModule } from '@features-evaluateur/projet-parcours/component/parcours-creation/steps/step-information-structure/step-information-structure.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SharedUiModule,
    MaterialModule,
    StepTypePartenaireModule,
    StepIdentificationStructureModule,
    StepInformationStructureModule,
    StepContactModule,
    StepChoixRoleModule,
  ],
  declarations: [ParcoursCreationComponent],
  exports: [ParcoursCreationComponent],
  providers: [],
})
export class ParcoursCreationModule {}
