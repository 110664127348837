<div class="page-content page-content--no-scroll list-page">
  <div class="list-page__header">
    <h1 class="list-page__title">Programmes</h1>

    <button
      type="button"
      id="program_list_btn_create"
      class="list-page__create-button ds-btn ds-btn--secondary"
      (click)="createProg()"
      *ngxPermissionsOnly="['PARAMETRAGE_WRITE']"
    >
      <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
      Nouveau programme
    </button>
  </div>

  <div class="table">
    <table mat-table id="program_list_table" [dataSource]="dataSource" matSort matSortActive="dateCreation" matSortDirection="desc">
      <caption>
        Liste des programmes
      </caption>
      <ng-container matColumnDef="nom" class="">
        <th id="programme-name" mat-header-cell *matHeaderCellDef mat-sort-header>Nom du programme</th>
        <td id="nom" mat-cell *matCellDef="let element">
          <strong>{{ element.nom }}</strong>
        </td>
      </ng-container>
      <ng-container matColumnDef="typeFront">
        <th id="programme-type" mat-header-cell *matHeaderCellDef mat-sort-header>Type du programme</th>
        <td id="type" mat-cell *matCellDef="let element">
          {{ element.typeFront }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateCreation">
        <th id="programme-creation-date" mat-header-cell *matHeaderCellDef mat-sort-header="dateCreation">Date de création</th>
        <td id="date-creation" mat-cell *matCellDef="let element">
          <span *ngIf="element.dateCreation">
            {{ element.dateCreation | date : 'dd/MM/yyyy' }}
          </span>
          <span *ngIf="!element.dateCreation"> - </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="aaps">
        <th id="programme-aap-number" mat-header-cell *matHeaderCellDef mat-sort-header>Nbr AAP</th>
        <td id="aaps-length" mat-cell *matCellDef="let element" [class.warning]="element.aaps.length === 0">{{ element.aaps.length }}</td>
      </ng-container>
      <ng-container matColumnDef="statut">
        <th id="programme-statut" mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
        <td mat-cell *matCellDef="let element">
          <span id="statut" class="table__status">{{ EnumAapStatut.toString(element.statut) }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="modifier">
        <th id="programme-update" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            id="update-programme"
            class="table__button"
            matTooltip="Modifier"
            (click)="updateProg(element)"
            *ngxPermissionsOnly="['PARAMETRAGE_WRITE']"
          >
            <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="r" *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div id="error-response" class="error" *ngIf="errorResponse">Une erreur est survenue : {{ errorResponse }}</div>
</div>
