<!-- Entête de la page -->
<lib-page-header
  *ngIf="comite"
  [title]="comite.nom !== '' ? comite.nom : 'Comité'"
  [subTitle]="EnumPhaseComite.toString(comite.type)"
  [goBackUrl]="'../comites'"
  [goBackLabel]="'Retourner sur la liste de comités'"
>
  <div class="list-page__dropdowns align-items-center" right-elements>
    <ng-container *ngIf="canUserEditComite">
      <button
        type="button"
        class="list-page__create-button ds-btn ds-btn--tertiary"
        (click)="closeComite()"
        *ngIf="!isComiteClosed() && !isComiteBrouillon()"
      >
        Clôturer ce comité
      </button>
      <button type="button" class="list-page__create-button ds-btn ds-btn--secondary ms-3" (click)="editComite()">
        <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
        Modifier ce comité
      </button>
    </ng-container>

    <div>
      <button id="actions-btn" class="ds-btn ds-btn--primary ms-3" placement="bottom-right" [ngbPopover]="popContent">
        Actions
        <lib-pxl-icon class="icon" icon="icon-arrow-expand-more" aria-hidden="true"></lib-pxl-icon>
      </button>
      <ng-template #popContent>
        <div class="popover_container">
          <ng-container *ngIf="canUserEditComite">
            <span class="popover_title">Comités</span>
            <div class="popover_link">
              <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
              <a (click)="addDateEnvoi()">Ajouter la date d'envoi</a>
            </div>
            <div class="popover_link">
              <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
              <a (click)="addDecision()" class="date-decision-label">Ajouter la décision et la date de validation</a>
            </div>
          </ng-container>
          <span class="popover_title">Extractions</span>
          <div class="popover_link">
            <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
            <a (click)="exportPvEvaluation()">Exporter le PV de présélection</a>
          </div>
          <div class="popover_link">
            <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
            <a (click)="exportPvAudition()">Exporter le PV d'audition</a>
          </div>
          <div class="popover_link">
            <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
            <a (click)="exportPvSelection()">Exporter le PV de selection</a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</lib-page-header>
<!-- Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <div class="filter-container">
    <app-comite-projets-list-filter
      *ngIf="filterIsReady"
      #filterComponent
      [comiteId]="comiteId"
      [thematiqueDataList]="thematiqueDataList"
      [etapeDataList]="etapeDataList"
      [statutDataList]="statutDataList"
      [centreMesureDataList]="centreMesureDataList"
      [regionDataList]="regionDataList"
      [dateReleveDataList]="dateReleveDataList"
      [comiteAaps]="comiteAaps"
      (searchEventEmitter)="searchEventCalled($event)"
    ></app-comite-projets-list-filter>
  </div>

  <div class="mt-3">
    <mat-accordion>
      <lib-expansion-panel
        [title]="'Présélection'"
        [subTitle]="preselectionProjetTableData.length + ' projets sélectionnés pour une décision en présélection'"
      >
        <div panel-content>
          <div class="table-container">
            <pxl-tableau-projet
              [(selection)]="selectedProjetsEvaluation"
              [displayedColumns]="displayedColumns"
              [(data)]="preselectionProjetTableData"
              (elementClicked)="goToComiteProjet($event, EnumPhaseComite.EVALUATION)"
            >
            </pxl-tableau-projet>
          </div>
        </div>
      </lib-expansion-panel>
    </mat-accordion>
  </div>

  <div class="mt-3">
    <mat-accordion>
      <lib-expansion-panel
        [title]="'Audition'"
        [subTitle]="auditionProjetTableData.length + ' projets sélectionnés pour une décision en audition'"
      >
        <div panel-content>
          <div class="table-container">
            <pxl-tableau-projet
              [(selection)]="selectedProjetsAudition"
              [displayedColumns]="displayedColumns"
              [(data)]="auditionProjetTableData"
              (elementClicked)="goToComiteProjet($event, EnumPhaseComite.AUDITION)"
            >
            </pxl-tableau-projet>
          </div>
        </div>
      </lib-expansion-panel>
    </mat-accordion>
  </div>

  <div class="mt-3">
    <mat-accordion>
      <lib-expansion-panel
        [title]="'Sélection'"
        [subTitle]="selectionProjetTableData.length + ' projets sélectionnés pour une décision en sélection'"
      >
        <div panel-content>
          <div class="table-container">
            <pxl-tableau-projet
              [(selection)]="selectedProjetsSelection"
              [displayedColumns]="displayedColumns"
              [(data)]="selectionProjetTableData"
              (elementClicked)="goToComiteProjet($event, EnumPhaseComite.SELECTION)"
            >
            </pxl-tableau-projet>
          </div>
        </div>
      </lib-expansion-panel>
    </mat-accordion>
  </div>
</div>
