import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComitesComponent } from './comites.component';
import { ComitesListComponent } from './comites-list/comites.list.component';
import { ComitesCreationComponent } from './comites-creation/comites-creation.component';
import { CanDeactivateGuard } from '../../guards/can-deactivate-guard';
import { FeatureFlippingGuard } from '@core-evaluateur/feature-flipping.guard';
import { EnumFeatureFlipping } from '@shared-ui';
import { ComiteProjetsListComponent } from './comites-list/comite-projets-list/comite-projets-list.component';

export const ComitesRoutes: Routes = [
  {
    path: 'comites',
    component: ComitesComponent,
    canActivate: [FeatureFlippingGuard],
    data: { featureName: EnumFeatureFlipping.COMITES },
    children: [
      {
        path: '',
        component: ComitesListComponent,
      },
      {
        path: 'creation',
        component: ComitesCreationComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'modification/:id',
        component: ComitesCreationComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: ':id',
        component: ComiteProjetsListComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ComitesRoutes)],
  exports: [RouterModule],
})
export class ComitesRoutingModule {}
