import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectionRegionSelectModalComponent } from './direction-regionale-select-modal.component';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared-evaluateur/shared.module';

@NgModule({
  declarations: [DirectionRegionSelectModalComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedModule],
})
export class DirectionRegionSelectModalModule {}
