import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Aap, EnumMotifNonRattachement, EnumRoleStructure, EnumTypeStructure, siretValidator, Structure } from '@shared-ui';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pxl-informations-structure',
  templateUrl: './informations-structure.component.html',
  styleUrls: ['./informations-structure.component.scss'],
})
export class InformationsStructureComponent implements OnInit, OnDestroy {
  @Input() aap: Aap = new Aap();
  @Input() structure: Structure = new Structure();
  @Input() roleSelectable = false;
  @Output() formChanged = new EventEmitter<Partial<Structure>>();
  @Input() disableForm: Observable<boolean>;
  @Input() fomSubmited = false;
  @Input() updateForm: Observable<Partial<Structure>>;
  @Output() structureDirty = new EventEmitter<boolean>();

  private subscriptions: Subscription[] = [];

  structureForm: UntypedFormGroup;
  lengthSiret = 17;
  structureEnCreation = false;
  typeStructureLastValue: string;

  constructor(private formBuilder: UntypedFormBuilder, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.createForms();
    this.handleFormControlsStatus();
    this.parseSiret();

    this.subscriptions.push(
      this.structureForm.valueChanges.subscribe(() => {
        if (this.structureForm.enabled) {
          this.formChanged.next(this.structureForm.getRawValue());
        }
        this.structureDirty.emit(this.structureForm.dirty);
      })
    );
    if (this.disableForm) {
      this.subscriptions.push(
        this.disableForm.subscribe(status => {
          this.changeFormStatus(status);
        })
      );
    }
    if (this.updateForm) {
      this.updateFormValues();
    }
  }

  updateFormValues(): void {
    this.subscriptions.push(
      this.updateForm.subscribe((structure: Partial<Structure>) => {
        if (structure) {
          this.structureForm.patchValue({
            typeStructure: structure?.typeStructure,
            siret: structure?.siret
              ?.replace(/\s/g, '')
              .replace(/.{13}(?=.)/, m => m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& '))),
            raisonSiret: structure?.raisonSiret,
            raisonSocial: structure?.raisonSocial,
          });
        }
      })
    );
  }

  changeFormStatus(value: boolean): void {
    if (value) {
      this.structureForm.disable();
    } else {
      this.structureForm.enable();
      this.handleFormControlsStatus();
    }
  }

  createForms(): void {
    this.structureForm = this.formBuilder.group({
      typeStructure: [this.structure.typeStructure, Validators.required],
      siret: [this.structure.siret, [Validators.required, siretValidator()]],
      raisonSiret: [this.structure.raisonSiret, Validators.required],
      raisonSocial: [this.structure.raisonSocial, Validators.required],
      role: [this.structure.role, Validators.required],
    });
  }

  handleFormControlsStatus(): void {
    if (this.structureForm.controls['raisonSiret'].value) {
      this.structureForm.controls['siret'].disable();
      this.structureEnCreation = this.structureForm.controls['raisonSiret'].value === EnumMotifNonRattachement.EN_COURS_DE_CREATION;
      if (this.structureEnCreation) {
        this.structureForm.controls['typeStructure'].reset();
        this.structureForm.controls['typeStructure'].disable();
      }
    } else if (this.structureForm.controls['siret'].value) {
      this.structureForm.controls['raisonSiret'].disable();
    }
  }

  /*
   * Reformat le siret pour ecriture
   * */
  writeSiret(): void {
    if (this.structureForm.value.siret) {
      this.structureForm.patchValue({
        siret: this.structureForm.value.siret.replace(/\s/g, ''),
      });
    }
  }

  onKeyPressSiret(): void {
    this.lengthSiret = 14;
  }

  /*
   * Reformat le Siret affiché
   * */
  parseSiret(): void {
    if (this.structureForm.value.siret) {
      this.structureForm.patchValue({
        siret: this.structureForm.value.siret.replace(/\s/g, ''),
      });
      if (this.structureForm.value.siret.length === 14) {
        this.lengthSiret = 17;
        this.structureForm.patchValue({
          siret: this.structureForm.value.siret.replace(/.{13}(?=.)/, (m: string) =>
            m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& '))
          ),
        });
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  disableSiret(event: any): void {
    if (event.target.value === '') {
      this.structureForm.controls['raisonSiret'].enable();
    }

    if (event.target.value !== '') {
      this.structureForm.controls['raisonSiret'].disable();
    }
  }

  selectRaison(event: any): void {
    const raisonSiret = this.structureForm.controls['raisonSiret'].value;
    this.structureEnCreation = event.target.value === EnumMotifNonRattachement.EN_COURS_DE_CREATION;
    if (raisonSiret === event.target.value) {
      this.resetDisabledControls();
    } else {
      this.disabledUnfillableControls();
    }
  }

  resetDisabledControls(): void {
    this.structureForm.controls['raisonSiret'].reset();
    this.structureForm.controls['siret'].enable();
    this.structureForm.controls['typeStructure'].enable();
    if (this.typeStructureLastValue) {
      this.structureForm.patchValue({ typeStructure: this.typeStructureLastValue });
    }
  }

  disabledUnfillableControls(): void {
    this.structureForm.controls['siret'].reset();
    this.structureForm.controls['siret'].disable();
    if (this.structureEnCreation) {
      this.typeStructureLastValue = this.structureForm.controls['typeStructure'].value;
      this.structureForm.controls['typeStructure'].reset();
      this.structureForm.controls['typeStructure'].disable();
    } else {
      this.structureForm.controls['typeStructure'].enable();
      if (this.typeStructureLastValue) {
        this.structureForm.patchValue({ typeStructure: this.typeStructureLastValue });
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }

  protected readonly EnumTypeStructure = EnumTypeStructure;
  protected readonly EnumRoleStructure = EnumRoleStructure;
}
