import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { ParametrageReferentielComponent } from './parametrage-referentiel.component';
import { ReferentielCriteresTableauModule } from './referentiel-criteres-tableau/referentiel-criteres-tableau.module';

@NgModule({
  declarations: [ParametrageReferentielComponent],
  imports: [CommonModule, SharedModule, SharedUiModule, MatTableModule, ReferentielCriteresTableauModule],
  exports: [ParametrageReferentielComponent],
})
export class ParametrageReferentielModule {}
