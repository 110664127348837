import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjetParcoursComponent } from './projet-parcours.component';
import { StepperProjetModule } from './component/stepper-parcours/stepper.projet.module';
import { ParcoursCreationModule } from './component/parcours-creation/parcours-creation.module';
import { ConfirmModalModule } from '@shared-evaluateur/components/modals/confirm-modal/confirm-modal.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { MatDividerModule } from '@angular/material/divider';
import { FlexModule } from '@angular/flex-layout';
import { SharedModule } from '@shared-evaluateur/shared.module';

@NgModule({
  declarations: [ProjetParcoursComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ConfirmModalModule,
    MatDividerModule,
    FlexModule,
    SharedModule,
    StepperProjetModule,
    ParcoursCreationModule,
  ],
})
export class ProjetParcoursModule {}
