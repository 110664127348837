import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '@environments-evaluateur/environment';
import { Aap } from '@shared-ui';

@Injectable()
export class AapService {
  apiUrl = environment.apiUrl;
  evalApiUrl = environment.apiUrl + 'evaluateurs/';
  adminApiUrl = environment.apiAdminUrl;

  aapByIdSubject: Observable<HttpResponse<Aap>> = new Observable();

  lastStoredAapId: string;

  constructor(private httpClient: HttpClient) {}

  getAaps(asMembreOrganisme?: boolean): Observable<HttpResponse<Aap[]>> {
    return this.httpClient.get<Aap[]>(this.evalApiUrl + 'aaps' + (asMembreOrganisme ? `?asMembreOrganisme=${asMembreOrganisme}` : ''), {
      observe: 'response',
    });
  }

  getAapById(id: string, forceRefresh?: boolean): Observable<HttpResponse<Aap>> {
    if (this.lastStoredAapId !== id || forceRefresh) {
      this.aapByIdSubject = this.httpClient
        .get<Aap>(environment.apiUrl + `aaps/${id}`, { observe: 'response' })
        .pipe(shareReplay({ refCount: true }));
      this.lastStoredAapId = id;
    }

    return this.loadAapSubject();
  }

  getAapsByProgrammesId(programmesId: string[]): Observable<HttpResponse<Aap[]>> {
    const params = new HttpParams().appendAll({ programmesId });
    return this.httpClient.get<Aap[]>(`${this.adminApiUrl}aaps`, { params, observe: 'response' });
  }

  createAap(aap: Aap): Observable<HttpResponse<Aap>> {
    return this.httpClient.post<Aap>(this.adminApiUrl + 'aaps', JSON.stringify(aap), { observe: 'response' });
  }

  updateAap(aap: Aap): Observable<HttpResponse<Aap>> {
    return this.httpClient.put<Aap>(this.adminApiUrl + 'aaps/', JSON.stringify(aap), { observe: 'response' });
  }

  public loadAapSubject(): Observable<HttpResponse<Aap>> {
    return this.aapByIdSubject;
  }
}
