<div class="tableau-title">
  <div>Référentiel des documents AAP</div>
</div>

<div class="table">
  <table mat-table [dataSource]="dataSource" aria-describedby="tableau de référentiel des documents AAP">
    <ng-container matColumnDef="actions">
      <th id="documents-aap-actions" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          id="update-documents-aap"
          class="table__button"
          matTooltip="Modifier documents AAP"
          (click)="updateDocument(element); $event.stopPropagation()"
        >
          <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="scope" class="">
      <th id="document-localisation" mat-header-cell *matHeaderCellDef>Scope</th>
      <td id="localisation" mat-cell *matCellDef="let element">
        <span>{{ EnumScope.toString(element.scope) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="disponible" class="">
      <th id="document-disponible" mat-header-cell *matHeaderCellDef>Disponibilité des documents sur l'AAP</th>
      <td id="disponible" mat-cell *matCellDef="let element">
        <mat-checkbox [checked]="element.disponibiliteAap" disabled> </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="depot" class="">
      <th id="document-depot" mat-header-cell *matHeaderCellDef>Phase</th>
      <td id="depot" mat-cell *matCellDef="let element">
        <span>{{ formatTypeDepot(element.etapes) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="nom" class="">
      <th id="intervenant-matricule" mat-header-cell *matHeaderCellDef>Nom du type de document</th>
      <td id="matricule" mat-cell *matCellDef="let element">
        <span>{{ element.nom }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="obligatoire" class="">
      <th id="document-obligatoire" mat-header-cell *matHeaderCellDef>Document obligatoire</th>
      <td id="obligatoire" mat-cell *matCellDef="let element">
        <mat-checkbox [checked]="element.champsObligatoire" disabled> </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="codeGed" class="">
      <th id="codeGed" mat-header-cell *matHeaderCellDef>Code GED</th>
      <td id="ged" mat-cell *matCellDef="let element">
        <span>{{ element.codeGed }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="habilitationWKF1" class="">
      <th [matTooltip]="tootltipWKF" id="document-habilitation-WKF1" mat-header-cell *matHeaderCellDef>
        Niveau d'habilit. WKF1 - Pré-dépôt + dépôt
      </th>
      <td id="habilitation-WKF1" mat-cell *matCellDef="let element">
        <span>{{ formatHabilitation(element.habilitationWKF1) }}</span>
      </td>
    </ng-container>

    <ng-container [matTooltip]="tootltipWKF" matColumnDef="habilitationWKF2" class="">
      <th id="document-habilitation-WKF2" mat-header-cell *matHeaderCellDef>Niveau d'habilit. WKF2 - Dépôt unique</th>
      <td id="habilitation-WKF2" mat-cell *matCellDef="let element">
        <span>{{ formatHabilitation(element.habilitationWKF2) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="structureProjet" class="">
      <th id="structure-projet" mat-header-cell *matHeaderCellDef>Structure projet</th>
      <td id="structure" mat-cell *matCellDef="let element">
        <span>{{ formatStructureprojet(element.typePartenaires) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="roles" class="">
      <th id="roles-projet" mat-header-cell *matHeaderCellDef>Rôles dans le Projet</th>
      <td id="roles" mat-cell *matCellDef="let element">
        <span>{{ formatRoles(element.roleStructures) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="type" class="">
      <th id="document-type-structure" mat-header-cell *matHeaderCellDef>Type(s) de structure(s)</th>
      <td id="type-structure" mat-cell *matCellDef="let element">
        <span>{{ formatTypesStructure(element.typeStructures) }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
  </table>
</div>
<div class="no-result" *ngIf="dataSource?.data?.length === 0">
  <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
  <span id="large-span-result">Il n'y a rien ici...</span>
  <span id="small-span-result">0 documents AAP répertorié.</span>
</div>
