import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DocumentProjet,
  EnumHabilitation,
  EnumProjetEtape,
  EnumRoleStructure,
  EnumScope,
  EnumTypePartenaire,
  EnumTypeStructure,
  ReferentielDocumentsAap,
  SubscriptionDestroyerComponent,
} from '@shared-ui';

@Component({
  selector: 'pxl-liste-documents-aap',
  templateUrl: './liste-documents-aap.component.html',
  styleUrls: ['./liste-documents-aap.component.scss'],
})
export class ListeDocumentsAapComponent extends SubscriptionDestroyerComponent {
  @Input()
  set dataSource(data: MatTableDataSource<ReferentielDocumentsAap>) {
    this._dataSource = data;
  }

  get dataSource() {
    return this._dataSource;
  }

  @Input() modificationPath: string;

  private _dataSource: MatTableDataSource<ReferentielDocumentsAap> | undefined;

  tootltipWKF =
    '# Evaluateurs: ministère et indépendant\n# Opérateurs associés: ADEME, SDE et Cabinet externe\n# Opérateurs associés instructeurs: ADEME et Cabinet externe';

  displayedColumns: string[] = [
    'scope',
    'disponible',
    'depot',
    'nom',
    'obligatoire',
    'codeGed',
    'habilitationWKF1',
    'habilitationWKF2',
    'structureProjet',
    'roles',
    'type',
    'actions',
  ];

  constructor(private router: Router, private route: ActivatedRoute) {
    super();
  }

  formatTypesStructure(typesStructure: EnumTypeStructure[]) {
    return (typesStructure || []).map(type => EnumTypeStructure.toString(type)).join(', ');
  }

  formatStructureprojet(typePartenaire: EnumTypePartenaire[]) {
    return (typePartenaire || []).map(partenaires => EnumTypePartenaire.toString(partenaires)).join(', ');
  }

  formatRoles(role: EnumRoleStructure[]) {
    return (role || []).map(roles => EnumRoleStructure.toString(roles)).join(', ');
  }

  formatTypeDepot(etapes: EnumProjetEtape[]) {
    if (etapes.includes(EnumProjetEtape.PRE_DEPOT)) {
      return 'Pré-dépôt';
    } else {
      return 'Dépôt';
    }
  }

  formatHabilitation(habilitation: string) {
    switch (habilitation) {
      case EnumHabilitation.H1:
        return 'Document accessible à Bpifrance, au Superviseur AAP, Evaluateurs, aux Opérateurs associés';
      case EnumHabilitation.H2:
        return 'Document accessible à Bpifrance, aux Opérateurs associés instructeur ';
      case EnumHabilitation.H3:
        return 'Document accessible à à Bpifrance, au Superviseur AAP, Evaluateur ministère, aux Opérateurs associés';
      default:
        return 'Document accessible par tous';
    }
  }

  updateDocument(document: DocumentProjet): void {
    this.router.navigate([`../${this.modificationPath}/${document.id}`], { relativeTo: this.route });
  }

  protected readonly EnumScope = EnumScope;
}
