import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@environments-evaluateur/environment';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { EnumFeatureFlipping, EnumPermissionUtilisateur, PermissionUtils, Utilisateur } from '@shared-ui';

@Component({
  selector: 'pxl-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  user!: Utilisateur;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(public sharedFunction: SharedFunction, private utilisateurService: UtilisateurService) {}

  ngOnInit(): void {
    this.utilisateurService
      .getUtilisateurObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => (this.user = user));
  }

  goToQlik() {
    const url = environment.urlQlik;
    window.open(url, '_blank');
  }

  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
  protected readonly EnumPermissionUtilisateur = EnumPermissionUtilisateur;
  protected readonly PermissionUtils = PermissionUtils;
}
