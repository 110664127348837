import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { Aap, EnumActivite, EnumFeatureFlipping, FeatureFlagService, Projet } from '@shared-ui';

export interface ModalData {
  title: string;
  projectList: Projet[];
  aap: Aap;
  isUserDar: boolean;
  textReturnButton: string;
  textGoButton: string;
  notationEvaluationPresent: boolean;
}

@Component({
  selector: 'pxl-auto-affect-modal',
  templateUrl: './auto-affectation-modal.component.html',
  styleUrls: ['./auto-affectation-modal.component.scss'],
})
export class AutoAffectationModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();

  selectedProjects: Projet[];
  autoAffectForm: UntypedFormGroup;
  activites: EnumActivite[] = [];
  notationEvaluationPresent: boolean;
  showIntervenantPI = false;

  get activitesFormArray() {
    return this.autoAffectForm.controls.activites as FormArray;
  }

  constructor(
    public dialogRef: MatDialogRef<AutoAffectationModalComponent>,
    private formBuilder: UntypedFormBuilder,
    public sharedFunction: SharedFunction,
    private featureFlagService: FeatureFlagService,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData
  ) {}

  ngOnInit(): void {
    if (this.modalData.projectList) {
      this.selectedProjects = this.modalData.projectList;
    }
    this.notationEvaluationPresent = this.modalData?.notationEvaluationPresent;

    this.createForm();

    this.sharedFunction
      .getAuthorizedActivities(this.notationEvaluationPresent, this.modalData.isUserDar, this.featureFlagService)
      .forEach(activite => {
        if (
          (activite === EnumActivite.ELIGIBILITE && !this.modalData.aap.eligibilitePresent) ||
          (activite === EnumActivite.AUDITION && !this.modalData.aap.notationAuditionPresent) ||
          (activite === EnumActivite.CONTRACTUALISATION && !this.modalData.aap.contractualisationPresent) ||
          (activite === EnumActivite.INSTRUCTION && !this.modalData.aap.instructionPresent)
        ) {
          return;
        }
        this.activites.push(activite);
        this.activitesFormArray.push(new FormControl(false));
      });

    this.showIntervenantPI = this.activites.includes(EnumActivite.INSTRUCTION);
  }

  createForm(): void {
    this.autoAffectForm = this.formBuilder.group({
      activites: new FormArray([], minSelectedCheckboxes(1)),
      referent: [false, [Validators.required]],
      intervenantPI: [{ value: false, disabled: true }, [Validators.required]],
    });

    this.autoAffectForm.get('activites').valueChanges.subscribe((activites: boolean[]) => {
      const instructionIndex = this.activites.indexOf(EnumActivite.INSTRUCTION);
      if (instructionIndex !== -1) {
        if (activites[instructionIndex]) {
          this.autoAffectForm.get('intervenantPI').enable();
        } else {
          this.autoAffectForm.get('intervenantPI').disable();
          this.autoAffectForm.get('intervenantPI').setValue(false);
        }
      }
    });
  }

  confirmerAffectation(): void {
    if (!this.autoAffectForm.valid) {
      this.autoAffectForm.markAllAsTouched();
      return;
    }

    const activites: string[] = [];

    this.autoAffectForm.get('activites').value.forEach((ischecked: boolean, index: number) => {
      if (ischecked) {
        activites.push(this.activites[index]);
      }
    });

    const projectsIdArray = this.selectedProjects.map(project => project.id);
    const referent = this.autoAffectForm.get('referent').value;
    const intervenantPI = this.autoAffectForm.get('intervenantPI').value;

    this.dialogRef.close({ projectsIdArray, activites, referent, intervenantPI });
  }

  protected readonly EnumActivite = EnumActivite;
  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
}

function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: AbstractControl) => {
    if (formArray instanceof FormArray) {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev: any, next: any) => (next ? prev + next : prev), 0);

      return totalSelected >= min ? null : { required: true };
    }
    throw new Error('formArray is not an instance of FormArray');
  };

  return validator;
}
