import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { CentreMesure, Region } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  constructor(private httpClient: HttpClient) {}

  getDirectionRegionaleCentreMesure(): Observable<HttpResponse<CentreMesure[]>> {
    return this.httpClient.get<CentreMesure[]>(environment.apiUrl + 'direction-regionale/centre-mesure', { observe: 'response' });
  }

  getRegions(): Observable<HttpResponse<Region[]>> {
    return this.httpClient.get<Region[]>(environment.apiUrl + 'regions', { observe: 'response' });
  }
}
