import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectSearchComponent } from './projectSearch.component';
import { CustomMultiSelectModule } from '@shared-evaluateur/components/multiselect/multiselect.module';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ProjectSearchComponent],
  imports: [CommonModule, CustomMultiSelectModule, SharedModule, MatTooltipModule, SharedUiModule],
  exports: [ProjectSearchComponent],
})
export class ProjectSearchModule {}
