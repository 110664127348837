import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared-evaluateur/shared.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { OrganismeListComponent } from './organisme.list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MaterialModule,
    MatCheckboxModule,
    NgbPopoverModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  declarations: [OrganismeListComponent],
  exports: [OrganismeListComponent],
  providers: [],
})
export class OrganismeListModule {}
