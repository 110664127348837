import { Injectable } from '@angular/core';
import { DomaineJson } from '@shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomainesSharedService {
  public domainesData = new BehaviorSubject<DomaineJson[]>([]);

  setDomainesData(data: any) {
    this.domainesData.next(data);
  }

  getDomainesData() {
    return this.domainesData.asObservable();
  }
}
