import { enumKeys } from "../../utils/enum-utils";

export enum EnumDecisionComite {
  FAVORABLE = 'FAVORABLE',
  DEFAVORABLE = 'DEFAVORABLE',
  AJOURNEMENT = 'AJOURNEMENT',
  REORIENTATION = 'REORIENTATION',
}

export namespace EnumDecisionComite {
  export function toString(option: EnumDecisionComite): string {
    switch (option) {
      case EnumDecisionComite.FAVORABLE: return 'Favorable';
      case EnumDecisionComite.DEFAVORABLE: return 'Défavorable';
      case EnumDecisionComite.AJOURNEMENT: return 'Ajournement';
      case EnumDecisionComite.REORIENTATION: return 'Réorientation';
    }
  }

  export function all(): EnumDecisionComite[] {
    return enumKeys(EnumDecisionComite) as unknown as EnumDecisionComite[];
  }
}
