import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Aap, DocumentAapModel, EnumProjetEtape, EnumScope, EnumTypeWorkflow, ReferentielDocumentsAap } from '@shared-ui';

export class DocumentationHelperService {
  static buildDocumentationFormArray(refDocuments: ReferentielDocumentsAap[], aap: Aap): FormArray {
    const documentsAapMap = this.getDocumentsAapMap(aap);
    const documentsAapForm = new FormArray([]);
    refDocuments.forEach(doc => {
      documentsAapForm.push(this.getDocumentAapFormGroup(doc, documentsAapMap, aap.typeWKF));
    });
    return documentsAapForm;
  }

  static getDocumentAapFormGroup(
    documentRef: ReferentielDocumentsAap,
    documentsAapMap: Map<string, DocumentAapModel>,
    typeWKF: EnumTypeWorkflow
  ): FormGroup {
    const documentAap = documentsAapMap.get(documentRef.nom);
    const hasAapDocuments = documentsAapMap.size > 0;
    return new FormGroup({
      scope: new FormControl(documentAap?.scope ?? documentRef.scope),
      // Set availability from ref only if no documents are configured.
      disponibiliteAap: new FormControl(hasAapDocuments ? !!documentAap : documentRef.disponibiliteAap),
      etapes: new FormControl(
        this.getSelectedEtape(documentAap?.etapes).length === 0 ? documentRef.etapes : this.getSelectedEtape(documentAap?.etapes)
      ),
      nomDocument: new FormControl(documentRef.nom),
      obligatoire: new FormControl(documentAap?.obligatoire ?? documentRef.champsObligatoire),
      accesDocument: new FormControl(this.getSelectedHabilitationWKF(documentAap, documentRef, typeWKF)),
      typeStructures: new FormControl(
        documentAap?.typeStructures
          ? { value: documentAap?.typeStructures, disabled: documentAap?.scope === EnumScope.PROJET }
          : { value: documentRef?.typeStructures, disabled: documentRef?.scope === EnumScope.PROJET }
      ),
      mediaTypes: new FormControl(documentAap?.mediaTypes),
      codeGed: new FormControl(documentAap?.codeGed),
      roleStructures: new FormControl(
        documentAap?.roleStructures
          ? { value: documentAap?.roleStructures, disabled: documentAap?.scope === EnumScope.PROJET }
          : { value: documentRef?.roleStructures, disabled: documentRef?.scope === EnumScope.PROJET }
      ),
      typePartenaires: new FormControl(documentAap?.typePartenaires ?? documentRef.typePartenaires),
    });
  }

  static getDocumentsAapMap(aap: Aap): Map<string, DocumentAapModel> {
    const documentsAapMap = new Map<string, DocumentAapModel>();
    if (!aap || !aap.documentAaps) return documentsAapMap;
    aap.documentAaps.forEach(doc => {
      documentsAapMap.set(doc.nomDocument, doc);
    });
    return documentsAapMap;
  }

  static getSelectedEtape(etapes: EnumProjetEtape[]): string[] {
    if (!etapes) return [];
    return etapes.includes(EnumProjetEtape.PRE_DEPOT) ? [EnumProjetEtape.PRE_DEPOT] : [EnumProjetEtape.DEPOT];
  }

  static getSelectedHabilitationWKF(documentAap: DocumentAapModel, documentRef: ReferentielDocumentsAap, typeWKF: EnumTypeWorkflow) {
    const noH = 'noH';
    if (documentAap) {
      return documentAap.accesDocument ?? noH;
    }
    const habiliation =
      typeWKF === EnumTypeWorkflow.WKF1 || typeWKF === undefined ? documentRef.habilitationWKF1 : documentRef.habilitationWKF2;
    return habiliation ?? noH;
  }

  static getEtapesFromSelectedValue(etapes: EnumProjetEtape[]): EnumProjetEtape[] {
    return etapes.includes(EnumProjetEtape.PRE_DEPOT)
      ? [
          EnumProjetEtape.PRE_DEPOT,
          EnumProjetEtape.DEPOT,
          EnumProjetEtape.INSTRUCTION,
          EnumProjetEtape.CONTRACTUALISATION,
          EnumProjetEtape.SUIVI,
        ]
      : [EnumProjetEtape.DEPOT, EnumProjetEtape.INSTRUCTION, EnumProjetEtape.CONTRACTUALISATION, EnumProjetEtape.SUIVI];
  }

  static getDocumentsAapDTO(documentsAapForm: FormArray): DocumentAapModel[] {
    return documentsAapForm.controls
      .filter(documentAapForm => documentAapForm.value.disponibiliteAap)
      .map(documentAapForm => {
        return {
          nomDocument: documentAapForm.get('nomDocument').value,
          etapes: this.getEtapesFromSelectedValue(documentAapForm.value.etapes),
          scope: documentAapForm.value.scope,
          obligatoire: documentAapForm.value.obligatoire,
          connaissanceClient: documentAapForm.value.connaissanceClient,
          accesDocument: documentAapForm.value.accesDocument === 'noH' ? null : documentAapForm.value.accesDocument,
          typeStructures: documentAapForm.value.typeStructures,
          mediaTypes: documentAapForm.value.mediaTypes,
          codeGed: documentAapForm.value.codeGed,
          roleStructures: documentAapForm.value.roleStructures,
          typePartenaires: documentAapForm.value.typePartenaires,
        };
      });
  }
}
