import { Injectable } from '@angular/core';
import { SecteurJson } from '@shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SecteursSharedService {
  public secteursData = new BehaviorSubject<SecteurJson[]>([]);

  setSecteursData(data: any) {
    this.secteursData.next(data);
  }

  getSecteursData() {
    return this.secteursData.asObservable();
  }
}
