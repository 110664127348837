<div class="filters-container">
  <div class="accordion-top">
    <button class="toggle-button" [ngClass]="{ 'toggle-open': accordionOpen }" (click)="accordionOpen = !accordionOpen">
      <h3>Filtres</h3>
      <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
    </button>
  </div>

  <div class="accordion-container" [ngClass]="{ 'hidden-row': !accordionOpen }">
    <div class="search-container">
      <div class="filter-row" id="first-row">
        <div class="single-filter-container" (focusout)="onProgrammesLeave()" (mouseleave)="onProgrammesLeave()">
          <label class="ds-input-group__label filter-label">
            Programme
            <span class="ds-input-group__label-asterisk">*</span>
          </label>
          <lib-ds-multiselect
            #programmeComponent
            class="filter"
            [dataList]="programmeDataList"
            [selectOptions]="{ placeholder: 'Programme' }"
            [name]="'programme-filter'"
          >
          </lib-ds-multiselect>
        </div>

        <div class="single-filter-container" (click)="onAapsLeave()">
          <label class="ds-input-group__label filter-label">
            Appel à projet
            <span class="ds-input-group__label-asterisk">*</span>
          </label>
          <lib-ds-multiselect
            #aapComponent
            class="filter"
            [dataList]="aapDataList"
            [selectOptions]="{ placeholder: 'Appel à projet' }"
            [name]="'aap-filter'"
          >
          </lib-ds-multiselect>
        </div>

        <div class="single-filter-container">
          <label class="ds-input-group__label filter-label"> Etape projet </label>
          <lib-ds-multiselect
            #etapeComponent
            class="filter"
            [dataList]="etapeDataList"
            [selectOptions]="{ placeholder: 'Etape', isDisabled: !fieldsActive }"
            [name]="'etape-filter'"
          >
          </lib-ds-multiselect>
        </div>

        <div class="single-filter-container">
          <label class="ds-input-group__label filter-label"> Statut projet </label>
          <lib-ds-multiselect
            #statutComponent
            class="filter"
            [dataList]="statutDataList"
            [selectOptions]="{ placeholder: 'Statut', isDisabled: !fieldsActive }"
            [name]="'statut-filter'"
          >
          </lib-ds-multiselect>
        </div>
      </div>

      <div class="filter-row" id="second-row">
        <div class="single-filter-container">
          <label class="ds-input-group__label filter-label"> Acronyme projet </label>
          <custom-multiselect
            #acronymeComponent
            (autocompleteEvent)="autocompleteEvent($event, ACRONYME)"
            (selectedListChange)="selectedListChange($event, ACRONYME)"
            [(dataList)]="acronymesDataList"
            [selectedList]="acronymesSelectedList"
            placeholder="Acronyme projet"
            name="Acronyme"
            class="filter"
            [isLoading]="loadingMap.get(ACRONYME)"
            [isDisabled]="!fieldsActive"
          >
          </custom-multiselect>
        </div>

        <div class="single-filter-container">
          <label class="ds-input-group__label filter-label"> Raison sociale ou SIREN </label>
          <custom-multiselect
            #rsSiretComponent
            (autocompleteEvent)="autocompleteEvent($event, RS_SIRET)"
            (selectedListChange)="selectedListChange($event, RS_SIRET)"
            [(dataList)]="rsSiretDataList"
            [selectedList]="rsSiretSelectedList"
            placeholder="Raison sociale ou SIREN"
            name="raison sociale ou siren"
            class="filter"
            [isLoading]="loadingMap.get(RS_SIRET)"
            [isDisabled]="!fieldsActive"
          >
          </custom-multiselect>
        </div>

        <div class="single-filter-container">
          <label class="ds-input-group__label filter-label"> Thématique </label>
          <lib-ds-multiselect
            #thematiqueComponent
            class="filter"
            [dataList]="[]"
            [selectOptions]="{ placeholder: 'Thématique', isDisabled: !fieldsActive }"
            [name]="'thematique-filter'"
          >
          </lib-ds-multiselect>
        </div>

        <div class="single-filter-container">
          <label class="ds-input-group__label filter-label"> Type projet </label>
          <lib-ds-multiselect
            #typeProjetComponent
            class="filter"
            [dataList]="typeProjetDataList"
            [selectOptions]="{ placeholder: 'Type projet', isDisabled: !fieldsActive }"
            [name]="'typeProjet-filter'"
          >
          </lib-ds-multiselect>
        </div>
      </div>

      <div class="filter-row" id="third-row">
        <div class="single-filter-container">
          <label class="ds-input-group__label filter-label"> Date de relève </label>
          <lib-date-range-picker #dateReleveComponent [isDisabled]="!fieldsActive"></lib-date-range-picker>
        </div>
      </div>
    </div>

    <div class="search-buttons-container">
      <button type="button" class="ds-btn ds-btn--secondary" (click)="resetAllFilters()">
        <span class="ds-icon ds-icon-left ds-float--left" aria-hidden="true">refresh</span>
        Réinitialiser
      </button>
      <button id="actions-btn" class="ds-btn ds-btn--primary" (click)="submitSearch()">Rechercher</button>
    </div>
  </div>
</div>
