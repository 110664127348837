import { Injectable } from '@angular/core';
import { Depenses, Scenario } from '@shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DepensesInstructeurService {
  private isShowing = new BehaviorSubject<boolean>(false);
  private activeScenario = new BehaviorSubject<Scenario>(null);
  private depenses = new BehaviorSubject<Depenses>(null);

  setIsShowing(data: boolean) {
    this.isShowing.next(data);
  }

  getIsShowing() {
    return this.isShowing.asObservable();
  }

  setActiveScenario(data: Scenario) {
    this.activeScenario.next(data);
  }

  getActiveScenario() {
    return this.activeScenario.asObservable();
  }

  setDepenses(data: Depenses) {
    this.depenses.next(data);
  }

  getDepenses() {
    return this.depenses.asObservable();
  }
}
