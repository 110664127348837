<div class="tableau-title">
  <div>Référentiel des Régimes d'Aides</div>
</div>

<div class="table">
  <table mat-table [dataSource]="RegimeAideDataSource" aria-describedby="tableau de référentiel des régimes d'aides">
    <ng-container matColumnDef="nom" class="">
      <th id="nom" mat-header-cell *matHeaderCellDef>Nom</th>
      <td id="ref-nom" mat-cell *matCellDef="let element">
        <span>{{ element.nom }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="modification">
      <th id="actions-mod" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="d-flex justify-content-end">
        <button id="mod-regime" class="table__button" matTooltip="Modifier Régime" (click)="updateRegime(element)">
          <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
        </button>
        <button id="delete-regime" class="table__button" matTooltip="Supprimer Régime" (click)="deleteRegime(element)">
          <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
  </table>
</div>
