import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-evaluateur/environment';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { ShowToastrService } from '@shared-ui';
import { timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'pxl-confirmation-acces-evaluateur',
  templateUrl: './confirmation-acces-evaluateur.component.html',
  styleUrls: ['./confirmation-acces-evaluateur.component.scss'],
})
export class ConfirmationAccesEvaluateurComponent implements OnInit {
  readonly DELAY_SECONDS = 3;

  secondsLeft = 0;
  sendEmailButtonActive = true;
  requestSent = false;
  userEmail: string;

  constructor(
    private userService: UtilisateurService,
    public evaluateurService: EvaluateurService,
    private router: Router,
    private toastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    this.userService.getUserInfos().subscribe(user => {
      this.userEmail = user.body.email;
    });
  }

  sendConfirmation(): void {
    this.evaluateurService.reactivationCompte().subscribe({
      next: result => {
        this.startButtonCountDown();
        if (result.status === 204) {
          this.toastrService.success('Votre compte est désormais actif.');
          this.router.navigate(['aaps']);
        }
      },
      error: () => {
        this.toastrService.error('Une erreur est survenue lors de la demande de confirmation, veuillez réessayer.');
      },
      complete: () => {
        this.requestSent = true;
      },
    });
  }

  goToEspace(): void {
    if (localStorage.getItem('DOUBLE_PROFILE') === 'true') {
      this.router.navigate(['evaluateur-choice']);
    } else {
      window.location.href = environment.espaceCandidat + '/espace';
    }
  }

  startButtonCountDown(): void {
    this.sendEmailButtonActive = false;
    // Countdown
    const countdown$ = timer(0, 1000).pipe(
      take(this.DELAY_SECONDS + 1),
      map(secondsElapsed => {
        if (secondsElapsed === this.DELAY_SECONDS) {
          this.sendEmailButtonActive = true;
        }
        return this.DELAY_SECONDS - secondsElapsed;
      })
    );

    countdown$.subscribe(secondsLeft => {
      this.secondsLeft = secondsLeft;
    });
  }
}
