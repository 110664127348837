<section class="form-step">
  <div id="anchor2" class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span
      >Dates & périodicité
    </h2>
  </header>

  <form id="periodicity-form" class="periodicity-form" [formGroup]="periodicityForm">
    <div class="form-step__section section-dates">
      <h3 class="form-step__subtitle">Dates de l'AAP</h3>
      <!-- Date aap ami  jorf -->
      <div class="form-step__date-aap-ami-jorf">
        <div
          id="date-picker-aap-ami-jorf"
          class="ds-input-group ds-input-group--date"
          [class.ds-input-group--error]="
            periodicityForm.get('datePublicationJorf').invalid && periodicityForm.get('datePublicationJorf').touched
          "
        >
          <label class="ds-input-group__label"> Date de publication AAP et AMI au JORF </label>
          <span class="ds-input-group__input">
            <input
              matInput
              [matDatepicker]="openDatePicker1"
              formControlName="datePublicationJorf"
              class="ds-input-group__input"
              placeholder="JJ/MM/AAAA"
            />
            <mat-datepicker-toggle matSuffix [for]="openDatePicker1"></mat-datepicker-toggle>
            <mat-datepicker #openDatePicker1></mat-datepicker>
          </span>
        </div>
      </div>
      <!-- FIN de Date aap ami  jorf -->
      <!-- Date et heure d'ouverture -->
      <div class="form-step__date-ouverture">
        <p class="form-step__input-group-title">Ouverture</p>

        <div class="date-time">
          <div
            id="date-picker-ouverture"
            class="date-time__date ds-input-group ds-input-group--date"
            [class.ds-input-group--error]="periodicityForm.get('dateOuverture').invalid && periodicityForm.get('dateOuverture').touched"
          >
            <label class="ds-input-group__label"> Date d&apos;ouverture <span class="ds-input-group__label-asterisk"> *</span> </label>
            <span class="ds-input-group__input">
              <input
                matInput
                [matDatepicker]="openDatePicker"
                formControlName="dateOuverture"
                class="ds-input-group__input"
                placeholder="JJ/MM/AAAA"
              />
              <mat-datepicker-toggle matSuffix [for]="openDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #openDatePicker></mat-datepicker>
            </span>
          </div>

          <div class="date-time__time ds-input-group" [class.ds-input-group--error]="false">
            <label for="heureOuverture" class="ds-input-group__label">
              Heure d'ouverture <span class="ds-input-group__label-asterisk"> *</span>
            </label>
            <div id="heure-ouverture" class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="heureOuverture" name="heureOuverture" formControlName="heureOuverture">
                <option *ngFor="let hour of dayHours" [value]="hour">{{ hour }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN de Date et heure d'ouverture -->

      <!-- Date et heure de fermeture -->
      <div class="form-step__date-fermeture">
        <p class="form-step__input-group-title">Fermeture</p>

        <div class="date-time">
          <div
            id="date-picker-fermeture"
            class="date-time__date ds-input-group ds-input-group--date"
            [class.ds-input-group--error]="periodicityForm.get('dateFermeture').invalid && periodicityForm.get('dateFermeture').touched"
          >
            <label class="ds-input-group__label"> Date de fermeture <span class="ds-input-group__label-asterisk"> *</span> </label>
            <span class="ds-input-group__input">
              <input
                matInput
                [min]="minDate"
                [matDatepicker]="closeDatePicker"
                formControlName="dateFermeture"
                class="ds-input-group__input"
                placeholder="JJ/MM/AAAA"
              />
              <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #closeDatePicker></mat-datepicker>
            </span>
          </div>

          <div class="date-time__time ds-input-group" [class.ds-input-group--error]="false">
            <label for="heureFermeture" class="ds-input-group__label">
              Heure de fermeture <span class="ds-input-group__label-asterisk"> *</span>
            </label>
            <div id="heure-fermeture" class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="heureFermeture" name="heureFermeture" formControlName="heureFermeture">
                <option *ngFor="let hour of dayHours" [value]="hour">{{ hour }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN de Date et heure de fermeture -->

      <!-- Autres dates -->
      <div class="form-step__autres-dates" *ngxPermissionsOnly="['PARAMETRAGE_WRITE']">
        <p class="form-step__input-group-title">Autres dates</p>

        <form
          id="autre-date-form"
          class="create-date-group"
          [formGroup]="addDateForm"
          #formDirective="ngForm"
          (ngSubmit)="addDate(addDateForm.value, formDirective)"
        >
          <div id="type-date" class="ds-input-group create-date-group__type" [class.ds-input-group--error]="false">
            <label class="ds-input-group__label">Type de la date</label>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select formControlName="type">
                <option *ngFor="let item of EnumDateType.all()" [value]="item">{{ EnumDateType.toString(item) }}</option>
              </select>
            </div>
          </div>

          <div id="input-nom-date" class="form-step__nom-date ds-input-group create-date-group__nom" [class.ds-input-group--error]="false">
            <label for="nomDate" class="ds-input-group__label">Nom de la date</label>
            <span class="ds-input-group__input">
              <input
                id="nomDate"
                name="nomDate"
                type="text"
                placeholder="Choisir une valeur"
                class="ds-input-group__input"
                formControlName="nom"
                #nameInput
              />
            </span>
          </div>

          <div class="date-time">
            <div
              id="date-picker-autre"
              class="date-time__date ds-input-group ds-input-group--date"
              [class.ds-input-group--error]="addDateForm.get('date').invalid && addDateForm.get('date').touched"
            >
              <label class="ds-input-group__label">Date</label>
              <span class="ds-input-group__input">
                <input
                  matInput
                  [min]="minDate"
                  [matDatepicker]="addDatePicker"
                  formControlName="date"
                  class="ds-input-group__input"
                  placeholder="JJ/MM/AAAA"
                />
                <mat-datepicker-toggle matSuffix [for]="addDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #addDatePicker></mat-datepicker>
              </span>
            </div>

            <div class="date-time__time ds-input-group" [class.ds-input-group--error]="false">
              <label for="heure" class="ds-input-group__label">Heure</label>
              <div id="heure-autre" class="ds-input-group__select ds-input-group__select--with-icon">
                <select id="heure" name="heure" formControlName="heure">
                  <option *ngFor="let hour of dayHours" [value]="hour">{{ hour }}</option>
                </select>
              </div>
            </div>
          </div>

          <button type="submit" class="ds-btn ds-btn--primary" [disabled]="!addDateForm.valid">Ajouter</button>
        </form>
      </div>
      <!-- FIN de autres dates -->

      <!-- Tableau des dates créées -->
      <div id="dates-table" class="table form-step__dates-table" *ngxPermissionsOnly="['PARAMETRAGE_WRITE']">
        <table mat-table [dataSource]="dataSource">
          <caption>
            Autres dates de relève
          </caption>
          <ng-container matColumnDef="nom" class="">
            <th id="date-name" mat-header-cell *matHeaderCellDef>Nom de la date</th>
            <td id="nom" mat-cell *matCellDef="let element">
              <strong>{{ element.nom }}</strong>
            </td>
          </ng-container>
          <ng-container matColumnDef="type" class="">
            <th id="date-type" mat-header-cell *matHeaderCellDef>Type de la date</th>
            <td id="type" mat-cell *matCellDef="let element">{{ element.type }}</td>
          </ng-container>
          <ng-container matColumnDef="valeur" class="">
            <th id="date-hour" mat-header-cell *matHeaderCellDef>Date et horaire</th>
            <td id="valeur" mat-cell *matCellDef="let element">{{ element.valeur | date : "dd/MM/yyyy - HH'h'mm" }}</td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th id="date-delete" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index">
              <button id="delete-date" class="table__button" matTooltip="Supprimer" (click)="deleteDate(i)">
                <svg class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                  <title>icon-action-delete</title>
                  <path
                    d="M8 25.334c0 1.466 1.2 2.666 2.666 2.666h10.668c1.466 0 2.666-1.2 2.666-2.666v-13.334c0-1.466-1.2-2.666-2.666-2.666h-10.668c-1.466 0-2.666 1.2-2.666 2.666v13.334zM24 5.334h-3.334l-0.946-0.948c-0.24-0.24-0.586-0.386-0.934-0.386h-5.572c-0.348 0-0.694 0.146-0.934 0.386l-0.946 0.948h-3.334c-0.734 0-1.334 0.6-1.334 1.332 0 0.734 0.6 1.334 1.334 1.334h16c0.734 0 1.334-0.6 1.334-1.334 0-0.732-0.6-1.332-1.334-1.332z"
                  ></path>
                </svg>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th id="periodicity-actions" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index">
              <button
                id="update-periodicity"
                class="table__button"
                matTooltip="Modifier"
                (click)="updatePeriodicity(element, i); $event.stopPropagation()"
              >
                <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </form>
</section>
