import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EnumTypeOrganisme, OrganismeModel } from '@shared-ui';

@Component({
  selector: 'pxl-organismes-tableau',
  templateUrl: './organismes-tableau.component.html',
  styleUrls: ['./organismes-tableau.component.scss'],
})
export class OrganismesTableauComponent {
  @Input()
  set dataSource(data: MatTableDataSource<OrganismeModel>) {
    this._dataSource = data;
    if (data) {
      data.sort = this.sort;
      data.sortingDataAccessor = (item: any, property: string) => {
        return property === 'type' ? EnumTypeOrganisme[item.type] : item[property];
      };
    }
  }
  get dataSource() {
    return this._dataSource;
  }
  @Input() selection = new SelectionModel<OrganismeModel>(true, []);

  @ViewChild(MatSort) sort: MatSort;

  organismeType = EnumTypeOrganisme;
  displayedColumns: string[] = ['select', 'nom', 'raisonSociale', 'type', 'mailValideur'];
  private _dataSource: MatTableDataSource<OrganismeModel> | undefined;

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  updateAllSelections(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection?.selected?.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }
}
