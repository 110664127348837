<h2 class="modal__title">Validation / Refus par l’Etat de la proposition du comité</h2>

<div class="modal__content">
  <span
    >Vous pouvez choisir la décision et la date de validation pour le{{ projetsIds.length > 1 ? 's' : '' }} projet{{
      projetsIds.length > 1 ? 's' : ''
    }}
    sélectionné{{ projetsIds.length > 1 ? 's' : '' }}.</span
  >

  <form [formGroup]="form">
    <div class="ds-input-group" [class.ds-input-group--error]="form.controls.avis.invalid && form.controls.avis.touched">
      <div class="ds-input-group__select ds-input-group__select--with-icon mt-2">
        <div class="toggle-title-container">
          <label for="select__avis" class="ds-input-group__label">Validation de la proposition du comité</label>
          <lib-asterisk />
        </div>
        <select id="select__avis" name="select__avis" formControlName="avis">
          <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
          <option *ngFor="let decision of EnumDecisionComite.all()" [value]="decision">
            {{ EnumDecisionComite.toString(decision) }}
          </option>
        </select>
      </div>
    </div>

    <div
      class="date-time__date ds-input-group ds-input-group--date mt-2"
      [class.ds-input-group--error]="form.controls.dateDecisionEtat?.invalid && form.controls.dateDecisionEtat?.touched"
    >
      <label class="ds-input-group__label" for="date-decision-etat">Date de validation / rejet de l’Etat</label><lib-asterisk />
      <span class="ds-input-group__input">
        <input
          id="date-decision-etat"
          matInput
          [matDatepicker]="dateDecisionEtatDatePicker"
          formControlName="dateDecisionEtat"
          class="ds-input-group__input"
          placeholder="Date de validation / rejet de l’Etat"
        />
        <mat-datepicker-toggle matSuffix [for]="dateDecisionEtatDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dateDecisionEtatDatePicker></mat-datepicker>
      </span>
    </div>

    <div id="input-commentaire" class="ds-input-group">
      <label for="commentaire" class="ds-input-group__label">Motivation du refus / Commentaire pour 'type de décision'</label>
      <textarea
        id="commentaire"
        name="commentaire"
        rows="5"
        col="0"
        class="ds-input-group__textarea"
        #descriptionInput
        formControlName="commentaire"
        placeholder="Vous pouvez saisir un commentaire"
      ></textarea>
    </div>

    <div class="ds-input-group mt-2" [class.ds-radio-classic--error]="form.controls.phase.invalid && form.controls.phase.touched">
      <label class="ds-radio-classic" for="preselection-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="preselection-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.EVALUATION"
        />
        <span class="ds-radio__label"><span>Présélection</span></span>
      </label>

      <label class="ds-radio-classic" for="audition-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="audition-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.AUDITION"
        />
        <span class="ds-radio__label"><span>Audition</span></span>
      </label>

      <label class="ds-radio-classic" for="selection-radio">
        <input
          type="radio"
          class="ds-radio__input"
          id="selection-radio"
          name="phase"
          formControlName="phase"
          [value]="EnumPhaseComite.SELECTION"
        />
        <span class="ds-radio__label"><span>Sélection</span></span>
      </label>
    </div>
  </form>
</div>

<div class="modal__actions">
  <button id="cancel" class="ds-btn ds-btn--secondary" (click)="closeDialog()">Annuler</button>
  <button id="go-action" class="ds-btn ds-btn--primary" (click)="submit()" cdkFocusInitial>Confirmer</button>
</div>
