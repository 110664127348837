<div id="intervention-tags-and-button-container">
  <div id="intervention-tags">
    <div
      class="ds-tag ds-tag-status--primary-100 card__tag--{{ interventionPiStatutColor }}"
      *ngIf="(projet.demandeInterventionPI && !aap.interventionPiObligatoire) || projet.interventionPI"
    >
      {{ projet.interventionPI ? EnumStatutInterventionPI.toString(projet.interventionPI.statut) : 'Demandée' }}
    </div>

    <div class="ds-tag ds-tag-status--primary-100 card__tag--blue" *ngIf="projet.demandeInterventionPI && !aap.interventionPiObligatoire">
      Instruction Approfondie
    </div>

    <div
      class="ds-tag ds-tag-status--primary-100 card__tag--orange"
      *ngIf="projet.demandeInterventionPI && projet.demandeInterventionPI.dateRendu && !aap.interventionPiObligatoire"
    >
      Date de rendu: {{ projet.demandeInterventionPI.dateRendu | date : 'dd/MM/yyyy' }}
    </div>
  </div>

  <button
    id="demander-intervention-btn"
    type="submit"
    class="ds-btn ds-btn--primary"
    (click)="openInterventionModal(); $event.stopPropagation()"
    *ngIf="!projet.demandeInterventionPI && !aap.interventionPiObligatoire"
  >
    Demander
  </button>
  <button
    id="demander-intervention-btn"
    type="submit"
    class="ds-btn ds-btn--secondary"
    (click)="openInterventionModal(); $event.stopPropagation()"
    *ngIf="projet.demandeInterventionPI && !aap.interventionPiObligatoire"
  >
    Modifier
  </button>
</div>
