import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-evaluateur/environment';

import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { Evaluateur, ShowToastrService, Utilisateur } from '@shared-ui';
import { catchError, EMPTY, filter, map, Observable } from 'rxjs';
import { ProfilEvaluateur } from '@core-evaluateur/profil.guard';

export const CHOSEN_PROFILE_KEY = 'CHOSEN_PROFILE';

@Component({
  selector: 'app-evaluateur-choice',
  templateUrl: './evaluateur-choice.component.html',
  styleUrls: ['./evaluateur-choice.component.scss'],
})
export class EvaluateurChoiceComponent implements OnInit {
  hasMembreOrganismeAccess = false;
  invitationConsumee = false;
  showTiles = false;
  evaluateur?: Evaluateur;

  constructor(
    private router: Router,
    private evaluateurService: EvaluateurService,
    private userService: UtilisateurService,
    private showToastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    this.showTiles = false;
    if (!this.router.getCurrentNavigation()?.extras?.state?.needEvaluation) {
      this.router.navigate(['']); // go to default page
    }
    this.loadUtilisateur()
      .pipe(filter(utilisateur => !!utilisateur))
      .subscribe(utilisateur => {
        this.loadEvaluateur(utilisateur).subscribe(evaluateur => {
          this.evaluateur = evaluateur;
          this.hasMembreOrganismeAccess = !!this.evaluateur?.organisme;
          this.showTiles = true;
        });
      });

    this.isInvitationConsumee().subscribe(isInvitationConsumee => {
      this.invitationConsumee = isInvitationConsumee;
    });
  }

  redirectEvaluateurExterne(): void {
    if (!this.invitationConsumee) {
      return; // tile not clickable
    }
    this.setChosenProfile(ProfilEvaluateur.EVALUATEUR_EXTERNE);
    this.router.navigate(['/aaps'], { state: { needEvaluation: false } });
  }

  redirectMembreOrganisme(): void {
    if (this.hasMembreOrganismeAccess) {
      this.setChosenProfile(ProfilEvaluateur.MEMBRE_ORGANISME);
      this.router.navigate(['/aaps'], { state: { needEvaluation: false } });
    } else {
      window.location.href = environment.espaceCandidat + '/rejoindre-projet-partenaire';
    }
  }

  setChosenProfile(profil: ProfilEvaluateur): void {
    localStorage.setItem(CHOSEN_PROFILE_KEY, profil);
  }

  isInvitationConsumee(): Observable<boolean> {
    return this.evaluateurService.getStatutInvitationCurrentEvaluateur().pipe(
      catchError(err => this.handleError(err)),
      map(response => response.body?.active === false)
    );
  }

  loadUtilisateur(): Observable<Utilisateur> {
    return this.userService.getUtilisateurObservable();
  }

  loadEvaluateur(utilisateur: Utilisateur): Observable<Evaluateur> {
    return this.evaluateurService.getEvaluateur(utilisateur?.email).pipe(
      catchError(err => this.handleError(err)),
      map(response => response.body!)
    );
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }
}
