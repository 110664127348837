import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@shared-ui';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RegimesAidesComponent } from './regimes-aides.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [RegimesAidesComponent],
  imports: [CommonModule, SharedUiModule, SharedModule, MatTableModule, MatSortModule, MatTooltipModule, MatDialogModule],
  exports: [RegimesAidesComponent],
})
export class RegimesAidesModule {}
