import { EnumProjetEtape } from '../../../shared/enums/enumProjetEtape';

export function getEtapeDataList() {
  return [EnumProjetEtape.PRE_DEPOT, EnumProjetEtape.DEPOT, EnumProjetEtape.INSTRUCTION, EnumProjetEtape.CONTRACTUALISATION].map(etape => {
    return {
      id: etape,
      value: EnumProjetEtape.toString(etape),
      isSelected: false,
    };
  });
}
