import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@shared-ui';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { ComitesTableauComponent } from './comites-tableau.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ComitesTableauComponent],
  imports: [CommonModule, SharedUiModule, SharedModule, MatTableModule, MatCheckboxModule, MatSortModule, MatTooltipModule],
  exports: [ComitesTableauComponent],
})
export class ComitesTableauModule {}
