import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjetService } from '@services-evaluateur/projet.service';
import { DateTimeUtils, EnumPhaseComite, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';

@Component({
  selector: 'app-date-envoi-modal',
  templateUrl: './date-envoi-modal.component.html',
  styleUrls: ['./date-envoi-modal.component.scss'],
})
export class DateEnvoiModalComponent extends SubscriptionDestroyerComponent implements OnInit {
  projetsIds: string[];
  date: Date;
  form: FormGroup<FormGroupInterface>;

  constructor(
    public dialogRef: MatDialogRef<DateEnvoiModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    public projetService: ProjetService,
    public showToastrService: ShowToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.projetsIds = this.modalData.projetsIds;
    this.createForm();
  }

  createForm(): void {
    this.form = new FormGroup<FormGroupInterface>({
      dateEnvoi: new FormControl(null, Validators.required),
      phase: new FormControl(null, Validators.required),
    });
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const date = this.form.controls.dateEnvoi.value;
    const phase = this.form.controls.phase.value;

    const dateRequest = {
      ids: this.modalData.projetsIds,
      dateEnvoiValidationEtat: DateTimeUtils.toUtcDate(date),
      phase,
    };

    this.projetService
      .putDateEnvoi(dateRequest)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: (err: HttpErrorResponse) => {
          if (err?.error?.message) {
            this.showToastrService.error(err.error.message);
          } else {
            this.showToastrService.checkCodeError(err?.error);
          }
        },
      });
  }

  closeDialog(): void {
    if (this.form.dirty) {
      const confirmation = window.confirm(`Vous avez demandé d'annuler votre demande. Vos modifications seront perdues.`);
      if (!confirmation) {
        return;
      }
    }
    this.dialogRef.close();
  }

  protected readonly EnumPhaseComite = EnumPhaseComite;
}

interface ModalData {
  projetsIds: string[];
}

interface FormGroupInterface {
  dateEnvoi: FormControl<Date>;
  phase: FormControl<EnumPhaseComite>;
}
