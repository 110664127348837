import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GeneralsInformationsModule } from './component/general-informations/general.informations.module';
import { PeriodicityModule } from './component/periodicity/periodicity.module';
import { ProjectStructureModule } from './component/project-structure/project.structure.module';
import { SidenavCreationAapModule } from './component/sidenav-creation/sidenav.creation.aap.module';

import { DateAdapter } from '@angular/material/core';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { CustomDateAdapter, SharedUiModule } from '@shared-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AapCreationComponent } from './aap.creation.component';
import { GrilleImpactModule } from './component/grille-impact/grille-impact.module';
import { CriteresEvaluationModule } from '@features-evaluateur/aap-creation/component/criteres-referentiel/criteres-referentiel.module';
import { OrganismesModule } from './component/organismes/organismes.module';
import { PhasesModule } from '@features-evaluateur/aap-creation/component/phases/phases.module';
import { PolesModule } from './component/poles/poles.module';
import { CategoriesModule } from './component/categories/categories.module';
import { DocumentationModule } from './component/documentation/documentation.module';
import { DocumentDematerialisesModule } from '@features-evaluateur/aap-creation/component/document-dematerialises/document-dematerialises.module';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SidenavCreationAapModule,
    SharedModule,
    GeneralsInformationsModule,
    PeriodicityModule,
    ProjectStructureModule,
    GrilleImpactModule,
    CriteresEvaluationModule,
    PhasesModule,
    OrganismesModule,
    PolesModule,
    CategoriesModule,
    DocumentationModule,
    DocumentDematerialisesModule,
    MaterialModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
    SharedUiModule,
  ],
  declarations: [AapCreationComponent],
  exports: [AapCreationComponent],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class AapCreationModule {}
