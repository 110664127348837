import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramService } from '@services-evaluateur/program.service';
import { EnumProgramType, Program, ShowToastrService } from '@shared-ui';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pxl-program-creation',
  templateUrl: './program.creation.component.html',
  styleUrls: ['./program.creation.component.scss'],
})
export class ProgramCreationComponent implements OnInit, OnDestroy {
  title = 'pxl_front_admin';

  program: Program = new Program();
  subscription: Subscription;
  programForm: UntypedFormGroup;

  isNewProgram = true;

  displayTypeInput = false;

  constructor(
    private programService: ProgramService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    private toastrService: ShowToastrService
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.programForm.dirty;
  }

  public ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'nav-collapsed');

    this.createForms();

    if (this.route.snapshot.params.id) {
      this.isNewProgram = false;
      this.programService.getProgramById(this.route.snapshot.params.id).subscribe({
        next: (response: HttpResponse<Program>) => {
          this.program = response.body;

          if (this.program.type) {
            this.program.typeComptable = 'Code inconnu';
            this.displayTypeInput = true;
          }

          this.programForm.patchValue({
            nom: this.program.nom,
            typeComptable: this.program.typeComptable,
            type: this.program.type,
          });
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    }
  }

  public createForms(): void {
    this.programForm = this.formBuilder.group({
      nom: [null, Validators.required],
      typeComptable: ['', Validators.required],
      type: [null],
    });

    this.setTypeValidators();
  }

  public setTypeValidators(): void {
    const type = this.programForm.get('type');

    this.programForm.get('typeComptable').valueChanges.subscribe(typeComptable => {
      if (typeComptable === 'Code inconnu') {
        type.setValidators([Validators.required]);
        this.displayTypeInput = true;
      } else {
        type.setValidators(null);
        this.displayTypeInput = false;
        type.setValue(null);
      }

      type.updateValueAndValidity();
    });
  }

  public addProgram(formProgram: Program): void {
    Object.assign(this.program, formProgram);

    if (this.program.typeComptable === 'Code inconnu') {
      this.program.typeComptable = null;
    }

    this.programForm.markAsPristine();

    if (this.program.id) {
      this.programService.updateProgram(this.program).subscribe({
        next: () => {
          this.toastrService.success('Le programme a bien été modifié');
          this.router.navigate(['programmes']);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    } else {
      this.programService.createProgram(this.program).subscribe({
        next: () => {
          this.toastrService.success('Nouveau programme créé');
          this.router.navigate(['programmes']);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    }
  }

  public scrolltoAnchor(elementId: string): void {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'nav-collapsed');
  }

  protected readonly EnumProgramType = EnumProgramType;
}
