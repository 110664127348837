import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PolesComponent } from './poles.component';
import { CustomMultiSelectModule } from '@shared-evaluateur/components/multiselect/multiselect.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CustomMultiSelectModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
    SharedUiModule,
  ],
  declarations: [PolesComponent],
  exports: [PolesComponent],
  providers: [],
})
export class PolesModule {}
