import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidenavCreationModule } from './component/sidenav-creation/sidenav.creation.programme.module';
import { ProgramCreationComponent } from './program.creation.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, SidenavCreationModule, SharedModule, MaterialModule],
  declarations: [ProgramCreationComponent],
  exports: [ProgramCreationComponent],
})
export class ProgramCreationModule {}
