<div id="card-info" class="card form-step" [formGroup]="formGroup">
  <h5 class="form-step__subtitle">{{ title }}</h5>

  <div *ngIf="formGroup.controls['isActive']">
    <lib-radio-buttons
      class="w-100"
      [text]="phaseOrIntervention + ' ' + phaseType + ' ' + activeOrObligatoire"
      [isMandatory]="true"
      [group]="formGroup"
      [options]="OPTIONS_YES_NO"
      [index]="phaseType + '_'"
      controlName="isActive"
    ></lib-radio-buttons>
  </div>

  <div *ngIf="formGroup.controls['typeNote']" id="select__type-avis" class="ds-input-group__select ds-input-group__select--with-icon w-100">
    <div class="toggle-title-container">
      <label [for]="'select__' + phaseType" class="ds-input-group__label"> Type d'avis</label>
      <span *ngIf="formGroup.controls['isActive'] && formGroup.controls['isActive'].value" class="ds-input-group__label-asterisk"> *</span>
    </div>
    <select [id]="'select__' + phaseType" [name]="'select__' + phaseType" formControlName="typeNote">
      <option [ngValue]="undefined" selected disabled hidden>Sélectionnez une option</option>
      <option *ngFor="let item of EnumNotationTypeNote.all()" [value]="item">{{ EnumNotationTypeNote.toString(item) }}</option>
    </select>
  </div>

  <!-- Fichier de notation -->
  <div
    *ngIf="formGroup.controls['template']"
    [id]="'input' + phaseType + '-template'"
    class="form-step__fichier-notation ds-input-group"
    [class.ds-input-group--error]="!formGroup.controls['template'].valid && formGroup.controls['template'].touched"
  >
    <label [for]="phaseType + 'template'" class="ds-input-group__label">Fichier de notation</label>
    <span class="ds-input-group__input">
      <input
        [id]="phaseType + 'template'"
        [name]="phaseType + 'template'"
        type="text"
        placeholder="Nom de fichier (formats acceptés : .doc, .docx, .ppt, .pptx, .xls, .xlsx)"
        class="ds-input-group__input"
        formControlName="template"
      />
    </span>
  </div>

  <!-- Barre de préselection / audition -->
  <div
    *ngIf="formGroup.controls['notationBarre']"
    [id]="'input' + phaseType + '-notation-barre'"
    class="form-step__fichier-notation ds-input-group"
    [class.ds-input-group--error]="!formGroup.controls['notationBarre'].valid && formGroup.controls['notationBarre'].touched"
  >
    <label [for]="phaseType + '-notation-barre'" class="ds-input-group__label">{{ notationBarreLabel }}</label>
    <span class="ds-input-group__input">
      <input
        [id]="phaseType + '-notation-barre'"
        [name]="phaseType + '-notation-barre'"
        type="number"
        placeholder="Exemple 2,4"
        class="ds-input-group__input"
        formControlName="notationBarre"
      />
    </span>
    <ng-container *ngIf="!formGroup.controls['notationBarre'].valid && formGroup.controls['notationBarre'].touched">
      <div class="ds-input-group__feedback">{{ notationBarreErrorValidationMessage }}</div>
    </ng-container>
  </div>
</div>
