import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  ConfirmModalComponent,
  DepensesProjetConfig,
  ReferentielHttpService,
  RegimesAide,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';

@Component({
  selector: 'pxl-regimes-aides',
  templateUrl: './regimes-aides.component.html',
  styleUrls: ['./regimes-aides.component.scss'],
})
export class RegimesAidesComponent extends SubscriptionDestroyerComponent {
  @Input()
  set dataSource(data: MatTableDataSource<DepensesProjetConfig[]>) {
    this._dataSource = new MatTableDataSource(data.data);
    this.dataSource.data.map((m: any) => (this.regime = m['regimesAide']));
    this.RegimeAideDataSource = new MatTableDataSource(this.regime);
  }
  get dataSource() {
    return this._dataSource;
  }
  @Input() modificationPathRegimesAides: string;

  private _dataSource: MatTableDataSource<DepensesProjetConfig[]> | undefined;

  displayedColumns: string[] = ['nom', 'modification'];

  constructor(
    private referentielService: ReferentielHttpService,
    private showToastrService: ShowToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public matDialog: MatDialog
  ) {
    super();
  }

  regime: RegimesAide[] = [];
  RegimeAideDataSource: MatTableDataSource<RegimesAide> = new MatTableDataSource();

  deleteRegime(regime: RegimesAide): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: "Suppression Régime d'aide",
        description: `<p> Cette action est définitive confirmez-vous la suppression du Régime ${regime.nom} ?</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.data.map((depenses: any) => {
          depenses.regimesAide = depenses.regimesAide.filter((regimesAide: RegimesAide) => regimesAide.nom !== regime.nom);
          delete depenses.dateModificationReferentiel;
        });

        this.referentielService
          .saveReferentielDepensesProjets(this.dataSource.data[0])
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: () => {
              this.showToastrService.success('Le regime a bien été supprimé)');
              this.RegimeAideDataSource.data = this.RegimeAideDataSource.data.filter(
                (regimesAide: RegimesAide) => regimesAide.nom !== regime.nom
              );
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
      }
    });
  }

  updateRegime(regime: RegimesAide): void {
    const extras: NavigationExtras = { relativeTo: this.route };
    extras.state = { regime: regime };
    this.router.navigate([`../${this.modificationPathRegimesAides}`], extras);
  }
}
