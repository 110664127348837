import { Auteur } from '@shared-ui';

export class AuteurUtils {
  public static getAuteurFullname(auteur: Auteur): string | undefined {
    if (!auteur) {
      return undefined;
    }

    return auteur.prenom + ' ' + auteur.nom;
  }
}
