<section class="form-step">
  <div id="anchor4" class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span
      >KPIs Grille d’impacts
    </h2>
  </header>

  <pxl-grille-impact-tableau [dataSource]="dataSource" [(selection)]="selection"></pxl-grille-impact-tableau>
</section>
