import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CritereReferentielAapForm, EnumCriteresReferentielOptions, SubscriptionDestroyerComponent } from '@shared-ui';

@Component({
  selector: 'pxl-critere-referentiel-card',
  templateUrl: './critere-referentiel-card.component.html',
  styleUrls: ['./critere-referentiel-card.component.scss'],
})
export class CritereReferentielCardComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() formGroup: FormGroup<CritereReferentielAapForm>;
  @Input() numberOfCards: number;
  @Input() isNotation: boolean;
  numberArray: number[] = [];

  get formPhases() {
    return this.formGroup.controls.phases;
  }

  get formIsChecked() {
    return this.formGroup.controls.isChecked;
  }

  ngOnInit(): void {
    // Numbers for select
    this.numberArray = Array.from({ length: this.numberOfCards }, (_, i) => i + 1);

    // If the checkbox is toggled, we enable/disable the rest of the form
    this.formIsChecked.valueChanges.pipe(this.takeUntilDestroyed()).subscribe({
      next: value => {
        if (value) {
          this.formGroup.controls.ordre.enable();
          if (this.isNotation) {
            this.formGroup.controls.phases.enable();
          } else {
            this.formGroup.controls.phases.disable();
          }
        } else {
          this.formGroup.controls.ordre.disable();
          this.formGroup.controls.phases.disable();
        }
      },
    });

    this.formIsChecked.updateValueAndValidity({ emitEvent: true, onlySelf: true });
  }

  selectCheckbox(option: EnumCriteresReferentielOptions): void {
    if (!this.formIsChecked.value) {
      return;
    }
    this.formPhases.setValue(
      this.formPhases.value.includes(option)
        ? this.formPhases.value.filter((value: EnumCriteresReferentielOptions) => value != option)
        : [option, ...this.formPhases.value]
    );
  }

  protected readonly EnumCriteresReferentielOptions = EnumCriteresReferentielOptions;
}
