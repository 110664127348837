import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Aap, Dispositif, DispositifInterface, FlechageBudgetaireItem } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class FlechageBudgetaireService {
  adminApiUrl = environment.apiAdminUrl;

  constructor(private httpClient: HttpClient) {}

  getDispositifs(): Observable<DispositifInterface[]> {
    return this.httpClient.get<DispositifInterface[]>(`${this.adminApiUrl}dispositifs`);
  }

  getDispositifById(id: string): Observable<DispositifInterface> {
    return this.httpClient.get<DispositifInterface>(`${this.adminApiUrl}dispositifs/${id}`);
  }

  createDispositif(dispositif: DispositifInterface): Observable<DispositifInterface> {
    return this.httpClient.post<DispositifInterface>(`${this.adminApiUrl}dispositifs`, dispositif);
  }

  updateDispositif(id: string, dispositif: DispositifInterface): Observable<DispositifInterface> {
    return this.httpClient.put<DispositifInterface>(`${this.adminApiUrl}dispositifs/${id}`, dispositif);
  }

  buildDispositifsListWithAap(aap: Aap, dispositifs: Dispositif[], dispositifSynthese?: string): FlechageBudgetaireItem[] {
    return dispositifs
      .filter(
        dispositif =>
          (dispositif.actif &&
            dispositif.dateFin != null &&
            new Date(dispositif.dateFin) > new Date() &&
            new Date(dispositif.dateDebut) != null &&
            new Date(dispositif.dateDebut) <= new Date()) ||
          aap.dispositif === dispositif.code ||
          aap.dispositifsSecondaires?.includes(dispositif.code) ||
          dispositifSynthese === dispositif.code
      )
      .map(dispositif => {
        const { code } = dispositif;
        const aapDispositifPrincipal = aap?.dispositif;
        const aapDispositifSecondaire = aap?.dispositifsSecondaires?.includes(code);
        let index = 2;
        let nom = code;

        if (aapDispositifPrincipal === code) {
          index = 0;
          nom = `${code} (Principal)`;
        } else if (aapDispositifSecondaire) {
          index = 1;
          nom = `${code} (Secondaire)`;
        }

        return { index, nom, code };
      })
      .sort((a, b) => a.index - b.index || a.code.localeCompare(b.code));
  }
}
