import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SidenavProjetsModule } from './component/sidenav-creation/sidenav.projets.module';
import { ProjetListComponent } from './projet.list.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AffectationIntervenantModalModule } from '@shared-evaluateur/components/modals/affectation-intervenant-modal/affectation-intervenant-modal.module';
import { CustomToastModule } from '@shared-evaluateur/components/toastr/custom-toastr.module';
import { DateSelectModalModule } from '@shared-evaluateur/components/modals/date-select-modal/date-select-modal.module';
import { CommonModule } from '@angular/common';
import { ProjectSearchModule } from './component/project-search/projectSearch.module';
import { CustomMultiSelectModule } from '@shared-evaluateur/components/multiselect/multiselect.module';
import { TableauProjetModule } from '@shared-evaluateur/components/tableau-projet/tableau-projet.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoAffectationModalModule } from '@shared-evaluateur/components/modals/auto-affectation-modal/auto-affectation-modal.module';
import { DirectionRegionSelectModalModule } from '@shared-evaluateur/components/modals/direction-regionale-select-modal/direction-regionale-select-modal.module';
import { AssignProjetComiteModalComponent } from './component/assign-projet-comite-modal/assign-projet-comite-modal.component';
import { SharedUiModule } from '@shared-ui';
import { DateEnvoiModalComponent } from './component/date-envoi-modal/date-envoi-modal.component';
import { DecisionComiteModalComponent } from './component/decision-comite-modal/decision-comite-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SidenavProjetsModule,
    RouterModule,
    SharedModule,
    MaterialModule,
    MatCheckboxModule,
    MatMenuModule,
    AffectationIntervenantModalModule,
    AutoAffectationModalModule,
    DateSelectModalModule,
    DirectionRegionSelectModalModule,
    CustomToastModule,
    CustomMultiSelectModule,
    ProjectSearchModule,
    TableauProjetModule,
    NgbPopoverModule,
    SharedUiModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  declarations: [ProjetListComponent, AssignProjetComiteModalComponent, DateEnvoiModalComponent, DecisionComiteModalComponent],
  exports: [ProjetListComponent],
  providers: [],
})
export class ProjetListModule {}
