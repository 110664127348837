import { Component, OnInit } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { Router } from '@angular/router';
import { PxlOidcService } from '@services-evaluateur/pxl-oidc.service';
import { EnumAuthConfigurationType } from '@shared-ui';

@Component({
  selector: 'pxl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: any;
  isMcb: boolean;

  constructor(private router: Router, public oidcSecurityService: PxlOidcService, public sharedFunction: SharedFunction) {}

  ngOnInit(): void {
    this.isMcb = localStorage.getItem('idp') === EnumAuthConfigurationType.MCB;
    this.oidcSecurityService.getUserData().subscribe(userData => {
      this.user = userData;
    });
  }

  deconnexion(): void {
    const configId = localStorage.getItem('idp');
    if (configId === EnumAuthConfigurationType.ISAM) {
      const x = window.open(environment.isam.stsServer + '/pkmslogout', '_blank');
      this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
        x.blur();
        x.close();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      });
    } else {
      this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {});
    }
  }

  monProfilBpi(): void {
    window.open(environment.mcb.profil, '_blank');
  }
}
