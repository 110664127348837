import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentDematerialisesComponent } from './document-dematerialises.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [DocumentDematerialisesComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedUiModule],
  exports: [DocumentDematerialisesComponent],
})
export class DocumentDematerialisesModule {}
