<div class="div_container" *ngIf="demandeInscription">
  <div class="page-content submit-success div_page_content">
    <div class="img-container">
      <img src="assets/images/inscription-envoyee.svg" alt="Inscription envoyée" />
    </div>

    <h4>
      {{
        demandeInscription?.instructeur?.prenom +
          ' ' +
          demandeInscription?.instructeur?.nom +
          ' (' +
          demandeInscription?.instructeur?.email +
          ') '
      }}
      demande une validation de son accès à la plateforme PICXEL
    </h4>

    <p>Validez le compte pour lui ouvrir l’accès à PICXEL en tant que membre de l’organisme {{ demandeInscription?.organisme?.nom }}</p>
    <div class="div_button">
      <button id="validation_inscription_btn_valider" type="submit" class="ds-btn ds-btn--primary" (click)="validateProfile()">
        <span>Autoriser le profil</span>
      </button>
      <button id="validation_btn_annuler" type="button" class="ds-btn ds-btn--secondary" (click)="navigateToAaps()">
        Ignorer le profil
      </button>
    </div>
  </div>
</div>
