import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pxl-document-dematerialises',
  templateUrl: './document-dematerialises.component.html',
  styleUrls: ['./document-dematerialises.component.scss'],
})
export class DocumentDematerialisesComponent {
  @Input() documentationDematerialiseForm: UntypedFormGroup;
  @Input() stepNumber: number;
  infoMessage =
    "Lorsqu'un parcours dématérialisé est activé ci-dessous, merci de s'assurer que son pendant au niveau fichier n'est pas sélectionné dans la section Documentation.";
}
