<!-- Upload fichiers -->
<label for="file" class="ds-input-group__label"
  >Rapport d'instruction
  <p class="info-size-message">Poids maximum par fichier {{ FILE_SIZE_LIMIT }}Mo</p>
</label>

<div class="border-grid">
  <div class="file-upload-grid">
    <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
    <div class="infos">
      <span class="grid-title">Rapport d'instruction</span>
    </div>
    <label class="file-upload__custom-input" *ngIf="canUserWrite">
      <input
        type="file"
        class="file-input"
        id="file"
        onclick="this.value=null;"
        (change)="onDocumentFileSelected($event.target.files)"
        [disabled]="!canUserWrite"
        accept=".pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .jpg, .csv"
      />
      <input type="hidden" class="file-input" name="fileHidden" />
      <div id="add-fichier" class="ds-btn ds-btn--secondary" [ngClass]="{ disabled: !canUserWrite }">
        <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
        Déposer un rapport d'instruction
      </div>
    </label>
  </div>
  <div class="documents-container">
    <div class="document-rapport-instruction" *ngFor="let doc of rapportInstructionDocuments">
      <lib-document-light *ngIf="projet"
        [projetId]="projet.id"
        [document]="doc"
        [subDirectory]="SUBDIRECTORY"
        [canUserWrite]="canUserWrite"
        (deleteDocument)="deleteDocument($event)"
      >
      </lib-document-light>
    </div>
  </div>
</div>
<!-- Fin upload fichiers -->
