<div class="filters-container">
  <div class="search-container">
    <div class="d-flex justify-content-between mb-3">
      <div class="single-filter-container" (focusout)="onProgrammesLeave()" (mouseleave)="onProgrammesLeave()">
        <label class="ds-input-group__label filter-label">Programme<lib-asterisk /></label>
        <lib-ds-multiselect
          #programmeComponent
          class="filter"
          [dataList]="programmeDataList"
          [selectOptions]="{ placeholder: 'Programme' }"
          [name]="'programme-filter__' + phase"
        >
        </lib-ds-multiselect>
      </div>

      <div class="single-filter-container" (click)="onAapsLeave()">
        <label class="ds-input-group__label filter-label">Appel à projet<lib-asterisk /></label>
        <lib-ds-multiselect
          #aapComponent
          class="filter"
          [dataList]="aapDataList"
          [selectOptions]="{ placeholder: 'Appel à projet', isDisabled: true }"
          [name]="'aap-filter__' + phase"
        >
        </lib-ds-multiselect>
      </div>

      <div class="single-filter-container">
        <label class="ds-input-group__label filter-label">Date de relève<lib-asterisk /></label>
        <lib-ds-multiselect
          #dateReleveComponent
          class="filter"
          [dataList]="dateReleveDataList"
          [selectOptions]="{ placeholder: 'Date de relève', isDisabled: true }"
          [name]="'releve-filter__' + phase"
        >
        </lib-ds-multiselect>
      </div>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <div class="single-filter-container">
        <label class="ds-input-group__label filter-label">Etapes</label>
        <lib-ds-multiselect
          #etapeComponent
          class="filter"
          [dataList]="etapeDataList"
          [selectOptions]="{ placeholder: 'Etapes' }"
          [name]="'etape-filter__' + phase"
        >
        </lib-ds-multiselect>
      </div>

      <div class="single-filter-container">
        <label class="ds-input-group__label filter-label">Statuts</label>
        <lib-ds-multiselect
          #statutComponent
          class="filter"
          [dataList]="statutDataList"
          [selectOptions]="{ placeholder: 'Statuts' }"
          [name]="'statut-filter__' + phase"
        >
        </lib-ds-multiselect>
      </div>

      <div class="single-filter-container">
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <div class="toggle-title-container">
            <label for="select__budget" class="ds-input-group__label">Budget présenté du projet</label>
          </div>
          <select id="select__budget" name="select__budget" [(ngModel)]="budgetProjet">
            <option [ngValue]="undefined" selected>Budget présenté du projet</option>
            <option *ngFor="let budget of budgetValues" [value]="budget.id">{{ budget.value }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div class="single-filter-container">
        <label class="ds-input-group__label filter-label">Thématiques (de ou des AAPs sélectionnés)</label>
        <lib-ds-multiselect
          #thematiqueComponent
          class="filter"
          [dataList]="thematiqueDataList"
          [selectOptions]="{ placeholder: 'Thématiques (de ou des AAPs sélectionnés)', isDisabled: true }"
          [name]="'thematique-filter__' + phase"
        >
        </lib-ds-multiselect>
      </div>
    </div>

    <div class="search-buttons-container">
      <button type="button" class="ds-btn ds-btn--secondary" (click)="resetAllFilters()">
        <span class="ds-icon ds-icon-left ds-float--left" aria-hidden="true">refresh</span>
        Réinitialiser
      </button>
      <button id="actions-btn" class="ds-btn ds-btn--primary" (click)="submitSearch()">Rechercher</button>
    </div>
  </div>
</div>
