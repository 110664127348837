import {
  Aap,
  ComiteProjet,
  DocumentAapModel,
  EnumDecisionComite,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumProjetTag,
  EnumScope,
  EnumTypePartenaire,
  ProjetLight,
  ProjetTableElement,
} from '@shared-ui';
import { AuteurUtils } from './auteur-utils';

export class ProjetUtils {
  public static readonly EMPTY_FIELD = '-';

  public static convertProjetLightToTableElement(projets: ProjetLight[] | undefined): ProjetTableElement[] {
    if (projets == null || !projets.length) {
      return [];
    }

    return projets.map(proj => {
      return <ProjetTableElement>{
        id: proj.id,
        identifiantPXL: proj.identifiantPXL,
        aapId: proj.aapId,
        aapCode: proj.aapCode || proj.aapNom,
        aapNomProgramme: proj.aapNomProgramme,
        acronyme: proj.acronyme || proj.nom,
        budget: proj.budget || this.EMPTY_FIELD,
        montantAideDemande: proj.montantAideDemande || this.EMPTY_FIELD,
        etape: EnumProjetEtape.toString(proj.derniereEtapeNom),
        statut: proj.statut
          ? EnumProjetStatut.toString(proj.statut, EnumProjetEtape.hasNomFeminin(proj.derniereEtapeNom))
          : this.EMPTY_FIELD,
        tag: proj.tag && proj.tag.toString() !== EnumProjetTag[EnumProjetTag.FERME] ? EnumProjetTag[proj.tag] : this.EMPTY_FIELD,
        relevePreDepot: proj.relevePreDepot,
        releveDepot: proj.releveDepot,
        dateEvaluation: proj.dateEvaluation,
        dateAudition: proj.dateAudition,
        libelleThematique: proj.libelleThematique || this.EMPTY_FIELD,
        structureCf: proj.structureCf?.raisonSocial || this.EMPTY_FIELD,
        partenaireType: EnumTypePartenaire.toString(proj.partenaireType),
        dateReorientation: proj.dateReorientation,
        auteurReorientation: AuteurUtils.getAuteurFullname(proj.auteurReorientation) || this.EMPTY_FIELD,
      };
    });
  }

  public static convertProjetComiteToTableElement(projets: ComiteProjet[] | undefined): ProjetTableElement[] {
    if (projets == null || !projets.length) {
      return [];
    }
    return projets.map(proj => {
      return <ProjetTableElement>{
        id: proj.id,
        aapCode: proj.aapCode || proj.aapNom,
        aapId: proj.aapId,
        acronyme: proj.acronyme || proj.nom,
        structureCf: proj.structureCf?.raisonSocial || this.EMPTY_FIELD,
        libelleThematique: proj.libelleThematique || this.EMPTY_FIELD,
        budget: proj.budget || this.EMPTY_FIELD,
        propositionComite: EnumDecisionComite.toString(proj?.propositionComite) || this.EMPTY_FIELD,
        decisionComite: EnumDecisionComite.toString(proj?.decisionComite) || this.EMPTY_FIELD,
        dateDecisionEtat: proj.dateDecisionEtat,
        relevePreDepot: proj.relevePreDepot,
        releveDepot: proj.releveDepot,
        dateReorientation: proj.dateReorientation,
        auteurReorientation: AuteurUtils.getAuteurFullname(proj.auteurReorientation),
      };
    });
  }

  public static listDocProjet(
    aap: Aap,
    partenaireType: EnumTypePartenaire,
    etapeProjet: EnumProjetEtape,
    scope: EnumScope
  ): DocumentAapModel[] {
    const listDocProjet = aap.documentAaps?.filter((document: any) => {
      if (document.etapes.includes(etapeProjet) && document.typePartenaires?.includes(partenaireType) && document.scope === scope) {
        return document;
      }
    });
    return listDocProjet.sort((a: { nomDocument: string }, b: { nomDocument: string }) => (a.nomDocument > b.nomDocument ? 1 : -1));
  }
}
