import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@shared-ui';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComitesListComponent } from './comites.list.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ComitesTableauModule } from './comites-tableau/comites-tableau.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalModule } from '@shared-evaluateur/components/modals/confirm-modal/confirm-modal.module';

import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { ComiteDecisionModalModule } from '@shared-evaluateur/components/modals/comite-decision-modal/comite-decision-modal.module';
import { ComiteSearchModule } from '@features-evaluateur/comites/comites-list/comite-search/comite-search.module';

@NgModule({
  declarations: [ComitesListComponent],
  imports: [
    CommonModule,
    ComitesTableauModule,
    ConfirmModalModule,
    ComiteDecisionModalModule,
    SharedUiModule,
    NgbPopoverModule,
    SharedModule,
    ComiteSearchModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
    MatCheckboxModule,
    MatTooltipModule,
    MaterialModule,
  ],
  exports: [ComitesListComponent],
})
export class ComitesListModule {}
