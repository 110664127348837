import { Component, Input, OnInit } from '@angular/core';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { Aap, Projet, Utilisateur } from '@shared-ui';
import { ProjetSyntheseNotationBaseComponent } from '@shared-evaluateur/components/projet-synthese-notation-base/projet-synthese-notation-base.component';

@Component({
  selector: 'pxl-projet-instruction-notation',
  templateUrl: './projet-instruction-notation.component.html',
  styleUrls: ['./projet-instruction-notation.component.scss'],
})
export class ProjetInstructionNotationComponent extends ProjetSyntheseNotationBaseComponent implements OnInit {
  @Input() aap: Aap = new Aap();
  @Input() projet: Projet = new Projet();
  @Input() utilisateur: Utilisateur = new Utilisateur();
  @Input() isUserReadOnly!: boolean;
  @Input() canUserWrite!: boolean;

  readMore = false;
  readMore2 = false;

  isLongCommentaire = false;
  isLongRecommandation = false;

  constructor(public sharedFunction: SharedFunction) {
    super(sharedFunction);
  }

  ngOnInit(): void {
    this.isLongCommentaire = this.isTextLong(this.notation?.commentaire?.length);
    this.isLongRecommandation = this.isTextLong(this.notation?.recommandationIntentionCandidat?.length);
  }

  afficherPlus(): void {
    this.readMore = !this.readMore;
  }

  afficherPlus2(): void {
    this.readMore2 = !this.readMore2;
  }

  getEvaluateurAbsentMessage(): string {
    return this.sharedFunction.isSiteAdmin()
      ? 'Cet évaluateur ne s’est jamais connecté sur PICXEL, il n’est actuellement pas possible de lui associer une notation.'
      : ' ';
  }
}
