import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { DemandeQlik } from '@shared-ui';
import { FicheCom } from 'libs/shared-ui/src/lib/shared/models/fiche-com.model';
import { Observable } from 'rxjs';

@Injectable()
export class FicheCommunicationService {
  constructor(private httpClient: HttpClient) {}

  createFicheCommunication(fiche: FicheCom, idProjet: string): Observable<HttpResponse<void>> {
    return this.httpClient.put<void>(environment.apiUrl + 'projets/' + idProjet + '/fiche-communication', JSON.stringify(fiche), {
      observe: 'response',
    });
  }

  createDemandeQlik(demandeqlik: DemandeQlik): Observable<HttpResponse<DemandeQlik>> {
    return this.httpClient.post<DemandeQlik>(environment.apiUrl + 'fiche-communication/qlik', JSON.stringify(demandeqlik), {
      observe: 'response',
    });
  }
}
