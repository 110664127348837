import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DepensesProjetConfig,
  RegimesAide,
  SubscriptionDestroyerComponent
} from '@shared-ui';

@Injectable()
export class CreationRegimesAidesHelperService extends SubscriptionDestroyerComponent {
  constructor() {
    super();
  }

  createForm(): FormGroup<any> {
    return new FormGroup<any>({
      nom: new FormControl(null, Validators.required),
    });
  }

  createReferentielFromForm(form: FormGroup<any>, isModification: boolean, valueRegime: string) : DepensesProjetConfig {
    form.markAllAsTouched();

    const depensesProjetConfig : DepensesProjetConfig = {
      tauxConfig: {
        pourcentageSolde: form.get('pourcentageSolde')?.value,
        tauxCE: form.get('tauxCE')?.value,
        versementMinimum: form.get('versementMinimum')?.value,
        differeRemboursement: form.get('differeRemboursement')?.value,
      },
      premierVersementConfig: {
        tauxPetiteEntreprise: form.get('tauxPetiteEntreprise')?.value,
        tauxMoyenneEntreprise: form.get('tauxMoyenneEntreprise')?.value,
        tauxEntrepriseIntermediaire: form.get('tauxEntrepriseIntermediaire')?.value,
        tauxGrandeEntreprise: form.get('tauxGrandeEntreprise')?.value,
        tauxLaboratoireRecherche: form.get('tauxLaboratoireRecherche')?.value,
      },
      regimesAide: form.get('regimesAide')?.value.push({ nom: form.get('nom')?.value, actif: true }),
    };
    depensesProjetConfig.regimesAide = form.get('regimesAide')?.value;

    if (isModification) {
      depensesProjetConfig.regimesAide = depensesProjetConfig.regimesAide.filter((regimeAide: RegimesAide) => regimeAide.nom !== valueRegime);
    }

    return depensesProjetConfig;
  }
}
