import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { CreationIntervenantComponent } from './creation-intervenant.component';

@NgModule({
  declarations: [CreationIntervenantComponent],
  imports: [CommonModule, SharedModule, SharedUiModule],
})
export class CreationIntervenantModule {}
