import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnumFeatureFlipping, EnumTypeCritere } from '@shared-ui';
import { ParametrageReferentielComponent } from './parametrage-referentiel/parametrage-referentiel.component';
import { ReferentielsComponent } from './referentiels.component';
import { CreationReferentielComponent } from './creation-referentiel/creation-referentiel.component';
import { AuthenticationGuard } from '@core-evaluateur/authentification.guard';
import { CanDeactivateGuard } from '../../guards/can-deactivate-guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { FeatureFlippingGuard } from '@core-evaluateur/feature-flipping.guard';
import { ParametrageIntervenantComponent } from './parametrage-intervenant/parametrage-intervenant.component';
import { CreationIntervenantComponent } from './creation-intervenant/creation-intervenant.component';
import { ParametrageDocumentsAapComponent } from './parametrage-documents-aap/parametrage-documents-aap.component';
import { CreationDocumentsAapComponent } from './creation-documents-aap/creation-documents-aap.component';
import { CreationDispositifComponent } from './creation-dispositif/creation-dispositif.component';
import { ParametrageDepensesProjetsComponent } from './parametrage-depenses-projets/parametrage-depenses-projets.component';
import { CreationDepensesProjetsComponent } from './creation-depenses-projets/creation-depenses-projets.component';
import { CreationRegimesAidesComponent } from './creation-regimes-aides/creation-regimes-aides.component';

const routes: Routes = [
  {
    path: 'referentiels',
    canActivate: [AuthenticationGuard, NgxPermissionsGuard, FeatureFlippingGuard],
    data: {
      permissions: {
        only: ['PARAMETRAGE_WRITE', 'PARAMETRAGE_READ'],
        redirectTo: '',
      },
      featureName: EnumFeatureFlipping.REFERENTIELS,
    },
    children: [
      { path: '', component: ReferentielsComponent },
      {
        path: 'criteres-evaluation',
        component: ParametrageReferentielComponent,
        canActivate: [FeatureFlippingGuard],
        data: {
          type: EnumTypeCritere.CRITERE_EVALUATION,
          creationPath: 'creation-critere-evaluation',
          featureName: EnumFeatureFlipping.CRITERE_EVAL_INDICATEUR_ELIGIBILITE,
          modificationPath: 'modification-critere-evaluation',
        },
      },
      {
        path: 'indicateurs-eligibilite',
        component: ParametrageReferentielComponent,
        canActivate: [FeatureFlippingGuard],
        data: {
          type: EnumTypeCritere.INDICATEUR_ELIGIBILITE,
          creationPath: 'creation-indicateur-eligibilite',
          featureName: EnumFeatureFlipping.CRITERE_EVAL_INDICATEUR_ELIGIBILITE,
          modificationPath: 'modification-indicateur-eligibilite',
        },
      },
      {
        path: 'strategie-acceleration',
        component: ParametrageReferentielComponent,
        canActivate: [FeatureFlippingGuard],
        data: {
          type: EnumTypeCritere.DISPOSITIFS,
          creationPath: 'creation-strategie-acceleration',
          featureName: EnumFeatureFlipping.STRATEGIE_ACCELERATION,
          modificationPath: 'modification-strategie-acceleration',
        },
      },
      {
        path: 'intervenant-document-commercial',
        component: ParametrageIntervenantComponent,
        canActivate: [FeatureFlippingGuard],
        data: {
          type: EnumTypeCritere.ROLE_CREATION_DC,
          creationPath: 'creation-intervenant-document-commercial',
          featureName: EnumFeatureFlipping.ROLE_CREATION_DC,
        },
      },
      {
        path: 'depenses-projets',
        component: ParametrageDepensesProjetsComponent,
        data: {
          type: EnumTypeCritere.DEPENSES_PROJETS,
          creationPath: 'creation-regimes-aides',
          modificationPathDepensesProjet: 'modification-depenses-projets',
          modificationPathRegimesAides: 'modification-regimes-aides',
        },
      },
      {
        path: 'documents-aap',
        component: ParametrageDocumentsAapComponent,
        data: {
          type: EnumTypeCritere.DOCUMENT_AAP,
          creationPath: 'creation-document-aap',
          modificationPath: 'modification-documents-aap',
        },
      },
      {
        path: 'creation-critere-evaluation',
        component: CreationReferentielComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [FeatureFlippingGuard],
        data: {
          type: EnumTypeCritere.CRITERE_EVALUATION,
          referentielPath: 'criteres-evaluation',
          featureName: EnumFeatureFlipping.CRITERE_EVAL_INDICATEUR_ELIGIBILITE,
        },
      },
      {
        path: 'creation-indicateur-eligibilite',
        component: CreationReferentielComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [FeatureFlippingGuard],
        data: {
          type: EnumTypeCritere.INDICATEUR_ELIGIBILITE,
          referentielPath: 'indicateurs-eligibilite',
          featureName: EnumFeatureFlipping.CRITERE_EVAL_INDICATEUR_ELIGIBILITE,
        },
      },
      {
        path: 'creation-intervenant-document-commercial',
        component: CreationIntervenantComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [FeatureFlippingGuard],
        data: {
          type: EnumTypeCritere.ROLE_CREATION_DC,
          referentielPath: 'intervenant-document-commercial',
          featureName: EnumFeatureFlipping.ROLE_CREATION_DC,
        },
      },
      {
        path: 'modification-depenses-projets',
        component: CreationDepensesProjetsComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.DEPENSES_PROJETS,
          referentielPath: 'depenses-projets',
        },
      },
      {
        path: 'creation-regimes-aides',
        component: CreationRegimesAidesComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.REGIMES_AIDES,
          referentielPath: 'depenses-projets',
        },
      },
      {
        path: 'creation-document-aap',
        component: CreationDocumentsAapComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.DOCUMENT_AAP,
          referentielPath: 'documents-aap',
        },
      },
      {
        path: 'modification-regimes-aides',
        component: CreationRegimesAidesComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.REGIMES_AIDES,
          referentielPath: 'depenses-projets',
        },
      },
      {
        path: 'modification-critere-evaluation/:id',
        component: CreationReferentielComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.CRITERE_EVALUATION,
          referentielPath: 'criteres-evaluation',
        },
      },
      {
        path: 'modification-indicateur-eligibilite/:id',
        component: CreationReferentielComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.INDICATEUR_ELIGIBILITE,
          referentielPath: 'indicateurs-eligibilite',
        },
      },
      {
        path: 'modification-documents-aap/:id',
        component: CreationDocumentsAapComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.DOCUMENT_AAP,
          referentielPath: 'documents-aap',
        },
      },
      {
        path: 'modification-strategie-acceleration/:id',
        component: CreationDispositifComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.DISPOSITIFS,
          referentielPath: 'strategie-acceleration',
          featureName: EnumFeatureFlipping.STRATEGIE_ACCELERATION,
        },
      },
      {
        path: 'creation-strategie-acceleration',
        component: CreationDispositifComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          type: EnumTypeCritere.DISPOSITIFS,
          referentielPath: 'strategie-acceleration',
          featureName: EnumFeatureFlipping.STRATEGIE_ACCELERATION,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReferentielRoutingModule {}
