import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomToastComponent } from '@shared-evaluateur/components/toastr/custom-toastr';
import { Aap, EnumProjetEtape, IntervenantRoles, Projet, ShowToastrService } from '@shared-ui';
import { ActiveToast, ToastrService } from 'ngx-toastr';

export interface ModalData {
  title: string;
  subTitle: string;
  aap: Aap;
  affecterEval: boolean;
  aapList: Aap[];
  projectList: Projet[];
  textReturnButton: string;
  textGoButton: string;
}

@Component({
  selector: 'pxl-modal',
  templateUrl: './affectation-intervenant-modal.component.html',
  styleUrls: ['./affectation-intervenant-modal.component.scss'],
})
export class AffectationIntervenantModalComponent implements OnInit, OnDestroy {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();

  readonly emailsInputSizeMax = 500;

  affectationForm: UntypedFormGroup;
  activeToast: ActiveToast<any>;

  isEvaluateurModal = false;
  selectedProjects: Array<Projet>;
  selectedAaps: Array<Aap>;

  enableEvaluation = false;
  enableAudition = false;
  isEmailsValid = true;

  // Form get variables
  get formRole(): UntypedFormControl {
    return this.affectationForm.get('role') as UntypedFormControl;
  }

  get formEmails(): UntypedFormControl {
    return this.affectationForm.get('emails') as UntypedFormControl;
  }

  get formEtape(): UntypedFormControl {
    return this.affectationForm.get('etape') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<AffectationIntervenantModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private formBuilder: UntypedFormBuilder,
    private showToastrService: ShowToastrService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.modalData.projectList) {
      if (this.modalData.affecterEval) {
        // Si la modale a été appelée pour l'affectation d'évaluateurs de projets
        this.affectationForm = this.formBuilder.group({
          emails: [null, [Validators.required]],
          role: [null, [Validators.required]],
          etape: [null, [Validators.required, this.etapeValidator]],
        });
        this.selectedProjects = this.modalData.projectList;
        this.isEvaluateurModal = true;
        this.enableEvaluation = this.modalData.aap.notationEvaluationPresent;
        this.enableAudition = this.modalData.aap.notationAuditionPresent;
      } else {
        this.affectationForm = this.formBuilder.group({
          emails: [null, [Validators.required]],
        });
        this.isEvaluateurModal = true;
        this.selectedProjects = this.modalData.projectList;
      }
    } else {
      // Si la modale a été appelée pour l'affectation de superviseurs d'aaps
      this.affectationForm = this.formBuilder.group({
        emails: [null, [Validators.required]],
      });
      this.selectedAaps = this.modalData.aapList;
    }
    this.affectationForm.get('emails').valueChanges.subscribe(() => {
      this.isEmailsValid = true;
    });
  }

  confirmerAffectation(): void {
    // Si un toastr d'erreur est déjà ouvert, on le ferme
    if (this.activeToast) {
      this.showToastrService.clearToastr(this.activeToast.toastId);
    }

    if (!this.isEmailsValid) {
      const incorrectList: string[] = this.findIncorrectEmails();
      this.isEmailsValid = incorrectList.length === 0;
    }

    this.affectationForm.markAllAsTouched();

    if (!this.isEmailsValid && this.formEmails.value?.length) {
      this.showEmailsError();
    }

    if (!this.affectationForm.valid || !this.isEmailsValid) {
      return;
    }

    const emails = this.formEmails.value
      .split(';')
      .map((email: string) => email.trim())
      .filter((email: string) => email !== '');

    if (this.isEvaluateurModal) {
      const projectsIdArray = this.selectedProjects.map(project => project.id);
      if (this.modalData.affecterEval) {
        const role = this.formRole.value;
        const notationEtapes = [this.formEtape?.value];
        this.dialogRef.close({ projectsIdArray, role, emails, notationEtapes });
      } else {
        this.dialogRef.close({ projectsIdArray, emails });
      }
    } else {
      const aapsIdArray = this.selectedAaps.map(aap => aap.id);
      this.dialogRef.close({ aapsIdArray, emails });
    }
  }

  findIncorrectEmails(): string[] {
    const incorrectList: string[] = [];

    let emailsList = this.formEmails.value.split(';');
    emailsList = emailsList.filter((email: string) => email.trim() !== '');

    // Alimentation de la liste d'emails incorrects
    emailsList.forEach((email: string) => {
      const trimmedEmail = email.trim();
      if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(trimmedEmail)) {
        incorrectList.push(trimmedEmail);
      }
    });

    return incorrectList;
  }

  /**
   *  Affiche le toastr d'erreur
   */
  showEmailsError(): void {
    const incorrectList: string[] = this.findIncorrectEmails();

    // Ouvre un toastr d'erreur
    this.activeToast = this.toastr.error(null, 'Emails incorrects.', {
      closeButton: true,
      toastComponent: CustomToastComponent,
      tapToDismiss: false,
      disableTimeOut: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: {
        incorrectList,
        type: CustomToastComponent.EMAIL_ERROR,
      },
    });
  }

  /**
   * Validator de form qui teste l'étape
   * @param control form control
   * @returns validateur du form control
   */
  etapeValidator = (control: UntypedFormControl): ValidationErrors => {
    if (!this || !this.selectedProjects) {
      return null;
    }
    if (control.value === EnumProjetEtape.AUDITION) {
      return this.allProjectsHaveAuditionDate() && this.allProjectsHaveAuditionPhase() ? null : { valid: true };
    } else if (control.value === EnumProjetEtape.EVALUATION) {
      return this.allProjectsHaveEvaluationDate() && this.allProjectsHaveEvaluationPhase() ? null : { valid: true };
    } else {
      return this.allProjectsHaveAuditionDate() &&
        this.allProjectsHaveEvaluationDate() &&
        this.allProjectsHaveAuditionPhase() &&
        this.allProjectsHaveEvaluationPhase()
        ? null
        : { valid: true };
    }
  };

  allProjectsHaveAuditionDate(): boolean {
    return this.selectedProjects.find(projet => projet.dateAudition == null) == null;
  }

  allProjectsHaveEvaluationDate(): boolean {
    return this.selectedProjects.find(projet => projet.dateEvaluation == null) == null;
  }

  allProjectsHaveAuditionPhase(): boolean {
    return this.selectedProjects.find(projet => !projet.phaseAudition) == null;
  }

  allProjectsHaveEvaluationPhase(): boolean {
    return this.selectedProjects.find(projet => !projet.phaseEvaluation) == null;
  }

  etapeRadioTouched(): void {
    this.formEtape.markAsTouched();
  }

  originalOrder = (): number => {
    return 0;
  };

  disableEtapeRadioButton(etape: EnumProjetEtape): boolean {
    return (etape === EnumProjetEtape.EVALUATION && !this.enableEvaluation) || (etape === EnumProjetEtape.AUDITION && !this.enableAudition);
  }

  getRadioTooltipMessage(etape: EnumProjetEtape): string {
    if (this.disableEtapeRadioButton(etape)) {
      if (!this.enableEvaluation) {
        return `La notation de la phase évaluation (présélection pour l'audition) n'est pas ouverte sur cet AAP, il n'est pas possible d'affecter un évaluateur sur cette étape`;
      }
      if (!this.enableAudition) {
        return `La notation de la phase audition (présélection pour l'instruction) n'est pas ouverte sur cet AAP, il n'est pas possible d'affecter un évaluateur sur cette étape`;
      }
    }

    return '';
  }

  ngOnDestroy(): void {
    // Si un toastr d'erreur est déjà ouvert, on le ferme
    if (this.activeToast) {
      this.showToastrService.clearToastr(this.activeToast.toastId);
    }
  }

  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly etapesAffectation = [EnumProjetEtape.EVALUATION, EnumProjetEtape.AUDITION];
  protected readonly IntervenantRoles = IntervenantRoles;
  protected readonly rolesIntervenantAffectable = [IntervenantRoles.EXPERT_MINISTERIEL, IntervenantRoles.EXPERT_INDEPENDANT];
}
