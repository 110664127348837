import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrilleImpactTableauComponent } from './grille-impact-tableau.component';
import { SharedUiModule } from '@shared-ui';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [GrilleImpactTableauComponent],
  imports: [CommonModule, SharedUiModule, MatTableModule, MatCheckboxModule, MatSortModule],
  exports: [GrilleImpactTableauComponent],
})
export class GrilleImpactTableauModule {}
