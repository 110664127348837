<div class="tableau-title">
  <div>Référentiel des {{ title }}</div>
</div>

<div class="table">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="label"
    matSortDirection="asc"
    matSortDisableClear
    aria-describedby="tableau de référentiel des critères"
  >
    <ng-container *ngIf="!isDispositif">
      <ng-container matColumnDef="label" class="">
        <th id="critere-label" mat-header-cell *matHeaderCellDef mat-sort-header>Critères</th>
        <td id="label" mat-cell *matCellDef="let element">
          <span>{{ element.label }}</span>
        </td>
      </ng-container>
    </ng-container>
    <!-- code et nom dispositif -->
    <ng-container *ngIf="isDispositif">
      <ng-container matColumnDef="code" class="">
        <th id="critere-label" mat-header-cell *matHeaderCellDef mat-sort-header>Code Dispositif</th>
        <td id="code" mat-cell *matCellDef="let element">
          <span>{{ element.code }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="nom" class="">
        <th id="critere-label" mat-header-cell *matHeaderCellDef mat-sort-header>Dispositif</th>
        <td id="nom" mat-cell *matCellDef="let element">
          <span>{{ element.nom }}</span>
        </td>
      </ng-container>
    </ng-container>
    <!-- Actif / Inactif -->
    <ng-container matColumnDef="actif" class="">
      <th id="critere-actif" mat-header-cell *matHeaderCellDef mat-sort-header>Actif / Inactif</th>
      <td id="actif" mat-cell *matCellDef="let element">
        <span>{{ element?.actif ? 'Actif' : 'Inactif' }}</span>
      </td>
    </ng-container>
    <!-- Date debut et fin dispositif -->
    <ng-container *ngIf="!isDispositif">
      <ng-container matColumnDef="dateCreation" class="">
        <th id="critere-dateCreation" mat-header-cell *matHeaderCellDef mat-sort-header>Date de création</th>
        <td id="dateCreation" mat-cell *matCellDef="let element">
          <span>{{ element.dateCreation | date : 'dd/MM/yyyy' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="dateModification" class="">
        <th id="critere-dateModification" mat-header-cell *matHeaderCellDef mat-sort-header>Date de modification</th>
        <td id="dateModification" mat-cell *matCellDef="let element">
          <span>{{ element.dateModification | date : 'dd/MM/yyyy' }}</span>
        </td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isDispositif">
      <ng-container matColumnDef="dateDebut" class="">
        <th id="critere-dateDebut" mat-header-cell *matHeaderCellDef mat-sort-header>Date de début</th>
        <td id="dateDebut" mat-cell *matCellDef="let element">
          <span>{{ element.dateDebut | date : 'dd/MM/yyyy' }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateFin" class="">
        <th id="critere-dateFin" mat-header-cell *matHeaderCellDef mat-sort-header>Date de fin</th>
        <td id="dateFin" mat-cell *matCellDef="let element">
          <span>{{ element.dateFin | date : 'dd/MM/yyyy' }}</span>
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th id="aap-actions" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          id="update-critere"
          class="table__button"
          matTooltip="Modifier critère"
          (click)="updateCritere(element); $event.stopPropagation()"
        >
          <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
  </table>
</div>
<div class="no-result" *ngIf="dataSource?.data?.length === 0">
  <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
  <span id="large-span-result">Il n'y a rien ici...</span>
  <span id="small-span-result">0 critère répertorié.</span>
</div>
