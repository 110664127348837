<!-- Entête de la page -->
<div id="titre_page" class="page-header">
  <a class="page-header__back" (click)="goToListComites()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Retourner sur la liste de comités
  </a>
  <h2 class="page-header__title">{{ getTitle() }}</h2>
</div>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <div class="mb-3">Vous devez renseigner toutes les informations avec <lib-asterisk /></div>
  <!-- Section infos comite -->
  <div id="card-info" class="card form-step" [formGroup]="formCreation" *ngIf="showForm">
    <h3 id="informations-header">Informations</h3>
    <div class="d-flex justify-content-between">
      <div
        class="ds-input-group nom-container single-field-container"
        [class.ds-input-group--error]="formCreation.controls.nom.invalid && formCreation.controls.nom.touched"
      >
        <label for="label" class="ds-input-group__label">Nom du comité<lib-asterisk /></label>
        <span class="ds-input-group__input">
          <input
            id="input__nom-comite"
            name="input__nom-comite"
            type="text"
            class="ds-input-group__input"
            formControlName="nom"
            placeholder="Nom du comité"
          />
        </span>
      </div>
      <div
        id="date-picker-fermeture"
        class="date-time__date ds-input-group ds-input-group--date single-field-container"
        [class.ds-input-group--error]="formCreation.controls.dateTenue.invalid && formCreation.controls.dateTenue.touched"
      >
        <label class="ds-input-group__label" for="input__date-tenue">Date de tenue<lib-asterisk /></label>
        <span class="ds-input-group__input">
          <input
            matInput
            [matDatepicker]="closeDatePicker"
            formControlName="dateTenue"
            class="ds-input-group__input"
            id="input__date-tenue"
            placeholder="JJ/MM/AAAA"
          />
          <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #closeDatePicker></mat-datepicker>
        </span>
      </div>

      <div
        class="select-container"
        [class.ds-input-group--error]="!formCreation.controls.heureTenue.valid && formCreation.controls.heureTenue.touched"
      >
        <div
          class="ds-input-group__select ds-input-group__select--with-icon single-field-container"
          [class.ds-input-group--error]="!formCreation.controls.heureTenue.valid && formCreation.controls.heureTenue.touched"
        >
          <div class="toggle-title-container">
            <label for="select__heure-tenue" class="ds-input-group__label">Heure de tenue</label>
            <lib-asterisk />
          </div>
          <select id="select__heure-tenue" name="select__heure-tenue" formControlName="heureTenue">
            <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
            <option *ngFor="let time of comitesTimes" [value]="time">{{ time }}</option>
          </select>
        </div>
      </div>

      <div
        class="select-container"
        [class.ds-input-group--error]="!formCreation.controls.programmeId.valid && formCreation.controls.programmeId.touched"
      >
        <div
          class="ds-input-group__select ds-input-group__select--with-icon single-field-container"
          [class.ds-input-group--error]="!formCreation.controls.programmeId.valid && formCreation.controls.programmeId.touched"
        >
          <div class="toggle-title-container">
            <label for="select__programme" class="ds-input-group__label">Programme</label>
          </div>
          <select id="select__programme" name="select__programme" formControlName="programmeId">
            <option [ngValue]="null" selected>Sélectionnez une option</option>
            <option *ngFor="let programme of programmes" [value]="programme.id">{{ programme.nom }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin section infos comite -->

  <ng-container *ngIf="showSearchBar && showForm">
    <div class="mt-3">
      <mat-accordion>
        <lib-expansion-panel [title]="'Présélection'" [subTitle]="preselectionPanelSubtitle" [showOverflow]="true">
          <div panel-content>
            <pxl-project-search-block-comites
              [showSearchBar]="showSearchBar"
              [(selection)]="selectedProjectsEvaluation"
              [phase]="EnumPhaseComite.EVALUATION"
              [programmes]="programmes"
              [comite]="comite"
              [displayedColumns]="displayedColumns"
            >
            </pxl-project-search-block-comites>
          </div>
        </lib-expansion-panel>
      </mat-accordion>
    </div>

    <div class="mt-3">
      <mat-accordion>
        <lib-expansion-panel [title]="'Audition'" [subTitle]="auditionPanelSubtitle" [showOverflow]="true">
          <div panel-content>
            <pxl-project-search-block-comites
              [showSearchBar]="showSearchBar"
              [(selection)]="selectedProjectsAudition"
              [phase]="EnumPhaseComite.AUDITION"
              [programmes]="programmes"
              [comite]="comite"
              [displayedColumns]="displayedColumns"
            >
            </pxl-project-search-block-comites>
          </div>
        </lib-expansion-panel>
      </mat-accordion>
    </div>

    <div class="mt-3">
      <mat-accordion>
        <lib-expansion-panel [title]="'Sélection'" [subTitle]="selectionPanelSubtitle" [showOverflow]="true">
          <div panel-content>
            <pxl-project-search-block-comites
              [showSearchBar]="showSearchBar"
              [(selection)]="selectedProjectsSelection"
              [phase]="EnumPhaseComite.SELECTION"
              [programmes]="programmes"
              [comite]="comite"
              [displayedColumns]="displayedColumns"
            >
            </pxl-project-search-block-comites>
          </div>
        </lib-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>

  <!-- Buttons -->
  <div class="d-flex align-items-center mt-3" *ngIf="canUserEditComite && showForm && (!isEdit || !helperService.isComiteCloture(comite))">
    <ng-container *ngIf="!formCreation.valid && saveClicked">
      <div class="ds-input-group ds-input-group--error w-100">
        <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
      </div>
    </ng-container>
    <div class="buttons-container">
      <button type="button" class="ds-btn ds-btn--tertiary" (click)="goToListComites()">Annuler</button>
      <button
        type="button"
        class="ds-btn ds-btn--secondary"
        (click)="enregistreComite()"
        *ngIf="!isEdit || helperService.isComiteBrouillon(comite)"
      >
        Enregistrer
      </button>
      <button
        id="actions-btn"
        *ngIf="!isEdit || helperService.isComiteBrouillon(comite)"
        class="ds-btn ds-btn--primary"
        (click)="createComite()"
      >
        {{ isEdit ? 'Créer le' : 'Créer un' }} comité
      </button>
      <button
        id="actions-btn"
        *ngIf="isEdit && helperService.isComiteCreated(comite)"
        class="ds-btn ds-btn--primary"
        (click)="createComite()"
      >
        Modifier le comité
      </button>
    </div>
  </div>
  <!-- Fin buttons -->
</div>
