import { EnumTypeOrganisme } from '../enums/enum.organismeType';
import { OrganismeInterface } from '../interfaces/organisme.interface';
import { Departement } from './departement.model';
import { StatutsVisibilite } from './_public_models';

export class OrganismeModel implements OrganismeInterface {
  id: string;
  nom: string;
  raisonSociale: string;
  siret: string;
  radicauxMails: string[] = [];
  instructeursValideursEmails: string[] = [];
  type: keyof typeof EnumTypeOrganisme;
  departements: Departement[];
  statutsVisibilite: StatutsVisibilite[];
  codeThematiques?: string[];
}
