<div class="modal_content">
  <h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

  <form
    id="form-validation-modification-date"
    class="modal__content"
    [formGroup]="modificationDateForm"
    #formDirective="ngForm"
    (ngSubmit)="confirmerDate(formDirective)"
  >
    <div
      class="ds-input-group validation-modification-date"
      [class.ds-input-group--error]="formValidationType.invalid && formValidationType.touched"
    >
      <label for="type" class="ds-input-group__label">Type de la date</label>
      <span class="ds-input-group__label-asterisk"> *</span>
      <div class="ds-input-group__select ds-input-group__select--with-icon">
        <select formControlName="type">
          <option *ngFor="let item of EnumDateType.all()" [value]="item">{{ EnumDateType.toString(item) }}</option>
        </select>
      </div>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-input-field" *ngIf="!formValidationType.valid && formValidationType.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le type</div>
      </ng-container>
    </div>

    <div
      class="ds-input-group validation-modification-nom"
      [class.ds-input-group--error]="formValidationNom.invalid && formValidationNom.touched"
    >
      <label for="nomDate" class="ds-input-group__label">Nom de la date</label>
      <span class="ds-input-group__label-asterisk"> *</span>
      <span class="ds-input-group__input">
        <input
          id="nomDate"
          name="nomDate"
          type="text"
          placeholder="Choisir une valeur"
          class="ds-input-group__input"
          formControlName="nom"
          #nameInput
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-input-field" *ngIf="!formValidationNom.valid && formValidationNom.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le nom</div>
      </ng-container>
    </div>

    <div class="ds-input-group ds-input-group--date">
      <label class="ds-input-group__label"
        >Date
        <span class="ds-input-group__label-asterisk"> *</span>
      </label>
      <span class="ds-input-group__input">
        <input matInput [matDatepicker]="addDatePicker" formControlName="date" class="ds-input-group__input" placeholder="JJ/MM/AAAA" />
        <mat-datepicker-toggle matSuffix [for]="addDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #addDatePicker></mat-datepicker>
      </span>
    </div>

    <div class="date-time__time ds-input-group" [class.ds-input-group--error]="false">
      <label for="heure" class="ds-input-group__label">Heure</label>
      <div id="heure-autre" class="ds-input-group__select ds-input-group__select--with-icon">
        <select id="heure" name="heure" formControlName="heure">
          <option *ngFor="let hour of dayHours" [value]="hour">{{ hour }}</option>
        </select>
      </div>
    </div>
  </form>

  <div id="modal-btn" class="modal__actions">
    <button *ngIf="modalData.textReturnButton" (click)="cancelModal()" class="ds-btn ds-btn--secondary">
      <lib-pxl-icon class="icon" icon="icon-action-close"></lib-pxl-icon> {{ modalData.textReturnButton }}
    </button>
    <button class="ds-btn ds-btn--primary" (click)="confirmerDate(formDirective)" cdkFocusInitial>
      <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon> {{ modalData.textGoButton }}
    </button>
  </div>
</div>
