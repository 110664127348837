<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="inputForm">
  <div
    class="ds-input-group"
    [class.ds-input-group--error]="!inputForm.controls['scenario'].valid && inputForm.controls['scenario'].touched"
  >
    <label *ngIf="!modalData.oldName" for="input-field" class="ds-input-group__label">{{ modalData.description }}</label>

    <ng-container *ngIf="modalData.oldName">
      <div>
        <label for="oldScenario" class="ds-input-group__label fw-bold">Nom du scénario</label>
      </div>
      <span class="ds-input-group__input">
        <input
          id="oldScenario"
          name="oldScenario"
          type="text"
          class="input_scenario ds-input-group__input mb-2"
          value="{{ modalData.oldName }}"
          disabled
        />
      </span>
    </ng-container>

    <div>
      <label for="scenario" class="ds-input-group__label fw-bold"
        >{{ !modalData.oldName ? modalData.text1 : modalData.text2 }}<lib-asterisk></lib-asterisk
      ></label>
    </div>
    <span class="ds-input-group__input">
      <input
        id="scenario"
        name="scenario"
        type="text"
        [placeholder]="!modalData.oldName ? 'Je nomme mon nouveau scénario' : 'Je choisis un nouveau nom'"
        class="input_scenario ds-input-group__input"
        formControlName="scenario"
      />
    </span>

    <!-- Icône et message d'erreur -->
    <ng-container id="error-input-field" *ngIf="!inputForm.controls['scenario'].valid && inputForm.controls['scenario'].touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le champ</div>
    </ng-container>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" (click)="cancelModal()" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" (click)="returnValue()" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
