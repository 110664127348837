import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DepensesProjetConfig, ReferentielHttpService, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';

@Component({
  selector: 'app-parametrage-depenses-projets',
  templateUrl: './parametrage-depenses-projets.component.html',
  styleUrls: ['./parametrage-depenses-projets.component.scss'],
})
export class ParametrageDepensesProjetsComponent extends SubscriptionDestroyerComponent implements OnInit {
  dataSource: MatTableDataSource<DepensesProjetConfig> = new MatTableDataSource();

  modificationPathDepensesProjet: string;
  modificationPathRegimesAides: string;

  depenses: DepensesProjetConfig[] = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    private referentielService: ReferentielHttpService
  ) {
    super();
    this.modificationPathDepensesProjet = this.route.snapshot.data.modificationPathDepensesProjet;
    this.modificationPathRegimesAides = this.route.snapshot.data.modificationPathRegimesAides;
  }

  ngOnInit(): void {
    this.loadDepensesProjets();
  }

  loadDepensesProjets(): void {
    this.referentielService
      .getReferentielDepensesProjetConfig()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<DepensesProjetConfig>) => {
          if (response) {
            this.depenses = [response.body];

            this.dataSource = new MatTableDataSource<DepensesProjetConfig>(this.depenses);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  goToTauxEtVersements(): void {
    this.router.navigate(['../' + this.route.snapshot.data.modificationPathDepensesProjet], { relativeTo: this.route });
  }

  goToRegimesAides(): void {
    this.router.navigate(['../' + this.route.snapshot.data.creationPath], { relativeTo: this.route });
  }

  goToReferentiels(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
