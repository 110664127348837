export const DOMAINES = [
  {
    codeDomaine: 100,
    libelleDomaine: 'Intelligence artificielle et données massives (connaissance, décision)',
    codeSousDomaine: 10010,
    libelleSousDomaine: 'Algorithme',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 100,
    libelleDomaine: 'Intelligence artificielle et données massives (connaissance, décision)',
    codeSousDomaine: 10020,
    libelleSousDomaine: 'Apprentissage automatique (machine learning, deep learning, apprentissage, aides … la décision,...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 100,
    libelleDomaine: 'Intelligence artificielle et données massives (connaissance, décision)',
    codeSousDomaine: 10030,
    libelleSousDomaine: 'Trait. Auto. de scripts, du langage, de la connaissance (Rob. log., NLP, Web sém., TALN, gén. Auto.)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 101,
    libelleDomaine: 'Réseaux numériques (transport du signal, communications élec., infra. hautes performances -cloud)',
    codeSousDomaine: 10110,
    libelleSousDomaine: 'Réseaux de transport (protocoles IP, multicast, routage, MPLS, Cœur, Convergence)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 101,
    libelleDomaine: 'Réseaux numériques (transport du signal, communications élec., infra. hautes performances -cloud)',
    codeSousDomaine: 10120,
    libelleSousDomaine: "Réseaux d'accès (fixes, mobiles, satellites)",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 101,
    libelleDomaine: 'Réseaux numériques (transport du signal, communications élec., infra. hautes performances -cloud)',
    codeSousDomaine: 10130,
    libelleSousDomaine: 'Réseaux locaux (Technologies des objets communicants : P2P, M2M, réseaux de capteurs, RFID, NFC...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 101,
    libelleDomaine: 'Réseaux numériques (transport du signal, communications élec., infra. hautes performances -cloud)',
    codeSousDomaine: 10140,
    libelleSousDomaine: 'Réseaux multimédia et de diffusion audiovisuelle (DVB, hybrides - HbbTV, IPTV)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 101,
    libelleDomaine: 'Réseaux numériques (transport du signal, communications élec., infra. hautes performances -cloud)',
    codeSousDomaine: 10150,
    libelleSousDomaine: 'Administration de réseaux (test, gestion, sécurité, géolocalisation, messagerie VoIP...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 102,
    libelleDomaine: "Traitements du signal / codage de l'information",
    codeSousDomaine: 10210,
    libelleSousDomaine: "Traitement du signal (radiolocalisation, captation, restitution, diffusion d'images & sons, TVHD...)",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 102,
    libelleDomaine: "Traitements du signal / codage de l'information",
    codeSousDomaine: 10220,
    libelleSousDomaine: 'Formats et gestion des données audio, images et vidéo numériques (encodage, compression, MPEG...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 102,
    libelleDomaine: "Traitements du signal / codage de l'information",
    codeSousDomaine: 10230,
    libelleSousDomaine: 'Protection de contenus (DRM, tatouage...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 103,
    libelleDomaine: 'Electronique, Micro et Nanoelectronique',
    codeSousDomaine: 10310,
    libelleSousDomaine: 'Composants actifs et passifs (puces, FPGA, ASIC, SoC, mémoire, microprocesseur, MEMS...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 103,
    libelleDomaine: 'Electronique, Micro et Nanoelectronique',
    codeSousDomaine: 10320,
    libelleSousDomaine: 'Conception électronique (cartes, systèmes, produits)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 103,
    libelleDomaine: 'Electronique, Micro et Nanoelectronique',
    codeSousDomaine: 10330,
    libelleSousDomaine: 'Techniques de fab. microélectronique (intégration 3D, équipements dédiés : implantation, CMP...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 103,
    libelleDomaine: 'Electronique, Micro et Nanoelectronique',
    codeSousDomaine: 10340,
    libelleSousDomaine: 'Electronique organique (composants et circuits)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 103,
    libelleDomaine: 'Electronique, Micro et Nanoelectronique',
    codeSousDomaine: 10350,
    libelleSousDomaine: 'Procédés/techniques relevant de la nanoélectronique',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 104,
    libelleDomaine: "Technologies de mesure-contrôle et d'analyse",
    codeSousDomaine: 10410,
    libelleSousDomaine: 'Capteurs',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 104,
    libelleDomaine: "Technologies de mesure-contrôle et d'analyse",
    codeSousDomaine: 10420,
    libelleSousDomaine: 'Métrologie (outils, méthodes)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 104,
    libelleDomaine: "Technologies de mesure-contrôle et d'analyse",
    codeSousDomaine: 10430,
    libelleSousDomaine: 'Contrôle non destructif',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 104,
    libelleDomaine: "Technologies de mesure-contrôle et d'analyse",
    codeSousDomaine: 10440,
    libelleSousDomaine: "Technologies d'analyse (biologique, filtrage, cycle de vie, analyse des matér., caract. phy....)",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 105,
    libelleDomaine: 'Technologies logicielles',
    codeSousDomaine: 10510,
    libelleSousDomaine: "Architectures systèmes (Architect. mat., Systèmes d'exploitation et embarqués, Bases de données,...)",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 105,
    libelleDomaine: 'Technologies logicielles',
    codeSousDomaine: 10520,
    libelleSousDomaine: "Documents num. et gestion de contenus (trait. de doc. Num., techno. d'acquisition et de restitution)",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 105,
    libelleDomaine: 'Technologies logicielles',
    codeSousDomaine: 10530,
    libelleSousDomaine: 'Sécurité des SI (cryptographie, authentification, protoc. de sécurité, VPN, mesures de protection)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 105,
    libelleDomaine: 'Technologies logicielles',
    codeSousDomaine: 10540,
    libelleSousDomaine: 'Logiciels de conception et modélisation numériques, simulation, HPC',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 106,
    libelleDomaine: 'Interface Homme-Machine  / nouvelles interfaces',
    codeSousDomaine: 10610,
    libelleSousDomaine: 'Réalité virtuelle / Réalité augmentée / spatialisation 3D',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 106,
    libelleDomaine: 'Interface Homme-Machine  / nouvelles interfaces',
    codeSousDomaine: 10620,
    libelleSousDomaine: 'Interactions 3D (Commandes vocales, Interactions gestuelles et visuelles)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 106,
    libelleDomaine: 'Interface Homme-Machine  / nouvelles interfaces',
    codeSousDomaine: 10630,
    libelleSousDomaine: "Analyse de la perception de l'environnement-machine en temps réel",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 107,
    libelleDomaine: 'Robotique',
    codeSousDomaine: 10710,
    libelleSousDomaine: 'Exosquelette/cobot',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 107,
    libelleDomaine: 'Robotique',
    codeSousDomaine: 10720,
    libelleSousDomaine: 'Système robotique mobile',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 107,
    libelleDomaine: 'Robotique',
    codeSousDomaine: 10730,
    libelleSousDomaine: 'Système robotique de manipulation',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 107,
    libelleDomaine: 'Robotique',
    codeSousDomaine: 10740,
    libelleSousDomaine: 'Mécatronique/Plastronique',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 108,
    libelleDomaine: "Analyse d'images",
    codeSousDomaine: 10810,
    libelleSousDomaine: 'Reconstruction 3D',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 108,
    libelleDomaine: "Analyse d'images",
    codeSousDomaine: 10820,
    libelleSousDomaine: "Analyse d'image et de mouvement / Modélisation géométrique",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 108,
    libelleDomaine: "Analyse d'images",
    codeSousDomaine: 10830,
    libelleSousDomaine: 'Animation 2D/3D, animation procédurale, mapping vidéo, capture de mouvement',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 109,
    libelleDomaine: 'Optique/photonique',
    codeSousDomaine: 10910,
    libelleSousDomaine: 'Lasers (cavité, systèmes)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 109,
    libelleDomaine: 'Optique/photonique',
    codeSousDomaine: 10920,
    libelleSousDomaine: 'Composants optiques et photoniques (connectique...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 109,
    libelleDomaine: 'Optique/photonique',
    codeSousDomaine: 10930,
    libelleSousDomaine: 'Imagerie (UV, IR, ultrason, tomographie, résonnance magn., multispectrale, cam., holographie...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 109,
    libelleDomaine: 'Optique/photonique',
    codeSousDomaine: 10940,
    libelleSousDomaine: 'rayonnement (X, gamma)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 109,
    libelleDomaine: 'Optique/photonique',
    codeSousDomaine: 10950,
    libelleSousDomaine: 'Affichage / Eclairage (LED, OLED, LCD, TFT...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 110,
    libelleDomaine: 'Biologie moleculaire et cellulaire',
    codeSousDomaine: 11010,
    libelleSousDomaine: 'Ingénierie génétique (thérapie génique, CRISPR, hybridation, OGM...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 110,
    libelleDomaine: 'Biologie moleculaire et cellulaire',
    codeSousDomaine: 11020,
    libelleSousDomaine: 'Ingénierie cellulaire/tissulaire (thérapie cellulaire...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 110,
    libelleDomaine: 'Biologie moleculaire et cellulaire',
    codeSousDomaine: 11030,
    libelleSousDomaine: 'Bioproduction/biosynthèse (fermentation...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 110,
    libelleDomaine: 'Biologie moleculaire et cellulaire',
    codeSousDomaine: 11040,
    libelleSousDomaine: 'Ingénierie des protéines (anticorps, détection biomarqueurs protéiques...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 110,
    libelleDomaine: 'Biologie moleculaire et cellulaire',
    codeSousDomaine: 11050,
    libelleSousDomaine: 'Génomique (séquençage, criblage, puce à ADN...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 110,
    libelleDomaine: 'Biologie moleculaire et cellulaire',
    codeSousDomaine: 11060,
    libelleSousDomaine: 'Transport ciblé de thérapie (nanoparticules, liposomes...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 110,
    libelleDomaine: 'Biologie moleculaire et cellulaire',
    codeSousDomaine: 11070,
    libelleSousDomaine: 'Microbiologie (ingénieurie du microbiome, alimentaire, des sols...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 111,
    libelleDomaine: 'Génie biologique et alimentaire',
    codeSousDomaine: 11110,
    libelleSousDomaine: 'Agronomie',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 111,
    libelleDomaine: 'Génie biologique et alimentaire',
    codeSousDomaine: 11120,
    libelleSousDomaine: 'Biodiversité, écologie (milieux naturels)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 111,
    libelleDomaine: 'Génie biologique et alimentaire',
    codeSousDomaine: 11130,
    libelleSousDomaine: 'Zootechnie (Sciences des productions animales et aquacoles)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 111,
    libelleDomaine: 'Génie biologique et alimentaire',
    codeSousDomaine: 11140,
    libelleSousDomaine: 'Sélection végetale et animale',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 111,
    libelleDomaine: 'Génie biologique et alimentaire',
    codeSousDomaine: 11150,
    libelleSousDomaine: 'Nutrition humaine et animale, diététique',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 111,
    libelleDomaine: 'Génie biologique et alimentaire',
    codeSousDomaine: 11160,
    libelleSousDomaine: 'Procédé/transformation alimentaire (pasteurisation, cuisson/extrusion, lyophilisation....)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 112,
    libelleDomaine: 'Chimie',
    codeSousDomaine: 11210,
    libelleSousDomaine: 'Synthèse, catalyse chimique, hydrolyse, oxydation, électrochimie...',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 112,
    libelleDomaine: 'Chimie',
    codeSousDomaine: 11220,
    libelleSousDomaine: 'Chimie analytique (chromatographie, spectroscopie, cristallographie, gravimétrie....)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 112,
    libelleDomaine: 'Chimie',
    codeSousDomaine: 11230,
    libelleSousDomaine: 'Produits chimiques biosourcés (algues, chimie ligno-cellulosique, chimie des lipides...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 112,
    libelleDomaine: 'Chimie',
    codeSousDomaine: 11240,
    libelleSousDomaine: 'Formulation (galénique, encapsulation...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 112,
    libelleDomaine: 'Chimie',
    codeSousDomaine: 11250,
    libelleSousDomaine: 'Chimies des colorants/pigments/peintures/adhésifs et autres produits',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11310,
    libelleSousDomaine: 'Polymères, plastiques et composites',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11320,
    libelleSousDomaine: 'Textiles techniques',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11330,
    libelleSousDomaine: 'Métaux, alliages, métallurgie des poudres',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11340,
    libelleSousDomaine: 'Matériaux fonctionnels, fonctionnalisation de surface',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11350,
    libelleSousDomaine: 'Nanomatériaux',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11360,
    libelleSousDomaine: 'Bois et papier',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11370,
    libelleSousDomaine: 'Verres et céramiques',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11380,
    libelleSousDomaine: 'Assemblage des matériaux (collage, soudage, déformation, fixation...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 11390,
    libelleSousDomaine: 'Traitement des matériaux (traitement de surface, renforcement de structure, dopage...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 113100,
    libelleSousDomaine: 'Biomatériaux, biosourcés, biocompatibles',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 113,
    libelleDomaine: 'Matériaux',
    codeSousDomaine: 113110,
    libelleSousDomaine: 'Microsystèmes (microréacteurs, micropompes, micromelangeurs, microvalves...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 114,
    libelleDomaine: 'Mécanique / Machines',
    codeSousDomaine: 11410,
    libelleSousDomaine: 'Transmission de puissance',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 114,
    libelleDomaine: 'Mécanique / Machines',
    codeSousDomaine: 11420,
    libelleSousDomaine: 'Machines aérodynamiques/aérauliques',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 114,
    libelleDomaine: 'Mécanique / Machines',
    codeSousDomaine: 11430,
    libelleSousDomaine: 'Machines hydrauliques',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 114,
    libelleDomaine: 'Mécanique / Machines',
    codeSousDomaine: 11440,
    libelleSousDomaine: 'Outillage, machine-outil et mécanique de précision',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 114,
    libelleDomaine: 'Mécanique / Machines',
    codeSousDomaine: 11450,
    libelleSousDomaine: 'Machines thermodynamiques (prod. de froid, chaudière, pompes à chaleur, fours indus., échangeurs...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 114,
    libelleDomaine: 'Mécanique / Machines',
    codeSousDomaine: 11460,
    libelleSousDomaine: 'Machines électriques (convertisseur, générateur, transformateur, onduleur, moteur...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 114,
    libelleDomaine: 'Mécanique / Machines',
    codeSousDomaine: 11470,
    libelleSousDomaine: 'Microfabrication',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11510,
    libelleSousDomaine: 'Procédés thermiques (combustion, gazéification, pyrolyse...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11520,
    libelleSousDomaine: 'Microfluidique',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11530,
    libelleSousDomaine: 'Automatique, transitique',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11540,
    libelleSousDomaine: 'Technologie de maintenance : préventive, prédictive',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11550,
    libelleSousDomaine: 'Intégration et chaînage numérique des processus (continuité numérique, traçabilité)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11560,
    libelleSousDomaine: 'Formage, moulage, fonderie, usinage',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11570,
    libelleSousDomaine: "Tech. d'extraction (liquide, ultrasons, CO2, eau supercrit., purifica., filtra., sépara., distilla.)",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11580,
    libelleSousDomaine: 'Qualité/Hygiène (stérilisation...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 11590,
    libelleSousDomaine: 'Fabrication additive - Impression 3D',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 115100,
    libelleSousDomaine: 'Systèmes de production et des procédés virtualisés (dont jumeau numérique)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 115,
    libelleDomaine: 'Techniques de production /génie des procédés/Génie industriel',
    codeSousDomaine: 115110,
    libelleSousDomaine: 'Génie nucléaire (fission nucléaire, fusion nucléaire, combustible, réacteur, sureté, protection...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 116,
    libelleDomaine: 'Génie énergétique',
    codeSousDomaine: 116120,
    libelleSousDomaine: "Technologies de stockage d'énergie (mécanique, thermique, électro-chimique, électro-statique...)",
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 116,
    libelleDomaine: 'Génie énergétique',
    codeSousDomaine: 116130,
    libelleSousDomaine: 'Energies fossiles (pétrole, gaz naturel, charbon...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 116,
    libelleDomaine: 'Génie énergétique',
    codeSousDomaine: 116140,
    libelleSousDomaine: 'Energies renouvelables (solaire, éolien, géothermie, biomasse, hydraulique, énergie marine...)',
    descriptionSousDomaine: ' ',
  },
  {
    codeDomaine: 117,
    libelleDomaine: 'Audit financier',
    codeSousDomaine: 11710,
    libelleSousDomaine: 'Audit financier',
    descriptionSousDomaine: ' ',
  },
];
