import { EnumProjetStatut } from '../../../shared/enums/enum.projetStatut';

export function getStatutDataList() {
  return [
    EnumProjetStatut.EN_ANALYSE,
    EnumProjetStatut.ANALYSE_REJETE,
    EnumProjetStatut.ANALYSE_VALIDE,
    EnumProjetStatut.EN_EVALUATION,
    EnumProjetStatut.EVALUATION_REJETE,
    EnumProjetStatut.EVALUATION_VALIDE,
    EnumProjetStatut.EN_AUDITION,
    EnumProjetStatut.AUDITION_VALIDE,
    EnumProjetStatut.AUDITION_REJETE,
    EnumProjetStatut.EN_COURS,
    EnumProjetStatut.TERMINE,
    EnumProjetStatut.ENVOYE,
  ].map(statut => {
    return {
      id: statut,
      value: EnumProjetStatut.toString(statut),
      isSelected: false,
    };
  });
}
