import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FlechageBudgetaireService } from '@services-evaluateur/flechage-budgetaire.service';
import {
  CritereReferentiel,
  DispositifInterface,
  EnumTypeCritere,
  EnumTypeCritereNamespace,
  ReferentielHttpService,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';

@Component({
  selector: 'app-parametrage-referentiel',
  templateUrl: './parametrage-referentiel.component.html',
  styleUrls: ['./parametrage-referentiel.component.scss'],
})
export class ParametrageReferentielComponent extends SubscriptionDestroyerComponent implements OnInit {
  typeCritere: EnumTypeCritere;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  criteres: CritereReferentiel[];
  title: string;
  subTitle: string;
  buttonText: string;
  modificationPath: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    private referentielService: ReferentielHttpService,
    private flechageBudgetaireService: FlechageBudgetaireService
  ) {
    super();
    this.typeCritere = this.route.snapshot.data.type;
    this.title = EnumTypeCritereNamespace.getTitle(this.typeCritere);
    this.subTitle = EnumTypeCritereNamespace.getSubTitle(this.typeCritere);
    this.buttonText = EnumTypeCritereNamespace.getButtonText(this.typeCritere);
    this.modificationPath = this.route.snapshot.data.modificationPath;
  }

  ngOnInit(): void {
    this.loadReferentielCriteres();
  }

  loadReferentielCriteres(): void {
    if (this.typeCritere === EnumTypeCritere.DISPOSITIFS) {
      this.flechageBudgetaireService.getDispositifs().subscribe({
        next: response => {
          this.dataSource = new MatTableDataSource<DispositifInterface>(response);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    } else {
      this.referentielService
        .getCriteresEvaluationsByType(this.typeCritere)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (response: HttpResponse<CritereReferentiel[]>) => {
            if (response) {
              this.criteres = response.body;
              this.dataSource = new MatTableDataSource<CritereReferentiel>(this.criteres);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  goToCreation(): void {
    this.router.navigate(['../' + this.route.snapshot.data.creationPath], { relativeTo: this.route });
  }

  goToReferentiels(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  protected readonly EnumTypeCritereNamespace = EnumTypeCritereNamespace;
}
