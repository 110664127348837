import { IntervenantRoles } from '../enums/enum.intervenantRoles';
import { EvaluateurNotationInterface } from '../interfaces/evaluateurNotation.interface';

export class EvaluateurNotation implements EvaluateurNotationInterface {
  id: string;
  utilisateurRole: IntervenantRoles;
  email: string;
  nom: string;
  prenom: string;
  matricule: string;
}
