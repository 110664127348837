<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form class="modal__content" [formGroup]="correctionForm">
  <label class="ds-input-group__label"
    >Direction générale Bpifrance (positionnée par rapport au code postal du lieu de réalisation de la structure chef de file)</label
  >
  <input
    id="drCalculated"
    name="drCalculated"
    type="text"
    class="ds-input-group__input input_projet"
    value="{{ modalData.drCalculated?.libCentreMesure }}"
    disabled
  />

  <label for="drdlSelected" class="ds-input-group__label">Liste des directions régionales</label>
  <div class="ds-input-group__select ds-input-group__select--with-icon">
    <select id="drdlSelected" name="drdlSelected" formControlName="drdlSelected">
      <option [ngValue]="modalData.drCalculated?.codeCentreMesure"></option>
      <option *ngFor="let item of modalData.drdlList" [ngValue]="item.codeCentreMesure">{{ item.libCentreMesure }}</option>
    </select>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" (click)="sendUpdateDldr()" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
