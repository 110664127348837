<h1 class="parcours__title">Nouveau projet</h1>

<div class="stepper-wrapper">
  <pxl-stepper-projet
    [page]="page"
    [isAapMultiMandatory]="isAapMultiMandatory"
    [isAapIndivMandatory]="isAapIndivMandatory"
    [structureEnCreation]="structureEnCreation"
    *ngIf="page !== -1"
  ></pxl-stepper-projet>
</div>

<main class="parcours page-content">
  <pxl-parcours-creation
    [aap]="aap"
    [isAapMultiMandatory]="isAapMultiMandatory"
    [isAapPersonnePhysique]="isAapPersonnePhysique"
    [isAapIndivMandatory]="isAapIndivMandatory"
    (atPage)="atPage()"
    (structureEnCreationChange)="structureEnCreation = $event"
  ></pxl-parcours-creation>
</main>
