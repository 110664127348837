import { Program } from '@shared-ui';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments-evaluateur/environment';

@Injectable()
export class ProgramService {
  constructor(private httpClient: HttpClient) {}

  getPrograms(statuts?: string[]): Observable<HttpResponse<Program[]>> {
    let url = `${environment.apiUrl}programmes`;
    let params = new HttpParams();

    if (statuts && statuts.length > 0) {
      url += '/filtres';
      params = params.appendAll({ statuts });
    }

    return this.httpClient.get<Program[]>(url, { observe: 'response', params });
  }

  getProgramById(id: string): Observable<HttpResponse<Program>> {
    return this.httpClient.get<Program>(environment.apiUrl + 'programmes/' + id, { observe: 'response' });
  }

  updateProgram(program: Program): Observable<HttpResponse<Program>> {
    return this.httpClient.put<Program>(environment.apiUrl + 'programmes/', JSON.stringify(program), { observe: 'response' });
  }

  deleteProgram(program: Program): Observable<HttpResponse<Program>> {
    return this.httpClient.post<Program>(environment.apiUrl + 'programmes/delete', JSON.stringify(program), { observe: 'response' });
  }

  createProgram(program: Program): Observable<HttpResponse<Program>> {
    return this.httpClient.post<Program>(environment.apiUrl + 'programmes', JSON.stringify(program), { observe: 'response' });
  }
}
