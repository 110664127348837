<div class="table-container table">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="nom"
    matSortDirection="asc"
    matSortDisableClear
    aria-describedby="tableau des organismes"
  >
    <ng-container matColumnDef="select">
      <th id="organisme-select" mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? updateAllSelections() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td id="id" mat-cell *matCellDef="let organisme" (click)="$event.stopPropagation()">
        <mat-checkbox (change)="$event ? selection.toggle(organisme) : null" [checked]="selection.isSelected(organisme)"> </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="nom">
      <th id="organisme-nom" mat-header-cell *matHeaderCellDef mat-sort-header>Nom de l'organisme</th>
      <td id="nom" mat-cell *matCellDef="let organisme">{{ organisme.nom }}</td>
    </ng-container>
    <ng-container matColumnDef="raisonSociale">
      <th id="organisme-raison-sociale" mat-header-cell *matHeaderCellDef mat-sort-header>Raison sociale</th>
      <td id="raisonSociale" mat-cell *matCellDef="let organisme">{{ organisme.raisonSociale }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th id="critere-affichage-des-projets" mat-header-cell *matHeaderCellDef mat-sort-header>Critère d'affichage des projets</th>
      <td id="type" mat-cell *matCellDef="let organisme">{{ organismeType[organisme.type] }}</td>
    </ng-container>
    <ng-container matColumnDef="mailValideur">
      <th id="mail-valideur" mat-header-cell *matHeaderCellDef>Mail valideur</th>
      <td id="mailValideur" mat-cell *matCellDef="let organisme">{{ organisme.instructeursValideursEmails }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
  </table>
  <div class="no-result" *ngIf="dataSource?.data?.length === 0">
    <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
    <span id="large-span-result">Il n'y a rien ici...</span>
    <span id="small-span-result">0 KPI répertorié.</span>
  </div>
</div>
