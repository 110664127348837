import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjetService } from '@services-evaluateur/projet.service';
import { UploadDocumentService } from '@services-evaluateur/upload-document.service';
import {
  Aap,
  DocumentProjet,
  EnumScanDocument,
  EnumScope,
  EnumTypeDocument,
  FILE_SIZE,
  Projet,
  ShowToastrService,
  UploadDocumentHttpService,
  Utilisateur,
} from '@shared-ui';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'pxl-projet-rapport-instruction',
  templateUrl: './projet-rapport-instruction.component.html',
  styleUrls: ['./projet-rapport-instruction.component.scss'],
})
export class ProjetRapportInstructionComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  @Input() projet!: Projet;
  @Input() canUserWrite!: boolean;
  @Input() utilisateur!: Utilisateur;
  @Input() aap!: Aap;
  @Input() rapportInstructionDocuments: DocumentProjet[] = [];
  @Output() rapportInstructionDocumentDeleted = new EventEmitter<DocumentProjet>();
  projetId!: string;

  readonly FILE_SIZE_LIMIT = FILE_SIZE;
  readonly SUBDIRECTORY = 'rapport_instruction';
  documentSelectionChange = false;

  constructor(
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    private projetService: ProjetService,
    private uploadDocumentService: UploadDocumentService,
    private uploadDocumentS3Service: UploadDocumentHttpService
  ) {}

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent?.params.projetId;
  }

  onDocumentFileSelected(event: any): void {
    if (!this.canUserWrite) {
      return;
    }

    const fileToUpload = event.item(0);
    if (fileToUpload.size > this.FILE_SIZE_LIMIT * 1048576) {
      this.showToastrService.error(
        `Le fichier importé est trop volumineux, la taille maximale autorisée est de ${this.FILE_SIZE_LIMIT} Mo`
      );
    } else if (
      ![
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
        'application/vnd.ms-powerpoint', // ppt
        'application/pdf', // pdf
        'application/vnd.ms-excel', // xls
        'image/jpeg', // jpg
        'text/csv', // csv
      ].includes(fileToUpload.type)
    ) {
      this.showToastrService.error("Le type du document sélectionné n'est pas supporté, merci de sélectionner un autre fichier.");
    } else {
      const rapportInstructionDocument: DocumentProjet = new DocumentProjet();
      rapportInstructionDocument.typeDoc = EnumTypeDocument.RAPPORT_INSTRUCTION;
      rapportInstructionDocument.nom = fileToUpload.name;
      rapportInstructionDocument.projetId = this.projetId;
      rapportInstructionDocument.createur = this.utilisateur.matricule;
      rapportInstructionDocument.file = fileToUpload;
      rapportInstructionDocument.scope = EnumScope.PROJET;
      this.rapportInstructionDocuments.push(rapportInstructionDocument);
    }
  }

  public saveDocuments(): Observable<{ document: DocumentProjet; isNew: boolean }[]> {
    if (this.rapportInstructionDocuments.length === 0) {
      return of([]);
    }
    return forkJoin(
      this.rapportInstructionDocuments.map(document => {
        if (document.id) {
          return of({ document, isNew: false });
        } else {
          return this.saveNewDocument(document);
        }
      })
    );
  }

  private saveNewDocument(document: DocumentProjet): Observable<{ document: DocumentProjet; isNew: boolean }> {
    return this.projetService.saveDocumentAdmin(document).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(response => response.body!),
      catchError(err => this.handleError(err)),
      map(doc => {
        document.id = doc.id;
        document.dateCreation = doc.dateCreation;
        this.uploadDocumentWithSignedUrl(document, doc.id);
        return { document, isNew: true };
      })
    );
  }

  uploadDocumentWithSignedUrl(document: DocumentProjet, idDoc: string) {
    const path = `${this.projetId}/${this.SUBDIRECTORY}/${idDoc}`;
    const fileType = 'B401';
    this.uploadDocumentService
      .getValueForDocUpload(document.nom, path, fileType, idDoc, this.projetId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(response => response.body!),
        catchError(err => this.handleError(err))
      )
      .subscribe(response => {
        const url = response.url;
        this.uploadDocument(url, document, idDoc, fileType);
      });
  }

  private uploadDocument(url: string, document: DocumentProjet, idDoc: string, fileType: string) {
    this.uploadDocumentService
      .uploadDoc(url, document.file, this.projetId, idDoc, fileType)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => this.handleError(err))
      )
      .subscribe();
  }

  deleteDocument(document: DocumentProjet, silent = false): void {
    if (document.id) {
      this.projetService
        .deleteDocumentAdmin(this.projetId, document.id)
        .pipe(takeUntilDestroyed(this.destroyRef), catchError(!silent ? this.handleError : () => EMPTY))
        .subscribe(() => {
          this.rapportInstructionDocuments = this.rapportInstructionDocuments.filter(doc => doc !== document);
          this.showToastrService.success('Le document a bien été supprimé.');

          if (document.scan !== EnumScanDocument.UNSAFE) {
            this.deleteDocumentFile(document);
          }
          this.rapportInstructionDocumentDeleted.emit(document);
        });
    } else {
      this.rapportInstructionDocuments = this.rapportInstructionDocuments.filter(doc => doc !== document);
    }
  }

  private deleteDocumentFile(document: DocumentProjet): void {
    const path = `${this.projetId}/${this.SUBDIRECTORY}/${document.id}/${document.nom}`;
    this.uploadDocumentS3Service
      .deleteDocFromS3(path, 'B401', document.id, this.projetId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => this.handleError(err))
      )
      .subscribe();
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }
}
