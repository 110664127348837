import { EnumTypeOrganisme, Evaluateur } from '@shared-ui';

/**
 * Evaluateur utils service
 * Contains pure functions that handles evaluateur objects
 * All functions should be static
 */
export class EvaluateurUtils {
  static isOrganismeMember(evaluateur: Evaluateur, types?: (keyof typeof EnumTypeOrganisme)[]): boolean {
    if (!evaluateur) return false;
    else if (!types || types.length === 0) {
      return !!evaluateur.organisme;
    }
    return types.some(type => type === evaluateur.organisme?.type);
  }
}
