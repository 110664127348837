import { KeyValue } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MultiEmailService } from '@services-evaluateur/multi-email.service';
import { MultiRadicauxEmailService } from '@services-evaluateur/multi-radicaux-email.service';
import { OrganismeService } from '@services-evaluateur/organisme.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Departement,
  DsMultiselectComponent,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumTypeOrganisme,
  EnumTypeOrganismeTech,
  FilterDataInterface,
  OrganismeModel,
  ShowToastrService,
  siretValidator,
  StatutsVisibilite,
  SubscriptionDestroyerComponent,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { OrganismeHelperService } from '../organisme-list/organisme-helper.service';

@Component({
  selector: 'pxl-organisme-creation',
  templateUrl: './organisme.creation.component.html',
  styleUrls: ['./organisme.creation.component.scss'],
})
export class OrganismeCreationComponent extends SubscriptionDestroyerComponent implements OnInit {
  @ViewChild('statutsVisibilityComponent') statutsVisibilityComponent: DsMultiselectComponent;
  @ViewChild('departementComponent') departementComponent: DsMultiselectComponent;

  aide = "Si aucun mail valideur n'est renseigné alors Bpifrance recevra les demandes d'accès et sera en charge de les valider.";
  aideStatut =
    "Seuls les projets en adéquation avec les thématiques de l’organisme seront visibles, à l'exception de ceux actuellement dans les étapes “Pré-dépôt” avec les statuts “En cours”, “Envoyé” ou “En analyse” sur le WKF1, ainsi que ceux dans les étapes “Dépôt” avec les statuts “En cours”, “Envoyé” ou “En analyse” sur le WKF2 qui ne seront pas affichés.";

  organisme: OrganismeModel = new OrganismeModel();
  organismeForm: UntypedFormGroup;
  saveClicked = false;
  isNewOrganisme = true;
  isEmailsValid = true;
  isRadicauxEmailsValid = true;
  organismeId: string;
  dataList: FilterDataInterface[] = [];
  departement: Departement[];
  dataListDepartement: FilterDataInterface[] = [];
  displayTypeInput = false;
  displayTypeInputThematique = false;

  organismeType = EnumTypeOrganisme;
  organismeTypeTech = EnumTypeOrganismeTech;
  statutsVisibiliteType: StatutsVisibilite[] = [
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.EN_COURS },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.ENVOYE },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.EN_ANALYSE },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.ANALYSE_VALIDE },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.ANALYSE_REJETE },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.EN_EVALUATION },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.EVALUATION_VALIDE },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.EVALUATION_REJETE },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.EN_AUDITION },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.AUDITION_VALIDE },
    { etape: EnumProjetEtape.PRE_DEPOT, statuts: EnumProjetStatut.AUDITION_REJETE },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.EN_COURS },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.ENVOYE },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.EN_ANALYSE },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.ANALYSE_VALIDE },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.ANALYSE_REJETE },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.EN_EVALUATION },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.EVALUATION_VALIDE },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.EVALUATION_REJETE },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.EN_AUDITION },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.AUDITION_VALIDE },
    { etape: EnumProjetEtape.DEPOT, statuts: EnumProjetStatut.AUDITION_REJETE },
    { etape: EnumProjetEtape.INSTRUCTION, statuts: EnumProjetStatut.EN_COURS },
    { etape: EnumProjetEtape.INSTRUCTION, statuts: EnumProjetStatut.VALIDE },
    { etape: EnumProjetEtape.INSTRUCTION, statuts: EnumProjetStatut.REJETE },
    { etape: EnumProjetEtape.CONTRACTUALISATION, statuts: EnumProjetStatut.EN_COURS },
    { etape: EnumProjetEtape.CONTRACTUALISATION, statuts: EnumProjetStatut.TERMINE },
  ];

  get formEmails(): UntypedFormControl {
    return this.organismeForm.get('instructeursValideursEmails') as UntypedFormControl;
  }

  get formType(): UntypedFormControl {
    return this.organismeForm.get('type') as UntypedFormControl;
  }

  get formRadicauxMails(): UntypedFormControl {
    return this.organismeForm.get('radicauxMails') as UntypedFormControl;
  }

  get formDepartements(): UntypedFormControl {
    return this.organismeForm.get('departements') as UntypedFormControl;
  }

  get formStatutsVisibilite(): UntypedFormControl {
    return this.organismeForm.get('statutsVisibilite') as UntypedFormControl;
  }

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public multiEmailService: MultiEmailService,
    public multiRadicauxEmailService: MultiRadicauxEmailService,
    private organismeService: OrganismeService,
    private showToastrService: ShowToastrService,
    private organismeHelperService: OrganismeHelperService,
    public sharedFunction: SharedFunction
  ) {
    super();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.organismeForm.dirty || this.saveClicked;
  }

  public ngOnInit(): void {
    this.statutsVisibiliteType.forEach(organisme => {
      this.dataList.push({
        id: organisme,
        value: EnumProjetEtape.fullFormat(organisme.etape, organisme.statuts),
        isSelected: false,
      });
    });

    this.organismeHelperService
      .getDepartementData()
      .pipe(this.takeUntilDestroyed())
      .subscribe((departement: Departement[]) => {
        this.departement = departement;
        this.departement.forEach((departement: Departement) => {
          this.dataListDepartement.push({
            id: departement.num_dep,
            value: departement.num_dep + ' - ' + departement.dep_name,
            isSelected: false,
          });
        });
      });

    this.organismeId = this.route.snapshot.params.id;

    this.createForms();

    if (this.organismeId) {
      this.isNewOrganisme = false;

      this.organismeHelperService
        .getOrganismeData()
        .pipe(this.takeUntilDestroyed())
        .subscribe((organisme: OrganismeModel) => {
          this.organisme = organisme;
        });

      this.revertCheckEmail();

      this.organismeForm.patchValue({
        id: this.organisme.id,
        nom: this.organisme.nom,
        raisonSociale: this.organisme.raisonSociale,
        siret: this.organisme.siret,
        radicauxMails: this.organisme.radicauxMails,
        instructeursValideursEmails: this.organisme.instructeursValideursEmails,
        type: this.organisme.type,
        statutsVisibilite: this.organisme.statutsVisibilite,
        departements: this.organisme.departements,
      });

      this.revertCheckStatutsVisibility();
      this.revertCheckDepartement();

      if (this.formType.value === 'SDE' && this.formDepartements.value) {
        this.displayTypeInput = true;
      }

      if (this.formType.value !== 'ADEME') {
        this.displayTypeInputThematique = true;
      }
    }

    this.formType.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(type => {
      this.setDepartementValidators(type);
      this.setVisibilityValidators(type);
    });

    this.formEmails.valueChanges.subscribe(() => {
      this.isEmailsValid = true;
    });

    this.formRadicauxMails.valueChanges.subscribe(() => {
      this.isRadicauxEmailsValid = true;
    });
  }

  public setDepartementValidators(type: string): void {
    if (type === 'SDE') {
      this.displayTypeInput = true;
      this.departementComponent.selectOptions.isDisabled = false;
      this.departementComponent.reset();
      this.formDepartements.setValidators(Validators.required);
      this.formDepartements.updateValueAndValidity();
    } else {
      this.displayTypeInput = false;
      this.departementComponent.selectOptions.isDisabled = true;
      this.departementComponent.reset();
      this.formDepartements.clearValidators();
      this.formDepartements.updateValueAndValidity();
    }
  }

  setVisibilityValidators(type: string): void {
    if (type !== 'ADEME') {
      this.displayTypeInputThematique = true;
      this.statutsVisibilityComponent.selectOptions.isDisabled = false;
      this.statutsVisibilityComponent.reset();
      this.formStatutsVisibilite.setValidators(Validators.required);
      this.formStatutsVisibilite.updateValueAndValidity();
    } else {
      this.displayTypeInputThematique = false;
      this.statutsVisibilityComponent.selectOptions.isDisabled = true;
      this.statutsVisibilityComponent.reset();
      this.formStatutsVisibilite.clearValidators();
      this.formStatutsVisibilite.updateValueAndValidity();
    }
  }

  public createForms(): void {
    this.organismeForm = this.formBuilder.group({
      id: [null],
      nom: [null, Validators.required],
      raisonSociale: [null, Validators.required],
      siret: [null, [Validators.required, siretValidator()]],
      radicauxMails: [null, Validators.required],
      instructeursValideursEmails: [null],
      type: [null, Validators.required],
      statutsVisibilite: [null],
      departements: [null],
    });
  }

  public addOrganisme(formOrganisme: OrganismeModel): void {
    this.saveClicked = true;

    this.checkStatutsVisibility();
    this.checkDepartement();

    Object.assign(this.organisme, formOrganisme);

    this.organismeForm.markAllAsTouched();

    this.checkEmail();
    this.checkRadicauxEmail();

    if (this.organismeForm.valid && this.isEmailsValid && this.isRadicauxEmailsValid) {
      if (this.organisme.id) {
        this.organismeService
          .updateOrganisme(this.organismeForm.value, this.organisme.id)
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: () => {
              this.showToastrService.success("L'organisme a bien été modifié");
              this.router.navigate(['organismes']);
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
      } else {
        this.organismeService
          .createOrganisme(this.organismeForm.value)
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: () => {
              this.showToastrService.success('Nouvel organisme créé');
              this.router.navigate(['organismes']);
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
      }
    }
  }

  public scrolltoAnchor(elementId: string): void {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  public checkEmail() {
    if (this.formEmails.value !== null) {
      const incorrectList: string[] = this.multiEmailService.findIncorrectEmails(this.organismeForm, 'instructeursValideursEmails');
      if (incorrectList.length > 0) {
        this.isEmailsValid = false;
      }

      if (!this.isEmailsValid && this.formEmails.value?.length) {
        this.multiEmailService.showEmailsError(this.organismeForm, 'instructeursValideursEmails');
      }

      if (!this.organismeForm.valid || !this.isEmailsValid) {
        return;
      }

      const emails = this.formEmails.value
        .split(';')
        .map((email: string) => email.trim())
        .filter((email: string) => email !== '');

      if (this.organismeForm.valid && this.isEmailsValid) {
        this.organismeForm.patchValue({ instructeursValideursEmails: emails });
      }
      this.organismeForm.patchValue({ instructeursValideursEmails: emails });
    } else {
      this.organismeForm.patchValue({ instructeursValideursEmails: null });
    }
  }

  public checkRadicauxEmail() {
    if (this.formRadicauxMails.value !== null) {
      const incorrectList: string[] = this.multiRadicauxEmailService.findIncorrectRadicauxEmails(this.organismeForm, 'radicauxMails');
      if (incorrectList.length > 0) {
        this.isRadicauxEmailsValid = false;
      }

      if (!this.isRadicauxEmailsValid && this.formRadicauxMails.value?.length) {
        this.multiRadicauxEmailService.showRadicauxEmailsError(this.organismeForm, 'radicauxMails');
      }

      if (!this.organismeForm.valid || !this.isRadicauxEmailsValid) {
        return;
      }

      const radicauxMails = this.formRadicauxMails.value
        .split(';')
        .map((radicauxMails: string) => radicauxMails.trim())
        .filter((radicauxMails: string) => radicauxMails !== '');

      if (this.organismeForm.valid && this.isRadicauxEmailsValid) {
        this.organismeForm.patchValue({ radicauxMails: radicauxMails });
      }
    }
  }

  checkStatutsVisibility() {
    const statutsVisibilite: any = [];
    this.statutsVisibilityComponent.getSelectedValues().forEach(statuts => {
      statutsVisibilite.push(statuts.id);
    });

    const result: any = [];

    statutsVisibilite.forEach((object: any) => {
      const existing = result.filter((item: any) => item.etape == object.etape);
      if (existing.length) {
        const existingIndex = result.indexOf(existing[0]);
        result[existingIndex].statuts = result[existingIndex].statuts.concat(object.statuts);
      } else {
        if (typeof object.statuts == 'string') object.statuts = [object.statuts];
        result.push(object);
      }
    });
    this.organismeForm.patchValue({ statutsVisibilite: result });
  }

  checkDepartement() {
    const departement: any = [];
    this.departementComponent.getSelectedValues().forEach(statuts => {
      departement.push(statuts.id);
    });
    this.organismeForm.patchValue({ departements: departement });
  }

  public revertCheckStatutsVisibility() {
    const select: any[] = [];
    this.formStatutsVisibilite.value.map(({ etape, statuts }: { etape: EnumProjetEtape; statuts: EnumProjetStatut[] }) => {
      statuts.forEach(statut => {
        select.push({ value: EnumProjetEtape.fullFormat(etape, statut) });
      });
    });
    this.dataList.forEach((d: FilterDataInterface) => {
      select.forEach((s: any) => {
        if (d.value === s.value) {
          d.isSelected = true;
        }
      });
    });
  }

  public revertCheckDepartement() {
    const select: any[] = [];

    if (this.formDepartements.value) {
      this.formDepartements.value.map((d: string) => {
        select.push({ id: d });
      });

      this.dataListDepartement.forEach((d: FilterDataInterface) => {
        select.forEach((s: any) => {
          if (d.id === s.id) {
            d.isSelected = true;
          }
        });
      });
    }
  }

  public revertCheckEmail() {
    const organisme = this.organisme as any;
    const emails = organisme.instructeursValideursEmails.join(';');
    const radicauxMails = organisme.radicauxMails.join(';');

    this.organisme.instructeursValideursEmails = emails;
    this.organisme.radicauxMails = radicauxMails;
  }

  // Order by ascending property value
  valueOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return a.value.localeCompare(b.value);
  }
}
