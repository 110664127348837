import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './custom-toastr';

@NgModule({
  declarations: [CustomToastComponent],
  imports: [CommonModule, MaterialModule, SharedModule, ToastrModule.forRoot({ toastComponent: CustomToastComponent })],
})
export class CustomToastModule {}
