import { FilterDataInterface } from '../interfaces/filterData.interface';
import { SelectedSearchValuesInterface } from '../interfaces/selectedSearchValues.interface';

export class SelectedSearchValues implements SelectedSearchValuesInterface {
  acronymes: FilterDataInterface[] = [];
  siretRaisonSociale: FilterDataInterface[] = [];
  thematiques: FilterDataInterface[] = [];
  datesReleves: FilterDataInterface[] = [];
  etapes: FilterDataInterface[] = [];
  statuts: FilterDataInterface[] = [];
  taches: FilterDataInterface[] = [];
  drdl: FilterDataInterface[] = [];
  idComites: FilterDataInterface[] = [];
  phasesDecision: FilterDataInterface[] = [];
  idPoles: FilterDataInterface[] = [];
}
