import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationAccesEvaluateurComponent } from './confirmation-acces-evaluateur.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [ConfirmationAccesEvaluateurComponent],
  imports: [CommonModule, SharedModule, SharedUiModule],
})
export class AccesEvaluateurModule {}
