<div class="modal_content">
  <h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>
  <span id="modal-title" class="modal__sub_title" *ngIf="modalData.subtitle">{{ modalData.subtitle }}</span>

  <form id="input-typeDoc" class="modal__content" [formGroup]="dateForm">
    <div
      class="ds-input-group ds-input-group--date"
      [class.ds-input-group--error]="!modalData.optionalDate && !formDate.valid && formDate.touched"
    >
      <label class="ds-input-group__description" *ngIf="modalData.description">{{ modalData.description }}</label>
      <label
        class="ds-input-group__label"
        *ngIf="modalData.label"
        [style.font-weight]="modalData.labelWeight ? modalData.labelWeight : 'normal'"
        >{{ modalData.label }}<span class="ds-input-group__label-asterisk" *ngIf="!modalData.optionalDate"> *</span></label
      >
      <span class="ds-input-group__input">
        <input
          matInput
          [matDatepicker]="openDatePicker"
          formControlName="date"
          class="ds-input-group__input"
          [placeholder]="modalData.placeholder"
        />
        <mat-datepicker-toggle matSuffix [for]="openDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #openDatePicker></mat-datepicker>
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-date" *ngIf="!modalData.optionalDate && !formDate.valid && formDate.touched">
        <div class="ds-input-group__feedback feedback_date">Merci de bien vouloir renseigner une date valide.</div>
      </ng-container>
    </div>
  </form>

  <div id="modal-btn" class="modal__actions">
    <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" (click)="cancelModal()">
      <lib-pxl-icon class="icon" icon="icon-action-close"></lib-pxl-icon> {{ modalData.textReturnButton }}
    </button>
    <button class="ds-btn ds-btn--primary" (click)="confirmerDate()" cdkFocusInitial>
      <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon> {{ modalData.textGoButton }}
    </button>
  </div>
</div>
