import { enumKeys } from '../../utils/enum-utils';

export enum EnumStatutInterventionPI {
  BROUILLON = 'BROUILLON',
  PUBLIEE = 'PUBLIEE',
  MODIFICATION_DEMANDEE = 'MODIFICATION_DEMANDEE',
  ACCEPTEE = 'ACCEPTEE',
}

export namespace EnumStatutInterventionPI {
  export function toString(value: EnumStatutInterventionPI): string {
    switch (value) {
      case EnumStatutInterventionPI.BROUILLON:
        return 'Non publiée';
      case EnumStatutInterventionPI.PUBLIEE:
        return 'Publiée';
      case EnumStatutInterventionPI.MODIFICATION_DEMANDEE:
        return 'Modification demandée';
      case EnumStatutInterventionPI.ACCEPTEE:
        return 'Clôturée'; // show 'Clôturée' instead of 'Acceptée'
    }
  }

  export function all(): EnumStatutInterventionPI[] {
    return enumKeys(EnumStatutInterventionPI) as unknown as EnumStatutInterventionPI[];
  }
}
