import { EnumProjetEtape, EnumProjetStatut, EnumProjetStatutType } from '@shared-ui';
import { Workflow } from '@shared-evaluateur/utils/constants/workflow';

export const WFK1EXTERNE: Workflow = [
  {
    etape: EnumProjetEtape.PRE_DEPOT,
    statuts: [
      {
        label: 'Evaluation',
        options: [
          {
            statut: EnumProjetStatut.EN_EVALUATION,
            type: EnumProjetStatutType.EN_COURS,
          },
          {
            statut: EnumProjetStatut.EVALUATION_VALIDE,
            type: EnumProjetStatutType.VALIDE,
          },
          {
            statut: EnumProjetStatut.EVALUATION_REJETE,
            type: EnumProjetStatutType.REJETE,
          },
        ],
      },
      {
        label: 'Audition',
        options: [
          {
            statut: EnumProjetStatut.EN_AUDITION,
            type: EnumProjetStatutType.EN_COURS,
          },
          {
            statut: EnumProjetStatut.AUDITION_VALIDE,
            type: EnumProjetStatutType.VALIDE,
          },
          {
            statut: EnumProjetStatut.AUDITION_REJETE,
            type: EnumProjetStatutType.REJETE,
          },
        ],
      },
    ],
  },
  {
    etape: EnumProjetEtape.DEPOT,
    statuts: [
      {
        label: 'En cours',
        options: [
          {
            statut: EnumProjetStatut.EN_COURS,
            type: EnumProjetStatutType.EN_COURS,
          },
        ],
      },
      {
        label: 'Analyse',
        options: [
          {
            statut: EnumProjetStatut.EN_ANALYSE,
            type: EnumProjetStatutType.EN_COURS,
          },
          {
            statut: EnumProjetStatut.ANALYSE_VALIDE,
            type: EnumProjetStatutType.VALIDE,
          },
          {
            statut: EnumProjetStatut.ANALYSE_REJETE,
            type: EnumProjetStatutType.REJETE,
          },
        ],
      },
    ],
  },
  {
    etape: EnumProjetEtape.INSTRUCTION,
    statuts: [
      {
        label: 'Instruction',
        options: [
          {
            statut: EnumProjetStatut.EN_COURS,
            type: EnumProjetStatutType.EN_COURS,
          },
          {
            statut: EnumProjetStatut.TERMINE,
            type: EnumProjetStatutType.TERMINE,
          },
        ],
      },
    ],
  },
];

export const WFK2EXTERNE: Workflow = [
  {
    etape: EnumProjetEtape.DEPOT,
    statuts: [
      {
        label: 'Evaluation',
        options: [
          {
            statut: EnumProjetStatut.EN_EVALUATION,
            type: EnumProjetStatutType.EN_COURS,
          },
          {
            statut: EnumProjetStatut.EVALUATION_VALIDE,
            type: EnumProjetStatutType.VALIDE,
          },
          {
            statut: EnumProjetStatut.EVALUATION_REJETE,
            type: EnumProjetStatutType.REJETE,
          },
        ],
      },
      {
        label: 'Audition',
        options: [
          {
            statut: EnumProjetStatut.EN_AUDITION,
            type: EnumProjetStatutType.EN_COURS,
          },
          {
            statut: EnumProjetStatut.AUDITION_VALIDE,
            type: EnumProjetStatutType.VALIDE,
          },
          {
            statut: EnumProjetStatut.AUDITION_REJETE,
            type: EnumProjetStatutType.REJETE,
          },
        ],
      },
    ],
  },
  {
    etape: EnumProjetEtape.INSTRUCTION,
    statuts: [
      {
        label: 'Instruction',
        options: [
          {
            statut: EnumProjetStatut.EN_COURS,
            type: EnumProjetStatutType.EN_COURS,
          },
          {
            statut: EnumProjetStatut.TERMINE,
            type: EnumProjetStatutType.TERMINE,
          },
        ],
      },
    ],
  },
];
