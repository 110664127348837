<div class="page-content page-content--no-scroll list-page">
  <a class="page-header__back" (click)="goToReferentiels()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Référentiels
  </a>
  <lib-card-title [title]="'Paramétrer le référentiel des rôles'"> </lib-card-title>
  <section class="page-section card-create">
    <div class="card card--button">
      <div class="card__title">
        <div>Vous pouvez créer des rôles</div>
      </div>
      <button type="button" class="ds-btn ds-btn--secondary" (click)="goToCreation()">
        <lib-pxl-icon class="icon" icon="icon-action-add" aria-hidden="true"></lib-pxl-icon>
        Créer un rôle
      </button>
    </div>
  </section>

  <pxl-intervenants-role class="tableau-container" [dataSource]="dataSource" [modificationPath]="modificationPath"></pxl-intervenants-role>
</div>
