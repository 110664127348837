import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DepensesProjetConfig, ReferentielHttpService, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';
import { Observable, takeUntil } from 'rxjs';
import { CreationDepensesProjetsHelperService } from './creation-depenses-projets.helper.service';

@Component({
  selector: 'app-creation-depenses-projets',
  templateUrl: './creation-depenses-projets.component.html',
  styleUrls: ['./creation-depenses-projets.component.scss'],
})
export class CreationDepensesProjetsComponent extends SubscriptionDestroyerComponent implements OnInit {
  submitClicked = false;
  form!: FormGroup<any>;
  showForm = true;
  refDepensesProjet!: DepensesProjetConfig;
  private digitOnlyRegex = new RegExp(/^[0-9.,]+$/g);

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.form.dirty;
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public helperService: CreationDepensesProjetsHelperService,
    public referentielService: ReferentielHttpService,
    public showToastrService: ShowToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.helperService.createForm();
    this.getReferentiel();
  }

  private getReferentiel() {
    this.referentielService
      .getReferentielDepensesProjetConfig()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<DepensesProjetConfig>) => {
          this.refDepensesProjet = response.body;
          this.patchForm(this.refDepensesProjet);
          this.showForm = true;
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);
        },
      });
  }

  handleError(err: HttpErrorResponse) {
    this.showToastrService.checkCodeError(err?.error);
  }

  private patchForm(depenses: DepensesProjetConfig) {
    this.form.patchValue({
      pourcentageSolde: depenses.tauxConfig.pourcentageSolde,
      tauxCE: depenses.tauxConfig.tauxCE,
      versementMinimum: depenses.tauxConfig.versementMinimum,
      differeRemboursement: depenses.tauxConfig.differeRemboursement,
      tauxPetiteEntreprise: depenses.premierVersementConfig.tauxPetiteEntreprise,
      tauxMoyenneEntreprise: depenses.premierVersementConfig.tauxMoyenneEntreprise,
      tauxGrandeEntreprise: depenses.premierVersementConfig.tauxGrandeEntreprise,
      tauxLaboratoireRecherche: depenses.premierVersementConfig.tauxLaboratoireRecherche,
    });
  }

  saveDepensesProjets(): void {
    this.submitClicked = true;
    if (this.form.valid) {
      const config: DepensesProjetConfig = this.helperService.createReferentielFromForm(this.form, this.refDepensesProjet);
      this.saveReferentielFromForm(config);
    }
  }

  saveReferentielFromForm(depensesConfig : DepensesProjetConfig): void {
    this.referentielService
      .saveReferentielDepensesProjets(depensesConfig)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: () => {
          this.showToastrService.success(`Votre référentiel de depenses projets a bien été modifié avec succès`);
          this.form.markAsPristine();
          this.goToDepensesProjets();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  goToDepensesProjets(): void {
    this.router.navigate([`../${this.route.snapshot.data.referentielPath}`], { relativeTo: this.route });
  }

  onkeypress($event: KeyboardEvent): boolean {
    if ($event.key) {
      if (!String($event.key).match(this.digitOnlyRegex)) {
        return false;
      }
    }
  }
}
