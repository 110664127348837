import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import {
  CritereReferentiel,
  CritereReferentielAap,
  EnumTypeCritere,
  ReferentielHttpService,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';
import { CriteresReferentielHelperService } from './criteres-referentiel-helper.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'pxl-criteres-referentiel',
  templateUrl: './criteres-referentiel.component.html',
  styleUrls: ['./criteres-referentiel.component.scss'],
})
export class CriteresReferentielComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() aapForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() criteres: CritereReferentielAap[] = [];
  @Input() isNotation: boolean;
  @Input() stepNumber: number;
  referentiel: CritereReferentiel[];
  referentielForm: FormArray = new FormArray([]);
  title: string;

  constructor(
    private referentielHttpService: ReferentielHttpService,
    private showToastrService: ShowToastrService,
    @Inject(DOCUMENT) public document: Document,
    private helperService: CriteresReferentielHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = this.isNotation ? "Critères d'évaluation" : "Indicateurs d'eligibilité";
    this.referentielHttpService
      .getCriteresEvaluationsByType(this.isNotation ? EnumTypeCritere.CRITERE_EVALUATION : EnumTypeCritere.INDICATEUR_ELIGIBILITE)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: result => {
          this.referentiel = result.body;
          this.referentielForm = this.helperService.buildFormArray(this.referentiel, this.criteres);
          if (this.aapForm) {
            this.aapForm.controls[this.controlName] = this.referentielForm;
            this.referentielForm.valueChanges.pipe(this.takeUntilDestroyed()).subscribe({
              next: () => this.aapForm.updateValueAndValidity({ emitEvent: false, onlySelf: true }),
            });
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }
}
