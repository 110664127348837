import { Injectable } from '@angular/core';

import { SITE_ADMIN, environment } from '@environments-evaluateur/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { EnumAuthConfigurationType, Utilisateur } from '@shared-ui';
import { MatDialog } from '@angular/material/dialog';
import { CguModalComponent } from '@shared-evaluateur/components/modals/cgu-modal/cgu-modal.component';
import { PxlOidcService } from '@services-evaluateur/pxl-oidc.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard {
  utilisateur: Utilisateur = new Utilisateur();

  constructor(private oidcSecurityService: PxlOidcService, private dialog: MatDialog, private userService: UtilisateurService) {}

  canActivate(): Observable<boolean> {
    // return checkAuth() again should be possible
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((isAuthenticated: boolean) => {
        if (!isAuthenticated && !environment.stubAuth) {
          return false;
        } else if (environment.site !== SITE_ADMIN) {
          this.userService.getUserObservableForCGU().subscribe((response: Utilisateur) => {
            this.utilisateur = response;
            if (this.utilisateur && !this.utilisateur.cgus[this.utilisateur.cgus.length - 1].dateValidation) {
              const dialogRef = this.dialog.open(CguModalComponent, {
                data: {
                  title: 'Les CGU évoluent',
                  textGoButton: 'Accepter',
                  textReturnButton: 'Refuser',
                },
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.utilisateur.cgus[this.utilisateur.cgus.length - 1].dateValidation = Date.now();
                  this.userService.setUserObservable(this.utilisateur);
                  this.userService.putCguUser().subscribe();
                } else {
                  this.deconnexion();
                }
              });
            }
          });
          return true;
        }
        return true;
      })
    );
  }

  private deconnexion() {
    const configId = localStorage.getItem('idp');
    if (configId === EnumAuthConfigurationType.ISAM) {
      this.oidcSecurityService.logoff();
      window.location.href = environment.espaceCandidat;
    } else {
      this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
      });
    }
  }
}
