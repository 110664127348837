import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared-evaluateur/shared.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { ComitesComponent } from './comites.component';
import { ComitesRoutingModule } from './comites-routing.module';
import { ComitesListModule } from './comites-list/comites.list.module';
import { ComitesCreationModule } from './comites-creation/comites-creation.module';
import { ComiteProjetsListModule } from './comites-list/comite-projets-list/comite-projets-list.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MaterialModule,
    ComitesRoutingModule,
    ComitesListModule,
    ComitesCreationModule,
    ComiteProjetsListModule,
  ],
  declarations: [ComitesComponent],
  providers: [],
})
export class ComitesModule {}
