<div class="modal_content">
  <h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>
  <span id="modal-title" class="modal__sub_title" *ngIf="modalData.title">{{ modalData.subtitle }}</span>

  <form id="form-validation-decision" class="modal__content" [formGroup]="decisionComiteForm">
    <div
      class="ds-input-group validation-proposition"
      [class.ds-input-group--error]="formValidationPropositionComite.invalid && formValidationPropositionComite.touched"
    >
      <label for="validationProposition" class="ds-input-group__label">Validation de la proposition du comité</label>
      <span class="ds-input-group__label-asterisk"> *</span>
      <div class="ds-input-group__select ds-input-group__select--with-icon">
        <select id="validationProposition" name="validationProposition" formControlName="validationPropositionComite">
          <option [ngValue]="null" selected disabled>Validation de la proposition du comité</option>
          <option *ngFor="let item of EnumDecisionComite.all()" [value]="item">
            {{ EnumDecisionComite.toString(item) }}
          </option>
        </select>
      </div>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-input-field" *ngIf="!formValidationPropositionComite.valid && formValidationPropositionComite.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le champ</div>
      </ng-container>
    </div>

    <div
      class="ds-input-group ds-input-group--date"
      [class.ds-input-group--error]="formDateDecisionEtat.invalid && formDateDecisionEtat.touched"
    >
      <label class="ds-input-group__label">
        Date de validation / rejet de l’Etat <span class="ds-input-group__label-asterisk"> *</span>
      </label>
      <span class="ds-input-group__input">
        <input
          matInput
          [matDatepicker]="openDatePicker"
          formControlName="dateDecisionEtat"
          class="ds-input-group__input"
          placeholder="Date de validation / rejet de l’Etat"
        />
        <mat-datepicker-toggle matSuffix [for]="openDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #openDatePicker></mat-datepicker>
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-date" *ngIf="!formDateDecisionEtat.valid && formDateDecisionEtat.touched">
        <div class="ds-input-group__feedback feedback_date">Merci de bien vouloir renseigner une date valide.</div>
      </ng-container>
    </div>

    <div class="ds-input-group">
      <label for="input-field" class="ds-input-group__label"> Motivation du refus/ Commentaire pour l’audition </label>
      <textarea
        id="input-field"
        name="input-field"
        rows="5"
        col="0"
        attr.maxlength="500"
        class="ds-input-group__textarea"
        formControlName="avisEtat"
        type="text"
        #textInput
      ></textarea>
      <div class="form-input__length-hint" [class.form-input__length-hint--full]="textInput.value?.length === 500">
        <span>{{ textInput.value?.length || 0 }}/500</span>
      </div>
    </div>
  </form>

  <div id="modal-btn" class="modal__actions">
    <button *ngIf="modalData.textReturnButton" (click)="cancelModal()" class="ds-btn ds-btn--secondary">
      <lib-pxl-icon class="icon" icon="icon-action-close"></lib-pxl-icon> Annuler
    </button>
    <button class="ds-btn ds-btn--primary" (click)="confirmerDate()" cdkFocusInitial>
      <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon> Confirmer
    </button>
  </div>
</div>
