import { Component, HostListener, OnInit } from '@angular/core';
import { CreationDispositifHelperService } from './creation-dispositif.helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FlechageBudgetaireService } from '@services-evaluateur/flechage-budgetaire.service';
import { DispositifForm, RadioButtonOption, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-creation-dispositif',
  templateUrl: './creation-dispositif.component.html',
  styleUrls: ['./creation-dispositif.component.scss'],
})
export class CreationDispositifComponent extends SubscriptionDestroyerComponent implements OnInit {
  MAXLENGTH = 250;
  form: FormGroup<DispositifForm>;
  isModification: boolean;
  dispositifId: string;
  submitClicked = false;

  readonly OPTIONS_YES_NO: RadioButtonOption[] = [
    { key: true, value: 'Actif' },
    { key: false, value: 'Inactif' },
  ];

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.form.dirty;
  }

  constructor(
    public flechageBudgetaireService: FlechageBudgetaireService,
    public creationDispositifHelperService: CreationDispositifHelperService,
    public showToastrService: ShowToastrService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super();
    this.dispositifId = this.route.snapshot.params.id;
    this.isModification = this.dispositifId == null ? false : true;
  }

  ngOnInit(): void {
    this.form = this.creationDispositifHelperService.createForm();
    if (this.isModification) {
      this.getDispositif();
    }
  }

  getDispositif(): void {
    this.flechageBudgetaireService
      .getDispositifById(this.dispositifId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: dispositif => {
          this.form.patchValue(dispositif);
        },
        error: () => {
          this.showToastrService.error("Une erreur s'est produite, veuillez réessayer ultérieurement.");
        },
      });
  }

  saveDispositif(): void {
    this.submitClicked = true;
    this.creationDispositifHelperService.saveDispositif(this.isModification, this.dispositifId, this.form, this.route);
  }

  goToReferentiel(): void {
    this.creationDispositifHelperService.goToDispositifsReferentiel(this.route, this.isModification);
  }
}
