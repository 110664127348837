<!-- Entête de la page -->
<lib-page-header [title]="'Comités'">
  <div [libMyRemoveIfFeatureOff]="'auto-affectation'" centered-elements>
    <lib-pxl-toggle
      [labels]="toggleLabels"
      [defaultLabel]="comiteToggleState ? ToggleValues.MES_COMITES : ToggleValues.TOUS_COMITES"
      (changeToggleEvent)="onChangeToggle($event)"
    ></lib-pxl-toggle>
  </div>

  <div class="list-page__dropdowns" right-elements>
    <button type="button" class="create-button ds-btn ds-btn&#45;&#45;primary" (click)="goToCreateComite()">
      <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
      Créer un comité
    </button>

    <button id="actions-btn" class="ds-btn ds-btn&#45;&#45;primary" placement="bottom-right" [ngbPopover]="popActions">
      Actions
      <lib-pxl-icon class="icon" icon="icon-arrow-expand-more" aria-hidden="true"></lib-pxl-icon>
    </button>
    <ng-template #popActions>
      <div class="popover_container">
        <ng-container>
          <span class="popover_title mt1"> Comité </span>
          <div class="popover_link">
            <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
            <a *ngIf="!comiteToggleState" (click)="openAutoAffectDialog()">M'affecter</a>
            <a *ngIf="comiteToggleState" (click)="openAutoDesaffectDialog()">Me désaffecter</a>
          </div>
          <div class="popover_link">
            <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
            <a (click)="closeSelectedComites()">Clôturer les comités</a>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</lib-page-header>

<div class="page-content--no-scroll filter_table_content">
  <div class="filter-container" *ngIf="!comiteToggleState">
    <pxl-comite-search
      *ngIf="canShowFilter"
      [aapFilterList]="aapFilterList"
      [dateReleveFilterList]="dateReleveFilterList"
      [comiteCreatorFilterList]="comiteCreatorFilterList"
      (searchEventEmitter)="searchEventCalled($event)"
    ></pxl-comite-search>
  </div>

  <div class="table-container">
    <pxl-comites-tableau [dataSource]="dataSource" [(selection)]="selection"> </pxl-comites-tableau>
  </div>
</div>
