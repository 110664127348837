import { enumKeys } from "../../utils/enum-utils";

export enum EnumProgramType {
  CODE_INCONNU = 'CODE_INCONNU',
  PSPC = 'PSPC',
}

export namespace EnumProgramType {
  export function toString(value: EnumProgramType): string {
    switch (value) {
      case EnumProgramType.CODE_INCONNU:
        return 'Code inconnu';
      case EnumProgramType.PSPC:
        return 'PSPC';
    }
  }

  export function all(): EnumProgramType[] {
    return enumKeys(EnumProgramType) as EnumProgramType[];
  }
}
