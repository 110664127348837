import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComiteProjetsListComponent } from './comite-projets-list.component';
import { TableauProjetModule } from '@shared-evaluateur/components/tableau-projet/tableau-projet.module';
import { SharedUiModule } from '@shared-ui';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ComiteProjetsListFilterModule } from '@features-evaluateur/comites/comites-list/comite-projets-list/comite-projets-list-filter/comite-projets-list-filter.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';

@NgModule({
  declarations: [ComiteProjetsListComponent],
  imports: [CommonModule, TableauProjetModule, SharedUiModule, NgbPopoverModule, ComiteProjetsListFilterModule, MaterialModule],
  exports: [ComiteProjetsListComponent],
})
export class ComiteProjetsListModule {}
