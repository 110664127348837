import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { Utilisateur } from '@shared-ui';

@Injectable({ providedIn: 'root' })
export class AdminGroupGuard {
  constructor(private sharedFunction: SharedFunction, private userService: UtilisateurService) {}

  canActivate(): Observable<boolean> {
    if (this.sharedFunction.isSiteAdmin()) {
      return new Observable<boolean>(observer => {
        return this.userService.getUtilisateurObservable().subscribe((user: Utilisateur) => {
          if (user) {
            observer.next(this.sharedFunction.isUserAdmin(user) || this.sharedFunction.isUserInno(user));
          }
        });
      });
    } else {
      return of(false);
    }
  }
}
