<ul class="stepper" *ngIf="page! && ((isAapMultiMandatory && !isAapIndivMandatory) || (!isAapMultiMandatory && isAapIndivMandatory))">
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 1,
      stepper__step_state_completed: page > 1
    }"
  >
    1. Choix du rôle de la structure
    <lib-pxl-icon class="icon" icon="icon-action-check-circle" aria-hidden="true" *ngIf="page > 1"></lib-pxl-icon>
  </li>
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 2,
      stepper__step_state_completed: page > 2
    }"
  >
    2. Informations de la structure
    <lib-pxl-icon class="icon" icon="icon-action-check-circle" aria-hidden="true" *ngIf="page > 2"></lib-pxl-icon>
  </li>
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 3,
      stepper__step_state_completed: page > 3
    }"
  >
    3. {{ structureEnCreation ? 'Adresse personnelle du candidat' : 'Informations du siège' }}
    <lib-pxl-icon class="icon" icon="icon-action-check-circle" aria-hidden="true" *ngIf="page > 3"></lib-pxl-icon>
  </li>
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 4
    }"
  >
    4. Informations du candidat
  </li>
</ul>

<ul class="stepper" *ngIf="page! && !isAapMultiMandatory && !isAapIndivMandatory">
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 1,
      stepper__step_state_completed: page > 1
    }"
  >
    1. Type de projet
    <lib-pxl-icon class="icon" icon="icon-action-check-circle" aria-hidden="true" *ngIf="page > 1"></lib-pxl-icon>
  </li>
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 2,
      stepper__step_state_completed: page > 2
    }"
  >
    2. Choix du rôle
    <lib-pxl-icon class="icon" icon="icon-action-check-circle" aria-hidden="true" *ngIf="page > 2"></lib-pxl-icon>
  </li>
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 3,
      stepper__step_state_completed: page > 3
    }"
  >
    3. Informations de la structure
    <lib-pxl-icon class="icon" icon="icon-action-check-circle" aria-hidden="true" *ngIf="page > 3"></lib-pxl-icon>
  </li>
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 4,
      stepper__step_state_completed: page > 4
    }"
  >
    4. {{ structureEnCreation ? 'Adresse personnelle du candidat' : 'Informations du siège' }}
    <lib-pxl-icon class="icon" icon="icon-action-check-circle" aria-hidden="true" *ngIf="page > 4"></lib-pxl-icon>
  </li>
  <li
    class="stepper__step"
    [ngClass]="{
      stepper__step_state_current: page === 5
    }"
  >
    5. Informations du candidat
  </li>
</ul>
