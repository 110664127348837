import { Component, Input } from '@angular/core';

@Component({
  selector: 'pxl-stepper-projet',
  templateUrl: './stepper.projet.component.html',
  styleUrls: ['./stepper.projet.component.scss'],
})
export class StepperProjetComponent {
  @Input() page = 1;
  @Input() isAapMultiMandatory: boolean;
  @Input() isAapPersonnePhysique: boolean;
  @Input() isAapIndivMandatory: boolean;
  @Input() structureEnCreation: boolean;
}
