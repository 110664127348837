import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@environments-evaluateur/environment';
import { StructureService } from '@services-evaluateur/structure.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Aap,
  Contact,
  EnumRoleContact,
  EnumRoleStructure,
  EnumTypePartenaire,
  Invitation,
  Projet,
  ShowToastrService,
  Structure,
} from '@shared-ui';

export interface ModalData {
  title: string;
  projet: Projet;
  aap: Aap;
  structures: Structure[];
  isChefFileExist: boolean;
  isMandatoryExist: boolean;
  listContacts: Contact[];
  textGoButton: string;
  textReturnButton: string;
}

@Component({
  selector: 'app-invite-structure-modal',
  templateUrl: './invite-structure-modal.component.html',
  styleUrls: ['./invite-structure-modal.component.scss'],
})
export class InviteStructureModalComponent implements OnInit {
  structureForm: UntypedFormGroup;
  roleSelectable = true;
  aap: Aap = new Aap();
  invitation: Invitation = new Invitation();

  isChefFileExist = false;
  isMandatoryExist = false;

  disableValidBtn = false;
  projet: Projet = new Projet();
  structure: Structure = new Structure();
  structureId: string;
  structures: Structure[] = [];
  projetId: string;
  lengthSiret = 17;
  constructor(
    private structureService: StructureService,
    public dialogRef: MatDialogRef<InviteStructureModalComponent>,
    public sharedFunction: SharedFunction,
    private formBuilder: UntypedFormBuilder,
    private showToastrService: ShowToastrService,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData
  ) {}

  ngOnInit(): void {
    this.structures = this.modalData.structures.filter((structure: Structure) => !structure.closed);
    this.createForms();
  }
  createForms(): void {
    this.structureForm = this.formBuilder.group({
      email: [null, Validators.required],
      raisonSocial: [null, Validators.required],
      role: [null, Validators.required],
    });
    if (this.modalData.projet?.partenaireType === EnumTypePartenaire.INDIV) {
      this.checkIndivProject();
    }
  }
  submitForm(formStructure: any): void {
    if (this.structureForm.valid) {
      const test = this.modalData.listContacts.filter((contact: Contact) => contact.email === this.structureForm.value?.email);
      if (test.length > 0) {
        this.showToastrService.error('Cet email est déjà associé à un contact existant.');
      } else {
        this.updateProjetConsortium(formStructure);
      }
    } else {
      this.structureForm.markAllAsTouched();
    }
  }

  updateProjetConsortium(formStructure: any): void {
    Object.assign(this.structure, formStructure);
    this.structure.projetId = this.modalData.projet.id;
    this.invitation.dateExpiration = new Date();
    this.invitation.dateExpiration.setDate(this.invitation.dateExpiration.getDate() + 7);

    const contact = new Contact();
    contact.email = this.structureForm.value.email;
    contact.roles = [(EnumRoleContact as any)[EnumRoleContact.AUTRE.toString()]];
    contact.dateModification = this.structure.dateModification;
    contact.dateExpirationInvitation = this.invitation.dateExpiration;

    this.structure.contacts.push(contact);

    this.structureService.addStructure(this.structure).subscribe({
      next: (response: HttpResponse<Structure>) => {
        if (response) {
          if (this.structureForm.value?.email) {
            // status 206 notification n'est pas envoyée
            if (response.status === 206) {
              this.showToastrService.error(
                "Une erreur s'est produite. L'invitation n'a pas été envoyée. Veuillez renvoyer l'invitation depuis la fiche contact dans la structure."
              );
            } else {
              this.showToastrService.success('La structure a bien été invitée');
            }
            this.modalData.structures.push(this.structure);
          }
          this.structureForm.reset();
          this.dialogRef.close(true);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  /*
   * Verifie s'il y a un chef de file/mandataire parmi les structures dans un projet INDIV
   * */
  checkIndivProject(): void {
    if (this.structures?.length === +0) {
      this.structureForm.patchValue({
        role: EnumRoleStructure.CHEF_DE_FILE,
      });
      this.roleSelectable = false;
    } else if (this.structures?.length > 0) {
      const isChefFileExist = this.structures.some(structure => {
        return structure.role === EnumRoleStructure.CHEF_DE_FILE;
      });
      const isMandatoryExist = this.structures.some(structure => {
        return structure.role === EnumRoleStructure.MANDATAIRE;
      });

      if (isChefFileExist) {
        this.structureForm.patchValue({
          role: EnumRoleStructure.MANDATAIRE,
        });
        this.roleSelectable = false;
      } else if (isMandatoryExist) {
        this.structureForm.patchValue({
          role: EnumRoleStructure.CHEF_DE_FILE,
        });
        this.roleSelectable = false;
      }
    }
  }

  downloadRGPD(): void {
    window.open(environment.docPublicBaseUrl + this.modalData.aap.programme.fichierRgpd);
  }
}
