import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-evaluateur//environment';
import { SITE_EVAL } from '@environments-evaluateur/environment.cloud';
import { CHOSEN_PROFILE_KEY } from '@features-evaluateur/acces-evaluateur/evaluateur-choice/evaluateur-choice.component';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { OrganismeService } from '@services-evaluateur/organisme.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { Evaluateur, OrganismeModel, ShowToastrService, Utilisateur } from '@shared-ui';
import { EMPTY, Observable, of, Subscriber, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export enum ProfilEvaluateur {
  MEMBRE_ORGANISME = 'MEMBRE_ORGANISME',
  EVALUATEUR_EXTERNE = 'EVALUATEUR_EXTERNE',
}

export const DOUBLE_PROFILE_KEY = 'DOUBLE_PROFILE';

@Injectable({ providedIn: 'root' })
export class ProfilGuard {
  static FIRST_EVALUATION = true;
  invitationConsomee = false;
  demandeInscriptionValidee = false;

  constructor(
    private router: Router,
    public showToastrService: ShowToastrService,
    private userService: UtilisateurService,
    private evaluateurService: EvaluateurService,
    private organismeService: OrganismeService,
    public sharedFunction: SharedFunction
  ) {}

  canActivate(): Observable<boolean> {
    if (environment.site !== SITE_EVAL || this.router.getCurrentNavigation()?.extras?.state?.needEvaluation === false) {
      return of(true);
    }
    return new Observable<boolean>(observer => {
      if (!ProfilGuard.FIRST_EVALUATION && this.isDoubleProfile()) {
        this.redirectToChosenProfile();
        observer.next(false);
        return;
      }
      this.loadUtilisateur().subscribe(utilisateur => {
        if (!utilisateur) {
          observer.next(false);
          return;
        }

        this.loadEvaluateurOrMock(utilisateur).subscribe({
          next: evaluateur => {
            this.resolveProfile(evaluateur, observer);
            ProfilGuard.FIRST_EVALUATION = false;
          },
          error: () => {
            this.router.navigate(['/confirmation-access-evaluateur']);
            observer.next(false);
          },
        });
      });
    });
  }

  resolveProfile(evaluateur: Evaluateur, observer: Subscriber<boolean>): void {
    this.isMembreOrganisme(evaluateur).subscribe(isMembreOrganisme => {
      const isEvaluateurExterne = this.isEvaluateurExterne(evaluateur);
      if (isMembreOrganisme && isEvaluateurExterne) {
        this.setDoubleProfile(true);
        this.isInvitationConsomee().subscribe(isInvitationConsomee => {
          this.invitationConsomee = isInvitationConsomee;
          this.demandeInscriptionValidee = this.isDemandeInscriptionValidee(evaluateur);
          this.redirectToChosenProfile();
        });
      } else if (isMembreOrganisme) {
        this.setDoubleProfile(false);
        this.demandeInscriptionValidee = this.isDemandeInscriptionValidee(evaluateur);
        this.redirectToChosenProfile(ProfilEvaluateur.MEMBRE_ORGANISME);
      } else if (isEvaluateurExterne) {
        this.setDoubleProfile(false);
        this.isInvitationConsomee().subscribe(isInvitationConsumee => {
          this.invitationConsomee = isInvitationConsumee;
          this.redirectToChosenProfile(ProfilEvaluateur.EVALUATEUR_EXTERNE);
        });
      } else {
        this.showToastrService.error("Vous n'êtes ni membre d'un organisme ni évaluateur d'un projet");
      }
      observer.next(false);
    });
  }

  redirectToChosenProfile(chosen?: ProfilEvaluateur): void {
    if (chosen === ProfilEvaluateur.EVALUATEUR_EXTERNE) {
      if (this.invitationConsomee) {
        this.setChoosenProfile(chosen);
        this.router.navigate(['/aaps'], { state: { needEvaluation: false } });
      } else {
        this.showToastrService.error(
          'Si vous avez reçu une invitation dans votre boite mail, merci de cliquer sur le lien dans le mail pour finaliser votre inscription et pouvoir accéder aux projets',
          "Vous ne pouvez pas accéder à l'espace évaluateur.",
          {
            disableTimeOut: true,
          }
        );
      }
    } else if (chosen === ProfilEvaluateur.MEMBRE_ORGANISME) {
      if (this.demandeInscriptionValidee) {
        this.setChoosenProfile(chosen);
        this.router.navigate(['/aaps'], { state: { needEvaluation: false } });
      } else {
        this.goToInscriptionInstructeur();
      }
    } else {
      this.router.navigate(['/evaluateur-choice'], { state: { needEvaluation: true } });
    }
  }

  setChoosenProfile(profil: ProfilEvaluateur): void {
    localStorage.setItem(CHOSEN_PROFILE_KEY, profil);
  }

  isMembreOrganisme(evaluateur: Evaluateur): Observable<boolean> {
    return evaluateur?.organisme ? of(true) : this.loadOrganismes(evaluateur).pipe(map(organismes => organismes.length > 0));
  }

  isEvaluateurExterne(evaluateur: Evaluateur): boolean {
    return evaluateur?.listAffectationProjets?.length > 0 || evaluateur?.aaps?.length > 0;
  }

  loadUtilisateur(): Observable<Utilisateur> {
    return this.userService.getUtilisateurObservable().pipe(catchError(err => this.handleError(err)));
  }

  loadEvaluateurOrMock(utilisateur: Utilisateur): Observable<Evaluateur> {
    return this.evaluateurService.getEvaluateur(utilisateur?.email).pipe(
      map(response => response.body!),
      catchError(err => {
        if (err?.status === 401 && err?.error?.message) {
          return throwError(() => err);
        } else {
          return of({ email: utilisateur?.email } as Evaluateur); // mock
        }
      })
    );
  }

  loadOrganismes(evaluateur: Evaluateur): Observable<OrganismeModel[]> {
    return this.organismeService.getOrganismesByEmail(evaluateur.email).pipe(map(response => response.body ?? []));
  }

  isInvitationConsomee(): Observable<boolean> {
    return this.evaluateurService.getStatutInvitationCurrentEvaluateur().pipe(
      catchError(err => this.handleError(err)),
      map(response => response.body?.active === false)
    );
  }

  isDemandeInscriptionValidee(evaluateur: Evaluateur): boolean {
    return !!evaluateur.organisme;
  }

  isDoubleProfile(): boolean {
    return localStorage.getItem(DOUBLE_PROFILE_KEY) === 'true';
  }

  setDoubleProfile(value: boolean): void {
    localStorage.setItem(DOUBLE_PROFILE_KEY, value.toString());
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }

  goToInscriptionInstructeur(): void {
    window.location.href = environment.espaceCandidat + '/rejoindre-projet-partenaire';
  }
}
