import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepIdentificationStructureComponent } from './step-identification-structure.component';
import { SharedUiModule } from '@shared-ui';
import { SharedModule } from '@shared-evaluateur/shared.module';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, SharedUiModule],
  declarations: [StepIdentificationStructureComponent],
  exports: [StepIdentificationStructureComponent],
  providers: [],
})
export class StepIdentificationStructureModule {}
