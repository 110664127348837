import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EnumNotationTypeNote } from '@shared-ui';

export class AvisValidators {
  static notationSur20Validator(typeNotation: EnumNotationTypeNote): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValid = true;

      if (typeNotation === EnumNotationTypeNote.NOTATION_NOTE_SUR_20) {
        const floatValue = parseFloat(control.value?.replace(',', '.'));
        isValid = floatValue <= 20;
      }

      return isValid ? null : { valid: true };
    };
  }
}
