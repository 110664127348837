<pxl-sidenav-creation-program (anchorCall)="scrolltoAnchor($event)" [program]="program"></pxl-sidenav-creation-program>

<main class="page-content">
  <form *ngIf="program" class="form" [formGroup]="programForm" (ngSubmit)="addProgram(programForm.value)">
    <section class="form-step">
      <div id="anchor1" class="form-step__anchor"></div>
      <header class="form-step__header">
        <h2 class="form-step__title"><span class="form-step__number">1</span>Informations générales</h2>
      </header>

      <div class="form-step__content">
        <!-- Type de programme comptable -->
        <div
          class="form-step__type-comptable ds-input-group"
          [class.ds-input-group--error]="!programForm.controls['typeComptable'].valid && programForm.controls['typeComptable'].touched"
        >
          <label for="creation_program_typeComptable" class="ds-input-group__label">Type de programme comptable *</label>
          <div class="ds-input-group__select ds-input-group__select--with-icon">
            <select id="creation_program_typeComptable" name="typeComptable" formControlName="typeComptable">
              <option value="" selected disabled hidden>Sélectionnez une valeur</option>
              <option *ngFor="let item of EnumProgramType.all()" [value]="EnumProgramType.toString(item)">
                {{ EnumProgramType.toString(item) }}
              </option>
            </select>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container *ngIf="!programForm.controls['typeComptable'].valid && programForm.controls['typeComptable'].touched">
            <div class="ds-input-group__feedback">Champ requis</div>
          </ng-container>
        </div>
        <!-- FIN de Type de programme comptable -->

        <!-- Type de programme libre -->
        <div
          id="input-type"
          class="form-step__type-libre ds-input-group"
          [class.ds-input-group--error]="!programForm.controls['type'].valid && programForm.controls['type'].touched"
          *ngIf="displayTypeInput"
        >
          <label for="creation_program_type" class="ds-input-group__label">Type de programme libre *</label>
          <span class="ds-input-group__input">
            <input
              id="creation_program_type"
              name="type"
              type="text"
              maxlength="20"
              class="ds-input-group__input"
              formControlName="type"
              #typeInput
            />
          </span>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="typeInput.value?.length === 20">
            <span>{{ typeInput.value?.length || 0 }}/20</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-type" *ngIf="!programForm.controls['type'].valid && programForm.controls['type'].touched">
            <div class="ds-input-group__feedback">Champ requis</div>
          </ng-container>
        </div>
        <!-- FIN de Type de programme libre -->

        <!-- Nom du programme -->
        <div
          id="input-nom"
          class="form-step__nom ds-input-group"
          [class.ds-input-group--error]="!programForm.controls['nom'].valid && programForm.controls['nom'].touched"
        >
          <label for="creation_program_nom" class="ds-input-group__label">Nom du programme *</label>
          <span class="ds-input-group__input">
            <input
              id="creation_program_nom"
              name="nom"
              type="text"
              maxlength="85"
              placeholder="Exemple : Fonds national pour la Société Numérique"
              class="ds-input-group__input"
              formControlName="nom"
              #nameInput
            />
          </span>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="nameInput.value?.length === 85">
            <span>{{ nameInput.value?.length || 0 }}/85</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-nom" *ngIf="!programForm.controls['nom'].valid && programForm.controls['nom'].touched">
            <div class="ds-input-group__feedback">Champ requis</div>
          </ng-container>
        </div>
        <!-- FIN de Nom du programme -->
      </div>
    </section>

    <div class="form-actions">
      <button
        *ngIf="isNewProgram"
        id="creation_program_btn_create"
        type="submit"
        class="ds-btn ds-btn--primary"
        [disabled]="!programForm.valid"
      >
        <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
        Créer le programme
      </button>
      <button
        *ngIf="!isNewProgram"
        id="creation_program_btn_update"
        type="submit"
        class="ds-btn ds-btn--primary"
        [disabled]="!programForm.valid"
      >
        <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
        Modifier le programme
      </button>
    </div>
  </form>
</main>
