import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferentielDocumentsAapForm, ReferentielHttpService, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';
import { takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreationDocumentHelperService extends SubscriptionDestroyerComponent {
  constructor(public httpService: ReferentielHttpService, private showToastrService: ShowToastrService, private router: Router) {
    super();
  }

  createForm(): FormGroup<ReferentielDocumentsAapForm> {
    return new FormGroup<ReferentielDocumentsAapForm>({
      scope: new FormControl(null, Validators.required),
      disponibiliteAap: new FormControl(null, Validators.required),
      etapes: new FormControl(null, Validators.required),
      nom: new FormControl('', Validators.required),
      habilitationWKF1: new FormControl(null, Validators.required),
      habilitationWKF2: new FormControl(null, Validators.required),
      champsObligatoire: new FormControl(null, Validators.required),
      codeGed: new FormControl(null, Validators.required),
      typeStructures: new FormControl(null, Validators.required),
      typePartenaires: new FormControl(null, Validators.required),
      roleStructures: new FormControl(null, Validators.required),
      absenceDocument: new FormControl(null, Validators.required),
      mediaTypes: new FormControl(null, Validators.required),
      domaineGed: new FormControl(null, Validators.required),
    });
  }

  saveReferentielFromForm(
    form: FormGroup<ReferentielDocumentsAapForm>,
    route: ActivatedRoute,
    isModification: boolean,
    idReferentiel: string
  ) {
    form.markAllAsTouched();

    if (form.invalid) {
      return;
    }

    const referentiel = form.getRawValue() as any;

    if (referentiel.habilitationWKF1 === 'noH') {
      referentiel.habilitationWKF1 = null;
    }
    if (referentiel.habilitationWKF2 === 'noH') {
      referentiel.habilitationWKF2 = null;
    }

    this.httpService
      .saveReferentielDocument(referentiel, isModification, idReferentiel)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: () => {
          this.showToastrService.success(`Votre référentiel de document a bien été ${isModification ? 'modifié' : 'créé'} avec succès`);
          form.markAsPristine();
          this.goToDocuments(route, isModification);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  goToDocuments(route: ActivatedRoute, isModification: boolean): void {
    this.router.navigate([`${isModification ? '../' : ''}../${route.snapshot.data.referentielPath}`], { relativeTo: route });
  }
}
