import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, Pole } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolesHelperService {
  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  getPoles(): Observable<Pole> {
    return this.httpClient.get<Pole>(`${this.environment.apiUrl}poles`);
  }
}
