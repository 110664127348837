import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'step-type-partenaire',
  templateUrl: './step-type-partenaire.component.html',
  styleUrls: ['./step-type-partenaire.component.scss'],
})
export class StepTypePartenaireComponent {
  @Input() partenaireForm: UntypedFormGroup;
  @Output() changePage = new EventEmitter<any>();
  constructor(private router: Router) {}

  changingPage(switchPage: boolean): void {
    this.changePage.emit(switchPage);
  }
}
