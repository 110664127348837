import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { RenewAccessEvalComponent } from './renew-access-evaluateur.component';

@NgModule({
  declarations: [RenewAccessEvalComponent],
  imports: [CommonModule, SharedModule],
})
export class RenewAccessEvalModule {}
