import { enumKeys } from "../../utils/enum-utils";

export enum EnumDateType {
  RELEVE = 'RELEVE',
  // AUDITION = 'AUDITION',
  // COPIL = 'COPIL',
}

export namespace EnumDateType {
  export function toString(type: EnumDateType): string {
    switch (type) {
      case EnumDateType.RELEVE:
        return 'Relève';
      // case EnumDateType.AUDITION:
      //   return 'Audition';
      // case EnumDateType.COPIL:
      //   return 'Copil';
    }
  }

  export function all(): EnumDateType[] {
    return enumKeys(EnumDateType) as unknown as EnumDateType[];
  }
}
