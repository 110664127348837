<section class="form-step">
  <div id="anchor1" class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span
      >Informations générales
    </h2>
  </header>

  <form id="informations-form" [formGroup]="informationsForm">
    <div class="form-step__section section-parametrage">
      <h3 class="form-step__subtitle">Paramétrage</h3>

      <!-- Catégorie de l'AAP -->
      <div id="input-categorie" class="form-step__categorie">
        <p class="radio-group-title">Catégorie de l'AAP <lib-asterisk></lib-asterisk></p>
        <div class="ds-radio-group">
          <input type="radio" name="categorie" id="aap" class="ds-radio-group__radio-input" value="AAP" formControlName="categorie" />
          <label for="AAP" class="ds-radio-group__radio-label">AAP</label>

          <input
            type="radio"
            name="categorie"
            id="ami"
            class="ds-radio-group__radio-input"
            value="AMI"
            formControlName="categorie"
            [attr.disabled]="true"
          />
          <label for="AMI" class="ds-radio-group__radio-label">AMI</label>

          <input
            type="radio"
            name="categorie"
            id="challenge"
            class="ds-radio-group__radio-input"
            value="Challenge"
            formControlName="categorie"
            [attr.disabled]="true"
          />
          <label for="Challenge" class="ds-radio-group__radio-label">Challenge</label>
        </div>
      </div>
      <!-- FIN de Catégorie de l'AAP -->

      <!-- Gré a gré -->
      <div id="input-niveau-instruction" class="form-step__greagre">
        <p class="radio-group-title">Gré à gré <lib-asterisk></lib-asterisk></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="greAgre"
            id="greAgre-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="greAgre"
          />
          <label for="greAgre-true" class="ds-radio-group__radio-label">Oui</label>

          <input
            type="radio"
            name="greAgre"
            id="greAgre-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="greAgre"
          />
          <label for="greAgre-false" class="ds-radio-group__radio-label">Non</label>
        </div>
      </div>
      <!-- FIN de Niveau gré à gré -->

      <!-- Immersion -->
      <div id="input-immersion" class="form-step__immersion">
        <p class="radio-group-title">Immersion <lib-asterisk></lib-asterisk></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="immersion"
            id="immersion-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="immersion"
          />
          <label for="immersion-true" class="ds-radio-group__radio-label">Oui</label>

          <input
            type="radio"
            name="immersion"
            id="immersion-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="immersion"
          />
          <label for="immersion-false" class="ds-radio-group__radio-label">Non</label>
        </div>
      </div>
      <!-- FIN Immersion -->

      <!-- Personne physique -->
      <div id="input-personne-physique" class="form-step__personnephysique">
        <p class="radio-group-title">Personne physique <lib-asterisk></lib-asterisk></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="personnePhysique"
            id="personnePhysique-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="personnePhysique"
            [attr.disabled]="projetLength > 0 && informationsForm.controls['personnePhysique'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': projetLength > 0 && informationsForm.controls['personnePhysique'].value === true }"
            for="personnePhysique-true"
            class="ds-radio-group__radio-label"
            >Oui</label
          >

          <input
            type="radio"
            name="personnePhysique"
            id="personnePhysique-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="personnePhysique"
            [attr.disabled]="projetLength > 0 && informationsForm.controls['personnePhysique'].value === true ? true : null"
          />
          <label
            [ngClass]="{ 'radio-disabled': projetLength > 0 && informationsForm.controls['personnePhysique'].value === true }"
            for="personnePhysique-false"
            class="ds-radio-group__radio-label"
            >Non</label
          >
        </div>
      </div>
      <!-- FIN Personne physique -->

      <!-- Ilab -->
      <div id="input-ilab" class="form-step__ilab">
        <p class="radio-group-title">I-LAB <lib-asterisk></lib-asterisk></p>
        <div class="ds-radio-group">
          <input type="radio" name="ilab" id="ilab-true" class="ds-radio-group__radio-input" [value]="true" formControlName="ilab" />
          <label for="ilab-true" class="ds-radio-group__radio-label">Oui</label>

          <input type="radio" name="ilab" id="ilab-false" class="ds-radio-group__radio-input" [value]="false" formControlName="ilab" />
          <label for="ilab-false" class="ds-radio-group__radio-label">Non</label>
        </div>
      </div>
      <!-- FIN de Niveau Ilab -->

      <!-- Niveau d'instruction -->
      <div id="input-niveau-instruction" class="form-step__instruction">
        <p class="radio-group-title">Intervenant(s) <lib-asterisk></lib-asterisk></p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="niveauInstruction"
            id="siege"
            class="ds-radio-group__radio-input"
            value="SIEGE"
            formControlName="niveauInstruction"
          />
          <label for="SIEGE" class="ds-radio-group__radio-label">Siège</label>

          <input
            type="radio"
            name="niveauInstruction"
            id="reseau"
            class="ds-radio-group__radio-input"
            value="RESEAUX"
            formControlName="niveauInstruction"
          />
          <label for="RESEAU" class="ds-radio-group__radio-label">Réseau</label>

          <input
            type="radio"
            name="niveauInstruction"
            id="mixte"
            class="ds-radio-group__radio-input"
            value="MIXTE"
            formControlName="niveauInstruction"
          />
          <label for="MIXTE" class="ds-radio-group__radio-label">Mixte</label>
        </div>
      </div>
      <!-- FIN de Niveau d'instruction -->

      <!-- Type de programme -->
      <div id="input-type" class="form-step__type ds-input-group" [class.ds-input-group--error]="false">
        <label for="type" class="ds-input-group__label">Type de programme <lib-asterisk></lib-asterisk></label>
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="type" name="type" formControlName="programme" [compareWith]="comparePrg">
            <option [ngValue]="{}" selected disabled>Sélectionnez une valeur</option>
            <option *ngFor="let program of programs" [ngValue]="program">{{ program.type }}{{ program.typeComptable }}</option>
          </select>
        </div>
      </div>
      <!-- FIN de Type de programme -->

      <!-- Dispositif de l'AAP -->
      <div id="input-dispositif" class="form-step__dispositif-principal ds-input-group">
        <label for="dispositif" class="ds-input-group__label">Dispositif principal de l'AAP</label>
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="dispositif" name="dispositif" formControlName="dispositif">
            <option [ngValue]="null" selected>Sélectionnez une valeur</option>
            <option *ngFor="let dispositif of shownDispositifs" [ngValue]="dispositif.code">{{ dispositif.code }}</option>
          </select>
        </div>
      </div>
      <!-- FIN de dispositif de l'AAP -->

      <!-- Dispositifs secondaires -->
      <div class="ds-select ds-select-group form-step__dispositifs-secondaires">
        <lib-ds-multiselect
          #dispositifsSecondairesComponent
          title="Dispositifs secondaires de l'AAP"
          class="filter"
          [dataList]="dispositifsDataList"
          [selectOptions]="{ placeholder: 'Sélectionnez une valeur' }"
          [name]="'dispositifsSecondaires'"
          (click)="dispositifsSecondairesChanged()"
        >
        </lib-ds-multiselect>
      </div>
      <!-- FIN de dispositifs secondaires -->

      <!-- Nom de l'AAP (référence comptable) -->
      <div id="input-code-comptable" class="form-step__nom-comptable ds-input-group" [class.ds-input-group--error]="false">
        <label for="codeComptable" class="ds-input-group__label">Nom de l'AAP (référence comptable) <lib-asterisk></lib-asterisk></label>
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="codeComptable" name="codeComptable" formControlName="codeComptable">
            <option [ngValue]="null" selected disabled hidden>Sélectionnez une valeur</option>
            <option value="Code inconnu">Code inconnu</option>
          </select>
        </div>
      </div>
      <!-- FIN de Nom de l'AAP (référence comptable) -->

      <!-- Nom de l'AAP à créer -->
      <div
        id="input-code"
        class="form-step__nom-a-creer ds-input-group"
        *ngIf="displayCodeInput"
        [class.ds-input-group--error]="!informationsForm.controls['code'].valid && informationsForm.controls['code'].touched"
      >
        <label for="nomACreer" class="ds-input-group__label">Nom de l'AAP à créer <lib-asterisk></lib-asterisk></label>
        <span class="ds-input-group__input">
          <input
            id="nomACreer"
            name="nomACreer"
            type="text"
            maxlength="32"
            class="ds-input-group__input"
            #nameCreateInput
            formControlName="code"
          />
        </span>
        <div class="form-input__length-hint" [class.form-input__length-hint--full]="nameCreateInput.value?.length === 32">
          <span>{{ nameCreateInput.value?.length || 0 }}/32</span>
        </div>
        <!-- Icône et message d'erreur -->
        <ng-container *ngIf="!informationsForm.controls['code'].valid && informationsForm.controls['code'].touched">
          <div class="ds-input-group__feedback">Champ requis</div>
        </ng-container>
      </div>
      <!-- FIN de Nom de l'AAP à créer -->

      <!-- Type de Workflow  -->
      <div id="input-workflow" class="form-step__workflow ds-input-group" [class.ds-input-group--error]="false">
        <label for="workflow" class="ds-input-group__label">Type de Workflow <lib-asterisk></lib-asterisk></label>
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="workflow" name="workflow" formControlName="typeWKF">
            <option [ngValue]="null" selected disabled hidden>Sélectionnez une valeur</option>
            <option *ngFor="let item of EnumTypeWorkFlow.all()" [value]="item">{{ EnumTypeWorkFlow.toString(item) }}</option>
          </select>
        </div>
      </div>
      <!-- FIN Type de Workflow -->

      <!-- Budget estimé -->
      <div id="input-budget" class="form-step__budget ds-input-group">
        <label for="budget" class="ds-input-group__label">Budget<lib-asterisk></lib-asterisk></label>
        <div class="ds-input-group__select ds-input-group__select--with-icon">
          <select id="budget" name="budget" formControlName="budgetEstime">
            <option [ngValue]="null" selected disabled>Sélectionnez une valeur</option>
            <option value="true">
              Budget estimé pour l'ensemble du projet par le chef de file (incitativité de l'aide pas avant le dépôt)
            </option>
            <option value="false">Budget calculé à partir des budgets déclarés par chaque déposant.</option>
          </select>
        </div>
      </div>
      <!-- FIN de Niveau Budget -->

      <!-- Nom de l'AAP pour les candidats -->
      <div
        id="input-nom"
        class="form-step__nom-candidats ds-input-group"
        [class.ds-input-group--error]="!informationsForm.controls['nom'].valid && informationsForm.controls['nom'].touched"
      >
        <label for="nomCandidats" class="ds-input-group__label">Nom de l'AAP pour les candidats <lib-asterisk></lib-asterisk></label>
        <span class="ds-input-group__input">
          <input
            id="nomCandidats"
            name="nomCandidats"
            type="text"
            maxlength="250"
            placeholder="Exemple : Aide aux projets de recherche et développement structurants pour la compétitivité"
            class="ds-input-group__input"
            #nameInput
            formControlName="nom"
            (keypress)="onkeypress($event)"
          />
        </span>
        <div class="form-input__length-hint" [class.form-input__length-hint--full]="nameInput.value?.length === 250">
          <span>{{ nameInput.value?.length || 0 }}/250</span>
        </div>
        <!-- Icône et message d'erreur -->
        <ng-container *ngIf="!informationsForm.controls['nom'].valid && informationsForm.controls['nom'].touched">
          <div class="ds-input-group__feedback">Champ requis</div>
        </ng-container>
      </div>
      <!-- FIN de Nom de l'AAP pour les candidats -->

      <!-- Description de l'AAP pour les candidats -->
      <div id="input-description" class="form-step__description ds-input-group">
        <label for="description" class="ds-input-group__label">Description de l'AAP pour les candidats</label>
        <textarea
          id="description"
          name="description"
          rows="5"
          col="0"
          maxlength="250"
          class="ds-input-group__textarea"
          #descriptionInput
          formControlName="description"
        ></textarea>
        <div class="form-input__length-hint" [class.form-input__length-hint--full]="descriptionInput.value?.length === 250">
          <span>{{ descriptionInput.value?.length || 0 }}/250</span>
        </div>
      </div>

      <!-- FIN de Description de l'AAP pour les candidats -->

      <!-- Logotype -->
      <div id="logotype" class="form-step__logotype file-upload" *ngxPermissionsOnly="['PARAMETRAGE_WRITE']">
        <div class="file-upload__label">Logotype</div>
        <label class="file-upload__custom-input">
          <input type="file" id="file" (change)="onUpload($event.target.files)" accept=".gif, .jpg, .png, .pdf, .bmp" />
          <input type="hidden" name="fileHidden" formControlName="logo" />
          <div id="add-fichier" class="ds-btn ds-btn--primary" *ngIf="!fileName">Ajouter un fichier</div>
          <div id="replace-fichier" class="ds-btn ds-btn--primary" *ngIf="fileName">Modifier le fichier</div>
        </label>
        <div id="info-upload-fichier" class="file-upload__hint" *ngIf="!informationsForm.get('logo').value">
          Types de fichiers acceptés : .jpg .png .pdf .gif .bmp<br />
          Poids maximum : 500ko<br />
          Taille recommandée : 200*60
        </div>
        <div id="logo-value" class="file-upload__hint" *ngIf="informationsForm.get('logo').value">
          {{ informationsForm.get('logo').value }}
        </div>
        <div id="error-logo-type" class="file-upload__error" *ngIf="fileNotSupported">
          Le type du document sélectionné n'est pas supporté, merci de sélectionner un autre fichier.
        </div>
        <div id="error-logo-size" class="file-upload__error" *ngIf="fileTooBig">
          Le fichier sélectionné est trop lourd, merci de sélectionner un autre fichier.
        </div>
      </div>
      <!-- FIN de Logotype -->
    </div>

    <div class="form-step__section section-support">
      <!-- ------- -->
      <!-- Support -->
      <!-- ------- -->
      <h3 class="form-step__subtitle">Support</h3>

      <!-- Boîte à lettres métier de l'AAP -->
      <div
        id="input-email"
        class="form-step__email ds-input-group"
        [class.ds-input-group--error]="!informationsForm.controls['bal'].valid && informationsForm.controls['bal'].touched"
      >
        <label for="email" class="ds-input-group__label">Boîte à lettres métier de l'AAP</label>
        <span class="ds-input-group__input">
          <input
            id="email"
            name="email"
            type="email"
            placeholder="Email visible côté candidat"
            class="ds-input-group__input"
            formControlName="bal"
          />
        </span>
        <ng-container *ngIf="!informationsForm.controls['bal'].valid && informationsForm.controls['bal'].touched">
          <div class="ds-input-group__feedback">L'email renseigné est invalide, veuillez réessayer</div>
        </ng-container>
      </div>
      <!-- FIN de Boîte à lettres métier de l'AAP -->

      <!-- Page de présentation de l'AAP -->
      <div
        id="input-presentation"
        class="form-step__presentation ds-input-group"
        [class.ds-input-group--error]="
          !informationsForm.controls['lienDocumentation'].valid &&
          informationsForm.controls['lienDocumentation'].touched &&
          showErrorLienDoc()
        "
      >
        <label for="presentation" class="ds-input-group__label">Page de présentation de l'AAP</label>
        <span class="ds-input-group__input">
          <input
            id="presentation"
            name="presentation"
            type="text"
            placeholder="copier-coller l'url depuis le site bpifrance"
            class="ds-input-group__input"
            formControlName="lienDocumentation"
          />
        </span>
        <ng-container
          *ngIf="
            !informationsForm.controls['lienDocumentation'].valid &&
            informationsForm.controls['lienDocumentation'].touched &&
            showErrorLienDoc()
          "
        >
          <div class="ds-input-group__feedback">L'URL renseignée est invalide, veuillez réessayer</div>
        </ng-container>
      </div>
      <!-- FIN de Page de présentation de l'AAP -->
    </div>
  </form>
</section>
