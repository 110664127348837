<div class="sidenav-creation">
  <div class="sidenav-creation__header">
    <a routerLink="/aaps" class="sidenav-creation__back">
      <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back"></lib-pxl-icon>
      Tous les appels à projets
    </a>

    <h1 class="sidenav-creation__title">Nouvel appel à projets</h1>
  </div>

  <nav class="sidenav-creation__nav">
    <ol>
      <li *ngFor="let step of steps; index as i">
        <a
          id="{{ 'step' + i }}"
          class="sidenav-creation__step"
          [class.sidenav-creation__step--current]="currentSection === step.section && activeAnchor === step.section"
          (click)="goToSection(step.section); currentSection = step.section"
        >
          <span class="sidenav-creation__step-number">{{ i + 1 }}</span>
          {{ step.label }}
        </a>
      </li>
    </ol>
  </nav>
</div>
