import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { ParametrageDepensesProjetsComponent } from './parametrage-depenses-projets.component';
import { RegimesAidesModule } from './regimes-aides/regimes-aides.module';
import { DepensesProjetConfigModule } from './depenses-projet-config/depenses-projet-config.module';

@NgModule({
  declarations: [ParametrageDepensesProjetsComponent],
  imports: [CommonModule, SharedModule, SharedUiModule, MatTableModule, DepensesProjetConfigModule, RegimesAidesModule],
  exports: [ParametrageDepensesProjetsComponent],
})
export class ParametrageDepensesProjetsModule {}
