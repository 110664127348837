import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { DsMultiselectComponent, FilterDataInterface, Pole, SubscriptionDestroyerComponent } from '@shared-ui';
import { PolesHelperService } from './poles-helper.service';

@Component({
  selector: 'pxl-poles',
  templateUrl: './poles.component.html',
  styleUrls: ['./poles.component.scss'],
})
export class PolesComponent extends SubscriptionDestroyerComponent implements OnInit {
  @ViewChild('poleComponent') polesComponent: DsMultiselectComponent;
  @Input() polesFormGroup: UntypedFormGroup;
  @Input() stepNumber: number;

  poles: Pole[];
  displayPolesInput = false;
  displayPolesObligatoireInput = false;
  dataListPoles: FilterDataInterface[] = [];

  constructor(@Inject(DOCUMENT) public document: Document, public polesHelperService: PolesHelperService) {
    super();
  }

  ngOnInit(): void {
    this.polesHelperService
      .getPoles()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (poles: Pole[]) => {
          this.poles = poles;
          this.poles.forEach((poles: Pole) => {
            this.dataListPoles.push({
              id: poles,
              value: poles.nomCommercial,
              isSelected: false,
            });
          });
          this.selectDataListPolesValues();
        },
        complete: () => {
          this.setListePolesInput(this.polesFormGroup.controls.polePresent.value);
          this.polesFormGroup.controls.polePresent.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(polePresent => {
            this.setListePolesInput(polePresent);
          });

          this.setListePolesValidators(this.polesFormGroup.controls.poleObligatoire.value);
          this.polesFormGroup.controls.poleObligatoire.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(poleObligatoire => {
            this.setListePolesValidators(poleObligatoire);
          });
        },
      });
  }

  public setListePolesInput(polePresent: string): void {
    this.displayPolesInput = !!polePresent;
    if (this.polesComponent) {
      this.polesComponent.selectOptions.isDisabled = !polePresent;
      this.polesComponent.reset();
    }
    if (!polePresent) {
      this.polesFormGroup.patchValue({ poleObligatoire: false });
    }
  }

  public setListePolesValidators(poleObligatoire: string): void {
    if (poleObligatoire) {
      this.displayPolesObligatoireInput = true;
      this.polesFormGroup.controls.poles.setValidators(Validators.required);
      this.polesFormGroup.controls.poles.updateValueAndValidity();
    } else {
      this.displayPolesObligatoireInput = false;
      this.polesFormGroup.controls.poles.clearValidators();
      this.polesFormGroup.controls.poles.updateValueAndValidity();
    }
  }

  checkPoles() {
    const pole: any = [];
    this.polesComponent.getSelectedValues().forEach((poles: any) => {
      pole.push({
        id: poles.id.id,
        nomCommercial: poles.value,
        actif: poles.id.actif,
      });
    });
    this.polesFormGroup.patchValue({ poles: pole });
  }

  selectDataListPolesValues() {
    const select: any[] = [];

    if (this.polesFormGroup.value.poles) {
      this.polesFormGroup.value.poles.map((p: Pole) => {
        select.push({ id: p.id, actif: p.actif, nomCommercial: p.nomCommercial });
      });

      this.dataListPoles.forEach((p: FilterDataInterface) => {
        const obj: any = p.id;
        select.forEach((po: any) => {
          if (obj['id'] === po.id) {
            p.isSelected = true;
          }
        });
      });
    }
  }
}
