<h2 class="step__title">Informations de ma structure</h2>

<!-- Informations de la structure -->
<form class="step__content">
  <!-- Siret de la structure -->
  <div class="ds-input-group">
    <lib-structure-searcher
      [displayCreationEnCoursOption]="isAapPersonnePhysique"
      (motifNonRattachementSelected)="onMotifNonRattachementSelected($event)"
      (structureSelected)="onStructureSelected($event)"
      (structuresFound)="onStructuresFound($event)"
      (maStructureNotListed)="onMaStructureNotListed()"
    ></lib-structure-searcher>
  </div>

  <!-- Raison sociale -->
  <div
    *ngIf="structureEnCreation"
    id="input-raison"
    class="ds-input-group"
    [class.ds-input-group--error]="
      structureForm.get('raisonSocial')?.invalid && structureForm.get('raisonSocial')?.enabled && structureForm.get('raisonSocial')?.touched
    "
  >
    <label for="raison" class="ds-input-group__label">Raison Sociale</label>
    <span class="ds-input-group__input">
      <input
        id="raison"
        name="raisonSocial"
        type="text"
        maxlength="100"
        placeholder="Votre Raison Sociale"
        class="input_raison ds-input-group__input"
        formControlName="raisonSocial"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-raison"
      *ngIf="
        structureForm.get('raisonSocial')?.invalid &&
        structureForm.get('raisonSocial')?.enabled &&
        structureForm.get('raisonSocial')?.touched
      "
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une raison sociale valide</div>
    </ng-container>
  </div>
</form>
<!-- FIN de Informations de la structure -->

<div class="step__buttons">
  <button class="ds-btn ds-btn--secondary" (click)="onPreviousButtonClick()">Précédent</button>
  <button class="ds-btn ds-btn--primary" (click)="onNextButtonClick()" [disabled]="nextButtonDisabled">
    {{ nextButtonAsSaisieManuelle ? 'Saisir vos informations' : 'Suivant' }}
  </button>
</div>
