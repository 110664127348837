<section class="form-step">
  <div class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span>
      {{ title }}
    </h2>
  </header>

  <div class="referentiels-list-container">
    <div class="referentiel-card" *ngFor="let referentielFormGroup of referentielForm.controls">
      <pxl-critere-referentiel-card
        [formGroup]="referentielFormGroup"
        [numberOfCards]="referentielForm.controls.length"
        [isNotation]="isNotation"
      ></pxl-critere-referentiel-card>
    </div>
  </div>
</section>
