import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { IntervenantsRoleModule } from './intervenants-role/intervenants-role.module';
import { ParametrageIntervenantComponent } from './parametrage-intervenant.component';

@NgModule({
  declarations: [ParametrageIntervenantComponent],
  imports: [CommonModule, SharedModule, SharedUiModule, MatTableModule, IntervenantsRoleModule],
  exports: [ParametrageIntervenantComponent],
})
export class ParametrageIntervenantModule {}
