import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ArrayUtils, DsMultiselectComponent, FilterDataInterface, FilterDatesReleves, SearchComitesFilter } from '@shared-ui';

@Component({
  selector: 'pxl-comite-search',
  templateUrl: './comite-search.component.html',
  styleUrls: ['./comite-search.component.scss'],
})
export class ComiteSearchComponent {
  @Input() aapFilterList: FilterDataInterface[] = [];
  @Input() dateReleveFilterList: FilterDataInterface[] = [];
  @Input() comiteCreatorFilterList: FilterDataInterface[] = [];

  // Event emitter
  @Output() searchEventEmitter = new EventEmitter<SearchComitesFilter>();

  @ViewChild('aapComponent') aapComponent: DsMultiselectComponent | undefined;
  @ViewChild('dateReleveComponent') dateReleveComponent: DsMultiselectComponent | undefined;

  comiteCreator: string | null = null;
  dateTenue: Date | null = null;

  accordionOpen = false;
  filteredDateReleveFilterList: FilterDataInterface[] = [];
  aapSelectedItems: string[] = [];

  resetAllFilters(): void {
    this.aapSelectedItems = [];

    this.aapComponent?.reset();
    this.dateReleveComponent?.reset();
    this.comiteCreator = null;
    this.dateTenue = null;
  }

  submitSearch(): void {
    this.searchEventEmitter.emit({
      aapsIds: this.selectedAapIds,
      matricule: this.comiteCreator,
      dateTenue: this.dateTenue,
      datesReleves: this.selectedDatesReleves,
    });
  }

  toggleFilters(): void {
    this.accordionOpen = !this.accordionOpen;
  }

  get selectedAapIds(): string[] {
    return this.aapComponent?.getSelectedValues().map(aap => <string>aap.id) ?? [];
  }

  get selectedDatesReleves(): Date[] {
    return (
      this.dateReleveComponent
        ?.getSelectedValues()
        .map(filter => <FilterDatesReleves>filter.id)
        .map(dateReleve => dateReleve.datesReleves)
        .reduce((accumulator, currentArray) => {
          return accumulator.concat(currentArray);
        }, []) ?? []
    );
  }

  onAapLeave() {
    if (!this.aapComponent) {
      this.filteredDateReleveFilterList = [];
      this.dateReleveComponent?.reset();
      return;
    }

    const aapIdList = this.aapComponent?.getSelectedValues()?.map(program => program.id.toString()) ?? [];

    if (ArrayUtils.arrayEquals(this.aapSelectedItems, aapIdList)) {
      return;
    }

    this.aapSelectedItems = aapIdList;
    this.filteredDateReleveFilterList = this.dateReleveFilterList.filter(
      dateReleveFilter => this.aapSelectedItems.indexOf((<FilterDatesReleves>dateReleveFilter.id).idAap) !== -1
    );
    this.dateReleveComponent?.reset();
  }
}
