import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProjetListTransverseAapsComponent } from './projet-list-transverse-aaps.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';
import { TableauProjetModule } from '@shared-evaluateur/components/tableau-projet/tableau-projet.module';
import { ProjectSearchTransverseModule } from './project-search/project-search-transverse.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SharedUiModule,
    MaterialModule,
    MatCheckboxModule,
    MatMenuModule,
    ProjectSearchTransverseModule,
    TableauProjetModule,
    NgbPopoverModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  declarations: [ProjetListTransverseAapsComponent],
  exports: [ProjetListTransverseAapsComponent],
  providers: [],
})
export class ProjetListTransverseAapsModule {}
