<div class="table-container table">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="clef"
    matSortDirection="asc"
    matSortDisableClear
    aria-describedby="tableau de grille d'impacts (KPIs)"
  >
    <ng-container matColumnDef="select">
      <th id="kpi-select" mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td id="id" mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <mat-checkbox (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)"> </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="titre">
      <th id="kpi-titre" mat-header-cell *matHeaderCellDef mat-sort-header>Titre</th>
      <td id="titre" mat-cell *matCellDef="let element">
        <span>{{ element?.titre }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="categorie">
      <th id="kpi-categorie" mat-header-cell *matHeaderCellDef mat-sort-header>Catégorie</th>
      <td id="categorie" mat-cell *matCellDef="let element">
        <span>{{ element?.categorie?.titre }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="scope">
      <th id="kpi-scope" mat-header-cell *matHeaderCellDef mat-sort-header>Périmètre</th>
      <td id="scope" mat-cell *matCellDef="let element">
        <span>{{ EnumScope.toString(element?.categorie?.scope) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="clef">
      <th id="kpi-clef" mat-header-cell *matHeaderCellDef mat-sort-header>Clé</th>
      <td id="clef" mat-cell *matCellDef="let element">
        <span>{{ element?.clef }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="r" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
  </table>
  <div class="no-result" *ngIf="dataSource?.data?.length === 0">
    <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
    <span id="large-span-result">Il n'y a rien ici...</span>
    <span id="small-span-result">0 KPI répertorié.</span>
  </div>
</div>
