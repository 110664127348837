export enum EnumTacheARealiser {
  VERROUILLAGE_A_FAIRE = 'VERROUILLAGE_A_FAIRE',
  VERROUILLAGE_FAIT = 'VERROUILLAGE_FAIT',
  ELIGIBILITE_A_FAIRE = 'ELIGIBILITE_A_FAIRE',
  ELIGIBILITE_FAIT = 'ELIGIBILITE_FAIT',
  OPPORTUNITE_A_FAIRE = 'OPPORTUNITE_A_FAIRE',
  OPPORTUNITE_FAITE = 'OPPORTUNITE_FAITE',
  AUDITION_A_FAIRE = 'AUDITION_A_FAIRE',
  AUDITION_FAITE = 'AUDITION_FAITE',
  INSTRUCTION_A_FAIRE = 'INSTRUCTION_A_FAIRE',
  INSTRUCTION_FAITE = 'INSTRUCTION_FAITE',
  A_EDITER = 'A_EDITER',
  EDITE_A_RELIRE_DJPI = 'EDITE_A_RELIRE_DJPI',
  EDITE_RELU_DJPI = 'EDITE_RELU_DJPI',
  SYTHESE_COMPLETUDE_SECOND_NIVEAU_A_FAIRE = 'SYTHESE_COMPLETUDE_SECOND_NIVEAU_A_FAIRE',
  SYTHESE_COMPLETUDE_SECOND_NIVEAU_FAITE = 'SYTHESE_COMPLETUDE_SECOND_NIVEAU_FAITE',
  CONTROLE_SECOND_NIVEAU_A_FAIRE = 'CONTROLE_SECOND_NIVEAU_A_FAIRE',
  CONTROLE_SECOND_NIVEAU_FAITE = 'CONTROLE_SECOND_NIVEAU_FAITE',
  CONTRAT_A_SIGNER = 'CONTRAT_A_SIGNER',
  CONTRAT_EN_COURS_DE_SIGNATURE = 'CONTRAT_EN_COURS_DE_SIGNATURE',
  CONTRAT_SIGNE = 'CONTRAT_SIGNE',
}

export namespace EnumTacheARealiser {
  export function toString(tacheARealiser: EnumTacheARealiser): string {
    switch (tacheARealiser) {
      case EnumTacheARealiser.VERROUILLAGE_A_FAIRE:
        return 'Analyse à faire';
      case EnumTacheARealiser.VERROUILLAGE_FAIT:
        return 'Analyse faite';
      case EnumTacheARealiser.ELIGIBILITE_A_FAIRE:
        return 'Eligibilité à faire';
      case EnumTacheARealiser.ELIGIBILITE_FAIT:
        return 'Eligibilité faite';
      case EnumTacheARealiser.OPPORTUNITE_A_FAIRE:
        return 'Notation opportunité à faire';
      case EnumTacheARealiser.OPPORTUNITE_FAITE:
        return 'Notation opportunité faite';
      case EnumTacheARealiser.AUDITION_A_FAIRE:
        return 'Notation audition à faire';
      case EnumTacheARealiser.AUDITION_FAITE:
        return 'Notation audition faite';
      case EnumTacheARealiser.INSTRUCTION_A_FAIRE:
        return 'Instruction à faire';
      case EnumTacheARealiser.INSTRUCTION_FAITE:
        return 'Instruction faite';
      case EnumTacheARealiser.A_EDITER:
        return 'Contractualisation à éditer';
      case EnumTacheARealiser.EDITE_A_RELIRE_DJPI:
        return 'Contractualisation édité à relire DJPI';
      case EnumTacheARealiser.EDITE_RELU_DJPI:
        return 'Contractualisation édité relu DJPI';
      case EnumTacheARealiser.SYTHESE_COMPLETUDE_SECOND_NIVEAU_A_FAIRE:
        return 'Complétude 2ème niveau à faire';
      case EnumTacheARealiser.SYTHESE_COMPLETUDE_SECOND_NIVEAU_FAITE:
        return 'Complétude 2ème niveau faite';
      case EnumTacheARealiser.CONTROLE_SECOND_NIVEAU_A_FAIRE:
        return 'Contrôles 2nd niveau - à faire';
      case EnumTacheARealiser.CONTROLE_SECOND_NIVEAU_FAITE:
        return 'Contrôles 2nd niveau - faits';
      case EnumTacheARealiser.CONTRAT_A_SIGNER:
        return 'Contrat(s) à signer';
      case EnumTacheARealiser.CONTRAT_EN_COURS_DE_SIGNATURE:
        return 'Contrat(s) en cours de signature';
      case EnumTacheARealiser.CONTRAT_SIGNE:
        return 'Contrat(s) signé(s)';
    }
  }
}
