import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { CreationRegimesAidesComponent } from './creation-regimes-aides.component';
import { CreationRegimesAidesHelperService } from './creation-regimes-aides.helper.service';

@NgModule({
  declarations: [CreationRegimesAidesComponent],
  imports: [CommonModule, SharedModule, SharedUiModule],
  providers: [CreationRegimesAidesHelperService],
})
export class CreationRegimesAidesModule {}
