import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { DemandeInscription, EnumRolePartenaire, ShowToastrService } from '@shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-projet-validation-demande-inscription',
  templateUrl: './projet-validation-demande-inscription.component.html',
  styleUrls: ['./projet-validation-demande-inscription.component.scss'],
})
export class ProjetValidationDemandeInscriptionComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  demandeInscriptionId: string;
  demandeInscription: DemandeInscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction,
    private evaluateurService: EvaluateurService
  ) {}

  ngOnInit(): void {
    this.demandeInscriptionId = this.route.snapshot.params.demandeInscriptionId;
    this.getDemandeInscription();
  }

  getDemandeInscription(): void {
    this.subscriptions.push(
      this.evaluateurService.getDemandeInscriptionById(this.demandeInscriptionId).subscribe({
        next: response => {
          if (response) {
            this.demandeInscription = response.body;
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
          this.navigateToAaps();
        },
      })
    );
  }

  validateProfile(): void {
    this.subscriptions.push(
      this.evaluateurService.validerDemandeInscription(this.demandeInscriptionId, 'Yes').subscribe({
        next: resultDemande => {
          const demandeValide = resultDemande.body;
          this.showToastrService.success(
            `${demandeValide?.instructeur?.email} recevra une notification pour accéder à son espace ${demandeValide?.organisme?.nom} dans PICXEL.`,
            `L'accès de ${demandeValide?.instructeur?.email} a été bien activé pour le rôle ${
              demandeValide?.instructeur?.role ? EnumRolePartenaire.toString(demandeValide.instructeur.role) : ''
            }.`
          );
        },
        error: (err: HttpErrorResponse) => {
          if (err?.error?.code === '1331') {
            this.showToastrService.error(`L'accès de ${this.demandeInscription?.instructeur?.email} est déjà validé`);
          } else if (err?.error?.code === '1332') {
            this.showToastrService.error(
              `${this.demandeInscription?.instructeur?.email} devra renouveller sa demande d'accès à son espace $_organisme dans PICXEL`,
              `Le lien de validation d'accès au compte de l'utilisateur ${this.demandeInscription?.instructeur?.email} a expiré.`
            );
          } else {
            this.showToastrService.checkCodeError(err?.error);
          }
        },
        complete: () => {
          this.navigateToAaps();
        },
      })
    );
  }

  /*
   * Cette méthode permet de retourner à la page de tous les aaps
   * */
  navigateToAaps(): void {
    this.router.navigate(['aaps']);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
