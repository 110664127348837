<h2 id="modal-title" class="modal__title font-weight-bold" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="affectationForm">
  <div class="form-step__role" *ngIf="isEvaluateurModal && modalData.affecterEval">
    <div class="ds-input-group" [class.ds-input-group--error]="!formRole.valid && formRole.touched" *ngIf="isEvaluateurModal">
      <div class="ds-input-group__label">Rôle *</div>
      <div class="select-container">
        <div id="id_input-role-select" class="input-role-select role-select">
          <ng-container *ngFor="let item of rolesIntervenantAffectable">
            <input formControlName="role" type="radio" name="role" [id]="item" [value]="item" hidden />
            <label class="radio-select-label" [for]="item">{{ IntervenantRoles.toString(item) }}</label>
          </ng-container>
        </div>
      </div>
      <ng-container id="error-role" *ngIf="!formRole.valid && formRole.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un rôle.</div>
      </ng-container>
    </div>
  </div>
  <div class="ds-input-group" [class.ds-input-group--error]="(!formEmails.valid && formEmails.touched) || !isEmailsValid">
    <ng-container id="subLabel" *ngIf="modalData.subTitle">
      <div class="ds-input-group__label">{{ modalData.subTitle }}</div>
    </ng-container>
    <label for="emails" class="ds-input-group__label bold"
      >Emails des {{ isEvaluateurModal ? 'intervenants' : 'superviseurs' }} (séparés par des points-virgules)
      <span class="ds-input-group__label-asterisk">*</span></label
    >
    <textarea
      id="emails"
      name="emails"
      type="text"
      [maxlength]="emailsInputSizeMax"
      placeholder="Ex: example@mail.fr; example2@mail.fr"
      formControlName="emails"
      class="input_email ds-input-group__textarea"
    ></textarea>
    <div class="form-input__length-hint" [class.form-input__length-hint--full]="formEmails.value?.length === emailsInputSizeMax">
      <span>{{ formEmails.value?.length || 0 }} / {{ emailsInputSizeMax }}</span>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-email" *ngIf="(!formEmails.valid && formEmails.touched) || !isEmailsValid">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner des emails valides.</div>
    </ng-container>
  </div>

  <div class="form-step__role" *ngIf="isEvaluateurModal && modalData.affecterEval">
    <div class="ds-input-group" [class.ds-input-group--error]="!formEtape.valid && formEtape.touched" *ngIf="isEvaluateurModal">
      <div class="ds-input-group__label">Etapes *</div>
      <div class="select-container">
        <div id="id_input-etape-select" class="input-role-select role-select">
          <ng-container *ngFor="let item of etapesAffectation">
            <input
              (click)="etapeRadioTouched()"
              formControlName="etape"
              type="radio"
              name="etape"
              [id]="item"
              [value]="item"
              hidden
              [attr.disabled]="disableEtapeRadioButton(item) ? true : null"
            />
            <label
              class="radio-select-label"
              [ngClass]="{ 'radio-disabled': disableEtapeRadioButton(item) }"
              [for]="item"
              [matTooltip]="getRadioTooltipMessage(item)"
              >{{ EnumProjetEtape.toString(item) }}</label
            >
          </ng-container>
        </div>
      </div>
      <ng-container id="error-etape" *ngIf="!formEtape.valid && formEtape.touched">
        <ng-container *ngIf="!formEtape.value; else etapeSelected">
          <div class="ds-input-group__feedback">Merci de sélectionner l'étape.</div>
        </ng-container>
        <ng-template #etapeSelected>
          <!-- Messages d'erreurs de dates evaluation et audition -->
          <div class="ds-input-group__feedback" *ngIf="!allProjectsHaveEvaluationDate() && formEtape.value === EnumProjetEtape.EVALUATION">
            Un ou plusieurs projets n'ont pas de date d'évaluation, merci de la renseigner avant l'affectation d'évaluateur(s).
          </div>
          <div class="ds-input-group__feedback" *ngIf="!allProjectsHaveAuditionDate() && formEtape.value === EnumProjetEtape.AUDITION">
            Un ou plusieurs projets n'ont pas de date d'audition, merci de la renseigner avant l'affectation d'évaluateur(s).
          </div>

          <!-- Messages d'erreurs de phases evaluation et audition -->
          <div class="ds-input-group__feedback" *ngIf="!allProjectsHaveEvaluationPhase() && formEtape.value === EnumProjetEtape.EVALUATION">
            Au moins un projet sélectionné n'a pas la notation phase évaluation ouverte en écriture (projet jusqu'à évaluation validée),
            merci de désélectionner les projets concernés pour pouvoir affecter des évaluateurs sur cette étape.
          </div>
          <div class="ds-input-group__feedback" *ngIf="!allProjectsHaveAuditionPhase() && formEtape.value === EnumProjetEtape.AUDITION">
            Au moins un projet sélectionné n'a pas la notation phase audition ouverte en écriture (audition en cours / audition validée),
            merci de désélectionner les projets concernés pour pouvoir affecter des évaluateurs sur cette étape.
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
    <lib-pxl-icon class="icon" icon="icon-action-close"></lib-pxl-icon> {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" (click)="confirmerAffectation()" cdkFocusInitial>
    <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon> {{ modalData.textGoButton }}
  </button>
</div>
