import { EnumPhaseComite } from '../enums/enum.typeComite';
import { ComitesDateReleveInterface, SearchObjectComitesInterface } from '../interfaces/search-object-comites.interface';
import { FilterRsSiretInterface } from '../interfaces/filterData.interface';

export class SearchObjectComites implements SearchObjectComitesInterface {
  idAAPs: string[] = [];
  phase: EnumPhaseComite;
  comiteId: string;
  acronymes: string[] = [];
  siretRaisonSociale: FilterRsSiretInterface[] = [];
  thematiques: string[] = [];
  filtreRelevesAap: ComitesDateReleve[];
  etapes: string[] = [];
  statuts: string[] = [];
  regionsBpifrance: string[] = [];
  directionsRegionales: string[] = [];
  budgetProjet: string;
}

export class ComitesDateReleve implements ComitesDateReleveInterface {
  dateReleve: Date;
  idAap: string;
}

export class SearchComitesFilter {
  phase?: string;
  aapsIds?: string[];
  matricule?: string;
  dateTenue?: Date;
  datesReleves?: Date[];
}
