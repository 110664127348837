import { NgModule } from '@angular/core';
import { ReferentielHttpService } from '@services-evaluateur/referentiel.http.service';
import { UploadDocumentService } from '@services-evaluateur/upload-document.service';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CreationIntervenantModule } from './creation-intervenant/creation-intervenant.module';
import { CreationReferentielModule } from './creation-referentiel/creation-referentiel.module';
import { ParametrageIntervenantModule } from './parametrage-intervenant/parametrage-referentiel.module';
import { ParametrageReferentielModule } from './parametrage-referentiel/parametrage-referentiel.module';
import { ReferentielRoutingModule } from './referentiels-routing.module';
import { ReferentielsComponent } from './referentiels.component';
import { ParametrageDocumentsAapModule } from './parametrage-documents-aap/parametrage-documents-aap.module';
import { CreationDocumentsAapModule } from './creation-documents-aap/creation-documents-aap.module';
import { CreationDispositifComponent } from './creation-dispositif/creation-dispositif.component';
import { MaterialModule } from 'libs/shared-ui/src/lib/shared/modules/material.module';
import { ParametrageDepensesProjetsModule } from './parametrage-depenses-projets/parametrage-depenses-projets.module';
import { CreationDepensesProjetsModule } from './creation-depenses-projets/creation-depenses-projets.module';
import { CreationRegimesAidesModule } from './creation-regimes-aides/creation-regimes-aides.module';

@NgModule({
  imports: [
    SharedModule,
    SharedUiModule,
    ParametrageReferentielModule,
    ParametrageIntervenantModule,
    ParametrageDocumentsAapModule,
    ParametrageDepensesProjetsModule,
    CreationReferentielModule,
    CreationIntervenantModule,
    CreationDocumentsAapModule,
    CreationDepensesProjetsModule,
    CreationRegimesAidesModule,
    MaterialModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
    ReferentielRoutingModule,
  ],
  declarations: [ReferentielsComponent, CreationDispositifComponent],
  exports: [ReferentielsComponent],
  providers: [ReferentielHttpService, UploadDocumentService],
})
export class ReferentielsModule {}
