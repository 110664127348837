<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="autoAffectForm">
  <div
    id="input-activite"
    class="form-step__activite ds-input-group"
    [class.ds-input-group--error]="!autoAffectForm.controls['activites'].valid && autoAffectForm.controls['activites'].touched"
  >
    <div class="ds-input-group__label">Activité.s<span class="ds-input-group__label-asterisk"> *</span></div>
    <div class="select-container">
      <div class="activite-select">
        <ng-container formArrayName="activites" *ngFor="let item of activites; let i = index">
          <input [formControlName]="i" type="checkbox" name="activite" [id]="item" hidden />
          <label [for]="item">{{ EnumActivite.toString(item) }}</label>
        </ng-container>
      </div>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-activite" *ngIf="!autoAffectForm.controls['activites'].valid && autoAffectForm.controls['activites'].touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner votre activité</div>
    </ng-container>
  </div>
  <div class="d-flex">
    <div id="input_referent" class="form-step__referent ds-input-group" *ngIf="!modalData.isUserDar">
      <div class="d-flex">
        <label for="referent" class="ds-input-group__label">Référent</label>
        <div
          [matTooltip]="
            'L\'affectation en tant que Référent sur le projet est un statut à titre indicatif. Il permet d\'indiquer la personne qui centralise les activités de la DE autour du projet.'
          "
          class="d-flex align-items-center mb-2"
        >
          <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
        </div>
      </div>
      <div>
        <span id="non-option" class="ds-body-2--regular label-r align-bottom me-2">Non</span>
        <label for="ref" class="ds-switch ds-switch--m">
          <input type="checkbox" id="ref" formControlName="referent" />
          <span class="slider"></span>
        </label>
        <span id="oui-option" class="ds-body-2--regular label-r align-bottom ms-2">Oui</span>
      </div>
    </div>

    <div
      id="input_jpi"
      class="form-step__jpi ds-input-group ms-5"
      *ngIf="showIntervenantPI"
      [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.INTERVENTION_JPI"
    >
      <div class="d-flex">
        <label for="jpi" class="ds-input-group__label">Intervention J/PI</label>
        <div
          [matTooltip]="
            'L\'affectation en tant qu\'Intervenant J/PI permet aux experts de la DJPI d\'évaluer le risque J/PI d\'un projet en phase d\'Instruction. En sélectionnant Oui, vous aurez le droit d\'écriture sur le formulaire de recommandations mais vous serez en consultation seule sur les autres activités de l\'Instruction.'
          "
          class="d-flex align-items-center mb-2"
        >
          <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
        </div>
      </div>
      <div>
        <span id="non-option" class="ds-body-2--regular label-r align-bottom me-2">Non</span>
        <label for="intervenantPI" class="ds-switch ds-switch--m">
          <input type="checkbox" id="intervenantPI" formControlName="intervenantPI" />
          <span class="slider"></span>
        </label>
        <span id="oui-option" class="ds-body-2--regular label-r align-bottom ms-2">Oui</span>
      </div>
    </div>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
    <lib-pxl-icon class="icon" icon="icon-action-close"></lib-pxl-icon> {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" (click)="confirmerAffectation()" cdkFocusInitial>
    <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon> {{ modalData.textGoButton }}
  </button>
</div>
