export const SECTEURS = [
  {
    codeSecteur: 101010,
    libelleSecteur: 'Services  équipement énergie',
    codeSousSecteur: 10101010,
    libelleSousSecteur: 'Forages petroliers et gaziers',
    descriptionSousSecteur:
      "Entrepreneurs de forage ou propriétaires d'appareils de forage qui offrent leurs services pour les puits de forage",
  },
  {
    codeSecteur: 101010,
    libelleSecteur: 'Services  équipement énergie',
    codeSousSecteur: 10101020,
    libelleSousSecteur: 'Oil & Gas Equipement & Services',
    descriptionSousSecteur:
      "Fabricants d'équipement, y compris les appareils et l'équipement de forage, et fournisseurs de produits et de services aux entreprises actives dans le forage, l'évaluation et la réalisation de puits gaziers et pétroliers.",
  },
  {
    codeSecteur: 101020,
    libelleSecteur: 'Pétrole, Gaz et Combustibles',
    codeSousSecteur: 10102010,
    libelleSousSecteur: 'Petrole et gaz intégré',
    descriptionSousSecteur:
      "Compagnies pétrolières intégrées spécialisées dans l'exploration et la production de pétrole et de gaz, ainsi que dans au moins une autre activité importante suivante : raffinage, commercialisation et transport, ou produits chimiques.",
  },
  {
    codeSecteur: 101020,
    libelleSecteur: 'Pétrole, Gaz et Combustibles',
    codeSousSecteur: 10102020,
    libelleSousSecteur: 'Exploration gazière petrolière  & Production',
    descriptionSousSecteur:
      "Sociétés spécialisées dans l'exploration et la production de pétrole et de gaz n'appartenant à aucune autre classification.",
  },
  {
    codeSecteur: 101020,
    libelleSecteur: 'Pétrole, Gaz et Combustibles',
    codeSousSecteur: 10102030,
    libelleSousSecteur: 'Pétrole & gaz raffinage & Marketing',
    descriptionSousSecteur:
      "Sociétés spécialisées dans le raffinage et la commercialisation de produits pétroliers, de gaz et/ou de produits raffinés et non-classées dans les sous-industries des Compagnies Gazières et Pétrolières Intégrées, ou des Producteurs Indépendants d'Électricité et du Commerce d'Énergie.",
  },
  {
    codeSecteur: 101020,
    libelleSecteur: 'Pétrole, Gaz et Combustibles',
    codeSousSecteur: 10102040,
    libelleSousSecteur: 'Pétrole & Gaz : Stockage & transports',
    descriptionSousSecteur:
      "Sociétés spécialisées dans le stockage et/ou le transport des produits pétroliers, de gaz et/ou de produits raffinés. Sont incluses les compagnies de gaz naturel diversifiées du secteur médian qui font face aux marchés concurrentiels, les conduites d'acheminement des produits pétroliers et des produits raffinés, les conduites d'acheminement des boues de charbon, et les entreprises de transport de pétrole et de gaz.",
  },
  {
    codeSecteur: 101020,
    libelleSecteur: 'Pétrole, Gaz et Combustibles',
    codeSousSecteur: 10102050,
    libelleSousSecteur: 'Charbon & Carburant',
    descriptionSousSecteur:
      "Sociétés qui font partie principalement de la production et l'exploitation de charbon, de produits connexes, et d'autres combustibles liés à la génération d'énergie. Ne sont pas incluses les sociétés qui produisent principalement des gaz et qui sont classées dans la sous-industrie des Gaz Industriels et les sociétés qui exploitent principalement le charbon métallurgique (cokéfaction) utilisé dans la production d'acier.",
  },
  {
    codeSecteur: 151010,
    libelleSecteur: 'Produits chimiques',
    codeSousSecteur: 15101010,
    libelleSousSecteur: 'Produits chimiques de base',
    descriptionSousSecteur:
      "Sociétés qui produisent principalement des produits chimiques industriels et des produits chimiques de base. Sont inclus, mais sans s'y limiter, les fabricants de plastiques, de fibres synthétiques, de films, de peintures et pigments à partir de produits de base, d'explosifs et de produits pétrochimiques. Ne sont pas incluses les sociétés classées dans les sous-industries des Gaz Industriels, des Produits Chimiques Spécialisés, des Engrais et Produits Chimiques Agricoles ou des Produits Chimiques Diversifiés.",
  },
  {
    codeSecteur: 151010,
    libelleSecteur: 'Produits chimiques',
    codeSousSecteur: 15101020,
    libelleSousSecteur: 'Produits chimiques diversifiés',
    descriptionSousSecteur:
      "Fabricants d'une gamme diversifiée de produits chimiques non-classés dans les sous-industries des Gaz Industriels, des Produits Chimiques de Base, des Produits Chimiques Spécialisés ou des Engrais et Produits Chimiques Agricoles.",
  },
  {
    codeSecteur: 151010,
    libelleSecteur: 'Produits chimiques',
    codeSousSecteur: 15101030,
    libelleSousSecteur: 'Engrais & produits chimiques agricoles',
    descriptionSousSecteur:
      "Producteurs d'engrais, de pesticides, de potasse ou d'autre produits chimiques liés à l'agriculture et n'appartenant à aucune autre classification.",
  },
  {
    codeSecteur: 151010,
    libelleSecteur: 'Produits chimiques',
    codeSousSecteur: 15101040,
    libelleSousSecteur: 'Gaz industriels',
    descriptionSousSecteur: 'Producteurs de gaz industriels.',
  },
  {
    codeSecteur: 151010,
    libelleSecteur: 'Produits chimiques',
    codeSousSecteur: 15101050,
    libelleSousSecteur: 'Produits chimiques à valeur ajoutée',
    descriptionSousSecteur:
      "Sociétés qui produisent principalement des produits chimiques à haute valeur ajoutée et utilisés dans la fabrication de nombreux produits, y compris, sans s'y limiter, les produits de chimie fine, additifs, polymères avancés, adhésifs, mastics, peintures spécialisées, pigments et enduits.",
  },
  {
    codeSecteur: 151020,
    libelleSecteur: 'Matériaux de Construction',
    codeSousSecteur: 15102010,
    libelleSousSecteur: 'Matériaux de construction (bruts)',
    descriptionSousSecteur:
      "Fabricants de matériaux de construction : sable, argile, gypse, chaux, agrégats, ciment, béton et briques. Les autres matériaux de construction finis ou semi-finis sont classés dans la sous-industrie des Produits pour l'Industrie du Bâtiment.",
  },
  {
    codeSecteur: 151030,
    libelleSecteur: 'Conteneurs & Emballage',
    codeSousSecteur: 15103010,
    libelleSousSecteur: 'Récipients en verre ou en métal',
    descriptionSousSecteur: 'Fabricants de conteneurs en métal, verre ou plastique. Sont inclus les fabricants de bouchons et de capsules.',
  },
  {
    codeSecteur: 151030,
    libelleSecteur: 'Conteneurs & Emballage',
    codeSousSecteur: 15103020,
    libelleSousSecteur: 'Emballages en papier',
    descriptionSousSecteur: "Fabricants de conteneurs et d'emballages en papier et carton.",
  },
  {
    codeSecteur: 151040,
    libelleSecteur: 'Exploitation Minière et Métaux',
    codeSousSecteur: 15104010,
    libelleSousSecteur: 'Aluminum',
    descriptionSousSecteur:
      "Producteurs d'aluminium et de produits connexes. Sont incluses les sociétés qui exploitent ou traitent la bauxite et qui recyclent l'aluminium pour fabriquer des produits finis ou semi-finis. Cette sous-industrie n'inclut pas les sociétés qui produisent principalement des matériaux de construction en aluminium, classées dans la sous-industrie des Produits pour l'Industrie du Bâtiment.",
  },
  {
    codeSecteur: 151040,
    libelleSecteur: 'Exploitation Minière et Métaux',
    codeSousSecteur: 15104020,
    libelleSousSecteur: 'Métaux diversifiés & minier',
    descriptionSousSecteur:
      "Entreprises engagées dans la production diversifiée ou l'extraction de métaux et de minéraux n'appartenant à aucune autre classification. Sont inclus, mais sans s'y limiter, l'exploitation minière de métaux non ferreux (sauf la bauxite), l'exploitation minière de sel et de borate, l'exploitation minière de roches phosphatées, et les opérations minières diversifiées. Ne sont pas inclus l'extraction du minerai de fer, classé dans la sous-industrie de l'Acier, de l'extraction de bauxite, classé dans la sous-industrie de l'Aluminium, et de l'extraction du charbon, classé soit dans les sous-industries de l'Acier soit du Charbon et Combustibles.",
  },
  {
    codeSecteur: 151040,
    libelleSecteur: 'Exploitation Minière et Métaux',
    codeSousSecteur: 15104025,
    libelleSousSecteur: 'Cuivre',
    descriptionSousSecteur: "Les sociétés principalement actives dans l'exploitation minière du minerai de cuivre.",
  },
  {
    codeSecteur: 151040,
    libelleSecteur: 'Exploitation Minière et Métaux',
    codeSousSecteur: 15104030,
    libelleSousSecteur: 'Or',
    descriptionSousSecteur:
      "Producteurs d'or ou de produits connexes. Sont incluses les sociétés qui extraient ou traitent l'or, et les organisations financières sud-africaines qui investissent principalement dans l'or sans l'extraire des mines.",
  },
  {
    codeSecteur: 151040,
    libelleSecteur: 'Exploitation Minière et Métaux',
    codeSousSecteur: 15104040,
    libelleSousSecteur: 'Métaux & minéraux précieux',
    descriptionSousSecteur:
      "Sociétés qui extraient les minerais et métaux précieux qui ne sont pas classés dans la sous-industrie de l'Or. Sont incluses les sociétés qui extraient principalement le platine.",
  },
  {
    codeSecteur: 151040,
    libelleSecteur: 'Exploitation Minière et Métaux',
    codeSousSecteur: 15104045,
    libelleSousSecteur: 'Argent',
    descriptionSousSecteur:
      "Sociétés qui extraient principalement l'argent. Ne sont pas incluses les sociétés classées dans les sous-industries de l'Or ou des Métaux et Minéraux Précieux.",
  },
  {
    codeSecteur: 151040,
    libelleSecteur: 'Exploitation Minière et Métaux',
    codeSousSecteur: 15104050,
    libelleSousSecteur: 'Acier',
    descriptionSousSecteur:
      "Producteurs de fer et d'acier et de produits connexes. Sont incluses les mines de charbon métallurgique (cokéfaction) utilisé dans la production d'acier.",
  },
  {
    codeSecteur: 151050,
    libelleSecteur: 'Papier & produits forestiers',
    codeSousSecteur: 15105010,
    libelleSousSecteur: 'Produits forestiers',
    descriptionSousSecteur:
      "Fabricants de bois et de produits à base de bois. Est inclus le  bois de construction pour l'Industrie du Bâtiment.",
  },
  {
    codeSecteur: 151050,
    libelleSecteur: 'Papier & produits forestiers',
    codeSousSecteur: 15105020,
    libelleSousSecteur: 'Produits  papier',
    descriptionSousSecteur:
      "Fabricants de toutes les qualités de papiers. Ne sont pas incluses les sociétés qui sont spécialisées dans la production d'emballage en papier et qui sont classées dans la sous-industrie des Emballages en Papier.",
  },
  {
    codeSecteur: 201010,
    libelleSecteur: 'Aérospatiale et Défense',
    codeSousSecteur: 20101010,
    libelleSousSecteur: 'Aérospatiale et Défense',
    descriptionSousSecteur:
      "Fabricants de produits, de pièces et de matériels civils ou militaires pour l'industrie aérospatiale et de défense. Sont inclus les fabricants d'électronique de défense et d'équipement de l'industrie spatiale.",
  },
  {
    codeSecteur: 201020,
    libelleSecteur: 'Produits de construction',
    codeSousSecteur: 20102010,
    libelleSousSecteur: 'Matériaux de construction (finis ou semi finis',
    descriptionSousSecteur:
      "Fabricants d'éléments de construction, et d'équipements et de produits pour l'aménagement d'intérieur. Cette sous-industrie n'inclut pas les fabricants de bois de construction et de contreplaqué, classés dans la sous-industrie des Produits de la Forêt, et les fabricants de ciment et d'autres matériaux, classés dans la sous-industrie des Matériaux de Construction.",
  },
  {
    codeSecteur: 201030,
    libelleSecteur: 'Ingénierie & Construction',
    codeSousSecteur: 20103010,
    libelleSousSecteur: 'Ingénierie et construction',
    descriptionSousSecteur:
      "Sociétés spécialisées principalement dans la construction non-résidentielle. Sont incluses les sociétés de génie civil et les entrepreneurs à grande échelle. Ne sont pas incluses les sociétés classées dans la sous-industrie de la Construction d'Habitations Résidentielles.",
  },
  {
    codeSecteur: 201040,
    libelleSecteur: 'Matériel Électrique',
    codeSousSecteur: 20104010,
    libelleSousSecteur: 'Equipement & Composants électriques',
    descriptionSousSecteur:
      'Sociétés qui fabriquent des câbles et fils électriques, des composants et des équipement électriques, et qui ne sont pas classées dans la sous-industrie des Équipement Électriques Lourds.',
  },
  {
    codeSecteur: 201040,
    libelleSecteur: 'Matériel Électrique',
    codeSousSecteur: 20104020,
    libelleSousSecteur: 'Matériel électrique lourd',
    descriptionSousSecteur:
      "Fabricants de groupes électrogènes et d'autres équipements électriques lourds, y compris les fabricants de turbines électriques et de machines électriques lourdes destinées aux systèmes électriques fixes et de grande taille. Ne sont pas inclus les fabricants de câbles et de fils, classés dans la sous-industrie des Composants et Équipements Électriques.",
  },
  {
    codeSecteur: 201050,
    libelleSecteur: 'Conglomérats Industriels',
    codeSousSecteur: 20105010,
    libelleSousSecteur: 'Conglomérats Industriels',
    descriptionSousSecteur:
      "Sociétés industrielles diversifiées, ayant des activités commerciales dans au moins trois secteurs, sans qu'aucun de ces secteurs ne contribue à la majorité des revenus. Les participations sont pour la plupart de type majoritaire, tandis que les parties prenantes conservent un intérêt opérationnel dans la conduite des filiales.",
  },
  {
    codeSecteur: 201060,
    libelleSecteur: 'Machines',
    codeSousSecteur: 20106010,
    libelleSousSecteur: 'Machines de construction & poids lourds',
    descriptionSousSecteur:
      'Fabricants de camions de chantier, de machines de laminage, de matériels de terrassement et de construction, et fabricants de produits connexes. Est incluse la construction navale civile.',
  },
  {
    codeSecteur: 201060,
    libelleSecteur: 'Machines',
    codeSousSecteur: 20106015,
    libelleSousSecteur: 'Machinerie agricole',
    descriptionSousSecteur:
      "Entreprises de production de machines agricoles et les pièces qui s'y rapportent. Sont inclus les machines utilisées pour la production des cultures et de bétail, les tracteurs agricoles, les planteuses et les fertiliseurs, les matériels d'épandage des engrais et des produits chimiques, les séchoirs et les ventilateurs à grain.",
  },
  {
    codeSecteur: 201060,
    libelleSecteur: 'Machines',
    codeSousSecteur: 20106020,
    libelleSousSecteur: 'Machinerie industrielle',
    descriptionSousSecteur:
      'Fabricants de machines et de composants industriels.  Sont incluses les sociétés qui fabriquent des presses, des machines-outils, des compresseurs, du matériel de contrôle de la pollution, des ascenseurs, des escaliers mécaniques, des isolateurs, des pompes, des roulements à rouleaux et autres constructions métalliques.',
  },
  {
    codeSecteur: 201070,
    libelleSecteur: 'Sociétés de négoce & distributeurs',
    codeSousSecteur: 20107010,
    libelleSousSecteur: 'Sociétés de négoce & distributeurs',
    descriptionSousSecteur: "Sociétés commerciales et autres distributeurs d'équipements et de produits industriels.",
  },
  {
    codeSecteur: 202010,
    libelleSecteur: 'fournisseurs de biens et de services à  destination des entreprises',
    codeSousSecteur: 20201010,
    libelleSousSecteur: 'Impression commerciale',
    descriptionSousSecteur:
      "Sociétés qui fournissent des services d'impression commerciale. Sont incluses les imprimeries qui travaillent principalement pour l'industrie des médias.",
  },
  {
    codeSecteur: 202010,
    libelleSecteur: 'fournisseurs de biens et de services à  destination des entreprises',
    codeSousSecteur: 20201050,
    libelleSousSecteur: 'Installation et maintenance environnementale',
    descriptionSousSecteur:
      "Sociétés qui fournissent des services liés à l'environnement et à l'entretien des locaux. Sont incluses les services de gestion des déchets, de gestion des installations et de contrôle de la pollution. Ne sont pas incluses les entreprises spécialisées dans les systèmes de traitement de l'eau à grande échelle, classés dans la sous-industrie des Compagnies des Eaux.",
  },
  {
    codeSecteur: 202010,
    libelleSecteur: 'fournisseurs de biens et de services à  destination des entreprises',
    codeSousSecteur: 20201060,
    libelleSousSecteur: 'Autres services aux entreprises et fournitures de bureau',
    descriptionSousSecteur:
      "Sociétés qui fournissent des services pour le bureau et fabricants de fournitures et d'équipements de bureau, n'appartenant à aucune autre classification.",
  },
  {
    codeSecteur: 202010,
    libelleSecteur: 'fournisseurs de biens et de services à  destination des entreprises',
    codeSousSecteur: 20201070,
    libelleSousSecteur: 'Services diversifiés aux entreprises',
    descriptionSousSecteur:
      "Sociétés qui fournissent principalement des services d'aide orientés sur la main-d'œuvre aux entreprises et aux gouvernements. Sont inclus les services de nettoyage commercial, les services de restauration et de traiteur, les services de réparation de matériel, les services de maintenance industrielle, les services de commissaire-priseur industriel, de stockage et d'entrepôt, de transaction, les services de location de vêtements de travail et autres services d'aide aux entreprises.",
  },
  {
    codeSecteur: 202010,
    libelleSecteur: 'fournisseurs de biens et de services à  destination des entreprises',
    codeSousSecteur: 20201080,
    libelleSousSecteur: 'Services de Sécurité et Alarme',
    descriptionSousSecteur:
      "Sociétés qui fournissent des services de sécurité et de protection aux entreprises et aux gouvernements. Sont incluses les sociétés qui fournissent des services tels que les établissements correctionnels, les services de sécurité et d'alarme, le transport de fonds par fourgon blindé et le gardiennage. Ne sont pas incluses les sociétés qui fournissent des logiciels de sécurité classés dans la sous-industrie des Logiciels Système et les services de sécurité à domicile classés dans la sous-industrie des Services consommateurs spécialisés. Ne sont également pas incluses les sociétés qui fabriquent du matériel dédié aux systèmes de sécurité classés dans la sous-industrie de l'Equipement et Instruments Electroniques.",
  },
  {
    codeSecteur: 202020,
    libelleSecteur: 'Services Professionnels',
    codeSousSecteur: 20202010,
    libelleSousSecteur: 'Ressources humaines & emploi Services',
    descriptionSousSecteur:
      "Sociétés qui fournissent des services d'aide aux entreprises liés à la gestion du capital humain. Sont inclus les agences pour l'emploi, la formation d'employés, les services d'aide à l'élaboration de bulletins de paie et de bénéfices, les services d'aide à la retraite, et les agences d'intérim.",
  },
  {
    codeSecteur: 202020,
    libelleSecteur: 'Services Professionnels',
    codeSousSecteur: 20202020,
    libelleSousSecteur: 'Recherche & Consulting',
    descriptionSousSecteur:
      "Sociétés qui fournissent principalement des services de recherche et conseil aux entreprises et aux gouvernements  n'appartenant à aucune autre classification. Sont incluses les sociétés impliquées dans des services de conseil-gestion, de design architectural, d'informations professionnelles ou de recherche scientifique, de marketing, de test et de certification. Ne sont pas incluses les sociétés qui fournissent des services de conseil informatique classés dans la sous-industrie des Conseils Liés aux Technologies de l'Information et Autres Services.",
  },
  {
    codeSecteur: 203010,
    libelleSecteur: 'Fret Aérien et Logistique',
    codeSousSecteur: 20301010,
    libelleSousSecteur: 'Fret Aérien et Logistique',
    descriptionSousSecteur:
      'Sociétés qui fournissent des services de transport maritime de biens ou de personnes. Ne sont pas inclus les bateaux de croisière classés dans la sous-industrie des Hôtels, des Lieux de Villégiature et des Croisiéristes.',
  },
  {
    codeSecteur: 203020,
    libelleSecteur: 'Compagnies Aériennes',
    codeSousSecteur: 20302010,
    libelleSousSecteur: 'Compagnies Aériennes',
    descriptionSousSecteur: 'Sociétés qui fournissent principalement des services de transport aérien de passagers.',
  },
  {
    codeSecteur: 203030,
    libelleSecteur: 'Marine',
    codeSousSecteur: 20303010,
    libelleSousSecteur: 'Marine',
    descriptionSousSecteur: 'Sociétés qui fournissent principalement des services de transport ferroviaire de biens et de personnes.',
  },
  {
    codeSecteur: 203040,
    libelleSecteur: 'Route & Rail',
    codeSousSecteur: 20304010,
    libelleSousSecteur: 'Chemins de fer',
    descriptionSousSecteur: 'Sociétés qui fournissent principalement des services de transport ferroviaire de biens et de personnes.',
  },
  {
    codeSecteur: 203040,
    libelleSecteur: 'Route & Rail',
    codeSousSecteur: 20304020,
    libelleSousSecteur: 'Transport routier - Logistique',
    descriptionSousSecteur:
      'Sociétés qui fournissent principalement des services de transport de biens et de personnes par voie terrestre. Sont incluses les agences de location de véhicules et les sociétés de taxis.',
  },
  {
    codeSecteur: 203050,
    libelleSecteur: 'Infrastructure de Transports',
    codeSousSecteur: 20305010,
    libelleSousSecteur: 'Services aéroportuaires',
    descriptionSousSecteur: "Gérants d'aéroports et de sociétés qui fournissent des services connexes.",
  },
  {
    codeSecteur: 203050,
    libelleSecteur: 'Infrastructure de Transports',
    codeSousSecteur: 20305020,
    libelleSousSecteur: 'Voirie & voies ferrées',
    descriptionSousSecteur: 'Propriétaires et gérants de routes, de tunnels et de voies ferrées.',
  },
  {
    codeSecteur: 203050,
    libelleSecteur: 'Infrastructure de Transports',
    codeSousSecteur: 20305030,
    libelleSousSecteur: 'Ports maritimes & Services',
    descriptionSousSecteur: 'Propriétaires et gérants de ports maritimes et de services connexes.',
  },
  {
    codeSecteur: 251010,
    libelleSecteur: 'Composants Automobiles',
    codeSousSecteur: 25101010,
    libelleSousSecteur: "Pièces d'auto & équipement",
    descriptionSousSecteur:
      "Fabricants de pièces et d'accessoires destinés aux automobiles et aux motocycles. Ne sont pas incluses les sociétés classées dans la sous-industrie des Pneus et du Caoutchouc.",
  },
  {
    codeSecteur: 251010,
    libelleSecteur: 'Composants Automobiles',
    codeSousSecteur: 25101020,
    libelleSousSecteur: 'Pneus & caoutchouc',
    descriptionSousSecteur: 'Fabricants de pneus et de caoutchouc.',
  },
  {
    codeSecteur: 251020,
    libelleSecteur: 'Automobiles',
    codeSousSecteur: 25102010,
    libelleSousSecteur: 'Constructeurs Automobiles',
    descriptionSousSecteur:
      'Sociétés qui produisent principalement des automobiles et des véhicules utilitaires légers. Ne sont pas incluses les sociétés qui produisent principalement des motocycles et des véhicules à trois roues classés dans la sous-industrie des Constructeurs de Motocycles et les Camions Poids Lourd classés dans la sous-industrie des Engins de Construction et des Camions Poids Lourd.',
  },
  {
    codeSecteur: 251020,
    libelleSecteur: 'Automobiles',
    codeSousSecteur: 25102020,
    libelleSousSecteur: 'Constructeurs de Motocycles',
    descriptionSousSecteur:
      'Sociétés qui produisent des motocycles, des scooters ou des véhicules à trois roues. Ne sont pas inclus les fabricants de bicyclettes classés dans la sous-industrie des Produits de Loisirs.',
  },
  {
    codeSecteur: 252010,
    libelleSecteur: 'Biens de Consommation Durables',
    codeSousSecteur: 25201010,
    libelleSousSecteur: 'Electronique grand public',
    descriptionSousSecteur:
      'Fabricants de produits électroniques grand public, y compris les téléviseurs, les systèmes audio, les consoles de jeux, les appareils photo numériques et de produits dérivés. Ne sont pas inclus les fabricants de PC classés dans la sous industrie de la Technologie du Matériel Informatique, du Stockage et des Périphériques, et les appareils électroménagers classés dans la sous-industrie des Appareils Électroménagers.',
  },
  {
    codeSecteur: 252010,
    libelleSecteur: 'Biens de Consommation Durables',
    codeSousSecteur: 25201020,
    libelleSousSecteur: 'Equipement de la maison',
    descriptionSousSecteur: 'Fabricants de petits ameublements ou meubles, y compris la tapisserie, la moquette et les revêtements muraux.',
  },
  {
    codeSecteur: 252010,
    libelleSecteur: 'Biens de Consommation Durables',
    codeSousSecteur: 25201030,
    libelleSousSecteur: 'Construction de maisons',
    descriptionSousSecteur:
      'Sociétés spécialisées dans les constructions résidentielles. Sont inclus les constructeurs de maisons préfabriquées ou de maisons préfabriquées mobiles.',
  },
  {
    codeSecteur: 252010,
    libelleSecteur: 'Biens de Consommation Durables',
    codeSousSecteur: 25201040,
    libelleSousSecteur: 'Appareils ménagers',
    descriptionSousSecteur:
      "Fabricants d'appareils électroménagers et de produits connexes. Sont inclus les fabricants d'outils électriques et manuels, et les outils de jardinage. Ne sont pas inclus les fabricants de téléviseurs et autres produits audio et vidéo, classés dans la sous-industrie de l'Électronique Grand Public et des Fabricants de PC, classés dans la sous-industrie de la Technologie du Matériel Informatique, du Stockage, et des Périphériques.",
  },
  {
    codeSecteur: 252010,
    libelleSecteur: 'Biens de Consommation Durables',
    codeSousSecteur: 25201050,
    libelleSousSecteur: 'Articles ménagers & spécialités',
    descriptionSousSecteur:
      "Fabricants d'articles ménagers durables, y compris des couverts, des ustensiles de cuisine, des verres, de l'argenterie, des ustensiles, de la vaisselle et des produits de consommation spécialisés n'appartenant à aucune autre classification.",
  },
  {
    codeSecteur: 252020,
    libelleSecteur: 'Produits de Loisirs',
    codeSousSecteur: 25202010,
    libelleSousSecteur: 'Produits de Loisirs',
    descriptionSousSecteur:
      "Fabricants d'équipements et de produits de loisir, y compris des équipements sportifs, des bicyclettes et des jouets.",
  },
  {
    codeSecteur: 252030,
    libelleSecteur: 'Textiles, vétêments & Luxe',
    codeSousSecteur: 25203010,
    libelleSousSecteur: 'Textiles, vêtements & luxe',
    descriptionSousSecteur:
      "Fabricants de vêtements, d'accessoires et de produits de luxe.  Sont incluses les sociétés qui produisent principalement des sacs à main, des portefeuilles, des valises, des bijoux et des montres design. Ne sont pas inclus les fabricants de chaussures, classés dans la sous-industrie des Chaussures.",
  },
  {
    codeSecteur: 252030,
    libelleSecteur: 'Textiles, vétêments & Luxe',
    codeSousSecteur: 25203020,
    libelleSousSecteur: 'Chaussures',
    descriptionSousSecteur: 'Fabricants de chaussures. Sont inclus les fabricants de chaussures en cuir et de chaussures de sport.',
  },
  {
    codeSecteur: 252030,
    libelleSecteur: 'Textiles, vétêments & Luxe',
    codeSousSecteur: 25203030,
    libelleSousSecteur: 'Textiles',
    descriptionSousSecteur:
      "Fabricants de textile et de produits connexes, non-classés dans les sous-industries de l'Habillement, Accessoires et Produits de luxe, des Chaussures ou de l'Ameublement.",
  },
  {
    codeSecteur: 253010,
    libelleSecteur: 'Hôtels, Restaurants & Loisirs',
    codeSousSecteur: 25301010,
    libelleSousSecteur: 'Casinos et jeux',
    descriptionSousSecteur:
      'Propriétaires et gérants de casinos et de salles de jeu. Sont inclus les entreprises fournissant des services de loterie et de paris.',
  },
  {
    codeSecteur: 253010,
    libelleSecteur: 'Hôtels, Restaurants & Loisirs',
    codeSousSecteur: 25301020,
    libelleSousSecteur: 'Hôtels, Resorts & croisières',
    descriptionSousSecteur:
      "Propriétaires et gérants d'hôtels, de lieux de villégiature et de bateaux de croisière. Sont inclus les agences de voyage, les voyagistes et les services connexes, n'appartenant à aucune autre classification. Ne sont pas inclus les hôtels-casinos classés dans la sous-industrie des Casinos et des Salles de Jeu.",
  },
  {
    codeSecteur: 253010,
    libelleSecteur: 'Hôtels, Restaurants & Loisirs',
    codeSousSecteur: 25301030,
    libelleSousSecteur: 'Equipements de loisirs',
    descriptionSousSecteur:
      'Propriétaires et gérants de centres de loisirs. Sont inclus les centres sportifs et les centres de remise en forme, les stades, les terrains de golf et les parcs de loisirs, non-classés dans la sous-industrie des Films et des Divertissements.',
  },
  {
    codeSecteur: 253010,
    libelleSecteur: 'Hôtels, Restaurants & Loisirs',
    codeSousSecteur: 25301040,
    libelleSousSecteur: 'Restaurants',
    descriptionSousSecteur:
      'Propriétaires et gérants de restaurants, bars, pubs, établissements de restauration rapide ou à emporter. Sont incluses des sociétés qui fournissent des services de restauration collective.',
  },
  {
    codeSecteur: 253020,
    libelleSecteur: 'Services Diversifiés aux Consommateurs',
    codeSousSecteur: 25302010,
    libelleSousSecteur: "Services d'enseignement",
    descriptionSousSecteur:
      "Sociétés qui fournissent des services éducatifs, en ligne ou par le biais de méthodes d'enseignement conventionnelles.  Sont inclus les universités privées, l'enseignement par correspondance, les organisateurs de séminaires éducatifs, les documents pédagogiques et la formation technique. Ne sont pas incluses les sociétés qui organisent des programmes de formation des employés, classés dans la sous-industrie des Services Liés aux Ressources Humaines et aux Emplois.",
  },
  {
    codeSecteur: 253020,
    libelleSecteur: 'Services Diversifiés aux Consommateurs',
    codeSousSecteur: 25302020,
    libelleSousSecteur: 'Services spécialisés aux consommateurs',
    descriptionSousSecteur:
      "Sociétés qui fournissent des services consommateurs n'appartenant à aucune autre classification. Sont inclus les services résidentiels, la sécurité domestique, les services juridiques, personnels, de rénovation et de décoration intérieure, les ventes aux enchères destinées aux consommateurs et les services relatifs aux mariages et aux enterrements.",
  },
  {
    codeSecteur: 254010,
    libelleSecteur: 'Media',
    codeSousSecteur: 25401010,
    libelleSousSecteur: 'Publicité',
    descriptionSousSecteur: 'Sociétés qui fournissent des services de publicité, de marketing ou de relations publiques.',
  },
  {
    codeSecteur: 254010,
    libelleSecteur: 'Media',
    codeSousSecteur: 25401020,
    libelleSousSecteur: 'Radiodiffusion',
    descriptionSousSecteur:
      'Propriétaires et gérants de systèmes de radiodiffusion et télédiffusion, y compris les prestataires de service de programmation. Sont incluses la radiodiffusion et la télédiffusion, les chaînes de radio et les stations de radio.',
  },
  {
    codeSecteur: 254010,
    libelleSecteur: 'Media',
    codeSousSecteur: 25401025,
    libelleSousSecteur: 'Cable & Satellite',
    descriptionSousSecteur:
      'Fournisseurs de services de télévision par câble ou par satellite. Sont inclus les réseaux câblés et les distributeurs de programme.',
  },
  {
    codeSecteur: 254010,
    libelleSecteur: 'Media',
    codeSousSecteur: 25401030,
    libelleSousSecteur: 'Films et Divertissements',
    descriptionSousSecteur:
      "Sociétés spécialisées dans la production et la vente de produits et de services de divertissement, y compris les sociétés spécialisées dans la production, la distribution et la diffusion de films et d'émissions de télévision, et dans la production et la distribution de musique, de pièces de théâtre et d'équipes sportives.",
  },
  {
    codeSecteur: 254010,
    libelleSecteur: 'Media',
    codeSousSecteur: 25401040,
    libelleSousSecteur: 'Edition',
    descriptionSousSecteur: 'Distributeurs',
  },
  {
    codeSecteur: 255010,
    libelleSecteur: 'Distributeurs',
    codeSousSecteur: 25501010,
    libelleSousSecteur: 'Distributeurs / Commerce de gros',
    descriptionSousSecteur:
      "Distributeurs et grossistes de marchandises diverses n'appartenant à aucune autre classification.  Sont inclus les concessionnaires automobiles.",
  },
  {
    codeSecteur: 255020,
    libelleSecteur: 'Vente par internet / par correspondance',
    codeSousSecteur: 25502020,
    libelleSousSecteur: 'Vente par internet / par correspondance',
    descriptionSousSecteur:
      'Les sociétés fournissant des services de vente au détail principalement sur Internet, par correspondance et télé-achat.',
  },
  {
    codeSecteur: 255030,
    libelleSecteur: 'Multiligne au détail',
    codeSousSecteur: 25503010,
    libelleSousSecteur: 'Grands magasins',
    descriptionSousSecteur: 'Propriétaires et gérants de grands magasins.',
  },
  {
    codeSecteur: 255030,
    libelleSecteur: 'Multiligne au détail',
    codeSousSecteur: 25503020,
    libelleSousSecteur: 'Magasins de marchandises diverses',
    descriptionSousSecteur:
      'Propriétaires et gérants de magasins offrant une grande variété de marchandises. Ne sont pas inclus les supermarchés et les hypermarchés de grande taille classés dans la sous-industrie des Supermarchés et des Hypermarchés.',
  },
  {
    codeSecteur: 255040,
    libelleSecteur: 'Commerce de détail spécialisé',
    codeSousSecteur: 25504010,
    libelleSousSecteur: 'Vente au détail de vêtements',
    descriptionSousSecteur: 'Détaillants spécialisés principalement dans les vêtements et les accessoires.',
  },
  {
    codeSecteur: 255040,
    libelleSecteur: 'Commerce de détail spécialisé',
    codeSousSecteur: 25504020,
    libelleSousSecteur: 'Informatique et électronique au détail',
    descriptionSousSecteur:
      'Propriétaires et gérants de magasins qui vendent au détail des appareils électroménagers, informatiques et vidéo, et des produits connexes.',
  },
  {
    codeSecteur: 255040,
    libelleSecteur: 'Commerce de détail spécialisé',
    codeSousSecteur: 25504030,
    libelleSousSecteur: "Amélioration de l'habitat",
    descriptionSousSecteur:
      'Propriétaires et gérants de magasins qui fournissent des produits pour la maison et le jardin. Sont inclus les magasins qui fournissent des matériaux de construction.',
  },
  {
    codeSecteur: 255040,
    libelleSecteur: 'Commerce de détail spécialisé',
    codeSousSecteur: 25504040,
    libelleSousSecteur: 'Magasins spécialisés',
    descriptionSousSecteur:
      "Propriétaires et gérants de magasins de détail spécialisés n'appartenant à aucune autre classification. Sont inclus les bijouteries, les magasins de jouets, les magasins de fournitures de bureau, les magasins de produits de santé et d'optique, les librairies et les magasins de produits de loisirs.",
  },
  {
    codeSecteur: 255040,
    libelleSecteur: 'Commerce de détail spécialisé',
    codeSousSecteur: 25504050,
    libelleSousSecteur: 'Commerce de détail automobile',
    descriptionSousSecteur:
      "Propriétaires et gérants de magasins spécialisés dans la vente au détail de produits pour l'automobile. Sont inclus les concessionnaires, les stations-service, et les détaillants d'accessoires pour voitures, motos et de pièces détachées, de verre pour les voitures, et d'équipements et de pièces détachées pour voitures.",
  },
  {
    codeSecteur: 255040,
    libelleSecteur: 'Commerce de détail spécialisé',
    codeSousSecteur: 25504060,
    libelleSousSecteur: 'Meubles de détail',
    descriptionSousSecteur:
      "Propriétaires et gérants de magasins spécialisés dans la vente au détail de mobilier et d'ameublement. Sont inclus le mobilier d'habitation, l'ameublement, les articles ménagers et la décoration intérieure. Ne sont pas inclus les magasins de produits pour la maison et le jardin, classés dans la sous-industrie de la Vente au Détail de Produits pour la Maison.",
  },
  {
    codeSecteur: 301010,
    libelleSecteur: 'Alimentation Consommation de base',
    codeSousSecteur: 30101010,
    libelleSousSecteur: 'Pharmacies et Parapharmacies',
    descriptionSousSecteur: 'Propriétaires et gérants de magasins de détail et pharmacies qui vendent principalement des médicaments.',
  },
  {
    codeSecteur: 301010,
    libelleSecteur: 'Alimentation Consommation de base',
    codeSousSecteur: 30101020,
    libelleSousSecteur: 'Produits alimentaires / Vente en gros',
    descriptionSousSecteur:
      "Sociétés spécialisées dans la distribution de produits alimentaires à d'autres sociétés et non directement au consommateur.",
  },
  {
    codeSecteur: 301010,
    libelleSecteur: 'Alimentation Consommation de base',
    codeSousSecteur: 30101030,
    libelleSousSecteur: 'Produits alimentaires / Vente au détail',
    descriptionSousSecteur: 'Propriétaires et gérants de magasins de vente au détail de produits principalement alimentaires.',
  },
  {
    codeSecteur: 301010,
    libelleSecteur: 'Alimentation Consommation de base',
    codeSousSecteur: 30101040,
    libelleSousSecteur: 'Hypermarchés & Supermarchés',
    descriptionSousSecteur:
      "Propriétaires et gérants de supermarchés et d'hypermarchés qui vendent des produits alimentaires et une large gamme de produits de première nécessité.  Ne sont pas inclus les Vendeurs au Détail de produits alimentaires et de médicaments, classés respectivement dans les sous-industries de la Vente au Détail de Produits Alimentaires et de la Vente au Détail de Médicaments.",
  },
  {
    codeSecteur: 302010,
    libelleSecteur: 'Boissons',
    codeSousSecteur: 30201010,
    libelleSousSecteur: 'Brasseurs',
    descriptionSousSecteur:
      'Producteurs de bière et de liqueur de malt. Sont incluses les brasseries, non-classées dans la sous-industrie des Restaurants.',
  },
  {
    codeSecteur: 302010,
    libelleSecteur: 'Boissons',
    codeSousSecteur: 30201020,
    libelleSousSecteur: 'Vins & Spiritueux',
    descriptionSousSecteur:
      'Distillateurs, négociants en vin et producteurs de boissons alcoolisées, non-classés dans la sous-industrie des Brasseurs.',
  },
  {
    codeSecteur: 302010,
    libelleSecteur: 'Boissons',
    codeSousSecteur: 30201030,
    libelleSousSecteur: 'Sodas',
    descriptionSousSecteur:
      'Producteurs de boissons non alcoolisées, y compris les eaux minérales. Ne sont pas inclus les producteurs de lait, classés dans la sous-industrie des Aliments Conditionnés.',
  },
  {
    codeSecteur: 302020,
    libelleSecteur: 'Produits Alimentaires',
    codeSousSecteur: 30202010,
    libelleSousSecteur: 'Produits Agricoles',
    descriptionSousSecteur:
      'Producteurs de produits agricoles. Sont inclus les cultivateurs, les propriétaires de plantations et les sociétés qui produisent et transforment les aliments sans les conditionner ni les commercialiser. Ne sont pas incluses les sociétés classées dans la sous-industrie des Produits de la Forêt ou celles qui conditionnent et commercialisent les produits alimentaires, classées dans la sous-industrie des Aliments Conditionnés.',
  },
  {
    codeSecteur: 302020,
    libelleSecteur: 'Produits Alimentaires',
    codeSousSecteur: 30202030,
    libelleSousSecteur: 'Aliments emballés & viandes',
    descriptionSousSecteur:
      "Producteurs d'aliments conditionnés y compris  les sociétés spécialisées dans les produits laitiers, les jus de fruits, la viande, la volaille et les aliments pour animaux.",
  },
  {
    codeSecteur: 302030,
    libelleSecteur: 'Tabac',
    codeSousSecteur: 30203010,
    libelleSousSecteur: 'Tabac',
    descriptionSousSecteur: 'Fabricants de cigarettes et de produits à base de tabac.',
  },
  {
    codeSecteur: 303010,
    libelleSecteur: 'Produits menagers',
    codeSousSecteur: 30301010,
    libelleSousSecteur: 'Produits menagers',
    descriptionSousSecteur:
      'Fabricants de produits domestiques non-durables, y compris des détergents, des savons, des couches, des mouchoirs en papier et du papier essuie-tout, non-classés dans la sous-industrie des Produits à Base de Papier.',
  },
  {
    codeSecteur: 303020,
    libelleSecteur: 'Produits Personnels',
    codeSousSecteur: 30302010,
    libelleSousSecteur: 'Produits personnels',
    descriptionSousSecteur: 'Fabricants de produits de soin et de beauté, y compris les parfums et les cosmétiques.',
  },
  {
    codeSecteur: 351010,
    libelleSecteur: 'Equipement de soins de santé & fournitures',
    codeSousSecteur: 35101010,
    libelleSousSecteur: 'Equipement de soins de santé',
    descriptionSousSecteur:
      "Fabricants d'appareils et de dispositifs médicaux. Sont inclus les instruments médicaux, les systèmes d'approvisionnement en médicaments, les appareils cardiovasculaires et orthopédiques, et les appareils de diagnostic.",
  },
  {
    codeSecteur: 351010,
    libelleSecteur: 'Equipement de soins de santé & fournitures',
    codeSousSecteur: 35101020,
    libelleSousSecteur: 'Fournitures de soins de santé',
    descriptionSousSecteur:
      "Fabricants de fournitures médicales et de produits médicaux n'appartenant à aucune autre classification. Sont inclus les produits de soins pour les yeux, les fournitures hospitalières,  les aiguilles de sécurité et les dispositifs à seringue.",
  },
  {
    codeSecteur: 351020,
    libelleSecteur: 'fournisseurs de soins de santé & Services',
    codeSousSecteur: 35102010,
    libelleSousSecteur: 'Distributeurs de soins de santé',
    descriptionSousSecteur: "Distributeurs et grossistes de produits de santé n'appartenant à aucune autre classification.",
  },
  {
    codeSecteur: 351020,
    libelleSecteur: 'fournisseurs de soins de santé & Services',
    codeSousSecteur: 35102015,
    libelleSousSecteur: 'Services de soins de santé',
    descriptionSousSecteur:
      "Fournisseurs de services de santé destinés aux clients n'appartenant à aucune autre classification. Sont inclus les centres de dialyse, les services de tests en laboratoire et les services de gestion des pharmacies. Sont également incluses les entreprises qui fournissent des services commerciaux d'assistance aux fournisseurs de soins de santé, tels que les services de secrétariat, les services de recouvrement, les services de recrutement et les services de marketing et de vente provenant de l'extérieur.",
  },
  {
    codeSecteur: 351020,
    libelleSecteur: 'fournisseurs de soins de santé & Services',
    codeSousSecteur: 35102020,
    libelleSousSecteur: 'Etablissements de soins de santé',
    descriptionSousSecteur:
      "Propriétaires et gérants d'infrastructures médicales, y compris les hôpitaux, les maisons de santé, les centres de rééducation et les cliniques vétérinaires.",
  },
  {
    codeSecteur: 351020,
    libelleSecteur: 'fournisseurs de soins de santé & Services',
    codeSousSecteur: 35102030,
    libelleSousSecteur: 'Organisme de gestion de santé',
    descriptionSousSecteur: "Propriétaires et gérants d'organismes de gestion de la santé et d'organismes similaires.",
  },
  {
    codeSecteur: 351030,
    libelleSecteur: 'Technologie des soins de santé',
    codeSousSecteur: 35103010,
    libelleSousSecteur: 'Technologie des soins de santé',
    descriptionSousSecteur:
      "Sociétés qui fournissent des services liés aux technologies de l'information principalement aux fournisseurs de soins de santé. Sont incluses les sociétés qui fournissent des logiciels d'application, systèmes et/ou de traitement des données, des outils d'Internet et des services-conseil en informatique aux médecins, hôpitaux ou aux entreprises qui opèrent principalement dans le secteur des soins de santé.",
  },
  {
    codeSecteur: 352010,
    libelleSecteur: 'Biotechnologie',
    codeSousSecteur: 35201010,
    libelleSousSecteur: 'Biotechnologie',
    descriptionSousSecteur:
      'Entreprises engagées principalement dans la recherche, le développement, la fabrication et/ou la commercialisation des produits basés sur l`analyse génétique et le génie génétique.',
  },
  {
    codeSecteur: 352020,
    libelleSecteur: 'Produits Pharmaceutiques',
    codeSousSecteur: 35202010,
    libelleSousSecteur: 'Produits Pharmaceutiques',
    descriptionSousSecteur:
      'Sociétés spécialisées dans la recherche, le développement ou la production de produits pharmaceutiques, y compris dans le domaine vétérinaire.',
  },
  {
    codeSecteur: 352030,
    libelleSecteur: 'Sciences de la vie outils & Services',
    codeSousSecteur: 35203010,
    libelleSousSecteur: 'Sciences de la vie outils & Services',
    descriptionSousSecteur:
      'Sociétés qui favorisent la continuité de la découverte, du développement et de la production de médicaments en fournissant des outils, des instruments, des consommables et des fournitures analytiques, des services pour les essais cliniques et des services de recherche sous contrat. Sont incluses les entreprises qui opèrent principalement pour les industries pharmaceutiques et biotechnologiques.',
  },
  {
    codeSecteur: 401010,
    libelleSecteur: 'Banques',
    codeSousSecteur: 40101010,
    libelleSousSecteur: 'Banques diversifiées',
    descriptionSousSecteur:
      "Grandes banques diversifiées géographiquement, avec une couverture nationale dont les revenus proviennent principalement des activités bancaires classiques, ont une activité significative dans la banque de détail et des petits et moyens prêts accordés aux entreprises, et fournissent une gamme diversifiée de services financiers. Ne sont pas incluses les entreprises classées dans les sous-industries des Banques régionales, et des Banques d'Epargne et Prêts Hypothécaires. Ne sont également pas incluses les banques d'investissement classées dans la sous-industrie des Services Bancaires d'Investissement et Courtage.",
  },
  {
    codeSecteur: 401010,
    libelleSecteur: 'Banques',
    codeSousSecteur: 40101015,
    libelleSousSecteur: 'Banques régionales',
    descriptionSousSecteur:
      "Banques commerciales dont les activités proviennent principalement des opérations bancaires classiques et ont une activité significative dans la banque de détail et des petits et moyens prêts accordés aux entreprises. Les banques régionales ont tendance à fonctionner dans des zones géographiques limitées. Ne sont pas incluses les entreprises classées dans les sous-industries des Banques aux Activités Diversifiée, des Banques d'Epargne et Prêts Hypothécaires. Ne sont également pas incluses les banques d'investissement classées dans la sous-industrie  des Services Bancaires d'Investissement et Courtage.",
  },
  {
    codeSecteur: 401020,
    libelleSecteur: 'Epargne et hypothèque',
    codeSousSecteur: 40102010,
    libelleSousSecteur: 'Epargne et hypothèque',
    descriptionSousSecteur:
      "Institutions financières qui fournissent des services de prêts hypothécaires et de prêts hypothécaires connexes. Il s'agit notamment des institutions financières dont les actifs sont principalement liés à l'hypothèque, l'épargne et les prêts, les établissements de crédit hypothécaire, la construction de sociétés et les entreprises fournissant des assurances aux banques spécialisées en prêts hypothécaires.",
  },
  {
    codeSecteur: 402010,
    libelleSecteur: 'Services Financiers Diversifiés',
    codeSousSecteur: 40201020,
    libelleSousSecteur: 'Autres Services financiers diversifiés',
    descriptionSousSecteur:
      "Sociétés qui fournissent une palette de services financiers et/ou ayant une certaine participation dans une vaste gamme d'activités bancaires, d'assurances et/ou de marchés de capitaux, mais sans secteur d'activité dominant. Ne sont pas incluses les entreprises classées dans les sous-industries des Banques Régionales et des Banques aux Activités Diversifiées.",
  },
  {
    codeSecteur: 402010,
    libelleSecteur: 'Services Financiers Diversifiés',
    codeSousSecteur: 40201030,
    libelleSousSecteur: 'Holdings Multisectorielles',
    descriptionSousSecteur:
      "Sociétés de holding ayant des activités dans au moins trois secteurs, sans qu'un de ces secteurs ne représente la majorité des bénéfices et/ou des ventes. Les participations sont pour la plupart de type non majoritaire. Sont incluses les sociétés financières diversifiées dont les participations sont de type majoritaire. Ne sont pas incluses d'autres sociétés diversifiées classées dans la sous-industrie des Conglomérats Industriels.",
  },
  {
    codeSecteur: 402010,
    libelleSecteur: 'Services Financiers Diversifiés',
    codeSousSecteur: 40201040,
    libelleSousSecteur: 'Finance spécialisée',
    descriptionSousSecteur:
      "Prestataires de services financiers spécialisés qui n'ont pas d'autre classification. Les sociétés appartenant à ce sous-secteur tirent la majorité de leurs revenus d'une branche d'activité spécialisée.",
  },
  {
    codeSecteur: 402020,
    libelleSecteur: 'Crédit à la Consommation',
    codeSousSecteur: 40202010,
    libelleSousSecteur: 'Crédit à la Consommation',
    descriptionSousSecteur:
      "Prestataires de services de crédit à la consommation, y compris crédit pour particuliers, cartes de crédit, financement de baux financiers, services financiers liés aux voyages et bureaux de prêteurs sur gages. Ne sont pas inclus les prêteurs hypothécaires classés dans la sous-industrie des Banques d'Epargne et Prêts Hypothécaires.",
  },
  {
    codeSecteur: 402030,
    libelleSecteur: 'Marchés de Capitaux',
    codeSousSecteur: 40203010,
    libelleSousSecteur: "Gestion d'actifs &  banques de dépôt",
    descriptionSousSecteur:
      "Institutions financières principalement impliquées dans la gestion des investissements et/ou les services de garde et de titres à frais fixes s'y rapportant. Sont inclus les sociétés de placement collectif, les fonds de placement fermé et les sociétés de placement de parts. Ne sont pas inclus les banques et les autres institutions financières principalement engagées dans les prêts commerciaux, les services bancaires d'investissement, le courtage et d'autres activités financières spécialisées.",
  },
  {
    codeSecteur: 402030,
    libelleSecteur: 'Marchés de Capitaux',
    codeSousSecteur: 40203020,
    libelleSousSecteur: "Banques d'investissement & courtage",
    descriptionSousSecteur:
      "Institutions financières principalement liées aux services bancaires d'investissement et les services de courtage, y compris les souscriptions emprunts et capitaux, les fusions et les acquisitions, les prêts de titres et les services consultatifs. Ne sont pas incluses les banques et les autres institutions financières principalement impliquées dans les prêts commerciaux, les gestions de biens et les activités financières spécialisées.",
  },
  {
    codeSecteur: 402030,
    libelleSecteur: 'Marchés de Capitaux',
    codeSousSecteur: 40203030,
    libelleSousSecteur: 'Marchés financiers diversifiés',
    descriptionSousSecteur:
      "Institutions financières principalement liées aux activités de marchés de capitaux diversifiés, y compris une présence importante dans au moins deux des domaines suivants: prêts aux grandes entreprises, services bancaires d'investissement, courtage et gestion des biens. Ne sont pas incluses les sociétés moins diversifiées classées dans les sous-industries des Banques  de Dépôt et de Gestion des Biens ou Services Bancaires d'Investissement et de Courtage.  Ne sont pas non plus incluses les entreprises classées dans les industries des Banques ou des Assurances, ou dans la sous-industrie du Crédit à la Consommation.",
  },
  {
    codeSecteur: 402030,
    libelleSecteur: 'Marchés de Capitaux',
    codeSousSecteur: 40203040,
    libelleSousSecteur: 'Echanges financiers et données',
    descriptionSousSecteur:
      "Les bourses financières pour les titres, produits de base, produits dérivés et autres instruments financiers, et les fournisseurs d'outils et produits d'aide à la prise de décision financière, y compris les agences de notation",
  },
  {
    codeSecteur: 402040,
    libelleSecteur: 'Sociétés Investissements hypothécaires et fiducies immobilières',
    codeSousSecteur: 40204010,
    libelleSousSecteur: "Sociétés d'invrestissements",
    descriptionSousSecteur:
      'Entreprises ou sociétés fiduciaire qui assistent, développent, acquièrent et/ou sécurisent les crédits hypothécaires résidentiels et/ou commerciaux. Sont incluses les sociétés fiduciaires qui investissent dans les titres adossés à des créances hypothécaires et autres avoirs hypothécaires.',
  },
  {
    codeSecteur: 403010,
    libelleSecteur: 'Assurance',
    codeSousSecteur: 40301010,
    libelleSousSecteur: "Courtiers d'assurance",
    descriptionSousSecteur: "Sociétés de Courtage d'Assurance et de Réassurance.",
  },
  {
    codeSecteur: 403010,
    libelleSecteur: 'Assurance',
    codeSousSecteur: 40301020,
    libelleSousSecteur: 'Assurance-vie & Assurance-santé',
    descriptionSousSecteur:
      "Sociétés qui fournissent principalement des assurances vie, des assurances d'invalidité, de responsabilité civile ou des assurances maladie complémentaires. Ne sont pas inclus les organismes de gestion de soin intégré, classés dans la sous-industrie de la Gestion Intégrée des Soins de Santé.",
  },
  {
    codeSecteur: 403010,
    libelleSecteur: 'Assurance',
    codeSousSecteur: 40301030,
    libelleSousSecteur: 'Assurance multiligne',
    descriptionSousSecteur:
      "Compagnies d'assurance qui fournissent divers services d'assurance vie et maladie, assurances de biens et assurances de risques divers.",
  },
  {
    codeSecteur: 403010,
    libelleSecteur: 'Assurance',
    codeSousSecteur: 40301040,
    libelleSousSecteur: 'Assurance dommages',
    descriptionSousSecteur: "Sociétés qui fournissent principalement des services d'assurances de biens et d'assurances de risques divers.",
  },
  {
    codeSecteur: 403010,
    libelleSecteur: 'Assurance',
    codeSousSecteur: 40301050,
    libelleSousSecteur: 'Réassurance',
    descriptionSousSecteur: 'Sociétés qui fournissent principalement des services de réassurance.',
  },
  {
    codeSecteur: 451010,
    libelleSecteur: 'Logiciel Internet & Services',
    codeSousSecteur: 45101010,
    libelleSousSecteur: 'Logiciel Internet & Services',
    descriptionSousSecteur:
      'Sociétés qui développent et commercialisent des logiciels internet et / ou fournissent des services Internet, y compris les bases de données en ligne et des services interactifs, ainsi que des entreprises qui tirent la majorité de leurs revenus de la publicité en ligne. Ne sont pas incluses les entreprises classées dans la sous-industrie de la Vente au Détail sur Internet.',
  },
  {
    codeSecteur: 451020,
    libelleSecteur: 'Services informatiques',
    codeSousSecteur: 45102010,
    libelleSousSecteur: 'Service Informatique Consulting & autres Services',
    descriptionSousSecteur:
      "Fournisseurs de technologies de l'information et de services d'intégration de systèmes, non-classés dans les sous-industries du Traitement de Données et Services Externalisés, ou des Logiciels et Services Internet. Sont inclus les services de conseil liés aux technologies de l'information et de gestion de l'information.",
  },
  {
    codeSecteur: 451020,
    libelleSecteur: 'Services informatiques',
    codeSousSecteur: 45102020,
    libelleSousSecteur: 'Traitement des données & Services externalisés',
    descriptionSousSecteur:
      "Fournisseurs de services de traitement des données électroniques commerciales et/ou de services externalisés des processus d'entreprises. Sont incluses les entreprises qui offrent des services pour automatiser des services administratifs.",
  },
  {
    codeSecteur: 451030,
    libelleSecteur: 'Software',
    codeSousSecteur: 45103010,
    libelleSousSecteur: "Logiciel d'application",
    descriptionSousSecteur:
      "Sociétés spécialisées dans le développement et la production de logiciels destinés à des applications spécialisées pour les professionnels et le grand public, y compris les logiciels d'entreprise ou techniques. Ne sont pas incluses les entreprises classées dans la sous-industrie des Logiciels de Divertissement à Domicile. Ne sont pas incluses non plus les entreprises qui produisent des systèmes ou des logiciels de gestion de base de données classées dans la sous-industrie des Logiciels Système.",
  },
  {
    codeSecteur: 451030,
    libelleSecteur: 'Software',
    codeSousSecteur: 45103020,
    libelleSousSecteur: "Logiciels système d'Information",
    descriptionSousSecteur:
      'Sociétés spécialisées dans le développement et la production de logiciels de gestion de bases de données et de logiciels système.',
  },
  {
    codeSecteur: 451030,
    libelleSecteur: 'Software',
    codeSousSecteur: 45103030,
    libelleSousSecteur: 'Logiciels de divertissement',
    descriptionSousSecteur:
      'Fabricants de logiciels de divertissement à domicile et de logiciels éducatifs, principalement utilisés à la maison.',
  },
  {
    codeSecteur: 452010,
    libelleSecteur: 'Matériel de communication',
    codeSousSecteur: 45201020,
    libelleSousSecteur: 'Matériel de communication',
    descriptionSousSecteur:
      "Fabricants d'équipement et de produits de communication (Y  compris le réseau local, le réseau étendu, les routeurs, les téléphones, les standards et les centrales téléphoniques). Ne sont pas inclus les fabricants de téléphones mobiles classés dans la sous-industrie de la Technologie du Matériel Informatique, du Stockage et des Périphériques.",
  },
  {
    codeSecteur: 452020,
    libelleSecteur: 'Matériel, stockage & périphériques',
    codeSousSecteur: 45202030,
    libelleSousSecteur: 'Matériel, stockage & périphériques',
    descriptionSousSecteur:
      "Fabricants de téléphones cellulaires, d'ordinateurs personnels, de serveurs, de composants électroniques pour ordinateurs et périphériques. Sont inclus des composants de stockage de données, des cartes mères, des cartes son et vidéo, des moniteurs, des claviers, des imprimantes et d'autres périphériques. Ne sont pas inclus les semi-conducteurs classés dans la sous-industrie des Semi-conducteurs.",
  },
  {
    codeSecteur: 452030,
    libelleSecteur: 'Equipements électroniques, Instruments & composants',
    codeSousSecteur: 45203010,
    libelleSousSecteur: 'Equipement électronique & Instruments',
    descriptionSousSecteur:
      "Fabricants d'équipement et d'instruments électroniques, y compris les instruments analytiques, de test électronique et de mesure, les scanners/lecteurs de code-barres, les lasers, les écrans d'affichage, les équipements du point de vente et les systèmes de sécurité.",
  },
  {
    codeSecteur: 452030,
    libelleSecteur: 'Equipements électroniques, Instruments & composants',
    codeSousSecteur: 45203015,
    libelleSousSecteur: 'Composants électroniques',
    descriptionSousSecteur:
      "Fabricants de composants électroniques. Sont inclus les composants électroniques, les dispositifs de connexion, les tubes électroniques, les condensateurs et résistances électroniques, les bobines électroniques, les cartes de circuit imprimé, les transformateurs et autres bobines d'induction, la technologie/les composants de traitement des signaux.",
  },
  {
    codeSecteur: 452030,
    libelleSecteur: 'Equipements électroniques, Instruments & composants',
    codeSousSecteur: 45203020,
    libelleSousSecteur: 'Services de fabrication électronique',
    descriptionSousSecteur:
      "Fabricants d'équipements électroniques destinés principalement aux marchés des Fabricants d'Équipement d'Origine.",
  },
  {
    codeSecteur: 452030,
    libelleSecteur: 'Equipements électroniques, Instruments & composants',
    codeSousSecteur: 45203030,
    libelleSousSecteur: 'Distributeurs de technologie',
    descriptionSousSecteur:
      "Distributeurs de matériel et d'équipement technologique. Sont inclus les distributeurs d'équipement de communication, d'ordinateurs et de périphériques, de semi-conducteurs, de composants et d'équipement électroniques.",
  },
  {
    codeSecteur: 453010,
    libelleSecteur: 'Equipment semi conducteurs',
    codeSousSecteur: 45301010,
    libelleSousSecteur: 'Equipment semi conducteurs',
    descriptionSousSecteur:
      "Fabricants d'équipement de semi-conducteurs. Sont inclus les fabricants de matières premières et de l'équipement utilisé dans l'industrie de l'énergie solaire.",
  },
  {
    codeSecteur: 453010,
    libelleSecteur: 'Equipment semi conducteurs',
    codeSousSecteur: 45301020,
    libelleSousSecteur: 'Semiconducteurs',
    descriptionSousSecteur:
      'Fabricants de semi-conducteurs et de produits connexes, y compris les fabricants de modules solaires et de photopiles.',
  },
  {
    codeSecteur: 501010,
    libelleSecteur: 'Services de télécommunications',
    codeSousSecteur: 50101010,
    libelleSousSecteur: 'Autres transporteurs',
    descriptionSousSecteur:
      'Prestataires de services de communication et de transmission de données à haut débit grâce principalement à un réseau de câbles en fibres optiques/à bande passante élevée.',
  },
  {
    codeSecteur: 501010,
    libelleSecteur: 'Services de télécommunications',
    codeSousSecteur: 50101020,
    libelleSousSecteur: 'Services de télécommunications intégrés',
    descriptionSousSecteur:
      "Gérants de réseaux de télécommunication principalement fixes et entreprises fournissant des services de télécommunication fixe et mobile n'appartenant à aucune autre classification.",
  },
  {
    codeSecteur: 501020,
    libelleSecteur: 'Services de télécommunications sans fil',
    codeSousSecteur: 50102010,
    libelleSousSecteur: 'Services de Télécommunication Sans Fil',
    descriptionSousSecteur:
      'Sociétés qui fournissent principalement des services de télécommunication sans fil ou de téléphonie mobile, y compris les services de radiomessagerie.',
  },
  {
    codeSecteur: 551010,
    libelleSecteur: "Services publics d'électricité",
    codeSousSecteur: 55101010,
    libelleSousSecteur: "Services publics d'électricité",
    descriptionSousSecteur:
      "Sociétés qui produisent ou distribuent l'électricité. Sont incluses à la fois les centrales nucléaires et non nucléaires.",
  },
  {
    codeSecteur: 551020,
    libelleSecteur: 'Services de gaz',
    codeSousSecteur: 55102010,
    libelleSousSecteur: 'Services de gaz',
    descriptionSousSecteur:
      "Sociétés spécialisées dans la distribution et la transmission de gaz naturel et de gaz fabriqué. Ne sont pas incluses les sociétés principalement spécialisées dans l'exploration et la production de gaz, classées dans la sous-industrie de l'Exploration et Production de Pétrole et de Gaz. Ne sont pas également incluses les sociétés de gaz naturel diversifiées du secteur médian, classées dans la sous-industrie du Stockage et Transport de Pétrole et de Gaz.",
  },
  {
    codeSecteur: 551030,
    libelleSecteur: 'Compagnies aux activités diversifiées',
    codeSousSecteur: 55103010,
    libelleSousSecteur: 'Compagnies aux activités diversifiées',
    descriptionSousSecteur:
      "Compagnies aux activités très diversifiées outre les activités fondamentales des Compagnies d'Électricité, de Gaz et/ou des Eaux.",
  },
  {
    codeSecteur: 551040,
    libelleSecteur: "Services publics d'eau",
    codeSousSecteur: 55104010,
    libelleSousSecteur: "Services publics d'eau",
    descriptionSousSecteur:
      "Sociétés qui achètent et redistribuent l'eau au consommateur final. Sont incluses les compagnies de traitement des eaux à grande échelle.",
  },
  {
    codeSecteur: 551050,
    libelleSecteur: "Producteurs d'électricité renouvables  indépendants",
    codeSousSecteur: 55105010,
    libelleSousSecteur: "Producteurs d'électricité renouvables  indépendants",
    descriptionSousSecteur:
      "Entreprises qui fonctionnent comme des producteurs indépendants d'électricité (PIE), des spécialistes de la fourniture et de la commercialisation du gaz et de l'électricité et /ou des fournisseurs spécialisés dans la performance énergétique intégrée. Ne sont pas inclus les producteurs d'électricité utilisant des sources d'énergie renouvelables, telles que l'énergie solaire, l'hydroélectricité et l'énergie éolienne. Ne sont également pas incluses les entreprises de transport d'électricité et les entreprises de distribution de services publics classées dans la sous-industrie de l'Électricité.",
  },
  {
    codeSecteur: 551050,
    libelleSecteur: "Producteurs d'électricité renouvables  indépendants",
    codeSousSecteur: 55105020,
    libelleSousSecteur: 'Electricité Renouvelable',
    descriptionSousSecteur:
      "Les entreprises qui se livrent à la production et la distribution d'électricité à partir de sources renouvelables, y compris, mais sans s'y limiter, les entreprises qui produisent de l'électricité à partir de la biomasse, de l'énergie géothermique, de l'énergie solaire, de l'hydroélectricité et de l'énergie éolienne. N'est pas incluse  la fabrication d'équipements utilisés pour produire de l'électricité à partir des sources renouvelables, tels que les fabricants de systèmes d'énergie solaire, les installateurs de cellules photovoltaïques, et les entreprises impliquées dans la fourniture de la technologie, de composants et services principalement à ce marché.",
  },
  {
    codeSecteur: 601010,
    libelleSecteur: 'Fonds de Placement Immobilier (FIP)',
    codeSousSecteur: 60101010,
    libelleSousSecteur: 'FIP Diversifiées',
    descriptionSousSecteur:
      'Entreprise ou société fiduciaire dont les activités sont très diversifiées concernant deux types de biens ou plus.',
  },
  {
    codeSecteur: 601010,
    libelleSecteur: 'Fonds de Placement Immobilier (FIP)',
    codeSousSecteur: 60101020,
    libelleSousSecteur: 'FIP Industrielles',
    descriptionSousSecteur:
      "Entreprises ou sociétés fiduciaire spécialisées dans l'acquisition, la promotion, la propriété, le crédit-bail, la gestion et l'exploitation des biens industriels. Sont incluses les sociétés qui gèrent des entrepôts industriels et des biens de distribution.",
  },
  {
    codeSecteur: 601010,
    libelleSecteur: 'Fonds de Placement Immobilier (FIP)',
    codeSousSecteur: 60101030,
    libelleSousSecteur: 'FIP Hotels & Resorts',
    descriptionSousSecteur:
      "Entreprises ou les sociétés fiduciaires spécialisées dans l'acquisition, le développement, la propriété, la location, la gestion et l'exploitation des hôtels et des lieux de villégiature.",
  },
  {
    codeSecteur: 601010,
    libelleSecteur: 'Fonds de Placement Immobilier (FIP)',
    codeSousSecteur: 60101040,
    libelleSousSecteur: 'FIP Bureaux',
    descriptionSousSecteur:
      "Entreprises ou sociétés fiduciaires spécialisées dans l'acquisition, la promotion, la propriété, le crédit-bail, la gestion et l'exploitation des biens bureautiques.",
  },
  {
    codeSecteur: 601010,
    libelleSecteur: 'Fonds de Placement Immobilier (FIP)',
    codeSousSecteur: 60101050,
    libelleSousSecteur: 'FIP Santé',
    descriptionSousSecteur:
      "Entreprises ou les sociétés fiduciaires spécialisées dans l'acquisition, le développement, la propriété, la location, la gestion et l'exploitation de propriétés au service de l'industrie des soins de santé, y compris les hôpitaux, les maisons de repos, et des résidences médicalisées.",
  },
  {
    codeSecteur: 601010,
    libelleSecteur: 'Fonds de Placement Immobilier (FIP)',
    codeSousSecteur: 60101060,
    libelleSousSecteur: 'FIP Résidentielles',
    descriptionSousSecteur:
      "Entreprises ou sociétés fiduciaires spécialisées dans l'acquisition, la promotion, la propriété, le crédit-bail, la gestion et l'exploitation des biens résidentiels, dont les logements multifamiliaux, les appartements, les maisons préfabriquées et les logements pour étudiants.",
  },
  {
    codeSecteur: 601010,
    libelleSecteur: 'Fonds de Placement Immobilier (FIP)',
    codeSousSecteur: 60101070,
    libelleSousSecteur: 'FIP Ventes au détail',
    descriptionSousSecteur:
      "Entreprises ou sociétés fiduciaires spécialisées dans l'acquisition, la promotion, la propriété, le crédit-bail, la gestion et l'exploitation de centres commerciaux, de points de vente, de centres commerciaux de quartier et communautaires.",
  },
  {
    codeSecteur: 601010,
    libelleSecteur: 'Fonds de Placement Immobilier (FIP)',
    codeSousSecteur: 60101080,
    libelleSousSecteur: 'FIP Spécialisées',
    descriptionSousSecteur:
      "Entreprises ou sociétés fiduciaires spécialisées dans l'acquisition, le développement, la propriété, la location, la gestion et l'exploitation de propriétés n'appartenant à aucune autre classification. Sont incluses les sociétés fiduciaires qui exploitent et investissent dans les propriétés de stockage. Elles comprennent également les SIIC qui ne génèrent pas la majorité de leurs revenus et de leur bénéfice d'exploitation à partir des activités de location immobilière et de crédit-bail immobilier.",
  },
  {
    codeSecteur: 601020,
    libelleSecteur: 'Gestion et développement immobilier',
    codeSousSecteur: 60102010,
    libelleSousSecteur: 'Activités immobilières diversifiées',
    descriptionSousSecteur:
      "Sociétés engagées dans un large éventail d'activités immobilières telles que la promotion et la vente immobilière, la gestion de biens ou les services immobiliers, sans branche d'activité dominante.",
  },
  {
    codeSecteur: 601020,
    libelleSecteur: 'Gestion et développement immobilier',
    codeSousSecteur: 60102020,
    libelleSousSecteur: "Sociétés d'exploitation Immobiliéres",
    descriptionSousSecteur: "Sociétés engagées dans l'exploitation de propriétés immobilières pour le crédit-bail et la gestion.",
  },
  {
    codeSecteur: 601020,
    libelleSecteur: 'Gestion et développement immobilier',
    codeSousSecteur: 60102030,
    libelleSousSecteur: 'Développement immobilier',
    descriptionSousSecteur:
      "Sociétés qui promeuvent l'immobilier et vendent des propriétés après en avoir fait la promotion. Ne sont pas incluses les sociétés classées dans la sous-industrie de la Construction d'Habitations Résidentielles.",
  },
  {
    codeSecteur: 601020,
    libelleSecteur: 'Gestion et développement immobilier',
    codeSousSecteur: 60102040,
    libelleSousSecteur: 'Services immobiliers',
    descriptionSousSecteur: 'Fournisseurs de services immobiliers tels que les agents immobiliers, les courtiers et les évaluateurs.',
  },
  {
    codeSecteur: 701010,
    libelleSecteur: 'Etat / Collectivités publiques',
    codeSousSecteur: 70101010,
    libelleSousSecteur: 'Etat / Collectivités publiques',
    descriptionSousSecteur: 'Etat / Collectivités publiques',
  },
];
