import { Injectable } from '@angular/core';
import { OrganismeModel } from '@shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganismeHelperService {
  public organismeData = new BehaviorSubject<any>([]);
  public departementData = new BehaviorSubject<any>([]);

  setOrganismeData(data: OrganismeModel) {
    this.organismeData.next(data);
  }

  getOrganismeData() {
    return this.organismeData.asObservable();
  }

  setDepartementData(data: any) {
    this.departementData.next(data);
  }

  getDepartementData() {
    return this.departementData.asObservable();
  }
}
