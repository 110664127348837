import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import {
  Aap,
  Budget,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumQualifRue,
  EnumRoleContact,
  EnumRoleStructure,
  EnumTypeVoie,
  PartenaireCsv,
  PiaCsv,
  Projet,
  ProjetEtape,
  Structure,
} from '@shared-ui';

export class ExportCsvFunction {
  readonly RESPONSABLE_PROJET = (EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()];
  readonly REPRESENTANT_LEGAL = (EnumRoleContact as any)[EnumRoleContact.REPRESENTANT_LEGAL.toString()];
  datePipe = new DatePipe('fr-FR');

  /*
   * Extrait et télécharge tous les projets format excel
   * */
  downloadFile(csvData: string, filename: string): void {
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  extractFileNameFromHeaders(headers: HttpHeaders): string {
    const contentDisposition = headers.get('Content-Disposition');
    const filename = contentDisposition?.split(';')[1].split('=')[1].replace(/"/g, '');
    return decodeURIComponent(filename || 'filename');
  }

  // Download partenaire CSV
  downloadPartenairesCsv(projetsList: Projet[], aaps: Aap[]): void {
    const partenairesCsv: PartenaireCsv[] = [];
    for (const projet of projetsList) {
      const aap = aaps?.filter(aapItem => projet.aap.id === aapItem.id)[0];
      if (!projet.structures) {
        continue;
      }
      const structures = projet.structures.filter(structure => !structure.closed);
      for (const structure of structures) {
        partenairesCsv.push(this.createPartenaireCsv(aap, projet, structure));
      }
    }

    const headerList = [
      'nomProgramme',
      'nomAap',
      'identifiantPXL',
      'acronyme',
      'titreProjet',
      'roleStructure',
      'raisonSocialSiege',
      'typeStruture',
      'siretSiege',
      'sirenSiege',
      'adresseSiege',
      'departementSiege',
      'regionAdministrative',
      'paysSiege',
      'genreRl',
      'nomRl',
      'prenomRl',
      'emailRl',
      'telRl',
      'siretRD',
      'raisonSocialRD',
      'adresseRD',
      'departementRD',
      'regionAdministrativeRD',
      'paysRD',
      'demandeFinancement',
      'budget',
      'montantAide',
      'budgetTotal',
      'statut',
      'etape',
      'dateValidationEtape',
      'dateReleve',
    ];

    const aapPersoPhysicalSelected = aaps.filter(aap => aap.personnePhysique).length > 0;
    if (aapPersoPhysicalSelected) {
      const newColumn = ['adressePerso', 'departementPerso', 'paysPerso'];
      headerList.splice(14, 0, ...newColumn);
    }

    const csvData = this.ConvertPartenaireToPartenaireCSV(partenairesCsv, headerList, aapPersoPhysicalSelected);

    this.downloadFile(csvData, 'Partenaire.csv');
  }

  createPartenaireCsv(aap: Aap, projet: Projet, structure: Structure): PartenaireCsv {
    const partenaireCsv: PartenaireCsv = new PartenaireCsv();
    partenaireCsv.nomProgramme = this.getInfoItem(aap?.programme.nom, null);
    partenaireCsv.nomAap = this.getInfoItem(aap?.nom, null);
    partenaireCsv.acronyme = this.getInfoItem(projet.acronyme, null);
    partenaireCsv.titreProjet = this.getInfoItem(projet.nom, null);
    partenaireCsv.identifiantPXL = this.getInfoItem(projet.identifiantPXL, null);
    partenaireCsv.roleStructure = this.getInfoItem(structure.role?.toString(), null);
    partenaireCsv.raisonSocialSiege = this.getInfoItem(structure.raisonSocial, null);
    partenaireCsv.typeStruture = this.getInfoItem(structure.typeStructure, null);

    if (structure.siret) {
      partenaireCsv.siretSiege = "'" + structure.siret;
      partenaireCsv.sirenSiege = "'" + structure.siret.slice(0, 9);
    } else {
      partenaireCsv.siretSiege = this.getInfoItem(structure.raisonSiret, null);
      partenaireCsv.sirenSiege = this.getInfoItem(structure.raisonSiret, null);
    }

    if (structure.adresse?.codePays === 'FR') {
      partenaireCsv.adresseSiege =
        '"' +
        this.getInfoItem(structure.adresse?.numero, ' ') +
        ' ' +
        this.getInfoItem((EnumQualifRue as any)[structure.adresse?.complement]?.toLowerCase(), '') +
        ' - ' +
        this.getInfoItem((EnumTypeVoie as any)[structure.adresse?.typeVoie]?.toLowerCase(), ' ') +
        ' ' +
        this.getInfoItem(structure.adresse?.voie, ' ') +
        ' - ' +
        this.getInfoItem(structure.adresse?.cp, ' ') +
        ' - ' +
        this.getInfoItem(structure.adresse?.ville, ' ') +
        ' - ' +
        this.getInfoItem(structure.adresse?.pays, ' ') +
        '"';
    } else {
      partenaireCsv.adresseSiege =
        '"' +
        this.getInfoItem(structure.adresse?.voie, ' ') +
        ' - ' +
        this.getInfoItem(structure.adresse?.cp, ' ') +
        ' - ' +
        this.getInfoItem(structure.adresse?.ville, ' ') +
        ' - ' +
        this.getInfoItem(structure.adresse?.pays, ' ') +
        '"';
    }
    partenaireCsv.departementSiege = this.getInfoItem(structure.adresse?.cp?.slice(0, 2), null);
    partenaireCsv.regionAdministrative = this.getInfoItem(structure.adresse?.regionAdministrative, null);
    partenaireCsv.paysSiege = this.getInfoItem(structure.adresse?.pays, null);

    // TODO mapper avec les données personne physique
    if (aap.personnePhysique) {
      partenaireCsv.adressePerso = null;
      partenaireCsv.departementPerso = null;
      partenaireCsv.regionAdministrativePerso = null;
      partenaireCsv.paysPerso = null;

      if (structure.typeStructure === 'PERSONNE_PHYSIQUE') {
        const rp = structure.contacts.filter(contact => contact.roles.includes(this.RESPONSABLE_PROJET))[0];

        if (rp?.adresse?.codePays === 'FR') {
          partenaireCsv.adressePerso =
            '"' +
            this.getInfoItem(rp.adresse?.numero, ' ') +
            ' ' +
            this.getInfoItem((EnumQualifRue as any)[rp.adresse?.complement]?.toLowerCase(), '') +
            ' - ' +
            this.getInfoItem((EnumTypeVoie as any)[rp.adresse?.typeVoie]?.toLowerCase(), ' ') +
            ' ' +
            this.getInfoItem(rp.adresse?.voie, ' ') +
            ' - ' +
            this.getInfoItem(rp.adresse?.cp, ' ') +
            ' - ' +
            this.getInfoItem(rp.adresse?.ville, ' ') +
            ' - ' +
            this.getInfoItem(rp.adresse?.pays, ' ') +
            '"';
        } else {
          partenaireCsv.adressePerso =
            '"' +
            this.getInfoItem(rp?.adresse?.voie, ' ') +
            ' - ' +
            this.getInfoItem(rp?.adresse?.cp, ' ') +
            ' - ' +
            this.getInfoItem(rp?.adresse?.ville, ' ') +
            ' - ' +
            this.getInfoItem(rp?.adresse?.pays, ' ') +
            '"';
        }

        partenaireCsv.departementPerso = this.getInfoItem(rp?.adresse?.cp?.slice(0, 2), null);
        partenaireCsv.regionAdministrativePerso = this.getInfoItem(rp?.adresse?.regionAdministrative, null);
        partenaireCsv.paysPerso = this.getInfoItem(rp?.adresse?.pays, null);
      }
    }

    const rl = structure.contacts.filter(contact => contact.roles.includes(this.REPRESENTANT_LEGAL))[0];
    partenaireCsv.genreRl = this.getInfoItem(rl?.genre, null);
    partenaireCsv.prenomRl = this.getInfoItem(rl?.prenom, null);
    partenaireCsv.nomRl = this.getInfoItem(rl?.nom, null);
    partenaireCsv.emailRl = this.getInfoItem(rl?.email, null);
    partenaireCsv.telRl = "'" + this.getInfoItem(rl?.telephone, null);

    if (structure.lieuRD?.siret) {
      partenaireCsv.siretRD = "'" + structure.lieuRD?.siret;
    } else {
      partenaireCsv.siretRD = this.getInfoItem(structure.raisonSiret, null);
    }

    partenaireCsv.raisonSocialRD = this.getInfoItem(structure.lieuRD?.raisonSocial, null);
    partenaireCsv.adresseRD =
      this.getInfoItem(structure.lieuRD?.adresse?.numero, null) + ' ' + this.getInfoItem(structure.lieuRD?.adresse?.voie, null);

    if (structure.lieuRD?.adresse?.codePays === 'FR') {
      partenaireCsv.adresseRD =
        '"' +
        this.getInfoItem(structure.lieuRD?.adresse?.numero, ' ') +
        ' ' +
        this.getInfoItem((EnumQualifRue as any)[structure.lieuRD?.adresse?.complement]?.toLowerCase(), '') +
        ' - ' +
        this.getInfoItem((EnumTypeVoie as any)[structure.lieuRD?.adresse?.typeVoie]?.toLowerCase(), ' ') +
        ' ' +
        this.getInfoItem(structure.lieuRD?.adresse?.voie, ' ') +
        ' - ' +
        this.getInfoItem(structure.lieuRD?.adresse?.cp, ' ') +
        ' - ' +
        this.getInfoItem(structure.lieuRD?.adresse?.ville, ' ') +
        ' - ' +
        this.getInfoItem(structure.lieuRD?.adresse?.pays, ' ') +
        '"';
    } else {
      partenaireCsv.adresseRD =
        '"' +
        this.getInfoItem(structure.lieuRD?.adresse?.voie, ' ') +
        ' - ' +
        this.getInfoItem(structure.lieuRD?.adresse?.cp, ' ') +
        ' - ' +
        this.getInfoItem(structure.lieuRD?.adresse?.ville, ' ') +
        ' - ' +
        this.getInfoItem(structure.lieuRD?.adresse?.pays, ' ') +
        '"';
    }
    partenaireCsv.departementRD = this.getInfoItem(structure.lieuRD?.adresse?.cp?.slice(0, 2), null);
    partenaireCsv.regionAdministrativeRD = this.getInfoItem(structure.lieuRD?.adresse?.regionAdministrative, null);
    partenaireCsv.paysRD = this.getInfoItem(structure.lieuRD?.adresse?.pays, null);
    partenaireCsv.demandeFinancement = this.getBesoin(structure.budgetDepot?.besoin);
    const budgetByEtape = this.getBudgetByEtape(projet, structure);
    partenaireCsv.budget = this.getInfoItem(budgetByEtape?.montant, '');
    partenaireCsv.montantAide = this.getInfoItem(budgetByEtape?.montantAideDemande, '');
    partenaireCsv.budgetTotal = this.getInfoItem(budgetByEtape?.budgetTotal, '');
    partenaireCsv.statut = this.lastEtape(projet.etapes) as any;
    partenaireCsv.etape = this.getInfoItem(projet.statut?.toString(), null);

    partenaireCsv.dateValidationEtape = this.getInfoItem(
      this.datePipe.transform(projet.etapes[projet.etapes.length - 1]?.date.toString(), 'dd/MM/yyyy'),
      null
    );
    partenaireCsv.dateReleve = this.getInfoItem(this.datePipe.transform(projet.relevePreDepot, 'dd/MM/yyyy'), null);

    return partenaireCsv;
  }

  getBesoin(besoin: boolean): string {
    return besoin ? 'oui' : 'non';
  }

  getBudgetByEtape(projet: Projet, structure: Structure): Budget {
    const lastEtapeProjet = this.lastEtape(projet.etapes);
    return lastEtapeProjet === EnumProjetEtape.PRE_DEPOT ? structure.budgetPreDepot : structure.budgetDepot;
  }

  ConvertPartenaireToPartenaireCSV(objArray: PartenaireCsv[], headerList: string[], aapPersoPhysicalSelected: boolean): string {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row =
      "Nom du programme;Nom de l'AAP;N° projet;Acronyme du projet;Titre du projet;Role de la structure;Raison sociale du siège;" +
      'Type de la struture;SIRET du siège;SIREN du siège;Adresse du siège;Département du siège;' +
      'Region administrative du siège;Pays du siège;' +
      (aapPersoPhysicalSelected ? "Adresse personnelle; Département de l'adresse personnelle;Pays de l'adresse personnelle;" : '') +
      'Représentant(e) légal(e) - Genre;Représentant(e) légal(e) - Nom;Représentant(e) légal(e) - Prénom;Représentant(e) légal(e) - Email;Représentant(e) légal(e) - Téléphone;' +
      'SIRET du lieu de réalisation du projet;Raison sociale du lieu de réalisation du projet;Adresse du lieu de réalisation du projet;' +
      'Département du lieu de réalisation du projet;Région administrative du lieu de réalisation du projet;Pays du lieu de réalisation du projet;' +
      "Demande de financement;Budget présenté (€);Montant d'aide (€);Budget total du projet (€);Phase courante;Statut de la phase;Date de validation de la phase;Relève;";

    row = row.slice(0, -1);
    str += row + '\r\n';
    for (const i in array) {
      if (Object.prototype.hasOwnProperty.call(array, i)) {
        let line = '';
        for (const index in headerList) {
          if (Object.prototype.hasOwnProperty.call(headerList, index)) {
            const head = headerList[index];

            line += array[i][head] + ';';
          }
        }
        str += line + '\r\n';
      }
    }
    return str;
  }

  getSousSects(projet: Projet): string {
    let sousSects = '';
    if (projet.secteurs) {
      for (const sect of projet.secteurs) {
        for (const sousSect of sect.sousSecteurs) {
          sousSects += sousSect.libelleSousSecteur + ',';
        }
      }
    }
    return sousSects;
  }

  getSousDomaines(projet: Projet): string {
    let sousDomaines = '';
    if (projet.domaines) {
      for (const domaine of projet.domaines) {
        for (const sousDomaine of domaine.sousDomaines) {
          sousDomaines += sousDomaine.libelleSousDomaine + ',';
        }
      }
    }
    return sousDomaines;
  }

  getPoles(projet: Projet): string {
    let poles = '';
    if (projet.poles) {
      for (const pole of projet.poles) {
        if (pole.labeled && pole.nomCommercial) {
          poles += pole.nomCommercial + ',';
        }
      }
    }
    return poles;
  }

  calculAssietteInit(aap: Aap, projet: Projet, structure: Structure, structureExceptMandataire: Structure[]): string | number | null {
    if (aap?.budgetEstime) {
      if (projet.budget) {
        const budgetGlobal = projet.budget?.montant ? Number(projet.budget?.montant) : 0;
        return Math.round(budgetGlobal / structureExceptMandataire.length)?.toString();
      } else {
        return null;
      }
    } else {
      if (structure.budgetPreDepot?.montant) {
        return structure.budgetPreDepot.montant;
      } else {
        return null;
      }
    }
  }

  // Download PIA CSV
  downloadPiaCSV(projetsList: Projet[], aaps: Aap[]): void {
    const piasCsv: PiaCsv[] = [];

    for (const projet of projetsList) {
      const aap = aaps?.filter(aapItem => projet.aap.id === aapItem.id)[0];
      if (!projet.structures) {
        continue;
      }
      const structures = projet.structures.filter(structure => !structure.closed);
      const structureExceptMandataire = structures.filter(struct => struct.role !== EnumRoleStructure.MANDATAIRE);

      for (const structure of structureExceptMandataire) {
        piasCsv.push(this.createPiaCSV(aap, projet, structure));
      }
    }

    const csvData = this.ConvertPiaToPiaCSV(piasCsv, [
      'nomProgramme',
      'nomAap',
      'identifiantPXL',
      'acronyme',
      'partenaireType',
      'raisonSocial',
      'role',
      'genreRp',
      'nomRp',
      'prenomRp',
      'emailRp',
      'telRp',
      'genreRl',
      'nomRl',
      'prenomRl',
      'emailRl',
      'telRl',
      'poles',
      'nbPartenaires',
      'thematique',
      'secteurs',
      'domaines',
      'description',
      'DRBpi',
      'typeStruture',
      'siret',
      'siren',
      'codePostal',
      'departement',
      'regionAdministrative',
      'CodePostalRD',
      'departementRD',
      'regionAdministrativeRD',
      'assietteInit',
      'assietteDetail',
      'dateVerrouillage',
      'statut',
      'etape',
      'dateValidationEtape',
      'dateReleve',
      'commentaire',
    ]);

    this.downloadFile(csvData, 'Reporting de suivi.csv');
  }

  createPiaCSV(aap: Aap, projet: Projet, structure: Structure): PiaCsv {
    const sousSects = this.getSousSects(projet);
    const sousDomaines = this.getSousDomaines(projet);
    const poles = this.getPoles(projet);

    const structures = projet.structures.filter(structure => !structure.closed);
    const structureExceptMandataire = structures.filter(struct => struct.role !== EnumRoleStructure.MANDATAIRE);

    const chefDeFile = structures.filter(structure => structure.role === EnumRoleStructure.CHEF_DE_FILE)[0];

    const piaCsv: PiaCsv = new PiaCsv();
    piaCsv.nomProgramme = this.getInfoItem(aap?.programme.type, null);
    piaCsv.nomAap = this.getInfoItem(projet.aap.code, null);
    piaCsv.identifiantPXL = this.getInfoItem(projet.identifiantPXL, null);
    piaCsv.acronyme = this.getInfoItem(projet.acronyme, null);
    piaCsv.partenaireType = this.getInfoItem(projet.partenaireType?.toString(), null);

    piaCsv.raisonSocial = this.getInfoItem(structure.raisonSocial, null);
    piaCsv.role = this.getInfoItem(structure.role?.toString(), null);
    piaCsv.poles = this.getInfoItem(poles, null);
    piaCsv.nbPartenaires = this.getInfoItem(structureExceptMandataire.length?.toString(), null);
    piaCsv.thematique = this.getInfoItem('"' + projet.thematique?.libelleThematique + '"', '');
    piaCsv.secteurs = sousSects;
    piaCsv.domaines = sousDomaines;
    piaCsv.description = '"' + this.getInfoItem(projet.description?.replace(/(\r\n|\n|\r)/gm, ' '), null) + '"';

    if (projet.centreMesure) {
      piaCsv.DRBpi = projet.centreMesure.libCentreMesure;
    } else {
      piaCsv.DRBpi = this.getInfoItem(chefDeFile?.lieuRD?.directionRegionale?.libCentreMesure, null);
    }

    piaCsv.regionAdministrative = this.getInfoItem(structure.adresse?.regionAdministrative, null);
    piaCsv.typeStruture = this.getInfoItem(structure.typeStructure, null);
    piaCsv.siret = "'" + this.getInfoItem(structure.siret?.toString(), null);

    if (structure.siret) {
      piaCsv.siret = "'" + structure.siret;
      piaCsv.siren = "'" + structure.siret.slice(0, 9);
    } else {
      piaCsv.siret = this.getInfoItem(structure.raisonSiret, null);
      piaCsv.siren = this.getInfoItem(structure.raisonSiret, null);
    }

    piaCsv.codePostal = this.getInfoItem(structure.adresse?.cp, null);
    piaCsv.departement = this.getInfoItem(structure.adresse?.cp?.slice(0, 2), null);
    piaCsv.regionAdministrative = this.getInfoItem(structure.adresse?.regionAdministrative, null);

    piaCsv.CodePostalRD = this.getInfoItem(structure.lieuRD?.adresse?.cp, null);
    piaCsv.departementRD = this.getInfoItem(structure.lieuRD?.adresse?.cp?.slice(0, 2), null);
    piaCsv.regionAdministrativeRD = this.getInfoItem(structure.lieuRD?.adresse?.regionAdministrative, null);

    piaCsv.assietteInit = this.calculAssietteInit(aap, projet, structure, structureExceptMandataire)?.toString();

    if (structure.budgetDepot?.montant) {
      piaCsv.assietteDetail = structure.budgetDepot?.montant.toString();
    } else {
      piaCsv.assietteDetail = null;
    }

    // recupère les dates de verrouillage du projet
    const dates = [...projet.etapes]
      .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
      .filter(etape => [EnumProjetStatut.ANALYSE_VALIDE, EnumProjetStatut.ANALYSE_REJETE].includes(etape.statut));

    piaCsv.dateVerrouillage = this.getInfoItem(this.datePipe.transform(dates[0]?.date, 'dd/MM/yyyy'), null);

    piaCsv.etape = this.getInfoItem(projet.statut?.toString(), null);
    piaCsv.statut = this.lastEtape(projet.etapes) as any;
    piaCsv.dateValidationEtape = this.getInfoItem(
      this.datePipe.transform(projet.etapes[projet.etapes.length - 1]?.date.toString(), 'dd/MM/yyyy'),
      null
    );
    piaCsv.dateReleve = this.getInfoItem(this.datePipe.transform(projet.relevePreDepot, 'dd/MM/yyyy'), null);

    const structRp = structure;
    const rp = structRp?.contacts.filter(contact => contact.roles.includes(this.RESPONSABLE_PROJET))[0];

    if (rp) {
      piaCsv.genreRp = this.getInfoItem(rp?.genre, null);
      piaCsv.prenomRp = this.getInfoItem(rp?.prenom, null);
      piaCsv.nomRp = this.getInfoItem(rp?.nom, null);
      piaCsv.emailRp = this.getInfoItem(rp?.email, null);
      piaCsv.telRp = "'" + this.getInfoItem(rp?.telephone, null);
    }

    const structRl = structure;
    const rl = structRl?.contacts.filter(contact => contact.roles.includes(this.REPRESENTANT_LEGAL))[0];
    if (rl) {
      piaCsv.genreRl = this.getInfoItem(rl?.genre, null);
      piaCsv.prenomRl = rl?.prenom;
      piaCsv.nomRl = rl?.nom;
      piaCsv.emailRl = this.getInfoItem(rl?.email, null);
      piaCsv.telRl = "'" + this.getInfoItem(rl?.telephone, null);
    }

    piaCsv.commentaire = this.getInfoItem(structure.verrouillage?.commentaire, null);

    return piaCsv;
  }

  ConvertPiaToPiaCSV(objArray: PiaCsv[], headerList: string[]): string {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row =
      "Plan;Code AAP;N° projet;Acronyme du projet;Indiv/Multi partenaire;Nom de l'entreprise;Chef de file;Responsable projet - Genre;Responsable projet - Nom;" +
      'Responsable projet - Prénom;Responsable projet - Email;Responsable projet - Téléphone;Représentant(e) légal(e) - Genre;Représentant(e) légal(e) - Nom;' +
      'Représentant(e) légal(e) - Prénom;Représentant(e) légal(e) - Email;Représentant(e) légal(e) - Téléphone;Pôle de compétitivité labellisateur;' +
      "Nb de partenaires;Thématique;Secteurs d'applications;Domaines technologiques;Description pour communication publique;" +
      'Direction régionale Bpifrance;Type de partenaire;SIRET;SIREN;Code postal siège;Département siège;Région siège;' +
      'Code postal lieu de réalisation;Département lieu de réalisation;Région lieu de réalisation;Assiette Initiale répartie;' +
      'Assiette détaillée;Date de verrouillage;Phase courante;Statut de la phase; Date de validation de la phase;Relève;Commentaire de verrouillage;';

    row = row.slice(0, -1);
    str += row + '\r\n';
    for (const i in array) {
      if (Object.prototype.hasOwnProperty.call(array, i)) {
        let line = '';
        for (const index in headerList) {
          if (Object.prototype.hasOwnProperty.call(headerList, index)) {
            const head = headerList[index];

            line += array[i][head] + ';';
          }
        }
        str += line + '\r\n';
      }
    }
    return str;
  }

  lastEtape(etapes: ProjetEtape[]): EnumProjetEtape | null {
    const datesEtapes = etapes;
    datesEtapes?.sort((b, a) => {
      return new Date(b.date).valueOf() - new Date(a.date).valueOf();
    });
    return etapes.length > 0 ? datesEtapes[datesEtapes.length - 1].nom : null;
  }

  getInfoItem(infoItem: any, defaultValue: string) {
    return infoItem || defaultValue;
  }
}
