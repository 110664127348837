import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpRequestInterceptor } from '@core-evaluateur/interceptor';
import { environment } from '@environments-evaluateur/environment';
import { AapCreationModule } from '@features-evaluateur/aap-creation/aap.creation.module';
import { AapListModule } from '@features-evaluateur/aap-list/aap.list.module';
import { AccesEvaluateurModule } from '@features-evaluateur/acces-evaluateur/confirmation-acces-evaluateur.module';
import { ComitesModule } from '@features-evaluateur/comites/comites.module';
import { OrganismeCreationModule } from '@features-evaluateur/organismes/organisme-creation/organisme.creation.module';
import { OrganismeListModule } from '@features-evaluateur/organismes/organisme-list/organisme.list.module';
import { ProgramCreationModule } from '@features-evaluateur/program-creation/program.creation.module';
import { ProgramListModule } from '@features-evaluateur/program-list/program.list.module';
import { ProjetListTransverseAapsModule } from '@features-evaluateur/projet-list-transverse-aaps/projet-list-transverse-aaps.module';
import { ProjetParcoursModule } from '@features-evaluateur/projet-parcours/projet-parcours.module';
import { ReferentielsModule } from '@features-evaluateur/referentiels/referentiels.module';
import { RenewAccessEvalModule } from '@features-evaluateur/renew-access-evaluateur/renew-access-evaluateur.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AapService } from '@services-evaluateur/aap.service';
import { ComiteService } from '@services-evaluateur/comite.service';
import { DemandeDeDocumentComplementaireService } from '@services-evaluateur/demandeDeDocumentComplementaire.service';
import { DepensesInstructeurService } from '@services-evaluateur/depenses-instructeur.service';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { FicheCommunicationService } from '@services-evaluateur/fiche-communication.http.service';
import { FicheDemandeAideService } from '@services-evaluateur/fiche-demande-aide.service';
import { IntervenantsDcService } from '@services-evaluateur/intervenantsDC.service';
import { MultiEmailService } from '@services-evaluateur/multi-email.service';
import { MultiRadicauxEmailService } from '@services-evaluateur/multi-radicaux-email.service';
import { OrganismeService } from '@services-evaluateur/organisme.service';
import { PoleService } from '@services-evaluateur/pole.service';
import { ProgramService } from '@services-evaluateur/program.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { PxlOidcService } from '@services-evaluateur/pxl-oidc.service';
import { StructureService } from '@services-evaluateur/structure.service';
import { UploadDocumentService } from '@services-evaluateur/upload-document.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { CguModalModule } from '@shared-evaluateur/components/modals/cgu-modal/cgu-modal.module';
import { CustomReuseStrategy } from '@shared-evaluateur/routing';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { ExportCsvFunction } from '@shared-evaluateur/utils/export-csv';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  FeatureFlagService,
  FrenchNumberFormatPipe,
  IbanPipe,
  PrevisionsEconomiquesSharedService,
  ProjetValidationFunction,
  ShowToastrService,
  StructureValidationFunction,
  ToNumberPipe,
} from '@shared-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { AppConfig } from '../app.config';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { AutoLoginComponent } from './core/auth-login.component';
import { AuthConfigModule } from './core/core.module';
import { SidenavProjetsModule } from './features/projet-list/component/sidenav-creation/sidenav.projets.module';
import { ProjetListModule } from './features/projet-list/projet.list.module';
import { CanDeactivateGuard } from './guards/can-deactivate-guard';
import { DepensesInstructeurHttpService } from '@services-evaluateur/depenses-instructeur-http.service';
import { EvaluateurChoiceModule } from '@features-evaluateur/acces-evaluateur/evaluateur-choice/evaluateur-choice.module';

registerLocaleData(localeFr);

function initializeApp(appConfig: AppConfig): any {
  return async () => {
    const url = new URL(location.href);
    const idp = url.searchParams.get('idp');
    if (idp) {
      localStorage.setItem('idp', idp);
      url.searchParams.delete('idp');
      location.href = url.href;
    }
    await appConfig.load();
  };
}

@NgModule({
  declarations: [AppComponent, AutoLoginComponent],
  imports: [
    AppRoutes,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ProgramListModule,
    ProgramCreationModule,
    OrganismeCreationModule,
    AapListModule,
    AapCreationModule,
    OrganismeListModule,
    ProjetParcoursModule,
    ProjetListModule,
    ProjetListTransverseAapsModule,
    RenewAccessEvalModule,
    ReferentielsModule,
    SidenavProjetsModule,
    CguModalModule,
    AccesEvaluateurModule,
    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AuthConfigModule,
    NgbModule,
    ComitesModule,
    EvaluateurChoiceModule,
  ],
  providers: [
    CanDeactivateGuard,
    AppConfig,
    { provide: 'appConfig', useExisting: AppConfig },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: 'environment', useValue: environment },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    FeatureFlagService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    UtilisateurService,
    ProgramService,
    UploadDocumentService,
    AapService,
    ProjetService,
    StructureService,
    IntervenantsDcService,
    ShowToastrService,
    DatePipe,
    ToNumberPipe,
    IbanPipe,
    FrenchNumberFormatPipe,
    SharedFunction,
    ExportCsvFunction,
    ProjetValidationFunction,
    StructureValidationFunction,
    PoleService,
    DemandeDeDocumentComplementaireService,
    EvaluateurService,
    PxlOidcService,
    ComiteService,
    OrganismeService,
    MultiEmailService,
    FicheCommunicationService,
    FicheDemandeAideService,
    MultiRadicauxEmailService,
    DepensesInstructeurService,
    DepensesInstructeurHttpService,
    PrevisionsEconomiquesSharedService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
