import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Comite, ComiteInterfaceForm, EnumStatutComite, Program, ProjetParPhase } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class ComitesCreationHelperService {
  /**
   * Création du formulaire comité
   * @param comite objet Comite, nécessaire si on est en modification pour remplir les champs
   * @returns l'objet FormGroup
   */
  buildFormCreation(canUserEditComite: boolean, comite?: Comite): FormGroup<ComiteInterfaceForm> {
    const formGroup = new FormGroup<ComiteInterfaceForm>({
      id: new FormControl(comite?.id),
      nom: new FormControl(comite?.nom ?? '', Validators.required),
      dateTenue: new FormControl(new Date(comite?.dateTenue), Validators.required),
      heureTenue: new FormControl(comite?.dateTenue ? this.getHourMinuteFromDate(new Date(comite.dateTenue)) : null, Validators.required),
      programmeId: new FormControl(comite?.programme?.id),
      statut: new FormControl(comite?.statut),
    });

    if (!canUserEditComite) {
      formGroup.disable();
    }

    return formGroup;
  }

  /**
   * Construit un objet Comite à partir du form
   * @param form Form de type ComiteInterfaceForm
   * @returns un objet Comite contenant la value du form
   */
  getComiteFromForm(form: FormGroup<ComiteInterfaceForm>, programmes: Program[]): Comite {
    const comiteFormValue = form.getRawValue();
    const comite: Comite = {
      ...form.getRawValue(),
      programme: programmes.find(prog => prog.id === comiteFormValue.programmeId),
    };
    if (form.controls.heureTenue) {
      const tenueTime = form.controls.heureTenue.value.split(':');
      const finalDate = form.controls.dateTenue.value;
      finalDate.setHours(Number.parseInt(tenueTime[0]), Number.parseInt(tenueTime[1]), 0);
      form.controls.dateTenue.setValue(finalDate);
    }

    return comite;
  }

  /**
   * Retourne l'heure et minute d'une date en string
   * @param date Date complète
   * @returns heure et minute au format hh:mm
   */
  getHourMinuteFromDate(date: Date): string {
    return `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
  }

  /**
   * Vérifie si les deux arrays de projets (ids) sont identiques, càd qu'on n'a pas touché à la sélection de projets
   * @param selected projets sélectionnés sur le tableau
   * @param originalProjets les projets initialement sélectionnés sur l'objet comité (en cas de modification)
   * @returns True si les deux arrays sont identiques, false sinon
   */
  isProjectSelectionPristine(selected: ProjetParPhase[], originalProjets: ProjetParPhase[]): boolean {
    if (selected.length !== originalProjets.length) {
      return false;
    }

    return selected.every(selectedProjet =>
      originalProjets.find(
        originalProjet => originalProjet.projetId === selectedProjet.projetId && originalProjet.phase === selectedProjet.phase
      )
    );
  }

  /**
   * Retourne si le comité est créé ou non, par créé on veut dire tous les status qui ne sont pas Brouillon ou Cloture.
   * Mis en place de cette façon car d'autre status seront mis en place après "CREE", donc on passe par la négation de la cloture/brouillon.
   * @param comite Comite
   * @returns True si le comite est créé, false sinon
   */
  isComiteCreated(comite: Comite): boolean {
    return comite?.statut !== EnumStatutComite.BROUILLON && comite?.statut !== EnumStatutComite.CLOTURE;
  }

  /**
   * Retourne si le comité est cloturé
   * @param comite Comite
   * @returns True si le comite est cloture, false sinon
   */
  isComiteCloture(comite: Comite): boolean {
    return comite?.statut === EnumStatutComite.CLOTURE;
  }

  /**
   * Retourne si le comité est en brouillon
   * @param comite Comite
   * @returns True si le comite est enbrouillon, false sinon
   */
  isComiteBrouillon(comite: Comite): boolean {
    return comite?.statut === EnumStatutComite.BROUILLON;
  }

  /**
   * Donne le titre de la page
   * @returns titre à afficher
   */
  getTitle(comite: Comite, isEdit: boolean): string {
    if (!isEdit) {
      return 'Nouveau comité';
    }

    if (comite?.nom == null || comite?.nom === '') {
      return 'Modification du comité';
    }

    return comite.nom;
  }
}
