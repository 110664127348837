import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { OrganismeService } from '@services-evaluateur/organisme.service';
import { OrganismeAap, OrganismeModel, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pxl-organismes',
  templateUrl: './organismes.component.html',
  styleUrls: ['./organismes.component.scss'],
})
export class OrganismesComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() stepNumber: number;
  dataSource: MatTableDataSource<OrganismeModel>;
  selection = new SelectionModel<OrganismeModel>(true, []);
  subscription: Subscription;
  private _organismeFormGroup: UntypedFormGroup;
  @Input() set organismeFormGroup(value: UntypedFormGroup) {
    this.subscription && this.subscription.unsubscribe();
    this._organismeFormGroup = value;
    if (value) {
      this.subscription = value.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
        this.setSelectedCheckboxes();
      });
    }
    this.setSelectedCheckboxes();
  }
  get organismeFormGroup(): UntypedFormGroup {
    return this._organismeFormGroup;
  }

  constructor(
    private organismeService: OrganismeService,
    private showToastrService: ShowToastrService,
    @Inject(DOCUMENT) public document: Document
  ) {
    super();
  }

  ngOnInit(): void {
    this.selection.changed.pipe(this.takeUntilDestroyed()).subscribe({
      next: () => {
        if (this._organismeFormGroup) {
          this.subscription && this.subscription.unsubscribe();
          const selectedOrganismes = this.getFormatedOrganismeAap(this.selection.selected);
          this._organismeFormGroup?.setValue(selectedOrganismes);
          this.subscription = this._organismeFormGroup.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
            this.setSelectedCheckboxes();
          });
        }
      },
    });
    this.organismeService
      .getOrganismes()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<OrganismeModel[]>) => {
          this.dataSource = new MatTableDataSource<OrganismeModel>(response.body);
          this.setSelectedCheckboxes();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  setSelectedCheckboxes(): void {
    if (!this.dataSource) {
      return;
    }
    this.selection.setSelection(
      ...this.dataSource.data.filter(data => {
        return this._organismeFormGroup.value.find((organisme: OrganismeAap) => organisme.idOrganisme === data.id) != null;
      })
    );
  }

  getFormatedOrganismeAap(selectedOrganismes: OrganismeModel[]): OrganismeAap[] {
    if (!selectedOrganismes) return [];
    return selectedOrganismes.map((organisme: OrganismeModel) => {
      const oldOrganisme = this._organismeFormGroup.value.find((org: OrganismeAap) => org.idOrganisme === organisme.id);
      return {
        idOrganisme: organisme.id,
        nomOrganisme: organisme.nom,
        codeThematiques: oldOrganisme?.codeThematiques || [],
        typeOrganisme: organisme.type || null,
      };
    });
  }
}
