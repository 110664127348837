<pxl-sidenav-projets *ngIf="aap.id" [projets]="projets" [aap]="aap"></pxl-sidenav-projets>

<div class="page-content page-content--no-scroll list-page">
  <div class="list-page__header">
    <h1 class="list-page__title" *ngIf="!aap.code">Les projets de l’AAP {{ aap.codeComptable }}</h1>
    <h1 class="list-page__title" *ngIf="aap.code">Les projets de l’AAP {{ aap.code }}</h1>

    <div [libMyRemoveIfFeatureOff]="'auto-affectation'">
      <lib-pxl-toggle
        *ngIf="sharedFunction.isSiteAdmin() || canEvaluateurAutoAffect"
        [labels]="toggleLabels"
        [defaultLabel]="defaultToggleLabel"
        (changeToggleEvent)="onChangeToggle($event)"
      ></lib-pxl-toggle>
    </div>

    <div class="list-page__dropdowns">
      <button
        id="switch-to-evaluateur"
        class="ds-btn ds-btn--secondary"
        *ngIf="sharedFunction.isSiteEval()"
        (click)="redirectToCandidats()"
      >
        Accéder à mon espace candidat
      </button>
      <button
        type="button"
        *ngIf="canUpdateWorkflow()"
        class="list-page__create-button ds-btn ds-btn--secondary"
        [matMenuTriggerFor]="Workflow"
        #menuTrigger="matMenuTrigger"
        (click)="showMsg()"
      >
        Workflow
        <lib-pxl-icon class="icon" icon="icon-arrow-expand-more"></lib-pxl-icon>
      </button>
      <mat-menu #Workflow="matMenu" class="menu-workflow" xPosition="before">
        <div class="arrow-div"></div>

        <!-- Workflow options -->
        <div *ngFor="let wkf of typeWkf">
          <label class="ds-input-group__label menu-label">{{ EnumProjetEtape.toString(wkf.etape) }}</label>
          <div *ngFor="let statut of wkf.statuts">
            <div class="menu-etape" mat-menu-item>
              <label style="text-decoration: underline">{{ statut.label }}</label>
              <div class="menu-btn card__actions">
                <ng-container *ngFor="let option of statut.options">
                  <button
                    type="button"
                    *ngIf="canUpdateThisStep(wkf.etape)"
                    class="card__action-button"
                    (click)="updateProjetEtapes(wkf.etape, option.statut)"
                    [disabled]="disableMenu(wkf.etape, option.type)"
                  >
                    <lib-pxl-icon class="icon" icon="icon-action-play" *ngIf="option.type === TYPE_EN_COURS"></lib-pxl-icon>
                    <lib-pxl-icon class="icon" icon="icon-human-thumb-up-alt" *ngIf="option.type === TYPE_VALIDE"></lib-pxl-icon>
                    <lib-pxl-icon class="icon" icon="icon-human-thumb-down-alt" *ngIf="option.type === TYPE_REJETE"></lib-pxl-icon>
                    <lib-pxl-icon class="icon" icon="icon-action-stop" *ngIf="option.type === TYPE_TERMINE"></lib-pxl-icon>
                  </button>
                </ng-container>
              </div>
            </div>
            <hr style="width: 85%; background: #e9e9e9; margin-left: 16px" />
          </div>
        </div>
        <!-- Fin workflow options -->
      </mat-menu>

      <!-- TODO Remove feature fliping archive V1 -->
      <button
        type="button"
        id="actions-btn"
        class="ds-btn ds-btn--primary"
        placement="bottom-right"
        [ngbPopover]="popContent"
        *ngIf="sharedFunction.isSiteAdmin() || sharedFunction.isEvaluateurInstructeurExterne(evaluateur)"
        [libMyRemoveIfFeatureOff]="'archiveV1'"
      >
        Actions
        <lib-pxl-icon class="icon" icon="icon-arrow-expand-more" aria-hidden="true"></lib-pxl-icon>
      </button>
      <!-- TODO Remove feature fliping archive V1 -->

      <div (libClickOutside)="popover.close()" id="actions-dropdown-menu">
        <button
          id="actions-btn"
          class="ds-btn ds-btn--primary"
          placement="bottom-right"
          [ngbPopover]="popContent"
          [libMyRemoveIfFeatureOff]="'archiveV2'"
          triggers="manual"
          (click)="popover.isOpen() ? popover.close() : popover.open()"
          #popover="ngbPopover"
          [autoClose]="false"
        >
          Actions
          <lib-pxl-icon class="icon" icon="icon-arrow-expand-more" aria-hidden="true"></lib-pxl-icon>
        </button>
        <ng-template #popContent>
          <div [libMyRemoveIfFeatureOff]="'auto-affectation'">
            <div *ngIf="sharedFunction.isSiteAdmin() && !isMesProjetsToggleSelected && !hiddenAutoAffectation">
              <div class="popover_link border-top-0">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="openAutoAffectDialog(); popover.close()">M'affecter</a>
              </div>
            </div>
            <div *ngIf="canEvaluateurAutoAffect && !isMesProjetsToggleSelected && !hiddenAutoAffectation">
              <div class="popover_link border-top-0">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="affecterAudition(); popover.close()">M'affecter en Audition</a>
              </div>
            </div>
            <div *ngIf="(sharedFunction.isSiteAdmin() || canEvaluateurAutoAffect) && isMesProjetsToggleSelected && !hiddenAutoAffectation">
              <div class="popover_link border-top-0">
                <lib-pxl-icon class="icon" icon="icon-action-minus-circle-outline"></lib-pxl-icon>
                <a (click)="autoDeaffect(); popover.close()">Me désaffecter</a>
              </div>
            </div>
          </div>

          <div class="popover_container" *ngIf="sharedFunction.isUserAdmin(user) || sharedFunction.isUserInno(user)">
            <button class="popover_title" (click)="juryRegionalDiv.classList.toggle('d-none')">
              {{ aap.ilab ? 'Jury Régional' : 'Evaluateur' }}
              <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #juryRegionalDiv class="d-none">
              <div class="popover_link" *ngIf="!aap.ilab">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="affecterDesaffecterIntervenant(affecter); popover.close()">Affecter</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="affecterIntervenantLecttureSeule(); popover.close()">Affecter en lecture seule</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-minus-circle-outline"></lib-pxl-icon>
                <a (click)="affecterDesaffecterIntervenant(desaffecter); popover.close()">Désaffecter</a>
              </div>
            </div>
          </div>

          <div class="popover_container" *ngIf="sharedFunction.isUserAdmin(user) && isAapIlab">
            <button class="popover_title" (click)="juryNationalDiv.classList.toggle('d-none')">
              Jury national <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #juryNationalDiv class="d-none">
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="affecterJuryNational(); popover.close()">Affecter expert</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="affecterPresident(); popover.close()">Affecter (vice) président </a>
              </div>
            </div>
          </div>

          <div class="popover_container" *ngIf="sharedFunction.isUserAdmin(user) || sharedFunction.isUserInno(user)">
            <button class="popover_title" (click)="projetDiv.classList.toggle('d-none')">
              Projet <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #projetDiv class="d-none">
              <div class="popover_link" *ngIf="!aap.ilab">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="addEvaluationDate(); popover.close()">Echéance évaluation</a>
              </div>
              <div class="popover_link" *ngIf="!aap.ilab">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="addAuditionDate(); popover.close()">Echéance audition</a>
              </div>
              <div class="popover_link" *ngIf="sharedFunction.isUserAdmin(user)">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="editDirectionReg(); popover.close()">Modifier la Direction régionale</a>
              </div>
              <ng-container *ngxPermissionsOnly="['PROJET_WRITE']">
                <div class="popover_link">
                  <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                  <a (click)="goToProjetParcours(); popover.close()">Nouveau projet</a>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="popover_container" *ngIf="(isUserGroup || isUserDar) && isMesProjetsToggleSelected">
            <button class="popover_title" (click)="projetDRDiv.classList.toggle('d-none')">
              Projet <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #projetDRDiv class="d-none">
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
                <a (click)="editDirectionReg(); popover.close()">Modifier la Direction régionale</a>
              </div>
            </div>
          </div>

          <div class="popover_container" *ngIf="canExtractProjetsOrPartenaires() || canEvaluateurExport">
            <button class="popover_title" (click)="extractionsDiv.classList.toggle('d-none')">
              Extractions <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #extractionsDiv class="d-none">
              <div
                class="popover_link"
                *ngIf="
                  (sharedFunction.isSiteAdmin() || canEvaluateurExport) && (aap.enqueteProjetPresent || aap.enqueteResponsableProjetPresent)
                "
              >
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadEnquetes(); popover.close()">Enquête</a>
              </div>
              <div class="popover_link" *ngIf="sharedFunction.isSiteAdmin() || canEvaluateurExport">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadExportAffectationsProjets(); popover.close()">Evaluateurs affectés</a>
              </div>
              <div class="popover_link" *ngIf="sharedFunction.isSiteAdmin() || canEvaluateurExport">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadNotationsProjets(); popover.close()">Notations projets</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadPartenaire(); popover.close()">Partenaires</a>
              </div>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadExportProjets(); popover.close()">Projets</a>
              </div>
              <div class="popover_link" *ngIf="sharedFunction.isSiteAdmin()">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadPIA(); popover.close()">Reporting de suivi</a>
              </div>
              <div class="popover_link" *ngIf="sharedFunction.canUserReadContractualisation(user)">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadExtraitDPM(); popover.close()">Extrait DPM</a>
              </div>
            </div>
          </div>

          <div class="popover_container" [libMyRemoveIfFeatureOff]="'archiveV2'">
            <button class="popover_title" (click)="archiveDiv.classList.toggle('d-none')">
              Archive <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
            </button>
            <div #archiveDiv class="d-none">
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadDossiersComplets(); popover.close()">Télécharger dossiers complets</a>
              </div>
              <div class="popover_link" *ngIf="sharedFunction.isSiteAdmin() && aap.ficheComPresent">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadFicheCommunication(); popover.close()">Télécharger fiches communication</a>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <div *ngIf="sharedFunction.isSiteAdmin()">
        <button
          id="comite-menu-btn"
          class="ds-btn ds-btn--primary"
          placement="bottom-right"
          [ngbPopover]="comiteMenu"
          *ngxPermissionsOnly="['COMITE_READ', 'COMITE_WRITE']"
        >
          <lib-pxl-icon class="icon" icon="icon-menu-organisme" aria-hidden="true"></lib-pxl-icon>
        </button>
        <ng-template #comiteMenu>
          <span class="popover_title">Comités</span>
          <div class="popover_link">
            <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
            <a (click)="assignProjetsToComite()">Associer à un comité</a>
          </div>
          <div class="popover_link">
            <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
            <a (click)="setProjetsDateEnvoi()">Date d'envoi</a>
          </div>
          <div class="popover_link">
            <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
            <a (click)="setProjetsDecision()">Décision et date de validation</a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="page-content--no-scroll filter_table_content">
    <!-- Component de recherche -->
    <project-search
      *ngIf="showSearchBar && sharedFunction.isSiteAdmin() && !isReorientedProjetsToggleSelected"
      [thematiqueDataList]="thematiqueDataList"
      [releveDataList]="releveDataList"
      [etapeDataList]="etapeDataList"
      [statutDataList]="statutDataList"
      [tacheDataList]="tacheDataList"
      [drdlDataList]="drdlDataList"
      [comiteDataList]="comiteDataList"
      [phaseDecisionDataList]="phaseDecisionDataList"
      [aapId]="aap.id"
      [affectedOnly]="isMesProjetsToggleSelected"
      (searchEventEmitter)="searchEventCalled($event)"
    ></project-search>

    <!-- TODO Remove feature fliping archive V1 -->
    <pxl-tableau-projet
      [libMyRemoveIfFeatureOff]="'archiveV1'"
      [(selection)]="selection"
      [displayedColumns]="isReorientedProjetsToggleSelected ? displayedColumnsProjetsReorientes : displayedColumnsOld"
      [(data)]="projetTableData"
      (elementClicked)="goToProjetInfo($event)"
      (sortChange)="onSortChange($event)"
    ></pxl-tableau-projet>
    <!-- TODO Remove feature fliping archive V1 -->

    <!-- Component de tableau de projets -->
    <pxl-tableau-projet
      [libMyRemoveIfFeatureOff]="'archiveV2'"
      [(selection)]="selection"
      [displayedColumns]="isReorientedProjetsToggleSelected ? displayedColumnsProjetsReorientes : displayedColumns"
      [(data)]="projetTableData"
      (elementClicked)="goToProjetInfo($event)"
      [savedSort]="savedSort"
      (sortChange)="onSortChange($event)"
    ></pxl-tableau-projet>
  </div>
</div>
