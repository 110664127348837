import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { CreationDepensesProjetsComponent } from './creation-depenses-projets.component';
import { CreationDepensesProjetsHelperService } from './creation-depenses-projets.helper.service';

@NgModule({
  declarations: [CreationDepensesProjetsComponent],
  imports: [CommonModule, SharedModule, SharedUiModule],
  providers: [CreationDepensesProjetsHelperService],
})
export class CreationDepensesProjetsModule {}
