import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { EvaluateurChoiceComponent } from './evaluateur-choice.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [EvaluateurChoiceComponent],
  imports: [SharedModule, MaterialModule, CommonModule, SharedUiModule],
  providers: [],
})
export class EvaluateurChoiceModule {}
