<section class="form-step">
  <div class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span>
      Organismes
    </h2>
  </header>

  <pxl-organismes-tableau [dataSource]="dataSource" [selection]="selection"></pxl-organismes-tableau>
</section>
