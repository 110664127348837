import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FeatureFlagService } from '@shared-ui';
import { Observable, filter, of, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureFlippingGuard {
  constructor(private router: Router, private featureFlagService: FeatureFlagService) {}

  /**
   * Feature flipping guard to check if a feature is accessible or not
   * @param route Activated route
   * @returns Boolean : true if the feature is activated and false if not
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.router.events
      ?.pipe(
        filter((e: any): e is RouterEvent => e instanceof RouterEvent),
        take(1)
      )
      .subscribe((evt: RouterEvent) => {
        if (evt instanceof NavigationEnd) {
          if (this.featureFlagService.featureOff(route.data.featureName)) {
            this.router.navigate(['/aaps']);
            return of(false);
          }
        }
      });
    return of(true);
  }
}
