import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SidenavCreationComponent } from './sidenav.creation.programme.component';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, SharedUiModule],
  declarations: [SidenavCreationComponent],
  exports: [SidenavCreationComponent],
  providers: [],
})
export class SidenavCreationModule {}
