<div class="sidenav-creation">
  <div class="sidenav-creation__header">
    <a routerLink="/programmes" class="sidenav-creation__back">
      <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back"></lib-pxl-icon>
      Tous les programmes
    </a>

    <h1 id="program-title" *ngIf="program.id" class="sidenav-creation__title">{{ program.nom }}</h1>
    <h1 id="nex-program" *ngIf="!program.id" class="sidenav-creation__title">Nouveau programme</h1>
  </div>

  <nav class="sidenav-creation__nav">
    <ol>
      <li>
        <a
          id="information-generale"
          class="sidenav-creation__step"
          [class.sidenav-creation__step--current]="currentSection === 'anchor1'"
          (click)="goToSection('anchor1'); currentSection = 'anchor1'"
        >
          <span class="sidenav-creation__step-number">1</span>
          Informations générales
        </a>
      </li>
    </ol>
  </nav>
</div>
