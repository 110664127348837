<div class="tableau-title">
  <div>Référentiel des dépenses projet</div>
</div>

<div class="table">
  <div class="ms-4">Taux & Versement</div>
  <table mat-table [dataSource]="dataSource" aria-describedby="tableau de référentiel des taux">
    <ng-container matColumnDef="pourcentage" class="">
      <th id="pourcentage" mat-header-cell *matHeaderCellDef>Pourcentage Solde (%)</th>
      <td id="ref-pourcentage" mat-cell *matCellDef="let element">
        <span>{{ element.tauxConfig.pourcentageSolde }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="taux" class="">
      <th id="taux" mat-header-cell *matHeaderCellDef>Taux CE (%)</th>
      <td id="ref-taux" mat-cell *matCellDef="let element">
        <span>{{ element.tauxConfig.tauxCE }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="versement" class="">
      <th id="versement" mat-header-cell *matHeaderCellDef>Versement minimum (€)</th>
      <td id="ref-versement" mat-cell *matCellDef="let element">
        <span>{{ element.tauxConfig.versementMinimum }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="remboursement" class="">
      <th id="differe-remboursement" mat-header-cell *matHeaderCellDef>Différé remboursement (nb années)</th>
      <td id="ref-differe-remboursement" mat-cell *matCellDef="let element">
        <span>{{ element.tauxConfig.differeRemboursement }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsTauxVersement; sticky: true"></tr>
    <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumnsTauxVersement" style="cursor: pointer"></tr>
  </table>

  <div class="ms-4">Aide pour le premier versement</div>
  <table mat-table [dataSource]="dataSource" aria-describedby="tableau de référentiel des versements">
    <ng-container matColumnDef="petite" class="">
      <th id="petite" mat-header-cell *matHeaderCellDef>Petite Entreprise (%)</th>
      <td id="ref-petite" mat-cell *matCellDef="let element">
        <span>{{ element.premierVersementConfig.tauxPetiteEntreprise }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="moyenne" class="">
      <th id="moyenne" mat-header-cell *matHeaderCellDef>Moyenne Entreprise (%)</th>
      <td id="ref-moyenne" mat-cell *matCellDef="let element">
        <span>{{ element.premierVersementConfig.tauxMoyenneEntreprise }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="grande" class="">
      <th id="grande" mat-header-cell *matHeaderCellDef>Grande Entreprise (%)</th>
      <td id="ref-grande" mat-cell *matCellDef="let element">
        <span>{{ element.premierVersementConfig.tauxGrandeEntreprise }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="labo" class="">
      <th id="labo" mat-header-cell *matHeaderCellDef>Laboratoire/non économique (%)</th>
      <td id="ref-labo" mat-cell *matCellDef="let element">
        <span>{{ element.premierVersementConfig.tauxLaboratoireRecherche }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsAidePremierVersement; sticky: true"></tr>
    <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumnsAidePremierVersement" style="cursor: pointer"></tr>
  </table>
</div>
