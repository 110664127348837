import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface ModalData {
  title: string;
  description: string;
  text1: string;
  text2?: string;
  oldName?: string;
  textGoButton: string;
  textReturnButton: string;
}

@Component({
  selector: 'pxl-scenarios-modal',
  templateUrl: './scenarios-modal.component.html',
  styleUrls: ['./scenarios-modal.component.scss'],
})
export class ScenariosModalComponent implements OnInit {
  inputForm!: UntypedFormGroup;

  get formInputField(): UntypedFormControl {
    return this.inputForm.get('scenario') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<ScenariosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.inputForm = this.formBuilder.group({
      scenario: [!this.modalData.oldName ? this.modalData.oldName : null, Validators.required],
    });
  }

  returnValue() {
    this.dialogRef.close(this.inputForm.get('scenario').value);
  }

  cancelModal(): void {
    if (this.inputForm.dirty) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
}
