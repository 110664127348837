import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { DocumentProjet, EnumNotationEtape, Projet, DocumentHelper, SignedUrlResponseModel } from '@shared-ui';
import { Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UploadDocumentService {
  adminApiUrl = environment.apiAdminUrl;
  private readonly _apiUrl = environment.apiUrlLambda;

  constructor(private httpClient: HttpClient) {}

  getValueForLogoUpload(fileName: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'PUT',
        typeBucket: 'DOCUMENT_PUBLIC',
        nom: 'document-public/' + fileName,
      },
      { observe: 'response' }
    );
  }

  uploadLogo(url: string, file: File): Observable<any> {
    return this.httpClient.put<any>(url, file);
  }

  getValueForDocDownload(projet: Projet, document: DocumentProjet): Observable<HttpResponse<{ url: string }>> {
    return this.getValueForDocDownloadFromPath(
      document.id ? projet.id + '/' + document.id + '/' + document.nom : projet.id + '/' + document.nom
    );
  }
  getValueForDocStructureDownload(
    projet: Projet,
    document: DocumentProjet,
    subDirectory = ''
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'DOCUMENT_PRIVATE',
        nom: projet.id + '/' + document.structureId + subDirectory + '/' + document.id + '/' + document.nom,
      },
      { observe: 'response' }
    );
  }

  getValueForDocNotationDownload(
    projet: Projet,
    document: DocumentProjet,
    stepNotation: EnumNotationEtape
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'DOCUMENT_PRIVATE',
        nom:
          projet.id + (stepNotation === EnumNotationEtape.EVALUATION ? '/evaluations/' : '/auditions/') + document.id + '/' + document.nom,
      },
      { observe: 'response' }
    );
  }

  createS3SignedUrl(documentProjet: DocumentProjet, folderPath: string, typeUpload: string, typeDocument?: string): Observable<string> {
    return this.getValueForDocUpload(
      documentProjet.nom,
      folderPath,
      typeUpload,
      documentProjet.id,
      documentProjet.projetId,
      typeDocument
    ).pipe(
      map(response => {
        if (!response.body || !response.body.url) {
          throw new Error("Erreur lors de la récupération de l'url signée");
        }
        return response.body.url;
      })
    );
  }

  getValueForDocUpload(
    fileName: string,
    path: string,
    typeUpload: string,
    documentId: string,
    projetId: string,
    typeDocument?: string
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'PUT',
        typeBucket: typeBucket,
        nom: path + '/' + fileName,
        metadata: {
          type: typeUpload,
          idProjet: projetId,
          idDocument: documentId,
          typeDocument: typeDocument ? typeDocument : '',
        },
      },
      { observe: 'response' }
    );
  }

  getValueForDocMembreEquipeDownload(projet: Projet, document: DocumentProjet): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'DOCUMENT_PRIVATE',
        nom: projet.id + '/' + document.structureId + '/cvs/' + document.id + '/' + document.nom,
      },
      { observe: 'response' }
    );
  }

  getValueForDocBDDUpload(path: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'PUT',
        typeBucket: 'DOCUMENT_PRIVATE',
        nom: path,
      },
      { observe: 'response' }
    );
  }

  getValueForDocDownloadFromPath(path: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'DOCUMENT_PRIVATE',
        nom: path,
      },
      { observe: 'response' }
    );
  }

  getValueForDocProjetDownload(path: string, typeUpload: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: typeBucket,
        nom: path,
      },
      { observe: 'response' }
    );
  }

  deletDocStructureUpload(
    path: string,
    typeUpload: string,
    documentId: string,
    projetId: string
  ): Observable<HttpResponse<SignedUrlResponseModel>> {
    const typeBucket = DocumentHelper.getDocumentBucketType(typeUpload);
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'DELETE',
        typeBucket: typeBucket,
        nom: path,
        metadata: {
          type: typeUpload,
          idProjet: projetId,
          idDocument: documentId,
        },
      },
      { observe: 'response' }
    );
  }

  public uploadDocumentToS3(
    documentProjet: DocumentProjet,
    typeUpload: string,
    typeDocument: string,
    file: File
  ): Observable<HttpResponse<void>> {
    const folderPath = DocumentHelper.getDocumentS3FolderPath(documentProjet);
    return this.createS3SignedUrl(documentProjet, folderPath, typeUpload, typeDocument).pipe(
      switchMap((signedUrl: string) =>
        this.uploadDoc(signedUrl, file, documentProjet.projetId, documentProjet.id, typeUpload, typeDocument)
      )
    );
  }

  uploadDoc(url: string, file: File, projetId: string, documentId: string, typeUpload: string, typeDocument?: string): Observable<any> {
    const httpHeader = new HttpHeaders({
      'x-amz-meta-type': typeUpload,
      'x-amz-meta-idProjet': projetId,
      'x-amz-meta-idDocument': documentId,
      'x-amz-meta-typeDocument': typeDocument ? typeDocument : '',
    });

    return this.httpClient.put<any>(url, file, { observe: 'response', headers: httpHeader });
  }

  uploadDocBDD(url: string, file: File): Observable<HttpResponse<void>> {
    return this.httpClient.put<void>(url, file, { observe: 'response' });
  }

  getValueForDocRGPD(fichierRgpd: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.httpClient.post<SignedUrlResponseModel>(
      this._apiUrl + 'uploads/signed-url',
      {
        typeAction: 'GET',
        typeBucket: 'DOCUMENT_PUBLIC',
        nom: fichierRgpd,
      },
      { observe: 'response' }
    );
  }
}
