import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@shared-ui';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { OrganismesTableauComponent } from './organismes-tableau.component';

@NgModule({
  declarations: [OrganismesTableauComponent],
  imports: [CommonModule, SharedUiModule, MatTableModule, MatCheckboxModule, MatSortModule],
  exports: [OrganismesTableauComponent],
})
export class OrganismesTableauModule {}
