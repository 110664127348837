<div class="page-content">
  <a class="page-header__back" (click)="goToDepensesProjets()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Dépenses Projets
  </a>
  <lib-card-title [title]="'Paramétrer le référentiel des régimes d\'aide'"> </lib-card-title>
  <section class="page-section" *ngIf="showForm">
    <!-- Contenu de la page -->
    <div class="form-step" *ngIf="form">
      <div class="mb-4">
        <h4>{{ isModification ? "Modification d'un régime d'aide" : "Création d'un nouveau régime d'aide" }}</h4>
        Saisir tous les champs suivants pour créer votre critère. Les champs avec
        <lib-asterisk></lib-asterisk> sont obligatoire.
      </div>

      <form class="form" [formGroup]="form">
        <div class="d-flex flex-wrap">
          <div class="ds-input-group w-100 p-2" [class.ds-input-group--error]="!form.controls?.nom?.valid && form.controls.nom.touched">
            <label for="aide" class="ds-input-group__label">Régime d'aide<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="nom"
                name="nom"
                type="text"
                class="ds-input-group__input"
                formControlName="nom"
                placeholder="Nom du régime d'aide"
              />
            </span>
          </div>

          <div class="w-100 d-flex">
            <ng-container *ngIf="!form.valid && submitClicked">
              <div class="ds-input-group ds-input-group--error w-100">
                <div class="ds-input-group__feedback">Merci de renseigner le champs entouré en rouge.</div>
              </div>
            </ng-container>

            <div class="w-100 d-flex justify-content-end mt-5">
              <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="goToDepensesProjets()">
                <span>Annuler</span>
              </button>
              <button type="submit" class="ds-btn ds-btn--primary" (click)="saveRegimeAide()">
                <span>Enregistrer</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
