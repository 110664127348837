<!-- Entête de la page -->
<div id="header-structure" class="page-header">
  <a class="page-header__back" (click)="onGoBack()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Instruction
  </a>
  <h2 class="page-header__title">Ma notation</h2>
</div>
<!-- FIN de Entête de la page -->
<!-- Contenu de la page -->
<div class="page-content" *ngIf="instructionForm">
  <div class="mb-4">Vous devez renseigner toutes les informations avec<span class="ds-input-group__label-asterisk"> *</span></div>
  <form id="projet-notation-form" class="form" [formGroup]="instructionForm" (ngSubmit)="modifyNotation()">
    <!-- Avis a conditionner en fonction de l'aap -->
    <section class="form-step notation-form">
      <div class="form-step__content" *ngIf="aap?.notationInstructionTypeNote">
        <!-- Avis du projet -->
        <lib-notation-avis-item
          [notationForm]="instructionForm"
          [typeNotation]="getNotationType()"
          [isMandatory]="false"
        ></lib-notation-avis-item>
      </div>

      <div id="" class="form-step__content mb-3">
        <label class="ds-input-group__label input_required mb-2"> Proposition grand prix<lib-asterisk></lib-asterisk></label>
        <div>
          <input
            type="radio"
            name="propositionGrandPrix"
            id="proposition-grand-prix-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="propositionGrandPrix"
          />
          <label for="eval-proposition-grand-prix-true" class="ds-radio-group__radio-label me-3">Oui</label>

          <input
            type="radio"
            name="propositionGrandPrix"
            id="proposition-grand-prix-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="propositionGrandPrix"
          />
          <label for="eval-proposition-grand-prix-false" class="ds-radio-group__radio-label">Non</label>
        </div>
        <ng-container
          *ngIf="instructionForm.controls['propositionGrandPrix'].invalid && instructionForm.controls['propositionGrandPrix'].touched"
        >
          <div class="ds-input-group__feedback error-message">Merci de sélectionner si vous attribuez un grand prix au projet</div>
        </ng-container>
      </div>

      <div class="form-step__content">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            !instructionForm?.controls['commentaire'].valid && instructionForm?.controls['commentaire'].touched
          "
        >
          <label for="demande_commentaire" class="ds-input-group__label input_required">Commentaire</label>
          <textarea
            id="demande_commentaire"
            name="commentaire"
            rows="5"
            col="0"
            maxlength="2500"
            class="ds-input-group__textarea"
            formControlName="commentaire"
            type="text"
            placeholder="Vous pouvez saisir un commentaire"
            #commentaireInput
          ></textarea>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="commentaireInput.value?.length === 2500">
            <span>{{ commentaireInput.value?.length || 0 }}/2500</span>
          </div>
        </div>
      </div>

      <div class="form-step__content">
        <div
          class="ds-input-group"
          [class.ds-input-group--error]="
            !instructionForm?.controls['recommandationIntentionCandidat'].valid &&
            instructionForm?.controls['recommandationIntentionCandidat'].touched
          "
        >
          <label for="demande_recommandation" class="ds-input-group__label input_required"> Recommandation à l'intention du candidat</label>
          <textarea
            id="demande_recommandation"
            name="recommandationIntentionCandidat"
            rows="5"
            col="0"
            maxlength="2500"
            class="ds-input-group__textarea"
            formControlName="recommandationIntentionCandidat"
            type="text"
            placeholder="Vous pouvez saisir une recommandation"
            #recommandationInput
          ></textarea>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="recommandationInput.value?.length === 2500">
            <span>{{ recommandationInput.value?.length || 0 }}/2500</span>
          </div>
        </div>
      </div>
    </section>

    <div class="form-actions">
      <button id="modification_projet_btn_annuler" type="button" class="ds-btn ds-btn--secondary" (click)="onGoToNotationEval()">
        Annuler
      </button>
      <button id="modification_projet_btn_ajouter" type="submit" class="ds-btn ds-btn--primary">Enregistrer</button>
    </div>
  </form>
</div>
<!-- FIN de Contenu de la page -->
