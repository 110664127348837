<div class="step">
  <!-- Info Structure -->
  <form id="parcours-form" class="form" [formGroup]="parcoursForm">
    <div *ngIf="page === 1 && !isAapMultiMandatory && !isAapIndivMandatory">
      <step-type-partenaire [partenaireForm]="parcoursForm.controls['partenaire']" (changePage)="changePage($event)"></step-type-partenaire>
    </div>

    <div
      id="content-page-role"
      *ngIf="(page === 1 && (isAapMultiMandatory || isAapIndivMandatory)) || (page === 2 && !isAapMultiMandatory && !isAapIndivMandatory)"
    >
      <step-choix-role [form]="parcoursForm" (changePage)="changePage($event)"></step-choix-role>
    </div>

    <div
      id="content-page-consortium"
      *ngIf="(page === 2 && (isAapMultiMandatory || isAapIndivMandatory)) || (page === 3 && !isAapMultiMandatory && !isAapIndivMandatory)"
    >
      <step-identification-structure
        [isAapPersonnePhysique]="isAapPersonnePhysique"
        [structureForm]="buildInfoStructureForm()"
        (structureEnCreationChange)="onStructureEnCreationChange($event)"
        (structureIdentifiedByRET)="structureIdentifiedByRET = $event"
        (changePage)="changePage($event)"
      ></step-identification-structure>
    </div>
    <!-- Identification Structure -->

    <!-- Info Structure -->
    <div
      id="content-page-adresse"
      *ngIf="(page === 3 && (isAapMultiMandatory || isAapIndivMandatory)) || (page === 4 && !isAapMultiMandatory && !isAapIndivMandatory)"
    >
      <step-information-structure
        [structureForm]="parcoursForm.get('infoStructure')"
        [structureEnCreation]="structureEnCreation"
        [structureIdentifiedByRET]="structureIdentifiedByRET"
        (changePage)="changePage($event)"
      ></step-information-structure>
    </div>
    <!-- Info Structure -->

    <div
      id="content-page-contact"
      *ngIf="(page === 4 && (isAapMultiMandatory || isAapIndivMandatory)) || (page === 5 && !isAapMultiMandatory && !isAapIndivMandatory)"
    >
      <step-contact
        [contacts]="parcoursForm.get('contacts')"
        [aap]="aap"
        (submit)="submitForm()"
        (changePage)="changePage($event)"
      ></step-contact>
    </div>
  </form>
</div>
