import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DepensesProjetConfig, ReferentielHttpService, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';
import { Observable, takeUntil } from 'rxjs';
import { CreationRegimesAidesHelperService } from './creation-regimes-aides.helper.service';

@Component({
  selector: 'app-creation-regimes-aides',
  templateUrl: './creation-regimes-aides.component.html',
  styleUrls: ['./creation-regimes-aides.component.scss'],
})
export class CreationRegimesAidesComponent extends SubscriptionDestroyerComponent implements OnInit {
  submitClicked = false;
  form!: FormGroup<any>;

  valueRegimeReferentiel: string | null | undefined;
  showForm = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.form.dirty;
  }

  isModification = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public helperService: CreationRegimesAidesHelperService,
    public referentielService: ReferentielHttpService,
    public showToastrService: ShowToastrService
  ) {
    super();
    this.valueRegimeReferentiel = this.router.getCurrentNavigation()?.extras?.state?.regime.nom;
    this.isModification = this.valueRegimeReferentiel !== undefined && this.valueRegimeReferentiel !== null;
  }

  ngOnInit(): void {
    this.form = this.helperService.createForm();
    this.getReferentiel();
    this.showForm = true;
  }

  private getReferentiel() {
    this.referentielService
      .getReferentielDepensesProjetConfig()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (depenses: HttpResponse<DepensesProjetConfig>) => {
          this.patchForm(depenses.body);
          this.showForm = true;
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);
        },
      });
  }

  handleError(err: HttpErrorResponse) {
    this.showToastrService.checkCodeError(err?.error);
  }

  private patchForm(depenses: DepensesProjetConfig) {
    this.form.addControl('regimesAide', new FormControl(depenses.regimesAide));
    this.form.addControl('pourcentageSolde', new FormControl(depenses.tauxConfig.pourcentageSolde));
    this.form.addControl('tauxCE', new FormControl(depenses.tauxConfig.tauxCE));
    this.form.addControl('differeRemboursement', new FormControl(depenses.tauxConfig.differeRemboursement));
    this.form.addControl('versementMinimum', new FormControl(depenses.tauxConfig.versementMinimum));
    this.form.addControl('tauxPetiteEntreprise', new FormControl(depenses.premierVersementConfig.tauxPetiteEntreprise));
    this.form.addControl('tauxMoyenneEntreprise', new FormControl(depenses.premierVersementConfig.tauxMoyenneEntreprise));
    this.form.addControl('tauxEntrepriseIntermediaire', new FormControl(depenses.premierVersementConfig.tauxEntrepriseIntermediaire));
    this.form.addControl('tauxGrandeEntreprise', new FormControl(depenses.premierVersementConfig.tauxGrandeEntreprise));
    this.form.addControl('tauxLaboratoireRecherche', new FormControl(depenses.premierVersementConfig.tauxLaboratoireRecherche));
    this.form.patchValue({ nom: this.valueRegimeReferentiel });
  }

  saveRegimeAide(): void {
    this.submitClicked = true;
    if (this.form.valid) {
      const config: DepensesProjetConfig = this.helperService.createReferentielFromForm(this.form, this.isModification, this.valueRegimeReferentiel);
      this.saveReferentielFromForm(config);
    }
  }

  saveReferentielFromForm(depensesProjetConfig:DepensesProjetConfig): void {
    this.referentielService
      .saveReferentielDepensesProjets(depensesProjetConfig)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: () => {
          this.showToastrService.success(`Votre régime d'aide a bien été ${this.isModification ? 'modifié' : 'créé'} avec succès`);
          this.form.markAsPristine();
          this.goToDepensesProjets();
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);
        },
      });
  }

  goToDepensesProjets(): void {
    this.router.navigate([`../${this.route.snapshot.data.referentielPath}`], { relativeTo: this.route });
  }
}
