import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ModalData {
  title: string;
  description: string;
  textReturnButton: string;
  textGoButton: string;
}

@Component({
  selector: 'pxl-lier-dossier-commercial-modal',
  templateUrl: './lier-dossier-commercial-modal.component.html',
  styleUrls: ['./lier-dossier-commercial-modal.component.scss'],
})
export class LierDossierCommercialModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    public dialogRef: MatDialogRef<LierDossierCommercialModalComponent>,
    private formBuilder: UntypedFormBuilder
  ) {}

  lierForm: UntypedFormGroup;

  get formNumero(): UntypedFormControl {
    return this.lierForm.get('numero').value;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.lierForm = this.formBuilder.group({
      numero: [null, Validators.required],
    });
  }

  goToDc() {
    this.dialogRef.close(this.formNumero);
  }

  onkeypress($event: KeyboardEvent): boolean {
    if ($event.key) {
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
  }
}
