import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@environments-evaluateur/environment';
import { MultiStatusResponse } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class ReferentielHttpService {
  constructor(private httpClient: HttpClient) {}

  postKpisReferentielCsv(filePath: string): Observable<HttpResponse<MultiStatusResponse>> {
    return this.httpClient.post<MultiStatusResponse>(
      environment.apiUrl + `kpis/csv`,
      { filePath },
      {
        observe: 'response',
      }
    );
  }
}
