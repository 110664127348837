import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ComiteService } from '@services-evaluateur/comite.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import {
  Aap,
  Comite,
  ConfirmModalComponent,
  EnumStatutComite,
  FilterDataInterface,
  FilterDateReleve,
  SearchComitesFilter,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
  enumValues,
} from '@shared-ui';
import { AapService } from '@services-evaluateur/aap.service';
import { catchError, forkJoin, of } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'pxl-comites-list',
  templateUrl: './comites.list.component.html',
  styleUrls: ['./comites.list.component.scss'],
})
export class ComitesListComponent extends SubscriptionDestroyerComponent implements OnInit {
  comites: Comite[];
  aaps: Aap[];
  mesComites: Comite[];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selection = new SelectionModel<Comite>(true, []);
  toggleLabels: string[];
  comiteToggleState = false;
  canShowFilter = false;

  aapFilterList: FilterDataInterface[] = [];
  dateReleveFilterList: FilterDataInterface[] = [];
  comiteCreatorFilterList: FilterDataInterface[] = [];

  constructor(
    public comiteService: ComiteService,
    public aapService: AapService,
    private showToastrService: ShowToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public matDialog: MatDialog,
    public userService: UtilisateurService,
    private datePipe: DatePipe
  ) {
    super();
    this.toggleLabels = enumValues(ToggleValues);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadComites(filter?: SearchComitesFilter) {
    this.selection?.clear();
    this.comiteService
      .getComites(filter)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<Comite[]>) => {
          this.comites = response.body ?? [];
          if (!filter) {
            this.updateMesComitesArray();
            this.comiteToggleState = this.mesComites?.length > 0;
          }
          this.updateComiteDataSource();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  updateMesComitesArray(): void {
    this.userService
      .getUtilisateurObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe((utilisateur: Utilisateur) => {
        if (utilisateur) {
          this.mesComites = this.getMesComites(utilisateur);
        }
      });
  }

  loadData() {
    this.selection?.clear();
    forkJoin({
      aaps: this.aapService.getAaps(),
      comites: this.comiteService.getComites(),
    })
      .pipe(catchError(error => of(error)))
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: result => {
          this.handleComitesResponse(result.comites);
          this.handleAapsResponse(result.aaps);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
        complete: () => {
          this.canShowFilter = true;
        },
      });
  }

  handleAapsResponse(response: HttpResponse<Aap[]>) {
    if (response) {
      this.aaps = response.body;
      this.updateAapFilterList();
      this.updateDateReleveFilterList();
    }
  }

  handleComitesResponse(response: HttpResponse<Comite[]>) {
    if (response) {
      this.comites = response.body;
      this.updateComiteCreatorFilterList();
      this.userService
        .getUtilisateurObservable()
        .pipe(this.takeUntilDestroyed())
        .subscribe((utilisateur: Utilisateur) => {
          if (utilisateur) {
            this.mesComites = this.getMesComites(utilisateur);
          }
        });
    }
    this.comiteToggleState = this.mesComites?.length > 0;
    this.updateComiteDataSource();
  }

  searchEventCalled(event: SearchComitesFilter): void {
    this.loadComites(event);
  }

  updateComiteCreatorFilterList() {
    if (!this.comites) {
      this.comiteCreatorFilterList = [];
      return;
    }

    const _fullComitesList = this.comites
      .map(comite => comite.auteur)
      .filter(auteur => !!auteur)
      .map(auteur => {
        return <FilterDataInterface>{
          id: auteur?.matricule,
          value: `${auteur?.prenom} ${auteur?.nom}`,
          isSelected: false,
        };
      });
    this.comiteCreatorFilterList = [...new Map(_fullComitesList.map(item => [item['id'], item])).values()];
  }

  updateAapFilterList(): void {
    if (!this.aaps) {
      this.aapFilterList = [];
      return;
    }

    this.aapFilterList = this.aaps.map(aap => {
      return {
        id: aap.id,
        value: aap.code,
        isSelected: false,
      };
    });
  }

  updateDateReleveFilterList(): void {
    if (!this.aaps) {
      this.dateReleveFilterList = [];
      return;
    }

    const _dateReleveFilterList = this.aaps
      .flatMap(aap => {
        if (!aap || !aap.dateAutres) {
          return [];
        }

        return aap.dateAutres
          .filter(dateAutre => dateAutre && 'RELEVE' === dateAutre.type)
          .map(dateAutre => {
            return <FilterDataInterface>{
              id: {
                dateReleve: dateAutre.valeur,
                idAap: aap.id,
              },
              value: `${this.datePipe.transform(dateAutre.valeur, 'dd/MM/yyyy')} (${aap.code})`,
              isSelected: false,
            };
          });
      })
      .reduce((accumulator: any, filterDataInterface: FilterDataInterface) => {
        if (!accumulator[filterDataInterface.value]) {
          accumulator[filterDataInterface.value] = {
            id: { idAap: (<FilterDateReleve>filterDataInterface.id).idAap, datesReleves: [] },
            value: filterDataInterface.value,
            isSelected: false,
          };
        }

        accumulator[filterDataInterface.value].id.datesReleves.push((<FilterDateReleve>filterDataInterface.id).dateReleve);

        return accumulator;
      }, {});
    this.dateReleveFilterList = Object.values(_dateReleveFilterList ?? {});
  }

  private updateComiteDataSource() {
    if (this.comiteToggleState) {
      this.dataSource = new MatTableDataSource<Comite>(this.mesComites);
    } else {
      this.dataSource = new MatTableDataSource<Comite>(this.comites);
    }
  }

  getMesComites(user: Utilisateur): Comite[] {
    return this.comites.filter(comite => {
      return (
        comite.auteur?.matricule == user.matricule ||
        comite.instructeurs?.filter(instructeur => instructeur.actif && instructeur.matricule === user.matricule)?.length > 0
      );
    });
  }

  onChangeToggle($event: string): void {
    this.comiteToggleState = $event === ToggleValues.MES_COMITES;
    this.updateComiteDataSource();
    this.selection?.clear();
  }

  goToCreateComite(): void {
    this.router.navigate(['creation'], { relativeTo: this.route });
  }

  closeSelectedComites(): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un comité.');
      return;
    }
    const selectedBrouillon = this.selection.selected.find(selected => selected.statut === EnumStatutComite.BROUILLON);
    if (selectedBrouillon) {
      this.showToastrService.error('Dans la liste des comités sélectionnés, au moins un comité est au statut "Brouillon".');
      return;
    }
    this.confirmComitesClose();
  }

  private confirmComitesClose(): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Clôture des comités sélectionnés',
        textGoButton: 'Confirmer',
        description: `<p>En confirmant l'action, les comités sélectionnés vont être clôturés. Cette action est irreversible.<br>
        Confirmez-vous l’action ?</p>`,
        textReturnButton: 'Annuler',
      },
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe(result => {
        if (result) {
          this.doCloseComites();
        }
      });
  }

  private doCloseComites(): void {
    const projetsIds: string[] = this.selection.selected.map(comite => comite.id);
    this.comiteService
      .closeComites(projetsIds)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.loadComites();
          this.showToastrService.success('La demande de clôture des comités a été réalisée avec succès.');
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  openAutoAffectDialog(): void {
    if (!this.selection.selected?.length) {
      this.showToastrService.error('Vous devez sélectionner au moins un comité pour vous auto-affecter dessus.');
      return;
    }
    const title = 'Confirmez vous l’auto affectation';
    const description = `<p>En confirmant votre action, vous aurez des
    droits de modification et de gestion des décisions pour les comités sélectionnés.</p>`;

    this.openConfirmDialog(title, description)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.autoAffecter();
        }
      });
  }

  autoAffecter(): void {
    const comitesIdArray = this.selection.selected.map(comite => comite.id);
    this.comiteService
      .autoAffectInstructeur(comitesIdArray)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.showToastrService.success("L'opération d'affectation a été appliquée avec succès.");
          this.comiteToggleState = true;
          this.loadComites();
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  openAutoDesaffectDialog(): void {
    let showDialog = true;
    const title = 'Confirmez vous l’auto désaffectation';
    const description =
      '<p>En confirmant votre action, vous perdrez votre rôle sur les comités sélectionnés et les permissions associées.</p>';

    if (!this.selection.selected?.length) {
      this.showToastrService.error('Vous devez sélectionner au moins un comité pour vous auto-désaffecter dessus.');
      return;
    }
    this.userService
      .getUtilisateurObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe((utilisateur: Utilisateur) => {
        if (utilisateur) {
          for (const comite of this.selection.selected) {
            if (comite.auteur.email === utilisateur.email) {
              this.showToastrService.error("Vous ne pouvez pas vous désaffecter d'un comité que vous avez créé");
              showDialog = false;
              return;
            }
          }
        }
      });
    if (showDialog) {
      this.openConfirmDialog(title, description)
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.autoDesaffecter();
          }
        });
    }
  }

  autoDesaffecter(): void {
    const comitesIdArray = this.selection.selected.map(comite => comite.id);
    this.comiteService
      .autoDesaffectInstructeur(comitesIdArray)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.showToastrService.success("L'opération de désaffectation a été appliquée avec succès.");
          this.comiteToggleState = true;
          this.loadComites();
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  openConfirmDialog(title: string, description: string): MatDialogRef<ConfirmModalComponent, any> {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: title,
        description: description,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });
    return dialogRef;
  }

  protected readonly ToggleValues = ToggleValues;
}

enum ToggleValues {
  TOUS_COMITES = 'Tous les comités',
  MES_COMITES = 'Mes comités',
}
