<div class="page-content">
  <a class="page-header__back" (click)="goToIntervenant()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Intervenants
  </a>
  <lib-card-title [title]="'Paramétrer le référentiel des rôles'"> </lib-card-title>
  <section class="page-section">
    <!-- Contenu de la page -->
    <div class="form-step" *ngIf="form">
      <div class="mb-4">
        <h4>Création d'un nouveau rôle</h4>
        Saisir tous les champs suivants pour créer votre rôle. Les champs avec
        <span class="ds-input-group__label-asterisk"> *</span> sont obligatoire.
      </div>

      <form class="form" [formGroup]="form" (ngSubmit)="saveIntervenant()">
        <div class="d-flex flex-wrap">
          <div class="ds-input-group w-50 p-2" [class.ds-input-group--error]="!form.controls.nom.valid && form.controls.nom.touched">
            <label for="nom" class="ds-input-group__label">Nom</label><span class="ds-input-group__label-asterisk"> *</span>
            <span class="ds-input-group__input">
              <input id="nom" name="nom" type="text" class="ds-input-group__input" formControlName="nom" placeholder="Nom" />
            </span>
            <ng-container id="error-nom" *ngIf="!form.controls['nom'].valid && form.controls['nom'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom</div>
            </ng-container>
          </div>

          <div class="ds-input-group w-50 p-2" [class.ds-input-group--error]="!form.controls.prenom.valid && form.controls.prenom.touched">
            <label for="prenom" class="ds-input-group__label">Prenom</label><span class="ds-input-group__label-asterisk"> *</span>
            <span class="ds-input-group__input">
              <input id="prenom" name="prenom" type="text" class="ds-input-group__input" formControlName="prenom" placeholder="Prenom" />
            </span>
            <ng-container id="error-prenom" *ngIf="!form.controls['prenom'].valid && form.controls['prenom'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un prenom</div>
            </ng-container>
          </div>

          <div
            class="ds-input-group w-50 p-2"
            [class.ds-input-group--error]="!form.controls.matricule.valid && form.controls.matricule.touched"
          >
            <label for="matricule" class="ds-input-group__label">Matricule</label><span class="ds-input-group__label-asterisk"> *</span>
            <span class="ds-input-group__input">
              <input
                id="matricule"
                name="matricule"
                type="text"
                class="ds-input-group__input"
                formControlName="matricule"
                placeholder="Matricule"
              />
            </span>
            <ng-container id="error-matricule" *ngIf="!form.controls['matricule'].valid && form.controls['matricule'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un matricule</div>
            </ng-container>
          </div>

          <div class="ds-input-group w-50 p-2" [class.ds-input-group--error]="!form.controls.role.valid && form.controls.role.touched">
            <label for="aide" class="ds-input-group__label">Rôle</label><span class="ds-input-group__label-asterisk"> *</span>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="role" name="role" formControlName="role" (change)="roleChangeEvent()">
                <option [ngValue]="null" selected disabled hidden>Sélectionner un rôle</option>
                <option *ngFor="let item of roles.all()" [value]="item">{{ roles.toString(item) }}</option>
              </select>
            </div>
            <ng-container id="error-matricule" *ngIf="!form.controls['role'].valid && form.controls['role'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un rôle</div>
            </ng-container>
          </div>

          <div class="ds-input-group w-50 p-2" [class.ds-input-group--error]="!form.controls.email.valid && form.controls.email.touched">
            <label for="email" class="ds-input-group__label">Email<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="email"
                name="email"
                type="text"
                maxlength="80"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                placeholder="contact@role.com"
                class="input_email ds-input-group__input"
                formControlName="email"
              />
            </span>
            <ng-container id="error-email" *ngIf="!form.controls['email'].valid && form.controls['email'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un mail valide</div>
            </ng-container>
          </div>

          <div
            class="ds-input-group w-50 p-2"
            [class.ds-input-group--error]="!form.controls.telephone.valid && form.controls.telephone.touched"
          >
            <label for="telephone" class="ds-input-group__label">Téléphone<lib-asterisk *ngIf="telephoneRequired"></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                (blur)="onBlur()"
                id="telephone"
                name="telephone"
                type="text"
                maxlength="20"
                formControlName="telephone"
                placeholder="Téléphone"
                class="input_telephone ds-input-group__input"
              />
            </span>
            <ng-container id="error-telephone" *ngIf="!form.controls['telephone'].valid && form.controls['telephone'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un numéro de téléphone valide</div>
            </ng-container>
          </div>

          <div class="w-100 d-flex">
            <ng-container *ngIf="!form.valid && submitClicked">
              <div class="ds-input-group ds-input-group--error w-100">
                <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
              </div>
            </ng-container>

            <div class="w-100 d-flex justify-content-end">
              <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="goToIntervenant()">
                <span>Annuler</span>
              </button>
              <button type="submit" class="ds-btn ds-btn--primary">
                <span>Enregistrer</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
