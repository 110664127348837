<!-- Section filtres projets -->
<pxl-project-search-bar-comites
  *ngIf="showSearchBar"
  [phase]="phase"
  [programmeDataList]="programmesFilter"
  [programmes]="programmes"
  (searchEventEmitter)="loadProjects($event)"
></pxl-project-search-bar-comites>
<!-- Fin section filtres projets -->

<!-- Component de tableau de projets -->
<div class="tableau-container mt-3">
  <div class="ds-input-group__label">
    Projets sélectionnés pour le comité
    <lib-asterisk />
  </div>
  <div class="table-container">
    <pxl-tableau-projet
      [(selection)]="selection"
      [displayedColumns]="displayedColumns"
      [(data)]="projetTableData"
      [autoSelectAll]="true"
      (elementClicked)="goToComiteProjet($event)"
    >
    </pxl-tableau-projet>
  </div>
</div>
<!-- Fin component de tableau de projets -->
