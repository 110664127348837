import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrganismeModel } from '@shared-ui';

@Component({
  selector: 'pxl-sidenav-creation-organisme',
  templateUrl: './sidenav.creation.organisme.component.html',
  styleUrls: ['./sidenav.creation.organisme.component.scss'],
})
export class SidenavCreationComponent {
  @Output() anchorCall = new EventEmitter<string>();
  @Input() organisme: OrganismeModel = new OrganismeModel();
  currentSection = 'anchor1';

  goToSection(index: string): void {
    this.anchorCall.emit(index);
  }
}
