<div class="filters-container">
  <div class="accordion-container">
    <div class="search-container">
      <div class="filter-row" id="first-row">
        <div class="single-filter-container">
          <custom-multiselect
            #acronymeComponent
            (autocompleteEvent)="autocompleteEvent($event, ACRONYME)"
            (selectedListChange)="selectedListChange($event, ACRONYME)"
            [(dataList)]="acronymesDataList"
            [selectedList]="acronymesSelectedList"
            placeholder="Acronyme projet"
            name="Acronyme"
            class="filter"
            [isLoading]="loadingMap.get(ACRONYME)"
          >
          </custom-multiselect>
        </div>
        <div class="single-filter-container">
          <custom-multiselect
            #rsSiretComponent
            (autocompleteEvent)="autocompleteEvent($event, RS_SIRET)"
            (selectedListChange)="selectedListChange($event, RS_SIRET)"
            [(dataList)]="rsSiretDataList"
            [selectedList]="rsSiretSelectedList"
            placeholder="Raison sociale ou SIREN"
            name="raison sociale ou siren"
            class="filter"
            [isLoading]="loadingMap.get(RS_SIRET)"
          >
          </custom-multiselect>
        </div>
      </div>
      <div class="filter-row" id="second-row" [ngClass]="{ 'hidden-row': !accordionOpen }">
        <div class="single-filter-container">
          <lib-ds-multiselect
            class="filter"
            #thematiqueComponent
            [dataList]="thematiqueDataList"
            [selectOptions]="{ placeholder: 'Thématiques' }"
            [name]="'comite-thematique-filter'"
          >
          </lib-ds-multiselect>
        </div>
        <div class="single-filter-container">
          <lib-ds-multiselect
            class="filter"
            #dateReleveComponent
            [dataList]="dateReleveDataList"
            [selectOptions]="{ placeholder: 'Relève' }"
            [name]="'comite-releve-filter'"
          >
          </lib-ds-multiselect>
        </div>
        <div class="single-filter-container">
          <lib-ds-multiselect
            class="filter"
            #etapeComponent
            [dataList]="etapeDataList"
            [selectOptions]="{ placeholder: 'Etape' }"
            [name]="'comite-etape-filter'"
          >
          </lib-ds-multiselect>
        </div>
      </div>
      <div class="filter-row" id="third-row" [ngClass]="{ 'hidden-row': !accordionOpen }">
        <div class="single-filter-container w-30">
          <lib-ds-multiselect
            class="filter"
            #statutComponent
            [dataList]="statutDataList"
            [selectOptions]="{ placeholder: 'Statut' }"
            [name]="'comite-statut-filter'"
          >
          </lib-ds-multiselect>
        </div>
        <div class="single-filter-container w-30">
          <lib-ds-multiselect
            class="filter"
            #regionAdministrativeComponent
            [dataList]="regionDataList"
            [selectOptions]="{ placeholder: 'Région administrative' }"
            [name]="'comite-region-administrative-filter'"
          >
          </lib-ds-multiselect>
        </div>
        <div class="single-filter-container w-40">
          <lib-ds-multiselect
            class="filter"
            #drDlComponent
            [dataList]="centreMesureDataList"
            [selectOptions]="{ placeholder: 'DR / DL Bpifrance' }"
            [name]="'comite-dr-dl-filter'"
          >
          </lib-ds-multiselect>
        </div>
      </div>
    </div>

    <div class="search-buttons-container">
      <button class="ds-btn ds-btn--tertiary" (click)="toggleFilters()">
        {{ accordionOpen ? 'Moins de critères' : 'Plus de critères' }}
      </button>
      <button type="button" class="ds-btn ds-btn--secondary" (click)="resetAllFilters()">
        <span class="ds-icon ds-icon-left ds-float--left" aria-hidden="true">refresh</span>
        Réinitialiser
      </button>
      <button id="actions-btn" class="ds-btn ds-btn--primary" (click)="submitSearch()">Rechercher</button>
    </div>
  </div>
</div>
