import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CritereReferentiel, CritereReferentielAap, CritereReferentielAapForm } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class CriteresReferentielHelperService {
  buildFormArray(referentiel: CritereReferentiel[], criteres: CritereReferentielAap[]): FormArray {
    const formArray = new FormArray(
      referentiel.map(ref => {
        const critere = criteres?.find(critere => critere.id === ref.id);

        return new FormGroup<CritereReferentielAapForm>({
          id: new FormControl(ref.id),
          identifiantCR: new FormControl(ref.identifiantCR),
          label: new FormControl(ref.label),
          isChecked: new FormControl(critere != null),
          phases: new FormControl(critere?.phases ?? [], this.minSelectedCheckboxes()),
          ordre: new FormControl(critere?.ordre, Validators.required),
        });
      })
    );
    return formArray;
  }

  minSelectedCheckboxes(): ValidatorFn {
    return (formControl: AbstractControl) => {
      const totalSelected = formControl.value.length;
      return totalSelected >= 1 ? null : { required: true };
    };
  }
}
