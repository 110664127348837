import { NgModule } from '@angular/core';
import { SharedUiModule } from '@shared-ui';
import { ComiteProjetsListFilterComponent } from '@features-evaluateur/comites/comites-list/comite-projets-list/comite-projets-list-filter/comite-projets-list-filter.component';
import { CustomMultiSelectModule } from '@shared-evaluateur/components/multiselect/multiselect.module';
import { SharedModule } from '@shared-evaluateur/shared.module';

@NgModule({
  declarations: [ComiteProjetsListFilterComponent],
  imports: [SharedUiModule, CustomMultiSelectModule, SharedModule, SharedUiModule],
  exports: [ComiteProjetsListFilterComponent],
})
export class ComiteProjetsListFilterModule {}
