<h2 id="modal-title" class="modal__title font-weight-bold">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="affectationForm" (ngSubmit)="onSubmit()">
  <div class="ds-input-group" [class.ds-input-group--error]="(!emails.valid && emails.touched) || !isEmailsValid">
    <label for="emails" class="ds-input-group__label bold">
      Emails des intervenants (séparés par des points-virgules) <span class="ds-input-group__label-asterisk">*</span>
    </label>
    <textarea
      id="emails"
      name="emails"
      type="text"
      [maxlength]="EMAILS_INPUT_SIZE_MAX"
      placeholder="Ex: example@mail.fr; example2@mail.fr"
      formControlName="emails"
      class="input_email ds-input-group__textarea"
    ></textarea>
    <div class="form-input__length-hint" [class.form-input__length-hint--full]="emails.value?.length === EMAILS_INPUT_SIZE_MAX">
      <span>{{ emails.value?.length || 0 }} / {{ EMAILS_INPUT_SIZE_MAX }}</span>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-email" *ngIf="(!emails.valid && emails.touched) || !isEmailsValid">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner des emails valides.</div>
    </ng-container>
  </div>

  <div class="ds-input-group role-container ds-input-group--error">
    <label for="role" class="ds-input-group__label bold">
      Niveau de visibilité des éléments sur les projets <span class="ds-input-group__label-asterisk">*</span>
    </label>
    <div id="role">
      <div>
        <input type="radio" id="etat" name="role" value="EVALUATEUR_ETAT_EN_LECTURE_SEULE" formControlName="role" />
        <label for="etat">Etat</label>
      </div>
      <div>
        <input type="radio" id="autre" name="role" value="EVALUATEUR_NON_ETAT_EN_LECTURE_SEULE" formControlName="role" />
        <label for="autre">Autre Evaluateur</label>
      </div>
    </div>

    <ng-container id="etat-role" *ngIf="role?.value === 'EVALUATEUR_ETAT_EN_LECTURE_SEULE'">
      <p>
        La sélection de "Etat" permet aux utilisateurs affectés d'accéder en lecture seule sur les projets, aux mêmes informations qu'un
        superviseur.
      </p>
    </ng-container>
    <ng-container id="autre-role" *ngIf="role?.value === 'EVALUATEUR_NON_ETAT_EN_LECTURE_SEULE'">
      <p>
        La sélection de "Autre évaluateur" permet aux utilisateurs affectés d'accéder en lecture seule sur les projets, aux mêmes
        informations qu'un expert indépendant.
      </p>
    </ng-container>
    <ng-container id="error-role" *ngIf="role.errors?.required && role.invalid && (role.dirty || role.touched)">
      <div class="ds-input-group__feedback">Merci de sélectionner le niveau de visibilité.</div>
    </ng-container>
  </div>

  <div id="modal-btn" class="modal__actions">
    <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
      <lib-pxl-icon class="icon" icon="icon-action-close"></lib-pxl-icon> {{ modalData.textReturnButton }}
    </button>
    <button class="ds-btn ds-btn--primary" type="submit" cdkFocusInitial>
      <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon> {{ modalData.textGoButton }}
    </button>
  </div>
</form>
