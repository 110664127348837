// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const SITE_ADMIN = 'admin';
export const SITE_EVAL = 'eval';

const ISAM_DEV_API_BASE_URL = 'https://apiv2-nprd.cloud.bpifrance.fr/dev/pxl/interne/sig-dev/api/';
const LAMBDA_DEV_API_BASE_URL = 'https://pxl.dev.api-at.cloud.bpifrance.fr/main/v1/';
const getIsamBaseUrl: (isLambdaRequest?: boolean) => string = (isLambdaRequest?: boolean) => {
  if (isLambdaRequest) {
    return (window as any)['env']['apiUrl'] || LAMBDA_DEV_API_BASE_URL;
  }
  return (window as any)['env']['isamBaseApiUrl'] || ISAM_DEV_API_BASE_URL;
};

export const environment = {
  production: true,
  name: (window as any)['env']['name'],
  isLocal: (window as any)['env']['isLocal'],
  mockToken: (window as any)['env']['mockToken'],
  apiSuiviUrl: (window as any)['env']['apiSuiviUrl'],
  apiUrlLambda: getIsamBaseUrl(true),
  apiUrl: getIsamBaseUrl(),
  apiAdminUrl: `${getIsamBaseUrl()}admin/`,
  apiAdminUrlV2: `${getIsamBaseUrl()}v2/admin/`,
  apiNotif: 'http://localhost:8082/api/',
  stsServer: (window as any)['env']['stsServer'],
  authWellknownEndpoint: (window as any)['env']['authWellknownEndpoint'],
  clientId: (window as any)['env']['clientId'],
  docPublicBaseUrl: (window as any)['env']['docPublicBaseUrl'],
  docLogosPublicBaseUrl: (window as any)['env']['docLogosPublicBaseUrl'],
  espaceCandidat: (window as any)['env']['espaceCandidat'],
  site: (window as any)['env']['site'],
  stub: false,
  stubPrograms: false,
  stubAaps: false,
  stubProjets: false,
  stubAuth: false,
  stubDoc: false,
  stubUtilisateur: false,
  urlGDC: 'https://gdcoad-ihm.dev.apps.web.bpifrance.fr/gdc/#/dc',
  urlQlik: 'https://dataviz.qls.cloud.bpifrance.fr/saml/hub/stream/3c56ffbc-1da8-4e7f-acf8-094a229a1026',
  urlKLF: 'https://kyc.moa.bpi.flaminem.com/customer-file/file/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
