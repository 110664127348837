import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { ProjectStructureComponent } from './project.structure.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [ProjectStructureComponent],
  exports: [ProjectStructureComponent],
  providers: [],
})
export class ProjectStructureModule {}
