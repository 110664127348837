import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { SharedUiModule } from '@shared-ui';
import { CreationDocumentsAapComponent } from './creation-documents-aap.component';

@NgModule({
  declarations: [CreationDocumentsAapComponent],
  imports: [CommonModule, SharedModule, SharedUiModule],
})
export class CreationDocumentsAapModule {}
