import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganismeCreationComponent } from './organisme.creation.component';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { SharedUiModule } from '@shared-ui';
import { CustomMultiSelectModule } from '@shared-evaluateur/components/multiselect/multiselect.module';
import { SidenavCreationModule } from './component/sidenav-creation/sidenav.creation.organisme.module';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CustomMultiSelectModule, SidenavCreationModule, SharedModule, MaterialModule, SharedUiModule],
  declarations: [OrganismeCreationComponent],
  exports: [OrganismeCreationComponent],
})
export class OrganismeCreationModule {}
