import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@shared-ui';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { PhasesComponent } from '@features-evaluateur/aap-creation/component/phases/phases.component';
import { PhaseCardModule } from '@features-evaluateur/aap-creation/component/phases/phase-card/phase-card.module';

@NgModule({
  declarations: [PhasesComponent],
  imports: [CommonModule, PhaseCardModule, SharedUiModule, SharedModule],
  exports: [PhasesComponent],
})
export class PhasesModule {}
