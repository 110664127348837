import { KpiAapInterface } from '../interfaces/kpiAap.interface';
import { EnumScope } from "../enums/enum.scope";

export class KpiAap implements KpiAapInterface {
  clef: string;
  id: string;
  scope: EnumScope;

  constructor(clef: string, id: string, scope: EnumScope) {
    this.clef = clef;
    this.id = id;
    this.scope = scope;
  }
}
