import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjetService } from '@services-evaluateur/projet.service';
import { ShowToastrService } from '@shared-ui';

@Component({
  selector: 'pxl-download-archive',
  templateUrl: './download-archive.component.html',
})
export class DownloadArchiveComponent implements OnInit {
  private archiveId: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public projetService: ProjetService,
    private showToastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    this.archiveId = this.route.snapshot.params.id;

    this.projetService.downloadArchiveById(this.archiveId).subscribe({
      next: response => {
        const url = response.body.urlS3;
        window.open(url);
      },
      error: err => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }
}
