import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AapService } from '@services-evaluateur/aap.service';
import { Aap, EnumTypePartenaire } from '@shared-ui';
import { Subscription } from 'rxjs';
import { ParcoursCreationComponent } from './component/parcours-creation/parcours-creation.component';

@Component({
  selector: 'pxl-projet-parcours',
  templateUrl: './projet-parcours.component.html',
  styleUrls: ['./projet-parcours.component.scss'],
})
export class ProjetParcoursComponent implements OnInit, OnDestroy {
  page = 1;
  @ViewChild(ParcoursCreationComponent) parcoursCreation: ParcoursCreationComponent;
  aap: Aap;

  isAapMultiMandatory = true;
  isAapIndivMandatory = false;
  isAapPersonnePhysique = false;
  aapId: string;
  subscriptions: Subscription[] = [];
  structureEnCreation = false;

  constructor(private aapService: AapService, private route: ActivatedRoute) {
    this.aapId = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.aapService.getAapById(this.aapId).subscribe((response: any) => {
        if (response) {
          this.aap = response.body;
          this.isAapPersonnePhysique = this.aap.personnePhysique;

          if (this.aap.partenaire.type === EnumTypePartenaire.MULTI && !this.aap.partenaire.obligatoire) {
            this.isAapMultiMandatory = false;
            this.isAapIndivMandatory = false;
          } else if (this.aap.partenaire.type === EnumTypePartenaire.MULTI && this.aap.partenaire.obligatoire) {
            this.isAapMultiMandatory = true;
            this.isAapIndivMandatory = false;
          } else {
            this.isAapIndivMandatory = true;
            this.isAapMultiMandatory = false;
          }
        }
      })
    );
  }

  atPage(): void {
    this.page = this.parcoursCreation.page;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
