import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';
import { environment } from '@environments-evaluateur/environment';
import { ShowToastrService, Utilisateur } from '@shared-ui';
import { PxlOidcService } from './pxl-oidc.service';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class UtilisateurService {
  private userSubject = new BehaviorSubject(null);

  // CGUs
  private userSubjectForCGU = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient, private showToastrService: ShowToastrService, private oidcSecurityService: PxlOidcService) {}

  getUtilisateurObservable(): Observable<Utilisateur> {
    return this.userSubject.asObservable();
  }

  getUtilisateur(): Observable<Utilisateur> {
    return this.getUserInfos().pipe(
      map(response => response.body!),
      tap(user => {
        this.oidcSecurityService.getUserData().subscribe(userData => {
          user.firstName = userData?.firstName || user.prenom;
          user.lastName = userData?.lastName || user.nom;
          user.emailAddress = userData?.email || user.email;
        });
        this.setUserObservable(user);
        this.setUserObservableForCGU(user);
      }),
      catchError((err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        return throwError(() => err);
      })
    );
  }

  public setUserObservable(user: any): void {
    this.userSubject.next(user);
  }

  getUserInfos(): Observable<HttpResponse<Utilisateur>> {
    return this.httpClient
      .get<Utilisateur>(environment.apiUrl + 'utilisateurs', { observe: 'response' })
      .pipe(shareReplay({ refCount: true }));
  }

  public patchUserAapFavorites(aapIds: string[]): Observable<HttpResponse<Utilisateur>> {
    return this.httpClient.patch<Utilisateur>(environment.apiAdminUrl + 'utilisateurs/aaps-favoris', JSON.stringify(aapIds), {
      observe: 'response',
    });
  }

  // CGUs
  public setUserObservableForCGU(user: Utilisateur): void {
    this.userSubjectForCGU.next(user);
  }

  public getUserObservableForCGU(): Observable<Utilisateur> {
    return this.userSubjectForCGU.asObservable();
  }

  public putCguUser(): Observable<object> {
    return this.httpClient.post<any>(environment.apiUrl + 'utilisateurs/cgu', { observe: 'response' });
  }
}
