import { enumKeys } from '../../utils/enum-utils';

export enum EnumStatutScenario {
  ANALYSE_EN_COURS = 'ANALYSE_EN_COURS',
  PRET_POUR_CPMO = 'PRET_POUR_CPMO',
  PRET_POUR_CONTRACTUALISATION = 'PRET_POUR_CONTRACTUALISATION',
}

export namespace EnumStatutScenario {
  export function toString(type: EnumStatutScenario) {
    switch (type) {
      case EnumStatutScenario.ANALYSE_EN_COURS:
        return 'Analyse en cours';
      case EnumStatutScenario.PRET_POUR_CPMO:
        return 'Prêt pour CPMO';
      case EnumStatutScenario.PRET_POUR_CONTRACTUALISATION:
        return 'Prêt pour contractualisation';
    }
  }

  export function all(): EnumStatutScenario[] {
    return enumKeys(EnumStatutScenario) as unknown as EnumStatutScenario[];
  }
}
