<div id="card-info" class="card form-step" [formGroup]="formGroup">
  <mat-checkbox [id]="'checkbox__' + formGroup.controls.identifiantCR.value" class="example-margin" formControlName="isChecked">
    {{ formGroup.controls.label.value }}
  </mat-checkbox>

  <hr />

  <div class="d-flex justify-content-between align-items-center">
    <div id="input-activite" class="form-step__activite ds-input-group w-50" *ngIf="isNotation">
      <div class="ds-input-group__label">
        Phase
        <span class="ds-input-group__label-asterisk" *ngIf="!formGroup.controls.phases.disabled"> *</span>
      </div>
      <div class="select-container">
        <div class="activite-select">
          <ng-container *ngFor="let item of EnumCriteresReferentielOptions.all(); let i = index">
            <input
              formArrayName="phases"
              type="checkbox"
              name="phases"
              [id]="item + i"
              hidden
              [checked]="formPhases.value.includes(item)"
            />
            <label [for]="item" (click)="selectCheckbox(item)" [class.checkbox-disabled]="formGroup.controls.phases.disabled">{{
              EnumCriteresReferentielOptions.toString(item)
            }}</label>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="ds-input-group__select ds-input-group__select--with-icon" [ngClass]="{ 'w-50': isNotation, 'w-100': !isNotation }">
      <div class="toggle-title-container">
        <label [for]="'select__' + formGroup.controls.identifiantCR.value" class="ds-input-group__label"> Ordre </label>
        <span class="ds-input-group__label-asterisk" *ngIf="!formGroup.controls.ordre.disabled"> *</span>
      </div>
      <select
        [id]="'select__' + formGroup.controls.identifiantCR.value"
        [name]="'select__' + formGroup.controls.identifiantCR.value"
        formControlName="ordre"
      >
        <option [ngValue]="null" selected disabled hidden>Sélectionnez une option</option>
        <option *ngFor="let item of numberArray" [ngValue]="item">{{ item }}</option>
      </select>
    </div>
  </div>
</div>
