import { Component, Input, OnInit } from '@angular/core';
import { EnumNotationTypeNote, EnumPhaseType, RadioButtonOption, SubscriptionDestroyerComponent, EnumInterventionType } from '@shared-ui';
import { UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'pxl-phase-card',
  templateUrl: './phase-card.component.html',
  styleUrls: ['./phase-card.component.scss'],
})
export class PhaseCardComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() title: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() phaseType: EnumPhaseType | EnumInterventionType;

  notationBarreLabel: string | undefined;
  notationBarreErrorValidationMessage: string | undefined;
  activeOrObligatoire: string;
  phaseOrIntervention: string;
  readonly OPTIONS_YES_NO: RadioButtonOption[] = [
    { key: true, value: 'Oui' },
    { key: false, value: 'Non' },
  ];

  ngOnInit(): void {
    this.initNotationBarreLabels();
    this.isActiveForm?.valueChanges.pipe(this.takeUntilDestroyed()).subscribe({
      next: value => {
        if (value) {
          this.formGroup.get('typeNote')?.setValidators([Validators.required]);
          this.formGroup.get('typeNote')?.updateValueAndValidity();
        } else {
          this.formGroup.get('typeNote')?.clearValidators();
          this.formGroup.get('typeNote')?.updateValueAndValidity();
        }
        this.formGroup.updateValueAndValidity();
      },
    });
    this.activeOrObligatoire = this.phaseType === EnumInterventionType.PROPIETE_INTELLECTUELLE ? 'obligatoire' : 'active';
    this.phaseOrIntervention = this.phaseType === EnumInterventionType.PROPIETE_INTELLECTUELLE ? 'Intervention' : 'Phase';
  }

  private initNotationBarreLabels() {
    if (this.phaseType === EnumPhaseType.EVALUATION) {
      this.notationBarreLabel = 'Barre de préselection (Cut)';
      this.notationBarreErrorValidationMessage = 'La barre de préselection renseignée est invalide, veuillez réessayer';
    } else if (this.phaseType === EnumPhaseType.AUDITION) {
      this.notationBarreLabel = "Barre d'audition (Cut)";
      this.notationBarreErrorValidationMessage = "La barre d'audition renseignée est invalide, veuillez réessayer";
    }
  }

  get isActiveForm() {
    return this.formGroup.controls.isActive;
  }

  protected readonly EnumNotationTypeNote = EnumNotationTypeNote;
}
