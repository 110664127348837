import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CritereReferentiel, EnumTypeCritere } from '@shared-ui';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pxl-referentiel-criteres-tableau',
  templateUrl: './referentiel-criteres-tableau.component.html',
  styleUrls: ['./referentiel-criteres-tableau.component.scss'],
})
export class ReferentielCriteresTableauComponent implements OnChanges, OnInit {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() isDispositif: boolean;
  @Input() typeCritere: EnumTypeCritere;
  @Input() title: string;
  @Input() modificationPath: string;

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['label', 'actif', 'dateCreation', 'dateModification', 'actions'];

  constructor(public router: Router, private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.isDispositif = this.typeCritere === EnumTypeCritere.DISPOSITIFS;
    if (this.isDispositif) {
      this.displayedColumns = ['code', 'nom', 'actif', 'dateDebut', 'dateFin', 'actions'];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] && this.sort && this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'label':
            return item?.label?.toLocaleLowerCase().trim(); // pour le tri il récupère les valeurs en minuscule
          case 'code':
            return item?.code?.toLocaleLowerCase().trim();
          case 'nom':
            return item?.nom?.toLocaleLowerCase().trim();
          case 'actif':
            return item?.actif;
          case 'dateDebut':
            return item?.dateDebut;
          case 'dateFin':
            return item?.dateFin;
          case 'dateCreation':
            return item?.dateCreation;
          case 'dateModification':
            return item?.dateModification;
        }
      };
      this.dataSource.sort = this.sort;
    }
  }

  updateCritere(critere: CritereReferentiel): void {
    this.router.navigate([`../${this.modificationPath}/${critere.id}`], { relativeTo: this.route });
  }
}
