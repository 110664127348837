import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramService } from '@services-evaluateur/program.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { RoutingOriginService } from '@services-evaluateur/routing-origin.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { ExportCsvFunction } from '@shared-evaluateur/utils/export-csv';
import { ProjetUtils } from '@shared-evaluateur/utils/projet-utils';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Aap,
  DemandeQlik,
  EnumTypePartenaire,
  FilterDataInterface,
  getEtapeDataList,
  getStatutDataList,
  Program,
  Projet,
  ProjetLight,
  ProjetTableElement,
  SearchObject,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { of, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FicheCommunicationService } from '@services-evaluateur/fiche-communication.http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'pxl-projet-list-transverse-aaps',
  templateUrl: './projet-list-transverse-aaps.component.html',
  styleUrls: ['./projet-list-transverse-aaps.component.scss'],
})
export class ProjetListTransverseAapsComponent extends SubscriptionDestroyerComponent implements OnInit {
  displayedColumns: string[] = [
    'select',
    'aapNomProgramme',
    'aapCode',
    'relevePreDepot',
    'libelleThematique',
    'acronyme',
    'structureCf',
    'budget',
    'etape',
    'statut',
    'partenaireType',
    'archive',
  ];

  projetTableData: ProjetTableElement[] = [];
  etapeDataList: FilterDataInterface[];
  statutDataList: FilterDataInterface[];
  typeProjetDataList: FilterDataInterface[];
  selection = new SelectionModel<ProjetTableElement>(true, []);
  aaps: Aap[];

  projets: ProjetLight[] = [];
  private searchObject: SearchObject;
  private user: Utilisateur;

  constructor(
    public sharedFunction: SharedFunction,
    public exportCsvFunction: ExportCsvFunction,
    private programService: ProgramService,
    private showToastrService: ShowToastrService,
    private projetService: ProjetService,
    private router: Router,
    private userService: UtilisateurService,
    private routingOriginService: RoutingOriginService,
    private ficheCommunicationService: FicheCommunicationService,
    private toastr: ToastrService
  ) {
    super();
  }

  accordionOpen = false;
  showSearchBar = false;
  programmesFilter: FilterDataInterface[] = [];
  programmes: FilterDataInterface[] = [];

  ngOnInit(): void {
    this.userService
      .getUtilisateurObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe(user => {
        this.user = user;
      });
    this.loadSearchData();
  }

  loadSearchData(): void {
    this.programService.getPrograms().subscribe({
      next: (response: any) => {
        this.programmes = response.body;
        this.initFilters(response.body);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  private initFilters(programs: Program[]) {
    this.programmesFilter = programs.map((program: Program) => {
      return {
        id: program.id,
        value: program.nom,
        isSelected: false,
      };
    });

    this.etapeDataList = getEtapeDataList();

    this.typeProjetDataList = [
      {
        id: EnumTypePartenaire.INDIV,
        value: EnumTypePartenaire.toString(EnumTypePartenaire.INDIV),
        isSelected: false,
      },
      {
        id: EnumTypePartenaire.MULTI,
        value: EnumTypePartenaire.toString(EnumTypePartenaire.MULTI),
        isSelected: false,
      },
    ];

    this.statutDataList = getStatutDataList();

    this.showSearchBar = true;
  }

  loadProjects(searchObject: SearchObject): void {
    this.selection?.clear();
    this.searchObject = searchObject;
    this.projetService.searchLightProjetsForAdmin(searchObject).subscribe({
      next: projets => {
        this.projets = projets;
        this.projetTableData = ProjetUtils.convertProjetLightToTableElement(projets);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  searchEventCalled(event: { searchObject: SearchObject; aaps: Aap[] }): void {
    this.aaps = event.aaps;
    this.loadProjects(event.searchObject);
  }

  // Download projet CSV
  downloadExportProjets(): void {
    const projectIds = this.projets.map(project => project.id);
    this.projetService.downloadExportProjets({ projectIds }).subscribe({
      next: response => {
        if (response && response.body) {
          this.exportCsvFunction.downloadFile(response.body, this.exportCsvFunction.extractFileNameFromHeaders(response.headers));
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  // Download partenaire CSV
  downloadPartenaire(): void {
    this.getLoadProjetsObservable().subscribe({
      next: (projets: Projet[]) => {
        this.exportCsvFunction.downloadPartenairesCsv(projets, this.aaps);
      },
    });
  }

  // Download PIA CSV
  downloadPIA(): void {
    this.getLoadProjetsObservable().subscribe({
      next: (projets: Projet[]) => {
        this.exportCsvFunction.downloadPiaCSV(projets, this.aaps);
      },
    });
  }

  /**
   * Redirige vers la page ayant les informations du projet
   */
  goToProjetInfo(projet: ProjetLight): void {
    this.routingOriginService.setIsFromTransverse(true);

    if (
      this.sharedFunction.isSiteAdmin() ||
      this.sharedFunction.canUserEvaluateProject(this.user, projet as unknown as Projet) ||
      this.sharedFunction.canUserAuditProject(this.user, projet as unknown as Projet)
    ) {
      this.router.navigate(['/projets/' + projet.id]);
    } else {
      this.router.navigate(['/projets/' + projet.id + '/projet-info-general']);
    }
  }

  private getLoadProjetsObservable() {
    if (!this.searchObject) {
      return of([]);
    }
    const searchObject = this.searchObject;
    return this.projetService.getProjetsTransverse(searchObject).pipe(
      take(1),
      map((projets: Projet[]) => this.sortProjets(projets)),
      catchError(err => {
        this.showToastrService.checkCodeError(err?.code);
        return throwError(() => err);
      })
    );
  }

  private sortProjets(projets: Projet[]) {
    return [...projets].sort((a, b) => {
      if (!a.relevePreDepot && b.relevePreDepot) {
        return 1;
      } else if (a.relevePreDepot && !b.relevePreDepot) {
        return -1;
      } else {
        return (new Date(b.relevePreDepot).getTime() as any) - (new Date(a.relevePreDepot).getTime() as any);
      }
    });
  }

  downloadFicheCommunication(): void {
    const appIds = this.selection.selected.map(proj => proj.aapId);
    const aapList: Aap[] = this.aaps.filter(aap => appIds.includes(aap.id));

    if (!aapList.every(aap => aap?.ficheComPresent)) {
      this.showToastrService.error(
        'Le Téléchargement de la fiche de communication publique ' +
          "des projets n'est possible que pour les projets qui ont leur fiche dématérialisée, " +
          "merci de désélectionner les projets rattaché aux AAPs qui n'ont pas activé ce mode"
      );
      return;
    }

    if (!this.selection.selected?.length) {
      this.showToastrService.error('Merci de sélectionner au moins un projet.');
    } else if (this.selection.selected?.length > 15) {
      this.showToastrService.error('Vous ne pouvez pas télécharger les fiches de communication de plus de 15 projets en même temps');
    } else {
      const demandeQlik = new DemandeQlik();
      demandeQlik.email = this.user.email;
      demandeQlik.idsProjets = this.selection.selected.map(proj => proj.id);
      this.ficheCommunicationService
        .createDemandeQlik(demandeQlik)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: () => {
            this.toastr.success('Votre demande a bien été prise en compte vous allez recevoir un mail sous 24h', '', { enableHtml: true });
          },
          error: (errorResponse: HttpErrorResponse) => {
            this.toastr.error(errorResponse?.error?.message, '', { enableHtml: true });
          },
        });
    }
  }
}
