<h2 class="modal__title">{{ modalData.title }}</h2>

<div class="modal__content" [innerHTML]="modalData.description"></div>

<form id="lier-form" class="form" [formGroup]="lierForm">
  <div
    id="input-numero"
    class="ds-input-group m-4"
    [class.ds-input-group--error]="!lierForm.controls['numero'].valid && lierForm.controls['numero'].touched"
  >
    <label for="lier" class="ds-input-group__label">Numéro collaboratif<lib-asterisk></lib-asterisk></label>
    <span class="ds-input-group__input">
      <input
        id="numero"
        name="numero"
        type="text"
        (keypress)="onkeypress($event)"
        placeholder="Numéro collaboratif"
        class="input_lier ds-input-group__input"
        formControlName="numero"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-lier" *ngIf="!lierForm.controls['numero'].valid && lierForm.controls['numero'].touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un numéro collaboratif</div>
    </ng-container>
  </div>

  <div class="modal__actions">
    <button id="cancel" class="ds-btn ds-btn--secondary" mat-dialog-close>
      {{ modalData.textReturnButton }}
    </button>
    <button id="go-action" class="ds-btn ds-btn--primary" [disabled]="!lierForm.valid" (click)="goToDc()">
      {{ modalData.textGoButton }}
    </button>
  </div>
</form>
