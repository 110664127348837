<div class="sidenav-projets">
  <div class="sidenav-projets__header">
    <a routerLink="/aaps" class="sidenav-projets__back">
      <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back"></lib-pxl-icon>
      Tous les AAP
    </a>
    <h1 class="sidenav-projets__title" *ngIf="!aap.code">{{ aap.codeComptable }}</h1>
    <h1 class="sidenav-projets__title" *ngIf="aap.code">{{ aap.code }}</h1>
    <br />
    <div id="aap-statut" class="table__status">{{ EnumAapStatut.toString(aap.statut) }}</div>
  </div>

  <div id="aap-logo" *ngIf="aap.logo" class="sidenav-projets__logo sidenav-projets__section">
    <img src="{{ this.docPublicBaseUrl + aap.logo }}" alt="logo" />
  </div>

  <div class="sidenav-projets__section">
    <h3 id="count-projet" class="sidenav-projets__subtitle">
      {{ nbrProjets }}&nbsp;<span *ngIf="nbrProjets <= 1">Projet</span><span *ngIf="nbrProjets > 1">Projets</span>
    </h3>

    <table class="sidenav-projets__table">
      <caption>
        Nombre de projets
      </caption>
      <th id="nbProjets"></th>
      <tr>
        <td>Envoyés</td>
        <td id="count-envoye">{{ nbrEnvoye }}</td>
      </tr>
    </table>
  </div>

  <div class="sidenav-projets__nav sidenav-projets__section">
    <h3 class="sidenav-projets__subtitle">Calendrier</h3>

    <div class="sidenav-projets__calendrier">
      <ng-container *ngFor="let date of dateReleve; index as i; first as isFirst">
        <div class="sidenav-projets__date" *ngIf="date.statut === 'effectue'">
          <svg class="icon">
            <title>icon-action-check</title>
            <g fill="none" fill-rule="evenodd">
              <g fill="#00B785">
                <path
                  d="M6 10.78L3.687 8.467c-.26-.26-.68-.26-.94 0-.26.26-.26.68 0 .94l2.786 2.786c.26.26.68.26.94 0l7.054-7.053c.26-.26.26-.68 0-.94-.26-.26-.68-.26-.94 0L6 10.78z"
                  transform="translate(-72 -510) translate(56 72) translate(0 408) translate(0 22) translate(16 8)"
                />
              </g>
            </g>
          </svg>
          <div>
            <div id="date-nom-effectue" class="sidenav-projets__releve">{{ date.nom }} (effectué)</div>
            <div id="date-effectue" class="sidenav-projets__value">{{ date.valeur | date : "dd/MM/yyyy - HH'H'mm" }}</div>
          </div>
        </div>

        <div class="sidenav-projets__date sidenav-projets__date--encours" *ngIf="date.statut === 'encours'">
          <svg class="icon">
            <title>icon-arrow_forward</title>
            <g fill="none" fill-rule="evenodd">
              <g fill="#5E514D">
                <path
                  d="M3.333 8.667h7.447L7.527 11.92c-.26.26-.26.687 0 .947.26.26.68.26.94 0l4.393-4.394c.26-.26.26-.68 0-.94l-4.387-4.4c-.26-.26-.68-.26-.94 0-.26.26-.26.68 0 .94l3.247 3.26H3.333c-.366 0-.666.3-.666.667 0 .367.3.667.666.667z"
                  transform="translate(-72 -558) translate(56 72) translate(0 408) translate(0 70) translate(16 8)"
                />
              </g>
            </g>
          </svg>
          <div>
            <div id="date-nom-en-cours" class="sidenav-projets__releve">{{ date.nom }} (en cours)</div>
            <div id="date-en-cours" class="sidenav-projets__value">{{ date.valeur | date : "dd/MM/yyyy - HH'H'mm" }}</div>
          </div>
        </div>

        <div class="sidenav-projets__date sidenav-projets__date--prochain" *ngIf="date.statut === 'prochain'">
          <svg class="icon">
            <title>icon-arrow-chevron-right</title>
            <g fill="none" fill-rule="evenodd">
              <g fill="#BCBCBC">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M6.193 4.473c-.26.26-.26.68 0 .94L8.78 8l-2.587 2.587c-.26.26-.26.68 0 .94.26.26.68.26.94 0l3.06-3.06c.26-.26.26-.68 0-.94l-3.06-3.06c-.253-.254-.68-.254-.94.006z"
                  transform="translate(-72 -606) translate(56 72) translate(0 408) translate(0 118) translate(16 8)"
                />
              </g>
            </g>
          </svg>
          <div>
            <div id="date-nom" class="sidenav-projets__releve">{{ date.nom }}</div>
            <div id="date" class="sidenav-projets__value">{{ date.valeur | date : "dd/MM/yyyy - HH'H'mm" }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
