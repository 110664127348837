import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  EnumPhaseType,
  PhaseOption,
  SubscriptionDestroyerComponent,
  EnumInterventionType,
  FeatureFlagService,
  EnumFeatureFlipping,
} from '@shared-ui';
import { UntypedFormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'pxl-phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.scss'],
})
export class PhasesComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() aapForm: UntypedFormGroup;
  @Input() stepNumber: number;

  phaseOptions: PhaseOption[] = [];

  constructor(@Inject(DOCUMENT) public document: Document, public featureFlagService: FeatureFlagService) {
    super();
  }

  ngOnInit(): void {
    this.phaseOptions = [
      { title: 'Phase Eligibilité', phaseType: EnumPhaseType.ELIGIBILITE, formGroup: this.eligibiliteForm },
      { title: 'Phase Evaluation / Opportunité', phaseType: EnumPhaseType.EVALUATION, formGroup: this.evaluationForm },
      { title: 'Phase Audition', phaseType: EnumPhaseType.AUDITION, formGroup: this.auditionForm },
      { title: 'Phase Instruction approfondie', phaseType: EnumPhaseType.INSTRUCTION, formGroup: this.instructionForm },
    ];

    if (this.featureFlagService.featureOn(EnumFeatureFlipping.INTERVENTION_JPI)) {
      this.phaseOptions.push({
        title: 'Intervention Juridique et Propriété Intellectuelle',
        phaseType: EnumInterventionType.PROPIETE_INTELLECTUELLE,
        formGroup: this.proprieteIntellectuelleForm,
      });
    }

    this.phaseOptions = [
      ...this.phaseOptions,
      {
        title: 'Phase Contractualisation',
        phaseType: EnumPhaseType.CONTRACTUALISATION,
        formGroup: this.contractualisationForm,
      },
    ];
  }

  get eligibiliteForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.aapForm.controls.notationEligibilite;
  }

  get evaluationForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.aapForm.controls.notationEvaluation;
  }

  get auditionForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.aapForm.controls.notationAudition;
  }

  get proprieteIntellectuelleForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.aapForm.controls.notationProprieteIntellectuelle;
  }

  get instructionForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.aapForm.controls.notationInstruction;
  }

  get contractualisationForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.aapForm.controls.notationContractualisation;
  }
}
