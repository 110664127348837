import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@environments-evaluateur/environment';

export interface ModalData {
  title: string;
  textReturnButton: string;
  textGoButton: string;
}

@Component({
  selector: 'pxl-cgu-modal',
  templateUrl: './cgu-modal.component.html',
  styleUrls: ['./cgu-modal.component.scss'],
})
export class CguModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();
  enabledBtnCGU = false;
  enabledBtnDataCollect = false;

  src = environment.docPublicBaseUrl + 'CGU_PICXEL.pdf';
  anchorSrc = this.src + '#rgpd';
  constructor(public dialogRef: MatDialogRef<CguModalComponent>, @Inject(MAT_DIALOG_DATA) public modalData: ModalData) {}

  ngOnInit(): void {
    /*
    Explanation: Following the upgrade of ng2-pdf-viewer from version 6.4.1 to 9.1.5, a regression has been noticed where
    the viewer-container has no longer a content height (position: absolute) but rather follows the parent height (height: 100%).
    This makes it difficult to implement the CGU modal where the user has to scroll all the way to the bottom of the pdf
    before accessing the agreement button. The maintainer of ng2-pdf-viewer had added a security to forbid any css override
    of the position of "ng2-pdf-viewer-container" (an error message "The `container` must be absolutely positioned" is displayed)
    ---
    Hack: Put a callback at the end of the call stack to update the position of the viewer-container to relative AFTER
    the library have checked and painted the pdf canvas.
    */
    setTimeout(() => {
      const viewerContainer = document.querySelectorAll('#cgu-viewer .ng2-pdf-viewer-container')[0] as HTMLElement;
      viewerContainer.style.position = 'relative';
    }, 10);
  }
}
