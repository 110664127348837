import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-admin/environment';
import { SITE_EVAL } from '@environments-admin/environment.cloud';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { Evaluateur, ShowToastrService, Utilisateur } from '@shared-ui';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EvaluateurGuard {
  constructor(
    private evaluateurService: EvaluateurService,
    private userService: UtilisateurService,
    public sharedFunction: SharedFunction,
    private showToastrService: ShowToastrService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    if (environment.site !== SITE_EVAL) {
      return of(true);
    }

    return new Observable<boolean>(observer => {
      this.userService.getUtilisateurObservable().subscribe((utilisateur: Utilisateur) => {
        if (!utilisateur) {
          return;
        }

        this.evaluateurService.getEvaluateur(utilisateur.email).subscribe({
          next: (response: HttpResponse<Evaluateur>) => {
            if (!response?.body?.tel || !response?.body?.matricule) {
              window.location.href = `${environment.espaceCandidat}/espace?error=INCOMPLETE_EVAL`;
              observer.next(false);
            } else {
              observer.next(true);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.handleError(err);
          },
        });
      });
    });
  }

  handleError(err: HttpErrorResponse): void {
    if (err?.status === 401 && err?.error?.message) {
      this.router.navigate(['/confirmation-access-evaluateur']);
    } else {
      this.showToastrService.checkCodeError(err?.error);
    }
  }
}
