import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FlechageBudgetaireService } from '@services-evaluateur/flechage-budgetaire.service';
import { Dispositif, DispositifForm, ShowToastrService } from '@shared-ui';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreationDispositifHelperService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    public flechageBudgetaireService: FlechageBudgetaireService,
    public showToastrService: ShowToastrService,
    public router: Router
  ) {}

  createForm(): FormGroup<DispositifForm> {
    return new FormGroup<DispositifForm>({
      code: new FormControl<string>(null, [Validators.required]),
      nom: new FormControl<string>(null),
      dateDebut: new FormControl<Date>(null, [Validators.required]),
      dateFin: new FormControl<Date>(null, [Validators.required]),
      actif: new FormControl<boolean>(null, [Validators.required]),
    });
  }

  saveDispositif(isModification: boolean, idDispositif: string, form: FormGroup<DispositifForm>, route: ActivatedRoute): void {
    form.markAllAsTouched();

    if (form.invalid) {
      return;
    }

    const dispositif = form.value as Dispositif;

    if (isModification) {
      dispositif.id = idDispositif;
      this.flechageBudgetaireService
        .updateDispositif(idDispositif, dispositif)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.showToastrService.success(`Le dispositif a été modifié avec succès`);
            form.markAsPristine();
            this.goToDispositifsReferentiel(route, isModification);
          },
          error: err => {
            if (err?.error?.code === '1002') {
              this.showToastrService.error(err?.error?.message, 'Action Impossible');
            } else {
              this.showToastrService.error("Une erreur s'est produite, veuillez réessayer ultérieurement.");
            }
          },
        });
      return;
    }

    this.flechageBudgetaireService
      .createDispositif(dispositif)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.showToastrService.success(`Le dispositif a été créé avec succès`);
          form.markAsPristine();
          this.goToDispositifsReferentiel(route, isModification);
        },
        error: err => {
          if (err?.error?.code === '2005') {
            this.showToastrService.error(err?.error?.message, 'Action Impossible');
          } else {
            this.showToastrService.error("Une erreur s'est produite, veuillez réessayer ultérieurement.");
          }
        },
      });
  }

  goToDispositifsReferentiel(route: ActivatedRoute, isModification: boolean): void {
    this.router.navigate([`${isModification ? '../' : ''}../${route.snapshot.data.referentielPath}`], { relativeTo: route });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
