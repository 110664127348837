import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ComiteService } from '@services-evaluateur/comite.service';
import { ProgramService } from '@services-evaluateur/program.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import {
  Aap,
  Comite,
  ComiteInterfaceForm,
  ConfirmModalComponent,
  DateTimeUtils,
  EnumPhaseComite,
  EnumStatutComite,
  Program,
  ProjetParPhaseInterface,
  ProjetTableElement,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { ComitesCreationHelperService } from './comites-creation.helper.service';
@Component({
  selector: 'app-comites-creation',
  templateUrl: './comites-creation.component.html',
  styleUrls: ['./comites-creation.component.scss'],
})
export class ComitesCreationComponent extends SubscriptionDestroyerComponent implements OnInit {
  formCreation: FormGroup<ComiteInterfaceForm>;

  showSearchBar = false;
  saveClicked = false;
  formSaved = false;
  isEdit = false; // True si on est en modification d'un comité, false si on est en création
  showForm = false; // True quand les données du comité sont chargées et qu'on peut afficher le formulaire
  canUserEditComite = false;

  programmes: Program[];
  aaps: Aap[] = [];
  comiteId: string;
  comite: Comite;
  user: Utilisateur;

  selectedProjectsEvaluation = new SelectionModel<ProjetTableElement>(true, []);
  selectedProjectsAudition = new SelectionModel<ProjetTableElement>(true, []);
  selectedProjectsSelection = new SelectionModel<ProjetTableElement>(true, []);
  selectedProjects: ProjetParPhaseInterface[] = [];

  preselectionPanelSubtitle = '';
  auditionPanelSubtitle = '';
  selectionPanelSubtitle = '';

  displayedColumns: string[] = ['aapCode', 'acronyme', 'structureCf', 'relevePreDepot', 'releveDepot'];

  comitesTimes = DateTimeUtils.generateTimes(8, 21, 30);

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (
      !this.formCreation.dirty &&
      (this.helperService.isProjectSelectionPristine(this.selectedProjects, this.comite?.projetsParPhases ?? []) || this.formSaved)
    );
  }

  constructor(
    public helperService: ComitesCreationHelperService,
    public router: Router,
    public route: ActivatedRoute,
    public comiteService: ComiteService,
    public programService: ProgramService,
    public showToastrService: ShowToastrService,
    public projetService: ProjetService,
    public matDialog: MatDialog,
    private userService: UtilisateurService,
    public sharedFunction: SharedFunction,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedProjectsEvaluation.changed.subscribe(() => this.setSelectedProjects());
    this.selectedProjectsAudition.changed.subscribe(() => this.setSelectedProjects());
    this.selectedProjectsSelection.changed.subscribe(() => this.setSelectedProjects());

    this.userService.getUtilisateurObservable().subscribe(user => (this.user = user));

    this.comiteId = this.route.snapshot.params.id;
    if (this.comiteId) {
      this.isEdit = true;
    }

    if (this.isEdit) {
      this.loadComite();
    } else {
      this.displayedColumns.unshift('select');
      this.formCreation = this.helperService.buildFormCreation(true);
      this.showForm = true;
      this.canUserEditComite = true;
    }
    this.setPhasePanelSubtitles();
    this.loadSearchData();
  }

  setSelectedProjects(): void {
    this.selectedProjects = this.selectedProjectsEvaluation.selected
      .map(projet => ({ projetId: projet.id, phase: EnumPhaseComite.EVALUATION }))
      .concat(this.selectedProjectsAudition.selected.map(projet => ({ projetId: projet.id, phase: EnumPhaseComite.AUDITION })))
      .concat(this.selectedProjectsSelection.selected.map(projet => ({ projetId: projet.id, phase: EnumPhaseComite.SELECTION })));

    this.setPhasePanelSubtitles();
  }

  setPhasePanelSubtitles(): void {
    const selectedCountEvaluation = this.selectedProjectsEvaluation.selected.length;
    const selectedCountAudition = this.selectedProjectsAudition.selected.length;
    const selectedCountSelection = this.selectedProjectsSelection.selected.length;

    this.preselectionPanelSubtitle =
      selectedCountEvaluation === 0
        ? 'Vous devez sélectionner vos projets pour une décision en préselection'
        : `${selectedCountEvaluation} projets sélectionnés pour une décision en présélection`;
    this.auditionPanelSubtitle =
      selectedCountAudition === 0
        ? 'Vous devez sélectionner vos projets pour une décision en audition'
        : `${selectedCountAudition} projets sélectionnés pour une décision en audition`;
    this.selectionPanelSubtitle =
      selectedCountSelection === 0
        ? 'Vous devez sélectionner vos projets pour une décision en sélection'
        : `${selectedCountSelection} projets sélectionnés pour une décision en sélection`;

    this.cdr.detectChanges();
  }

  loadComite(): void {
    this.comiteService
      .getComiteById(this.comiteId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: result => {
          this.comite = result.body;
          this.comite.id = this.comiteId;
          this.canUserEditComite = this.sharedFunction.canUserEditComite(this.user, this.comite);
          if (this.canUserEditComite) {
            this.displayedColumns.unshift('select');
          }
          this.formCreation = this.helperService.buildFormCreation(this.canUserEditComite, this.comite);
          this.showForm = true;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  loadSearchData(): void {
    this.programService.getPrograms().subscribe({
      next: response => {
        this.programmes = response.body;

        this.showSearchBar = true;
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  saveComite(isCreate?: boolean): void {
    if (this.formCreation.invalid) {
      return;
    }

    if (!isCreate) {
      this.formCreation.controls.statut.setValue(EnumStatutComite.BROUILLON);
      this.selectedProjectsEvaluation.clear();
      this.selectedProjectsAudition.clear();
      this.selectedProjectsSelection.clear();
    } else {
      this.formCreation.controls.statut.setValue(EnumStatutComite.CREE);
    }

    const comite: Comite = this.helperService.getComiteFromForm(this.formCreation, this.programmes);
    comite.projetsParPhases = this.selectedProjects;
    comite.auteur = this.comite?.auteur;
    comite.instructeurs = this.comite?.instructeurs;

    this.comiteService.saveComite(comite, this.isEdit).subscribe({
      next: () => {
        let actionMsg;
        if (this.isEdit && this.helperService.isComiteCreated(this.comite)) {
          actionMsg = 'modifié';
        } else {
          actionMsg = isCreate ? 'créé' : 'enregistré';
        }

        this.showToastrService.success(`Votre comité a été ${actionMsg}.`);
        this.formCreation.markAsPristine();
        this.formSaved = true;
        this.goToListComites();
      },
      error: (err: HttpErrorResponse) => {
        if (err?.error?.message) {
          this.showToastrService.error(err.error.message);
        } else {
          this.showToastrService.checkCodeError(err?.error);
        }
      },
    });
  }

  createComite(): void {
    this.saveClicked = true;
    this.formCreation.markAllAsTouched();
    if (this.formCreation.invalid) {
      return;
    }
    if (this.selectedProjects?.length > 0) {
      const comiteCreated = this.isEdit && this.helperService.isComiteCreated(this.comite);
      const dialogRef = this.matDialog.open(ConfirmModalComponent, {
        data: {
          title: comiteCreated ? 'Modification d’un comité' : 'Création d’un comité',
          description: comiteCreated
            ? 'Souhaitez-vous modifier le comité ?'
            : `<p>En confirmant l'action, le comité va être définitivement créé. Cette action est irreversible.<br>
          Confirmez-vous l’action ?</p>`,
          textGoButton: 'Confirmer',
          textReturnButton: 'Annuler',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.saveComite(true);
        }
      });
    } else {
      this.showToastrService.error('Vous devez sélectionner au moins un projet.');
    }
  }

  enregistreComite(): void {
    this.saveClicked = true;
    this.formCreation.markAllAsTouched();

    if (this.formCreation.invalid) {
      return;
    }

    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Enregistrement d’un comité',
        description: `<p>En confirmant l'action, le comité va être enregistré sous forme de brouillon.
        Les projets sélectionnés pour le comité ne seront pas enregistrés.<br>
        Confirmez-vous l’action ?</p>`,
        textGoButton: 'Enregistrer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveComite(false);
      }
    });
  }

  goToListComites(): void {
    this.router.navigate([`../${this.isEdit ? '../' : ''}`], { relativeTo: this.route });
  }

  getTitle(): string {
    return this.helperService.getTitle(this.comite, this.isEdit);
  }

  protected readonly EnumPhaseComite = EnumPhaseComite;
}
