import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComitesCreationComponent } from './comites-creation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { TableauProjetModule } from '@shared-evaluateur/components/tableau-projet/tableau-projet.module';
import { SharedUiModule } from '@shared-ui';
import { ProjectSearchBarComitesComponent } from './project-search-bar-comites/project-search-bar-comites.component';
import { ProjectSearchBlockComitesComponent } from './project-search-block-comites/project-search-block-comites.component';

@NgModule({
  declarations: [ComitesCreationComponent, ProjectSearchBarComitesComponent, ProjectSearchBlockComitesComponent],
  imports: [CommonModule, ReactiveFormsModule, TableauProjetModule, MaterialModule, SharedUiModule, FormsModule],
  exports: [ComitesCreationComponent],
})
export class ComitesCreationModule {}
