<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="col-9">
    <div class="col-3 text-right">
      <a *ngIf="!options.closeButton" class="btn btn-close btn-sm" (click)="action($event)"> {{ undoString }} </a>
      <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-sm toast-close-button"> &times; </a>
    </div>
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">{{ title }}</div>
    <div *ngIf="message && options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
    <div *ngIf="message && !options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message">{{ message }}</div>
    <div *ngIf="data.incorrectList" role="alert" [class]="options.messageClass">
      <ul>
        <li *ngFor="let item of shownArray">{{ item }}</li>
        <li *ngIf="differenceLengthBetweenArrays" id="li-hidden-lines">
          ... {{ differenceLengthBetweenArrays }} ligne{{differenceLengthBetweenArrays === 1 ? '' : 's'}}
          cachée{{differenceLengthBetweenArrays === 1 ? '' : 's'}}. Copiez la liste complète ci-dessous.
        </li>
      </ul>
    </div>
    <div *ngIf="differenceLengthBetweenArrays">
      <button (click)="copyData()" class="card__action-button">
        <lib-pxl-icon class="icon" icon="icon-action-content-copy"></lib-pxl-icon>
        <span>Rapport des erreurs.</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
