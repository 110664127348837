<section class="page-section" *ngIf="showMaNotation && !sharedFunction.isSiteAdmin()">
  <lib-card-title title="Ma notation"></lib-card-title>
  <!-- Si certaines informations sont manquantes -->
  <div
    *ngIf="notationsUtilisateur.length === 0"
    id="card-info"
    class="card card--message mb-3"
    [ngClass]="{ 'card--alert': notationsUtilisateur.length === 0 && isInstructionEnCours }"
    [class.card--inactive]="readOnly || (!isInstructionEnCours && showMaNotation)"
    (click)="onGoToProjetInstruction()"
  >
    <p class="card__title">Vous devez remplir votre notation en tant que membre du jury national</p>
    <lib-pxl-icon class="card__arrow" *ngIf="isInstructionEnCours" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"> </lib-pxl-icon>
  </div>
  <ng-container *ngIf="notationsUtilisateur.length !== 0">
    <div class="div_notations_cards">
      <pxl-projet-instruction-notation
        *ngFor="let notation of notationsUtilisateur"
        [aap]="aap"
        [projet]="projet"
        [notation]="notation"
        [utilisateur]="user"
        [canUserWrite]="showMaNotation && isInstructionEnCours"
        [isUserReadOnly]="readOnly"
        (click)="onGoToProjetInstruction(notation)"
      >
      </pxl-projet-instruction-notation>
    </div>
  </ng-container>
</section>

<section>
  <div *ngIf="expertsJuryNational?.length === 0" id="card-info" class="card card--message">
    <p class="card__title">Aucun membre du jury national n'est affecté</p>
  </div>
  <ng-container *ngIf="notationsExpertJuryNational.length !== 0">
    <div class="div_notations_cards">
      <pxl-projet-instruction-notation
        *ngFor="let notation of notationsExpertJuryNational"
        [aap]="aap"
        [projet]="projet"
        [notation]="notation"
        [utilisateur]="user"
        [class.card--inactive]="readOnly || showMaNotation"
        [canUserWrite]="showMaNotation || isUserInterneBPI"
        [isUserReadOnly]="readOnly && !isUserInterneBPI"
        (click)="onGoToProjetInstruction(notation)"
      ></pxl-projet-instruction-notation>
    </div>
  </ng-container>
</section>
