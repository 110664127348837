import { Component } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferentielHttpService } from '@services-evaluateur/referentiel.http.service';
import { UploadDocumentService } from '@services-evaluateur/upload-document.service';
import { ConfirmModalComponent } from '@shared-evaluateur/components/modals/confirm-modal/confirm-modal.component';
import { EnumFeatureFlipping, ShowToastrService } from '@shared-ui';

@Component({
  selector: 'pxl-referentiels',
  templateUrl: './referentiels.component.html',
  styleUrls: ['./referentiels.component.scss'],
})
export class ReferentielsComponent {
  private readonly MAX_FILE_SIZE_MB = 5;
  private readonly MAX_FILE_SIZE = this.MAX_FILE_SIZE_MB * 1024 * 1024;
  readonly SUPPORTED_FILE_EXTENSION = '.csv';

  constructor(
    private httpService: ReferentielHttpService,
    private uploadService: UploadDocumentService,
    private showToastrService: ShowToastrService,
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  onSelectDoc(event: any): void {
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;
    const file: File = fileInput.files[0];
    if (!file) {
      return;
    }
    fileInput.value = null;
    if (!file.name.toLowerCase().endsWith(this.SUPPORTED_FILE_EXTENSION)) {
      this.showToastrService.error(
        `le type de fichier sélectionné n'est pas supporté, le seul format accepté est ${this.SUPPORTED_FILE_EXTENSION}`
      );
      return;
    }
    if (file.size > this.MAX_FILE_SIZE) {
      this.showToastrService.error(
        `Le fichier importé est trop volumineux, la taille maximale autorisée est de ${this.MAX_FILE_SIZE_MB}Mo`
      );
      return;
    }
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Confirmation',
        description: `Vous allez charger le fichier référentiel des KPI de la grille d'impacts. <br/>

                      <ul>
                        <li>Les nouveaux KPI présents dans votre fichier seront ajoutés au référentiel </li>
                        <li>Les KPI existants seront mis à jour avec vos modifications.</li>
                        <li>Les KPI déjà chargés dans le référentiel et non présents dans votre fichier ne seront pas affectés par votre import.</li>
                      </ul>
                      Ce référentiel est transverse à tous les AAPs et peut impacter les AAPs en cours.`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
        descriptionFontSize: '1.05rem',
      },
    });

    dialogRef.afterClosed().subscribe(hasConfirmed => {
      if (hasConfirmed) {
        this.uploadFile(file);
      }
    });
  }

  uploadFile(file: File) {
    const path = `tmp/KPIS_IMPORT_${uuidv4()}_${file.name}`;
    // Get signed url
    this.uploadService.getValueForDocBDDUpload(path).subscribe({
      next: result => {
        this.uploadDocToS3(result.body.url, file, path);
      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      },
    });
  }

  uploadDocToS3(url: string, file: File, path: string): void {
    // Upload sur S3
    this.uploadService.uploadDocBDD(url, file).subscribe({
      next: () => {
        this.postKpisCsv(path);
      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      },
    });
  }

  postKpisCsv(path: string): void {
    this.httpService.postKpisReferentielCsv(path).subscribe({
      next: () => {
        this.showToastrService.success("L'ensemble des KPIs ont été importés avec succès.");
      },
      error: (err: HttpErrorResponse) => {
        if (err.status == HttpStatusCode.BadRequest && err?.error?.code == 1366) {
          this.showToastrService.error(err.error.message);
        } else {
          this.handleError(err);
        }
      },
    });
  }

  goToCritereEvaluation(): void {
    this.router.navigate(['criteres-evaluation'], { relativeTo: this.route });
  }

  goToIndicateursEligibilite(): void {
    this.router.navigate(['indicateurs-eligibilite'], { relativeTo: this.route });
  }

  goToIndicateursStrategiesAcceleration(): void {
    this.router.navigate(['strategie-acceleration'], { relativeTo: this.route });
  }

  goToRoleDC(): void {
    this.router.navigate(['intervenant-document-commercial'], { relativeTo: this.route });
  }

  goToDocumentAAP(): void {
    this.router.navigate(['documents-aap'], { relativeTo: this.route });
  }

  goToDepensesProjets(): void {
    this.router.navigate(['depenses-projets'], { relativeTo: this.route });
  }

  handleError(err: HttpErrorResponse) {
    this.showToastrService.checkCodeError(err?.error);
  }

  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
}
