import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  /* eslint-disable-next-line */
  selector: '[custom-toast-component]',
  styleUrls: ['./custom-toastr.scss'],
  templateUrl: './custom-toastr.html',
})
export class CustomToastComponent extends Toast {
  static readonly EMAIL_ERROR = 'email_error';
  static readonly AFFECTATION_ERROR = 'affectation_error';
  data: any;

  shownArray: string[] = []; // Array affiché en message
  differenceLengthBetweenArrays = 0; // Différence de taille entre l'array original et l'array affiché

  // used for demo purposes
  undoString = 'undo';

  constructor(protected override toastrService: ToastrService, public override toastPackage: ToastPackage, private clipboard: Clipboard) {
    super(toastrService, toastPackage);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.data = this.options.data;
    if (this.data?.incorrectList) {
      this.shownArray = this.data.incorrectList.slice(0, 3);
      this.differenceLengthBetweenArrays = this.data.incorrectList.length - this.shownArray.length;
    }
  }

  action(event: Event): boolean {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }

  /**
   * Copie le message dans le presse-papier
   */
  copyData(): void {
    if (!this.data) {
      return;
    }

    let copiedMessage: any;
    switch (this.data.type) {
      case CustomToastComponent.AFFECTATION_ERROR:
        copiedMessage = `Erreurs d'affectation : \r\n${this.data.incorrectList.join(' \r\n')}`;
        break;
      case CustomToastComponent.EMAIL_ERROR:
        copiedMessage = `Emails incorrects : \r\n${this.data.incorrectList.join(' \r\n')}`;
        break;
      default:
        break;
    }

    this.clipboard.copy(copiedMessage);
    this.toastrService.success('Rapport des erreurs copié.');
  }
}
