<div class="page-content page-content--no-scroll list-page">
  <div class="list-page__header d-flex justify-content-between mb-3">
    <h1 class="list-page__title">Organisme</h1>
    <div class="list-page__dropdowns">
      <ng-container>
        <button
          type="button"
          class="list-page__create-button ds-btn ds-btn--secondary"
          (click)="createOrganisme()"
          *ngxPermissionsOnly="['PARAMETRAGE_WRITE']"
        >
          <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
          Nouvel organisme
        </button>
      </ng-container>
    </div>
  </div>

  <div id="organisme-table" class="table">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="nom" matSortDirection="asc">
      <caption>
        Liste des organismes
      </caption>
      <ng-container matColumnDef="nom" class="">
        <th id="organisme-nom" mat-header-cell *matHeaderCellDef mat-sort-header>Nom de l'organisme</th>
        <td id="nom" mat-cell *matCellDef="let element">
          {{ element.nom }}
        </td>
      </ng-container>
      <ng-container matColumnDef="raisonSociale" class="">
        <th id="organisme-raison-sociale" mat-header-cell *matHeaderCellDef mat-sort-header>Raison sociale</th>
        <td id="raisonSociale" mat-cell *matCellDef="let element">{{ element.raisonSociale }}</td>
      </ng-container>
      <ng-container matColumnDef="type" class="">
        <th id="critere-affichage-des-projets" mat-header-cell *matHeaderCellDef mat-sort-header>Critère d'affichage des projets</th>
        <td id="type" mat-cell *matCellDef="let element">{{ organismeType[element.type] }}</td>
      </ng-container>
      <ng-container matColumnDef="mailValideur" class="">
        <th id="mail-valideur" mat-header-cell *matHeaderCellDef>Mail valideur</th>
        <td id="mailValideur" mat-cell *matCellDef="let element">
          {{ element.instructeursValideursEmails }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th id="organisme-actions" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            id="update-organisme"
            class="table__button"
            matTooltip="Modifier"
            (click)="updateOrganisme(element); $event.stopPropagation()"
            *ngxPermissionsOnly="['PARAMETRAGE_WRITE']"
          >
            <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr id="go-organisme" mat-row class="r" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
    </table>
  </div>
</div>
