import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepensesProjetConfig, SubscriptionDestroyerComponent } from '@shared-ui';

@Injectable()
export class CreationDepensesProjetsHelperService extends SubscriptionDestroyerComponent {
  constructor() {
    super();
  }

  createForm(): FormGroup<any> {
    return new FormGroup<any>({
      pourcentageSolde: new FormControl(null, Validators.required),
      tauxCE: new FormControl(null, Validators.required),
      versementMinimum: new FormControl(null, Validators.required),
      differeRemboursement: new FormControl(null, Validators.required),
      tauxPetiteEntreprise: new FormControl(null, Validators.required),
      tauxMoyenneEntreprise: new FormControl(null, Validators.required),
      tauxGrandeEntreprise: new FormControl(null, Validators.required),
      tauxLaboratoireRecherche: new FormControl(null, Validators.required),
    });
  }

  createReferentielFromForm(form: FormGroup<any>, refDepensesConfig: DepensesProjetConfig) : DepensesProjetConfig {
    form.markAllAsTouched();

    return {
      tauxConfig: {
        pourcentageSolde: form.get('pourcentageSolde')?.value,
        tauxCE: form.get('tauxCE')?.value,
        versementMinimum: form.get('versementMinimum')?.value,
        differeRemboursement: form.get('differeRemboursement')?.value,
      },
      premierVersementConfig: {
        tauxPetiteEntreprise: form.get('tauxPetiteEntreprise')?.value,
        tauxMoyenneEntreprise: form.get('tauxMoyenneEntreprise')?.value,
        tauxEntrepriseIntermediaire: refDepensesConfig.premierVersementConfig.tauxEntrepriseIntermediaire,
        tauxGrandeEntreprise: form.get('tauxGrandeEntreprise')?.value,
        tauxLaboratoireRecherche: form.get('tauxLaboratoireRecherche')?.value,
      },
      regimesAide: refDepensesConfig.regimesAide,
    };
  }
}
