import { Component, Inject, OnInit } from '@angular/core';
import { Aap, Projet, ShowToastrService } from '@shared-ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { MultiEmailService } from '@services-evaluateur/multi-email.service';

export interface ModalData {
  title: string;
  subTitle: string;
  aap: Aap;
  aapList: Aap[];
  projectList: Projet[];
  textReturnButton: string;
  textGoButton: string;
}

@Component({
  selector: 'pxl-affectation-intervenant-lecture-seule-modal',
  templateUrl: './affectation-intervenant-lecture-seule-modal.component.html',
  styleUrls: ['./affectation-intervenant-lecture-seule-modal.component.scss'],
})
export class AffectationIntervenantLectureSeuleModalComponent implements OnInit {
  readonly EMAILS_INPUT_SIZE_MAX = 500;

  public affectationForm: FormGroup | undefined;
  public isEmailsValid = true;
  private activeToast: ActiveToast<any> | undefined;

  get emails(): AbstractControl | undefined | null {
    return this.affectationForm?.get('emails');
  }

  get role(): AbstractControl | undefined | null {
    return this.affectationForm?.get('role');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    public dialogRef: MatDialogRef<AffectationIntervenantLectureSeuleModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private showToastrService: ShowToastrService,
    private toastr: ToastrService,
    private emailService: MultiEmailService
  ) {}

  ngOnInit(): void {
    this.affectationForm = this.formBuilder.group({
      emails: [null, [Validators.required]],
      role: [null, [Validators.required]],
    });
  }

  public onSubmit(): void {
    this.role?.markAllAsTouched();
    this.emails?.markAllAsTouched();
    this.controlEmails();

    if (this.affectationForm?.valid && this.isEmailsValid) {
      this.terminateAffectation();
    }
  }

  private controlEmails(): void {
    const incorrectList: string[] = this.emailService.findIncorrectEmails(this.affectationForm, 'emails');
    this.isEmailsValid = incorrectList.length <= 0;

    if (!this.isEmailsValid && this.emails?.value?.length) {
      this.emailService.showEmailsError(this.affectationForm, 'emails');
    }
  }

  private terminateAffectation(): void {
    const role = this.role?.value;
    const emails = this.emails?.value
      .split(';')
      .map((email: string) => email.trim())
      .filter((email: string) => email !== '');
    this.dialogRef.close({ role, emails });
  }
}
