import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ComitePropositionInterfaceForm, Notation } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class ProjetPropositionComiteSyntheseHelperService {
  getNotationsAverage(notations: Notation[]): number {
    const filteredNotations = notations
      .filter(notation => notation?.grilleEvaluation?.noteProjet != null && !notation?.statutPriseEnCompte?.estIgnore)
      .map(notation => Number(notation.grilleEvaluation.noteProjet));

    if (filteredNotations.length === 0) return 0;

    return filteredNotations.reduce((n1, n2) => n1 + n2, 0) / filteredNotations.length;
  }

  buildFormSynthese(): FormGroup<ComitePropositionInterfaceForm> {
    return new FormGroup<ComitePropositionInterfaceForm>({
      avis: new FormControl(null),
      dateEnvoiValidationEtat: new FormControl(null),
      syntheseADestinationEtat: new FormControl(null),
      grandPrix: new FormControl(false),
      dispositif: new FormControl(null),
      convention: new FormControl(null),
    });
  }
}
