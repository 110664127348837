import { OrganismeModel } from '@shared-ui';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments-evaluateur/environment';

@Injectable()
export class OrganismeService {
  constructor(private httpClient: HttpClient) {}

  getOrganisme(id: string): Observable<HttpResponse<OrganismeModel>> {
    return this.httpClient.get<OrganismeModel>(environment.apiUrl + 'organismes/' + id, { observe: 'response' });
  }

  getOrganismes(): Observable<HttpResponse<OrganismeModel[]>> {
    return this.httpClient.get<OrganismeModel[]>(environment.apiUrl + 'organismes', { observe: 'response' });
  }

  getOrganismesByEmail(email: string): Observable<HttpResponse<OrganismeModel[]>> {
    const radical = email.split('@').pop();
    return this.httpClient.get<OrganismeModel[]>(environment.apiUrl + 'organismes?radicalmail=@' + radical, { observe: 'response' });
  }

  updateOrganisme(organisme: OrganismeModel, id: string): Observable<HttpResponse<OrganismeModel>> {
    return this.httpClient.put<OrganismeModel>(environment.apiAdminUrl + 'organismes/' + id, JSON.stringify(organisme), {
      observe: 'response',
    });
  }

  createOrganisme(organisme: OrganismeModel): Observable<HttpResponse<OrganismeModel>> {
    return this.httpClient.post<OrganismeModel>(environment.apiAdminUrl + 'organismes', JSON.stringify(organisme), { observe: 'response' });
  }
}
