import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { Aap, DateAutres, EnumAapStatut, Projet } from '@shared-ui';

@Component({
  selector: 'pxl-sidenav-projets',
  templateUrl: './sidenav.projets.component.html',
  styleUrls: ['./sidenav.projets.component.scss'],
})
export class SidenavProjetsComponent implements OnInit, OnChanges {
  @Input() projets: Projet[];
  @Input() aap: Aap = new Aap();
  nbrProjets: number;
  nbrEnvoye: number;
  nbrCompEnvoye: number;
  nbrCompDemande: number;
  dateReleve: any = '';
  currentDate = new Date();
  docPublicBaseUrl: string;

  EnumAapStatut = EnumAapStatut;

  constructor(public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.nbrProjets = this.getNbrProjets();
    this.nbrEnvoye = this.getNbrEnvoye();
    this.nbrCompEnvoye = this.getNbrCompEnvoye();
    this.nbrCompDemande = this.getNbrCompDemande();
    this.docPublicBaseUrl = environment.docPublicBaseUrl;
  }

  getNbrProjets(): number {
    return this.projets ? this.projets.length : 0;
  }

  getNbrEnvoye(): number {
    return this.projets ? this.projets.filter(projet => projet.statut?.toString() === 'ENVOYE').length : 0;
  }

  getNbrCompEnvoye(): number {
    return this.projets ? this.projets.filter(projet => projet.statut?.toString() === 'COMPLEMENT_ENVOYE').length : 0;
  }

  getNbrCompDemande(): number {
    return this.projets ? this.projets.filter(projet => projet.statut?.toString() === 'COMPLEMENT_DEMANDE').length : 0;
  }

  ngOnChanges(): void {
    let encours = false;
    this.dateReleve = this.aap.dateAutres;
    this.dateReleve.sort((b: DateAutres, a: DateAutres) => {
      return (new Date(b.valeur) as any) - (new Date(a.valeur) as any);
    });
    for (const date of this.dateReleve) {
      if (this.datepipe.transform(new Date(date.valeur), 'yyyy-MM-dd') < this.datepipe.transform(this.currentDate, 'yyyy-MM-dd')) {
        date.statut = 'effectue';
      } else if (
        this.datepipe.transform(new Date(date.valeur), 'yyyy-MM-dd') >= this.datepipe.transform(this.currentDate, 'yyyy-MM-dd') &&
        !encours
      ) {
        date.statut = 'encours';
        encours = true;
      } else {
        date.statut = 'prochain';
      }
    }
    this.nbrProjets = this.getNbrProjets();
    this.nbrEnvoye = this.getNbrEnvoye();
    this.nbrCompEnvoye = this.getNbrCompEnvoye();
    this.nbrCompDemande = this.getNbrCompDemande();
  }
}
