import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AapService } from '@services-evaluateur/aap.service';
import {
  Aap,
  ArrayUtils,
  DsMultiselectComponent,
  EnumPhaseComite,
  FilterDataInterface,
  FilterDateReleve,
  SearchObjectComites,
  ShowToastrService,
  getEtapeDataList,
  getStatutDataList,
} from '@shared-ui';

@Component({
  selector: 'pxl-project-search-bar-comites',
  templateUrl: './project-search-bar-comites.component.html',
  styleUrls: ['./project-search-bar-comites.component.scss'],
})
export class ProjectSearchBarComitesComponent implements OnInit {
  readonly ACRONYME = 'acronymes';
  readonly RS_SIRET = 'sirets';

  // Event emitter
  @Output() searchEventEmitter = new EventEmitter<SearchObjectComites>();

  // Données filtres
  @Input() programmeDataList: FilterDataInterface[] = [];
  @Input() phase: EnumPhaseComite;

  aapDataList: FilterDataInterface[] = [];
  dateReleveDataList: FilterDataInterface[] = [];
  etapeDataList: FilterDataInterface[] = [];
  statutDataList: FilterDataInterface[] = [];
  thematiqueDataList: FilterDataInterface[] = [];
  budgetValues: FilterDataInterface[] = [
    { id: 'MOINS_10M', value: 'De 0 à 10M€' },
    { id: 'PLUS_10M', value: 'Supérieur à 10M€' },
  ];

  budgetProjet: string;
  @ViewChild('programmeComponent') programmeComponent: DsMultiselectComponent;
  @ViewChild('aapComponent') aapComponent: DsMultiselectComponent;
  @ViewChild('dateReleveComponent') dateReleveComponent: DsMultiselectComponent;
  @ViewChild('statutComponent') statutComponent: DsMultiselectComponent;
  @ViewChild('etapeComponent') etapeComponent: DsMultiselectComponent;
  @ViewChild('thematiqueComponent') thematiqueComponent: DsMultiselectComponent;

  programmesIdHistory: string[] = [];
  aapsIdHistory: string[] = [];
  aaps: Aap[];

  constructor(public showToastrService: ShowToastrService, public aapService: AapService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.etapeDataList = getEtapeDataList();
    this.statutDataList = getStatutDataList();
  }

  /**
   * Fonction lancée quand on quitte la div de selection de programme
   */
  onProgrammesLeave(): void {
    const ids = this.programmeComponent.getSelectedValues().map(program => program.id.toString());

    if (ArrayUtils.arrayEquals(this.programmesIdHistory, ids)) {
      return;
    }

    this.programmesIdHistory = ids;
    this.aapService.getAapsByProgrammesId(ids).subscribe(result => {
      this.aaps = result.body;
      this.aapDataList = result.body.map(aap => {
        return {
          id: aap.id,
          value: aap.code,
          isSelected: false,
        };
      });

      if (ids.length === 0) {
        this.dateReleveComponent.disable();
        this.aapComponent.disable();
      } else {
        this.dateReleveComponent.reset();
        this.aapComponent.enable();
      }
    });
  }

  /**
   * Fonction lancée quand on quitte la div de selection d'aap
   */
  onAapsLeave(): void {
    const ids = this.aapComponent.getSelectedValues().map(aap => aap.id.toString());

    if (ArrayUtils.arrayEquals(this.aapsIdHistory, ids)) {
      return;
    }

    this.aapsIdHistory = ids;

    if (ids.length === 0) {
      this.dateReleveComponent.disable();
      this.thematiqueComponent.disable();
    } else {
      this.setDateReleveValues();
      this.setThematiqueValues();
    }
  }

  /**
   * Remplit la liste des dates de releve
   */
  setDateReleveValues(): void {
    this.dateReleveDataList = [];
    this.aaps
      .filter(aap => this.aapComponent.getSelectedValues().find(aapSelected => aapSelected.id === aap.id))
      .forEach(aap => {
        const dates = aap.dateAutres.map(date => {
          return {
            id: {
              dateReleve: date.valeur,
              idAap: aap.id,
            },
            value: `${this.datePipe.transform(date.valeur, 'dd/MM/yyyy')} (${aap.code})`,
            isSelected: false,
          };
        });

        this.dateReleveDataList.push(...dates);
      });

    this.dateReleveComponent.enable();
  }

  setThematiqueValues(): void {
    this.thematiqueDataList = [];
    this.aaps
      .filter(aap => this.aapComponent.getSelectedValues().find(aapSelected => aapSelected.id === aap.id))
      .forEach((aap: Aap) => {
        this.thematiqueDataList.push(
          ...aap.thematiques.map(thematique => {
            return {
              id: thematique.codeThematique,
              value: thematique.libelleThematique,
              isSelected: false,
            };
          })
        );
      });

    this.thematiqueDataList = this.thematiqueDataList.filter(
      (thematique, index, self) => index === self.findIndex(temp => temp.value === thematique.value)
    );

    this.thematiqueComponent.enable();
  }

  /**
   * Remet à zéro tous les champs de filtres
   */
  resetAllFilters(): void {
    this.programmesIdHistory = [];
    this.aapsIdHistory = [];
    this.aapComponent.dataList = [];
    this.dateReleveComponent.dataList = [];
    this.thematiqueComponent.dataList = [];
    this.budgetProjet = undefined;

    this.programmeComponent.reset();
    this.statutComponent.reset();
    this.etapeComponent.reset();
    this.thematiqueComponent.disable();
    this.aapComponent.disable();
    this.dateReleveComponent.disable();
  }

  getCurrentSearchObject(): SearchObjectComites {
    const searchObject = {} as SearchObjectComites;
    searchObject.idAAPs = this.aapComponent.getSelectedValues().map(aap => aap.id as string);
    searchObject.filtreRelevesAap = this.dateReleveComponent.getSelectedValues().map(value => value.id as FilterDateReleve);
    searchObject.statuts = this.statutComponent.getSelectedValues().map(statut => statut.id as string);
    searchObject.etapes = this.etapeComponent.getSelectedValues().map(etape => etape.id as string);
    searchObject.thematiques = this.thematiqueComponent.getSelectedValues().map(thematique => thematique.value);
    searchObject.budgetProjet = this.budgetProjet;

    return searchObject;
  }

  /**
   * Envoi de l'event de recherche projets
   */
  submitSearch(): void {
    if (this.programmeComponent.getSelectedValues()?.length <= 0) {
      this.showToastrService.error('Merci de sélectionner au moins un programme, un AAP et une date de relève.');
    } else if (this.aapComponent.getSelectedValues()?.length <= 0) {
      this.showToastrService.error('Merci de sélectionner au moins un AAP et une date de relève.');
    } else if (this.dateReleveComponent.getSelectedValues()?.length <= 0) {
      this.showToastrService.error('Merci de sélectionner au moins une date de relève.');
    } else {
      const searchObject = this.getCurrentSearchObject();
      this.searchEventEmitter.emit(searchObject);
    }
  }
}
