import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AapService } from '@services-evaluateur/aap.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { MultiselectComponent } from '@shared-evaluateur/components/multiselect/multiselect.component';
import {
  Aap,
  ArrayUtils,
  DsMultiselectComponent,
  FilterDataInterface,
  FilterRsSiretInterface,
  SearchObject,
  ShowToastrService,
} from '@shared-ui';
import { DateRangePickerComponent } from '../../../../../../../libs/shared-ui/src/lib/components/date-range-picker/date-range-picker.component';

@Component({
  selector: 'project-search-transverse',
  templateUrl: './project-search-transverse.component.html',
  styleUrls: ['./project-search-transverse.component.scss'],
})
export class ProjectSearchTransverseComponent {
  readonly ACRONYME = 'acronymes';
  readonly RS_SIRET = 'sirets';

  // Event emitter
  @Output() searchEventEmitter = new EventEmitter<any>();

  // Données filtres
  @Input() programmeDataList: FilterDataInterface[] = [];
  @Input() etapeDataList: FilterDataInterface[] = [];
  @Input() statutDataList: FilterDataInterface[] = [];
  @Input() typeProjetDataList: FilterDataInterface[] = [];
  aapDataList: FilterDataInterface[] = [];

  aaps: Aap[];

  @ViewChild('programmeComponent') programmeComponent: DsMultiselectComponent;
  @ViewChild('aapComponent') aapComponent: DsMultiselectComponent;
  @ViewChild('etapeComponent') etapeComponent: DsMultiselectComponent;
  @ViewChild('statutComponent') statutComponent: DsMultiselectComponent;
  @ViewChild('acronymeComponent') acronymeComponent: MultiselectComponent;
  @ViewChild('rsSiretComponent') rsSiretComponent: MultiselectComponent;
  @ViewChild('thematiqueComponent') thematiqueComponent: DsMultiselectComponent;
  @ViewChild('typeProjetComponent') typeProjetComponent: DsMultiselectComponent;
  @ViewChild('dateReleveComponent') dateReleveComponent: DateRangePickerComponent;

  // Données acronyme
  acronymesDataList: FilterDataInterface[] = [];
  acronymesSelectedList: FilterDataInterface[] = [];

  // Données Rs Siret
  rsSiretDataList: FilterDataInterface[] = [];
  rsSiretSelectedList: FilterDataInterface[] = [];

  // Afficher ou non la ligne de filtres
  accordionOpen = true;

  // Chargement
  loadingMap = new Map();

  // Activer ou non le reste des champs
  fieldsActive = false;

  programmesIdHistory: string[] = [];
  aapsIdHistory: string[] = [];

  constructor(private projetService: ProjetService, private showToastrService: ShowToastrService, private aapService: AapService) {
    this.loadingMap.set(this.ACRONYME, false);
    this.loadingMap.set(this.RS_SIRET, false);
  }

  /**
   * Fonction lancée quand on quitte la div de selection de programme
   */
  onProgrammesLeave(): void {
    const ids = this.programmeComponent.getSelectedValues().map(program => program.id.toString());

    if (ArrayUtils.arrayEquals(this.programmesIdHistory, ids)) {
      return;
    }

    this.programmesIdHistory = ids;
    this.aapService.getAapsByProgrammesId(ids).subscribe(result => {
      this.aaps = result.body;
      this.aapDataList = result.body.map(aap => {
        return {
          id: aap.id,
          value: aap.code,
          isSelected: false,
        };
      });
      this.fieldsActive = false;
      this.resetSecondaryFilters();

      setTimeout(() => {
        this.aapComponent.reset();
      }, 10);
    });
  }

  /**
   * Fonction lancée quand on quitte la div de selection d'aap
   */
  onAapsLeave(): void {
    const ids = this.aapComponent.getSelectedValues().map(aap => aap.id.toString());

    if (ArrayUtils.arrayEquals(this.aapsIdHistory, ids)) {
      return;
    }

    this.aapsIdHistory = ids;

    this.fieldsActive = ids?.length > 0;

    this.etapeComponent.selectOptions.isDisabled = !this.fieldsActive;
    this.statutComponent.selectOptions.isDisabled = !this.fieldsActive;
    this.thematiqueComponent.selectOptions.isDisabled = !this.fieldsActive;
    this.typeProjetComponent.selectOptions.isDisabled = !this.fieldsActive;

    this.setThematiques();

    this.resetSecondaryFilters();
  }

  setThematiques(): void {
    this.thematiqueComponent.dataList = [];
    this.aaps
      .filter(aap => this.aapComponent.getSelectedValues().filter(selectedAap => aap.id === selectedAap.id).length > 0)
      .forEach((aap: Aap) => {
        this.thematiqueComponent.dataList.push(
          ...aap.thematiques.map(thematique => {
            return {
              id: thematique.codeThematique,
              value: thematique.libelleThematique,
              isSelected: false,
            };
          })
        );
      });

    this.thematiqueComponent.dataList = this.thematiqueComponent.dataList.filter(
      (thematique, index, self) => index === self.findIndex(temp => temp.value === thematique.value)
    );
  }

  /**
   * Récupère l'input du composant fils, lance la recherche d'autocomplete, ensuite actualise la liste des 10 options
   * @param event Event envoyé par le composant fils de filtre, soit le texte écrit dans l'input
   * @param source Le nom du composant fils qui a emit cet event
   */
  autocompleteEvent(event: string, source: string): void {
    this.loadingMap.set(source, true);
    const searchObject = this.getCurrentSearchObject();
    searchObject.idAAPs = this.aapComponent.getSelectedValues().map((aap: FilterDataInterface) => aap.id.toString());
    switch (source) {
      case this.ACRONYME:
        searchObject.inputAcronyme = event;
        break;
      case this.RS_SIRET:
        searchObject.inputSiretRS = event;
        break;
    }

    this.projetService.getAutocomplete(source, searchObject).subscribe({
      next: resultAutocomplete => {
        switch (source) {
          case this.ACRONYME:
            this.acronymesDataList = (resultAutocomplete.body as string[]).map(acronyme => ({ id: acronyme, value: acronyme }));
            break;
          case this.RS_SIRET:
            this.rsSiretDataList = (resultAutocomplete.body as FilterRsSiretInterface[]).map(rsSiret => ({
              id: rsSiret,
              value: (rsSiret.siret != null && rsSiret.siret?.trim() !== '' ? rsSiret.siret + ' - ' : '') + rsSiret.raisonSocial,
            }));
            break;
        }
        this.loadingMap.set(source, false);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
        this.loadingMap.set(source, false);
      },
    });
  }

  /**
   * Récupère la nouvelle valeur de la liste d'options sélectionnées sur le composant fils
   * @param event Event envoyé par le composant fils de filtre, soit la liste des options sélectionnées sur le composant
   * @param source Le nom du composant fils qui a emit cet event
   */
  selectedListChange(event: FilterDataInterface[], source: string): void {
    switch (source) {
      case this.ACRONYME:
        this.acronymesSelectedList = event;
        break;
      case this.RS_SIRET:
        this.rsSiretSelectedList = event;
        break;
    }
  }

  /**
   * Remet à zéro tous les champs de filtres
   */
  resetAllFilters(): void {
    this.fieldsActive = false;

    this.programmesIdHistory = [];
    this.aapsIdHistory = [];
    this.aapComponent.dataList = [];

    this.programmeComponent.reset();
    this.aapComponent.reset();

    this.resetSecondaryFilters();
  }

  /**
   * Remet à zéro les champs des filtres secondaires (tous sauf AAP et Programme)
   */
  resetSecondaryFilters(): void {
    this.etapeComponent.selectOptions.isDisabled = !this.fieldsActive;
    this.statutComponent.selectOptions.isDisabled = !this.fieldsActive;
    this.thematiqueComponent.selectOptions.isDisabled = !this.fieldsActive;
    this.typeProjetComponent.selectOptions.isDisabled = !this.fieldsActive;

    this.acronymeComponent.reset();
    this.rsSiretComponent.reset();
    this.thematiqueComponent.reset();
    this.etapeComponent.reset();
    this.statutComponent.reset();
    this.typeProjetComponent.reset();
    this.dateReleveComponent.reset();
  }

  getCurrentSearchObject(): SearchObject {
    const searchObject = new SearchObject();
    searchObject.idAAPs = this.aapComponent.getSelectedValues().map(aap => aap.id as string);
    searchObject.etapes = this.etapeComponent.getSelectedValues().map(etape => etape.id as string);
    searchObject.statuts = this.statutComponent.getSelectedValues().map(statut => statut.id as string);
    searchObject.acronymes = this.acronymesSelectedList.map(acronyme => acronyme.id as string);
    searchObject.siretRaisonSociale = this.rsSiretSelectedList.map(rs => ({
      siret: (rs.id as FilterRsSiretInterface).siret,
      raisonSocial: (rs.id as FilterRsSiretInterface).raisonSocial,
    }));
    searchObject.thematiques = this.thematiqueComponent.getSelectedValues().map(thematique => thematique.value);
    searchObject.partenaireType = this.typeProjetComponent.getSelectedValues().map(type => type.id as string);

    searchObject.rangeDatesReleve = this.dateReleveComponent.getSelectedDate();

    return searchObject;
  }

  submitSearch(): void {
    if (this.programmeComponent.getSelectedValues()?.length <= 0) {
      this.showToastrService.error('Merci de sélectionner au moins un programme et un AAP.');
    } else if (this.aapComponent.getSelectedValues()?.length <= 0) {
      this.showToastrService.error('Merci de sélectionner au moins un AAP.');
    } else {
      const searchObject = this.getCurrentSearchObject();
      const aaps = this.aaps?.filter(aap => searchObject.idAAPs.includes(aap.id));
      this.searchEventEmitter.emit({
        searchObject,
        aaps,
      });
    }
  }
}
