import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ShowToastrService } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class MultiEmailService {
  public constructor(private showToastrService: ShowToastrService) {}

  public findIncorrectEmails(form: UntypedFormGroup, value: string): string[] {
    const incorrectList: string[] = [];
    const formValue = form.get(value) as UntypedFormGroup;

    let emailsList = formValue.value.split(';');
    emailsList = emailsList.filter((email: string) => email.trim() !== '');

    // Alimentation de la liste d'emails incorrects
    emailsList.forEach((email: string) => {
      const trimmedEmail = email.trim();
      if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(trimmedEmail)) {
        incorrectList.push(trimmedEmail);
      }
    });

    return incorrectList;
  }

  public showEmailsError(form: UntypedFormGroup, value: string): void {
    const incorrectList: string[] = this.findIncorrectEmails(form, value);

    // Ouvre un toastr d'erreur
    this.showToastrService.error('Emails incorrects : ' + incorrectList);
  }
}
