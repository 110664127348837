<section class="form-step">
  <div class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span>
      Phases
    </h2>
  </header>

  <ng-container *ngFor="let phaseCardConfig of phaseOptions">
    <div class="phase-card">
      <pxl-phase-card
        [formGroup]="phaseCardConfig.formGroup"
        [phaseType]="phaseCardConfig.phaseType"
        [title]="phaseCardConfig.title"
      ></pxl-phase-card>
    </div>
  </ng-container>
</section>
