import { Component, Input } from '@angular/core';

@Component({
  selector: 'pxl-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  @Input() routerLink!: string;
  @Input() title!: string;
  @Input() iconName!: string;
}
