import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FilterDataInterface, FilterRsSiretInterface } from '@shared-ui';

@Component({
  selector: 'custom-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent {
  readonly TIMEOUT_MS = 300;

  @ViewChild('search') searchElement: ElementRef;

  @Input() dataList: FilterDataInterface[];
  @Output() dataListChange = new EventEmitter<FilterDataInterface[]>();
  @Input() selectedList: FilterDataInterface[];
  @Output() selectedListChange = new EventEmitter<FilterDataInterface[]>();

  @Input() name: string;
  @Input() placeholder: string;
  @Input() isLoading = false;
  @Input() isDisabled = false;

  @Output() autocompleteEvent = new EventEmitter<string>();

  focused = false;
  autocompleteInputValue = '';
  autocompleteTimeout: NodeJS.Timeout;

  emitSelectedList(): void {
    this.selectedListChange.emit(this.selectedList);
  }

  onFocus(): void {
    this.focused = true;
    this.searchElement.nativeElement.focus();
  }

  onFocusOut(): void {
    this.focused = false;
  }

  /**
   * Vérifie si la liste d'options doit être affichée ou non
   * @returns boolean indiquant si la liste doit être affichée ou non
   */
  showList(): boolean {
    return this.focused && (this.selectedList?.length > 0 || this.autocompleteInputValue?.trim().length > 2);
  }

  /**
   * Fonction appelée quand la valeur de l'input change
   */
  onSearchInputChange(): void {
    clearTimeout(this.autocompleteTimeout);
    if (this.autocompleteInputValue.length > 2) {
      this.autocompleteTimeout = setTimeout(() => {
        this.autocompleteEvent.emit(this.autocompleteInputValue?.trim());
      }, this.TIMEOUT_MS);
    } else {
      this.dataList = null;
    }
  }

  /**
   * Clic sélection checkbox
   * @param option checkbox à selectionner
   */
  checkboxSelected(e: Event, option: FilterDataInterface): void {
    e.stopPropagation();
    this.selectedList.push(option);
    this.emitSelectedList();
  }

  /**
   * Clic déselection checkbox
   * @param option checkbox à déselectionner
   */
  checkboxUnselected(e: Event, option: FilterDataInterface): void {
    e.stopPropagation();
    this.selectedList.splice(this.selectedList.indexOf(option), 1);
    this.emitSelectedList();
  }

  /**
   * Determine si on doit afficher une checkbox, car si l'option est déjà
   * sélectionnée, on ne doit pas l'afficher sur la liste de choix
   * @param option checkbox à afficher ou non
   * @returns true si on affiche la checkbox et false sinon
   */
  showFilterOption(option: FilterDataInterface): boolean {
    const find = this.selectedList.filter(data => {
      if (typeof data.id === 'string') {
        return data.id === option.id;
      } else {
        const optionId = option.id as FilterRsSiretInterface;
        return (
          (data.id as FilterRsSiretInterface).raisonSocial === optionId.raisonSocial &&
          (data.id as FilterRsSiretInterface).siret === optionId.siret
        );
      }
    });

    return find.length === 0;
  }

  /**
   * Clic sur la checkbox Sélectionner tout
   */
  checkboxAllClicked(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
    if (this.isCheckboxAllChecked()) {
      this.selectedList = [];
    } else {
      this.dataList.forEach(data => {
        if (this.selectedList.filter(selected => data.id === selected.id).length === 0) {
          this.selectedList.push(data);
        }
      });
    }
    this.emitSelectedList();
  }

  /**
   * Determine si la checkbox Sélectionner tout est checkée ou non
   * @returns true si la checkbox est sélectionnée, false sinon
   */
  isCheckboxAllChecked(): boolean {
    return this.selectedList.length > 0;
  }

  /**
   * Retourne la valeur qui sera affichée sur le placeholder
   */
  getPlaceHolderValue(): string {
    const addCharS = this.selectedList?.length > 1 ? 's' : '';
    return this.selectedList?.length > 0 ? `${this.selectedList?.length} "${this.placeholder}" sélectionné` + addCharS : this.placeholder;
  }

  /**
   * Fonction qui réinitialise les valeurs du champs aux valeurs par défaut
   */
  reset(): void {
    this.autocompleteInputValue = '';
    this.selectedList = [];
    this.emitSelectedList();
  }
}
