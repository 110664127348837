export class PartenaireCsv {
  nomProgramme: string;
  nomAap: string;
  identifiantPXL: string;
  acronyme: string;
  titreProjet: string;
  raisonSocialSiege: string;
  roleStructure: string;
  typeStruture: string;
  siretSiege: string;
  sirenSiege: string;
  adresseSiege: string;
  departementSiege: string;
  paysSiege: string;

  adressePerso: string;
  departementPerso: string;
  regionAdministrativePerso: string;
  paysPerso: string;

  genreRl: string;
  nomRl: string;
  prenomRl: string;
  emailRl: string;
  telRl: string;
  siretRD: string;
  raisonSocialRD: string;
  adresseRD: string;
  departementRD: string;
  regionAdministrativeRD: string;
  paysRD: string;
  demandeFinancement: string;
  budget: string;
  montantAide: string;
  budgetTotal: string;
  regionAdministrative: string;
  statut: string;
  etape: string;
  dateValidationEtape: string;
  dateReleve: string;
}
