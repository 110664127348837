import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CriteresReferentielComponent } from './criteres-referentiel.component';
import { CritereReferentielCardModule } from '@features-evaluateur/aap-creation/component/criteres-referentiel/critere-referentiel-card/critere-referentiel-card.module';
import { SharedUiModule } from '@shared-ui';
import { SharedModule } from '@shared-evaluateur/shared.module';

@NgModule({
  declarations: [CriteresReferentielComponent],
  imports: [CommonModule, CritereReferentielCardModule, SharedUiModule, SharedModule],
  exports: [CriteresReferentielComponent],
})
export class CriteresEvaluationModule {}
