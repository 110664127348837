<div class="form-step__content" [formGroup]="structureForm">
  <!-- siretRD de la structure -->
  <div
    id="input-siretRD"
    class="ds-input-group"
    [class.ds-input-group--error]="structureForm.controls['siret'].invalid && (structureForm.controls['siret'].touched || fomSubmited)"
  >
    <label for="siret" class="ds-input-group__label">SIRET <span class="ds-input-group__label-asterisk"> *</span></label>
    <span class="ds-input-group__input">
      <input
        id="siret"
        name="siret"
        type="text"
        [maxlength]="lengthSiret"
        placeholder="SIRET de la structure"
        class="ds-input-group__input"
        formControlName="siret"
        (keypress)="onKeyPressSiret()"
        (focus)="writeSiret()"
        (focusout)="parseSiret()"
        (input)="disableSiret($event)"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-siret"
      *ngIf="structureForm.controls['siret'].invalid && (structureForm.controls['siret'].touched || fomSubmited)"
    >
      <div class="ds-input-group__feedback">
        Merci de bien vouloir renseigner un SIRET valide ou de sélectionner la raison de son absence.
      </div>
    </ng-container>

    <div id="input-siret">
      <!-- No Siret -->
      <div class="no_siret">
        <input
          for="en-cours-imm"
          type="radio"
          id="en-cours-imm"
          name="raisonSiret"
          formControlName="raisonSiret"
          value="IMMATRICULATION_EN_COURS"
          (click)="selectRaison($event)"
        />
        <label for="en-cours-imm" class="ds-input-group__label immatriculation-label">Structure en cours d'immatriculation au RCS</label>
      </div>
      <div class="no_siret">
        <input
          for="non-imm"
          type="radio"
          id="non-imm"
          formControlName="raisonSiret"
          name="raisonSiret"
          value="NON_IMMATRICULE"
          (click)="selectRaison($event)"
        />
        <label for="non-imm" class="ds-input-group__label immatriculation-label">Structure non immatriculée au RCS</label>
      </div>
      <ng-container *ngIf="aap?.personnePhysique">
        <div class="no_siret">
          <input
            for="en-cours-crea"
            type="radio"
            id="en-cours-crea"
            formControlName="raisonSiret"
            name="raisonSiret"
            value="EN_COURS_DE_CREATION"
            (click)="selectRaison($event)"
          />
          <label for="en-cours-crea" class="ds-input-group__label immatriculation-label">Structure en cours de création</label>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- Type de la structure -->
  <div
    id="input-raison"
    class="ds-input-group"
    [class.ds-input-group--error]="
      structureForm.controls['typeStructure'].invalid && (structureForm.controls['typeStructure'].touched || fomSubmited)
    "
  >
    <label for="typeStructure" class="ds-input-group__label"
      >Type de structure <span class="ds-input-group__label-asterisk"> *</span></label
    >
    <div class="ds-input-group__select ds-input-group__select--with-icon">
      <select id="typeStructure" name="typeStructure" formControlName="typeStructure">
        <option [ngValue]="null" selected disabled hidden>Merci de sélectionner votre type de structure</option>
        <option *ngFor="let item of EnumTypeStructure.forPersonneMorale()" [value]="item">{{ EnumTypeStructure.toString(item) }}</option>
      </select>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container
      *ngIf="structureForm.controls['typeStructure'].invalid && (structureForm.controls['typeStructure'].touched || fomSubmited)"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un type de structure valide</div>
    </ng-container>
  </div>
  <!-- Raison sociale -->
  <div
    id="input-raison"
    class="ds-input-group"
    [class.ds-input-group--error]="
      structureForm.controls['raisonSocial'].invalid && (structureForm.controls['raisonSocial'].touched || fomSubmited)
    "
  >
    <label for="raison" class="ds-input-group__label">Raison Sociale <span class="ds-input-group__label-asterisk"> *</span></label>
    <span class="ds-input-group__input">
      <input
        id="raison"
        name="raisonSocial"
        type="text"
        maxlength="100"
        placeholder="Raison sociale de la structure"
        class="input_raison ds-input-group__input"
        formControlName="raisonSocial"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container *ngIf="structureForm.controls['raisonSocial'].invalid && (structureForm.controls['raisonSocial'].touched || fomSubmited)">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une raison sociale valide</div>
    </ng-container>
  </div>

  <!-- Role de la structure -->
  <div
    id="input-role"
    class="form-step__role"
    *ngIf="roleSelectable"
    [class.ds-input-group--error]="!structureForm.controls['role'].valid && (structureForm.controls['role'].touched || fomSubmited)"
  >
    <div class="ds-input-group__label">Rôle <span class="ds-input-group__label-asterisk"> *</span></div>
    <div class="select-container">
      <div id="id_input-role-select" class="input-role-select role-select">
        <input formControlName="role" type="radio" name="role" id="candidat" value="CANDIDAT" hidden />
        <label for="candidat">Candidat</label>
        <input formControlName="role" type="radio" name="role" id="chef" value="CHEF_DE_FILE" hidden />
        <label for="chef">Chef de file</label>
        <input formControlName="role" type="radio" name="role" id="mandataire" value="MANDATAIRE" hidden />
        <label for="mandataire">Mandataire</label>
      </div>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container *ngIf="!structureForm.controls['role'].valid && (structureForm.controls['role'].touched || fomSubmited)">
      <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner votre rôle</div>
    </ng-container>
    <!--Informations siege -->
    <div class="card--blue-paragraph role-message">
      <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
      <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.CANDIDAT" class="card--info--text">
        La structure avec le rôle Candidat est partie prenante du projet. Elle doit renseigner les éléments de sa structure et pourra
        accéder aux autres données du projet en lecture seule. Le nombre de structure avec le rôle Candidat n'est pas limité.
      </p>
      <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.CHEF_DE_FILE" class="card--info--text">
        La structure avec le rôle Chef de file est partie prenante du projet. Elle est en charge de renseigner les éléments du projet,
        assure la coordination du dépôt et aura la charge de transmettre le dossier complet à Bpifrance. La structure Chef de file possède
        un droit d'écriture sur l'ensemble des sections projet et structure hors grille d'impacts. Il n'y a qu'une seule structure avec le
        rôle Chef de file sur le projet.
      </p>
      <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.MANDATAIRE" class="card--info--text">
        La structure avec le rôle Mandataire <b>ne prend pas part au projet</b>. Elle possède un mandat pour venir déposer tout ou partie du
        dossier au nom de son ou ses clients, et aura la charge de transmettre le dossier complet à Bpifrance. La structure Mandataire
        possède un droit d'écriture sur l'ensemble des sections projet et structure. Il n'y a qu'une seule structure avec le rôle Mandataire
        sur le projet.
      </p>
    </div>
  </div>
</div>
