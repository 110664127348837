import { Component, OnInit } from '@angular/core';
import { PxlOidcService } from '@services-evaluateur/pxl-oidc.service';

@Component({
  selector: 'auto-login',
  template: '',
})
export class AutoLoginComponent implements OnInit {
  constructor(public oidcSecurityService: PxlOidcService) {}

  ngOnInit(): void {
    this.oidcSecurityService.authorize(localStorage.getItem('idp'));
  }
}
