import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrilleImpactComponent } from './grille-impact.component';
import { GrilleImpactTableauModule } from './grille-impact-tableau/grille-impact-tableau.module';

@NgModule({
  declarations: [GrilleImpactComponent],
  imports: [CommonModule, GrilleImpactTableauModule],
  exports: [GrilleImpactComponent],
})
export class GrilleImpactModule {}
