<pxl-sidenav-creation-organisme (anchorCall)="scrolltoAnchor($event)" [organisme]="organisme"> </pxl-sidenav-creation-organisme>

<main class="page-content">
  <form *ngIf="organisme" class="form" [formGroup]="organismeForm">
    <section class="form-step">
      <div id="anchor1" class="form-step__anchor"></div>
      <header class="form-step__header">
        <h2 class="form-step__title"><span class="form-step__number">1</span>Informations générales</h2>
      </header>

      <div class="form-step__content">
        <div
          class="form-step__nom ds-input-group"
          [class.ds-input-group--error]="!organismeForm.controls['nom']?.valid && organismeForm.controls['nom']?.touched"
        >
          <label for="creation_organisme_nom" class="ds-input-group__label">Nom de l'organisme<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="creation_organisme_nom"
              name="nom"
              type="text"
              maxlength="50"
              class="ds-input-group__input"
              formControlName="nom"
              placeholder="Nom commercial de l'organisme"
            />
          </span>
          <ng-container *ngIf="!organismeForm.controls['nom']?.valid && organismeForm.controls['nom']?.touched">
            <div class="ds-input-group__feedback">Merci de renseigner le nom</div>
          </ng-container>
        </div>

        <div
          class="form-step__raison-sociale ds-input-group"
          [class.ds-input-group--error]="
            !organismeForm.controls['raisonSociale']?.valid && organismeForm.controls['raisonSociale']?.touched
          "
        >
          <label for="creation_organisme_raison_sociale" class="ds-input-group__label">Raison sociale<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="creation_organisme_raison_sociale"
              name="raisonSociale"
              type="text"
              maxlength="150"
              class="ds-input-group__input"
              formControlName="raisonSociale"
              placeholder="Raison sociale de l'organisme"
            />
          </span>
          <ng-container *ngIf="!organismeForm.controls['raisonSociale']?.valid && organismeForm.controls['raisonSociale']?.touched">
            <div class="ds-input-group__feedback">Merci de renseigner la raison sociale</div>
          </ng-container>
        </div>

        <div
          class="form-step__siret ds-input-group"
          [class.ds-input-group--error]="!organismeForm.controls['siret']?.valid && organismeForm.controls['siret']?.touched"
        >
          <label for="creation_organisme_siret" class="ds-input-group__label">SIRET<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              libSiret
              id="creation_organisme_siret"
              name="siret"
              type="text"
              class="ds-input-group__input"
              formControlName="siret"
              placeholder="SIRET de l'organisme"
            />
          </span>
          <ng-container *ngIf="!organismeForm.controls['siret']?.valid && organismeForm.controls['siret']?.touched">
            <div class="ds-input-group__feedback">Merci de renseigner un siret valide</div>
          </ng-container>
        </div>

        <div
          class="form-step__radicaux-mail ds-input-group"
          [class.ds-input-group--error]="
            !organismeForm.controls['radicauxMails']?.valid && organismeForm.controls['radicauxMails']?.touched
          "
        >
          <label for="creation_organisme_radicaux_mail" class="ds-input-group__label">Radicaux mail<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="creation_organisme_radicaux_mail"
              name="radicauxMails"
              type="text"
              maxlength="100"
              class="ds-input-group__input"
              formControlName="radicauxMails"
              placeholder="Radicaux mail rattachés à l'organisme (@xxxx.xx; @xxxx.xxx.xx;...)"
            />
          </span>
          <ng-container *ngIf="!organismeForm.controls['radicauxMails']?.valid && organismeForm.controls['radicauxMails']?.touched">
            <div class="ds-input-group__feedback">Merci de renseigner le ou les radicaux mail</div>
          </ng-container>
        </div>

        <div
          class="form-step__validateur ds-input-group"
          [class.ds-input-group--error]="!organismeForm.controls['instructeursValideursEmails']?.valid"
        >
          <label for="creation_organisme_validateur" class="ds-input-group__label d-flex"
            >Mails des valideurs
            <div [matTooltip]="aide" class="d-flex align-items-center">
              <lib-pxl-icon class="icon-info" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </label>
          <span class="ds-input-group__input">
            <input
              id="creation_organisme_validateur"
              name="instructeursValideursEmails"
              type="text"
              maxlength="200"
              class="ds-input-group__input"
              formControlName="instructeursValideursEmails"
              placeholder="Mails séparés par un point virgule des personnes qui valident les accès pour cet organisme (email1@domain.fr; email2@domain.fr;...)"
            />
          </span>
        </div>

        <div
          class="form-step__critere-affichage ds-input-group"
          [class.ds-input-group--error]="!organismeForm.controls['type']?.valid && organismeForm.controls['type']?.touched"
        >
          <label for="creation_organisme_critere-affichage" class="ds-input-group__label"
            >Critère d'affichage des projets<lib-asterisk></lib-asterisk
          ></label>
          <div class="ds-input-group__select ds-input-group__select--with-icon">
            <select id="creation_organisme_critere-affichage" name="type" formControlName="type">
              <option [ngValue]="null" selected disabled hidden>Sélectionner un critère</option>
              <option *ngFor="let item of organismeTypeTech | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
            </select>
          </div>
          <ng-container *ngIf="!organismeForm.controls['type']?.valid && organismeForm.controls['type']?.touched">
            <div class="ds-input-group__feedback">Merci de renseigner le critère d'affichage</div>
          </ng-container>
        </div>

        <div
          class="form-step__departement ds-input-group"
          [class.ds-input-group--error]="
            displayTypeInput && !organismeForm.controls['departements']?.valid && organismeForm.controls['departements']?.touched
          "
        >
          <label for="creation_organisme_departement" class="ds-input-group__label"
            >Liste des départements<lib-asterisk *ngIf="displayTypeInput"></lib-asterisk
          ></label>
          <div
            class="ds-select ds-select-group"
            [class.ds-select--erreur]="
              displayTypeInput && !organismeForm.controls['departements']?.valid && organismeForm.controls['departements']?.touched
            "
            *ngIf="dataListDepartement.length"
          >
            <lib-ds-multiselect
              #departementComponent
              class="filter"
              [dataList]="dataListDepartement"
              [selectOptions]="{ placeholder: 'Sélectionner les départements liés à l\'organisme', isDisabled: !displayTypeInput }"
              [name]="'creation_organisme_departement'"
            >
            </lib-ds-multiselect>
          </div>
          <ng-container
            *ngIf="displayTypeInput && !organismeForm.controls['departements']?.valid && organismeForm.controls['departements']?.touched"
          >
            <div class="ds-input-group__feedback">Merci de renseigner le/les département(s)</div>
          </ng-container>
        </div>

        <div
          class="form-step__statuts-visibilite ds-input-group"
          [class.ds-input-group--error]="
            displayTypeInputThematique &&
            !organismeForm.controls['statutsVisibilite']?.valid &&
            organismeForm.controls['statutsVisibilite']?.touched
          "
        >
          <label for="creation_organisme_statuts-visibilite" class="ds-input-group__label d-flex"
            >Etape(s) et statut(s) des projets visibles de l'organisme<lib-asterisk *ngIf="displayTypeInputThematique"></lib-asterisk>
            <div [matTooltip]="aideStatut" class="d-flex align-items-center">
              <lib-pxl-icon class="icon-info" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon></div
          ></label>
          <div
            class="ds-select ds-select-group"
            [class.ds-select--erreur]="
              displayTypeInputThematique &&
              !organismeForm.controls['statutsVisibilite']?.valid &&
              organismeForm.controls['statutsVisibilite']?.touched
            "
          >
            <lib-ds-multiselect
              #statutsVisibilityComponent
              class="filter"
              [dataList]="dataList"
              [selectOptions]="{
                placeholder: 'Sélectionner les étapes et statuts des projets en visibilité de l\'organisme',
                isDisabled: !displayTypeInputThematique
              }"
              [name]="'creation_organisme_statuts-visibilite'"
            >
            </lib-ds-multiselect>
          </div>
          <ng-container
            *ngIf="
              displayTypeInputThematique &&
              !organismeForm.controls['statutsVisibilite']?.valid &&
              organismeForm.controls['statutsVisibilite']?.touched
            "
          >
            <div class="ds-input-group__feedback">Merci de renseigner le/les statuts de visibilité(s)</div>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="!organismeForm.valid && saveClicked">
        <div class="ds-input-group ds-input-group--error w-100">
          <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
        </div>
      </ng-container>
    </section>

    <div class="form-actions">
      <button
        *ngIf="isNewOrganisme"
        id="creation_organisme_btn_create"
        type="submit"
        class="ds-btn ds-btn--primary"
        type="button"
        (click)="addOrganisme(organismeForm.value)"
      >
        <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
        Créer l'organisme
      </button>
      <button
        *ngIf="!isNewOrganisme"
        id="creation_organisme_btn_update"
        type="submit"
        class="ds-btn ds-btn--primary"
        type="button"
        (click)="addOrganisme(organismeForm.value)"
      >
        <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
        Modifier l'organisme
      </button>
    </div>
  </form>
</main>
