import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OrganismeService } from '@services-evaluateur/organisme.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { EnumTypeOrganisme, OrganismeModel, SubscriptionDestroyerComponent } from '@shared-ui';
import { DEPARTEMENTS } from '../../../../assets/json/departements';
import { OrganismeHelperService } from './organisme-helper.service';

@Component({
  selector: 'pxl-organisme-list',
  templateUrl: './organisme.list.component.html',
  styleUrls: ['./organisme.list.component.scss'],
})
export class OrganismeListComponent extends SubscriptionDestroyerComponent implements OnInit {
  displayedColumns: string[] = ['nom', 'raisonSociale', 'type', 'mailValideur'];
  dataSource: MatTableDataSource<OrganismeModel>;
  selection = new SelectionModel<OrganismeModel>(true, []);
  organismeType = EnumTypeOrganisme;
  @ViewChild(MatSort) sort: MatSort;
  readonly DEPARTEMENTS = DEPARTEMENTS;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public sharedFunction: SharedFunction,
    public router: Router,
    public organismeService: OrganismeService,
    public organismeHelperService: OrganismeHelperService
  ) {
    super();
    if (this.sharedFunction.isSiteAdmin()) {
      this.displayedColumns = [...this.displayedColumns, 'actions'];
    }
  }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'nav-collapsed');
    this.loadOrganismes();
    this.organismeHelperService.setDepartementData(this.DEPARTEMENTS);
  }

  loadOrganismes(): void {
    this.organismeService
      .getOrganismes()
      .pipe(this.takeUntilDestroyed())
      .subscribe((response: HttpResponse<OrganismeModel[]>) => {
        if (response) {
          this.dataSource = new MatTableDataSource(response.body);
          this.dataSource.sort = this.sort;
        }
      });
  }

  createOrganisme(): void {
    this.router.navigate(['creationOrganisme']);
  }

  updateOrganisme(organisme: OrganismeModel): void {
    this.router.navigate(['modifierOrganisme/' + organisme.id]);
    this.organismeHelperService.setOrganismeData(organisme);
  }
}
