import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle, Params } from '@angular/router';
import * as _ from 'lodash';

/** Interface for object which can store both:
 * An ActivatedRouteSnapshot, which is useful for determining whether or not you should attach a route (see this.shouldAttach)
 * A DetachedRouteHandle, which is offered up by this.retrieve, in the case that you do want to attach the stored route
 */
interface RouteStorageObject {
  snapshot: ActivatedRouteSnapshot;
  handle: DetachedRouteHandle;
}

export class CustomReuseStrategy implements RouteReuseStrategy {
  storedRoutes: { [key: string]: RouteStorageObject } = {};
  private acceptedRoutes: string[] = [];

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (this.acceptedRoutes.indexOf(route.routeConfig.path) > -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment
   * @param route This is stored for later comparison to requested routes, see `this.shouldAttach`
   * @param handle Later to be retrieved by this.retrieve, and offered up to whatever controller is using this class
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const storedRoute: RouteStorageObject = {
      snapshot: route,
      handle,
    };

    // console.log('store:', storedRoute, 'into: ', this.storedRoutes);
    // routes are stored by path - the key is the path name,
    // and the handle is stored under it so that you can only ever have one object stored for a single path
    this.storedRoutes[route.routeConfig.path] = storedRoute;
  }

  /**
   * Determines whether or not there is a stored route and, if there is, whether or not it should be rendered in place of requested route
   * @param route The route the user requested
   * @returns boolean indicating whether or not to render the stored route
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const canAttach: boolean = !!route.routeConfig && !!this.storedRoutes[route.routeConfig.path];

    if (this.shouldResetReuseStrategy(route)) {
      this.storedRoutes = {};
      return false;
    }

    if (canAttach) {
      const paramsMatch: boolean = this.compareObjects(route.params, this.storedRoutes[route.routeConfig.path].snapshot.params);
      const queryParamsMatch: boolean = this.compareObjects(
        route.queryParams,
        this.storedRoutes[route.routeConfig.path].snapshot.queryParams
      );

      return paramsMatch && queryParamsMatch;
    } else {
      return false;
    }
  }

  /**
   * Finds the locally stored instance of the requested route, if it exists, and returns it
   * @param route New route the user has requested
   * @returns DetachedRouteHandle object which can be used to render the component
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || !this.storedRoutes[route.routeConfig.path]) {
      return null;
    }

    return this.storedRoutes[route.routeConfig.path].handle;
  }

  /**
   * Determines whether or not the current route should be reused
   * @param future The route the user is going to, as triggered by the router
   * @param curr The route the user is currently on
   * @returns boolean basically indicating true if the user intends to leave the current route
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const routeConfigsMatch: boolean = future.routeConfig === curr.routeConfig;
    const paramsMatch: boolean = _.isEqual(future.firstChild?.params, curr.firstChild?.params);

    return routeConfigsMatch && paramsMatch;
  }

  /**
   * This nasty bugger finds out whether the objects are _traditionally_ equal to each other,
   * like you might assume someone else would have put this function in vanilla JS already
   * One thing to note is that it uses coercive comparison (==) on properties which both objects have, not strict comparison (===)
   * Another important note is that the method only tells you if `compare` has all equal parameters to `base`, not the other way around
   * @param base The base object which you would like to compare another object to
   * @param compare The object to compare to base
   * @returns boolean indicating whether or not the objects have all the same properties and those properties are ==
   */
  private compareObjects(base: Params, compare: Params): boolean {
    if (typeof base !== 'object' || typeof compare !== 'object') {
      return false;
    }

    const baseKeys = Object.keys(base);

    if (baseKeys.length !== Object.keys(compare).length) {
      return false;
    }

    for (const baseProperty of baseKeys) {
      if (!Object.getOwnPropertyDescriptor(compare, baseProperty)) {
        return false;
      }

      if (base[baseProperty] !== compare[baseProperty]) {
        return false;
      }
    }
    return true;
  }

  private shouldResetReuseStrategy(route: ActivatedRouteSnapshot): boolean {
    let snapshot: ActivatedRouteSnapshot = route;

    while (snapshot.children && snapshot.children.length) {
      snapshot = snapshot.children[0];
    }
    return snapshot.data && snapshot.data.resetReuseStrategy;
  }
}
