import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganismesComponent } from './organismes.component';
import { OrganismesTableauModule } from './organismes-tableau/organismes-tableau.module';
import { SharedModule } from '@shared-evaluateur/shared.module';

@NgModule({
  declarations: [OrganismesComponent],
  imports: [CommonModule, OrganismesTableauModule, SharedModule],
  exports: [OrganismesComponent],
})
export class OrganismesModule {}
