<div
  id="card-info"
  class="card card--message mb-3"
  [class.card--inactive]="!((notation.evaluateur?.matricule && canUserWrite) || isUserReadOnly)"
>
  <div>
    <div class="div_notation_eval_date_note">
      <div>
        <span class="span_eval_name_date_note">{{ getEvaluateurNomPrenom(notation) }}</span>
      </div>

      <span class="mr-3" *ngIf="notation.evaluateur?.matricule">{{ getEvaluateurEmail(notation) }}</span>
      <div class="icon-fleche" *ngIf="notation.evaluateur?.matricule && canUserWrite">
        <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
      </div>
    </div>
    <hr *ngIf="notation.dateModification" />
    <p class="p_eval_indisponible_message" *ngIf="!notation.evaluateur?.matricule">
      {{ getEvaluateurAbsentMessage() }}
    </p>
    <div class="div_notation_eval_date_note_avis">
      <div class="div_note">
        <ng-container *ngIf="notation.dateModification">
          <span class="span_eval_name_date_note">Dernière modification</span>
          <span class="p_eval_email">{{ notation.dateModification | date : 'dd/MM/yyyy' }}</span>
        </ng-container>
      </div>
      <div class="div_avis" *ngIf="notation.avis">
        <ng-container>
          <span class="span_eval_name_date_note">Avis</span>
          <div
            *ngIf="notation.avis"
            class="div_avis_projet"
            [ngClass]="{
              div_avis_projet_green: isNoteGreen(),
              div_avis_projet_orange: isNoteOrange(),
              div_avis_projet_red: isNoteRed(),
              div_avis_projet_default: isNoteBlue()
            }"
          >
            <span
              class="span_avis_projet"
              [ngClass]="{
                span_avis_projet_green: isNoteGreen(),
                span_avis_projet_orange: isNoteOrange(),
                span_avis_projet_red: isNoteRed(),
                div_avis_projet_default: isNoteBlue()
              }"
            >
              {{ notation.avis }}
              <span
                *ngIf="
                  aap?.notationInstructionTypeNote === EnumNotationTypeNote.NOTATION_NOTE_SUR_20 &&
                  stepNotation === EnumNotationEtape.INSTRUCTION
                "
              >
                /20
              </span>
            </span>
          </div>
        </ng-container>
      </div>
      <div class="div_proposition" *ngIf="notation.propositionGrandPrix !== undefined && notation.propositionGrandPrix !== null">
        <ng-container>
          <span class="span_eval_name_date_note">Proposition grand prix</span>
          <div
            class="div_proposition_projet"
            [ngClass]="{
              div_avis_projet_green: notation.propositionGrandPrix,
              div_avis_projet_red: !notation.propositionGrandPrix,
            }"
          >
            <span
              class="span_proposition_projet"
              [ngClass]="{
              span_avis_projet_green: notation.propositionGrandPrix,
              span_avis_projet_red: !notation.propositionGrandPrix,
              }"
            >
              {{ notation.propositionGrandPrix ? 'Oui' : 'Non' }}
            </span>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="div_commentaire_documents">
      <ng-container *ngIf="notation.commentaire">
        <span class="span_commentaire_titre">Commentaire</span>
        <p class="span_eval_commentaire">
          {{ readMore || !isLongCommentaire ? notation.commentaire : (notation.commentaire | slice : 0 : 250) + '...' }}
        </p>
        <button class="button_voir_plus" *ngIf="isLongCommentaire" (click)="afficherPlus()">{{ showMoreButtonText(readMore) }}</button>
      </ng-container>
    </div>

    <div class="div_commentaire_documents">
      <ng-container *ngIf="notation.recommandationIntentionCandidat">
        <span class="span_recommandation_titre">Recommandation à l'intention du candidat</span>
        <p class="span_eval_recommandation">
          {{
            readMore2 || !isLongRecommandation
              ? notation.recommandationIntentionCandidat
              : (notation.recommandationIntentionCandidat | slice : 0 : 250) + '...'
          }}
        </p>
        <button class="button_voir_plus" *ngIf="isLongRecommandation" (click)="afficherPlus2()">{{ showMoreButtonText(readMore) }}</button>
      </ng-container>
    </div>
  </div>
</div>
