<div id="card-info" class="card">
  <div>
    <div class="notation-eval-date-note">
      <div>
        <span class="eval-name-date-note" *ngIf="notation?.evaluateur?.nom">{{ getEvaluateurNomPrenom(notation) }}</span>
        <span class="eval-email-no-name" *ngIf="!notation?.evaluateur?.nom">{{ getEvaluateurEmail(notation) }}</span>
      </div>

      <div class="notation-eval-email-icons">
        <span class="eval-email" *ngIf="notation?.evaluateur?.nom">{{ getEvaluateurEmail(notation) }}</span>
        <div class="icon-fleche" *ngIf="notation.evaluateur.matricule && canUserWrite">
          <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true"></lib-pxl-icon>
        </div>
        <div class="icon-fleche" *ngIf="notation.evaluateur.matricule && !canUserWrite && notation.id && typeNotation !== 'Audition'">
          <lib-pxl-icon class="card__arrow" icon="icon-visibility" aria-hidden="true"></lib-pxl-icon>
        </div>
      </div>
    </div>
    <hr *ngIf="notation?.dateCreation" />
    <p class="eval-indisponible-message" *ngIf="sharedFunction.isSiteAdmin() && !notation.evaluateur.matricule">
      {{ getEvaluateurAbsentMessage() }}
    </p>
    <div *ngIf="notation?.dateCreation">
      <div class="notation-eval-date-note-avis">
        <div class="eval-date-note-avis">
          <ng-container *ngIf="notation.dateModification">
            <span class="eval-name-date-note">Dernière modification</span>
            <span class="eval-email">{{ notation.dateModification | date : 'dd/MM/yyyy' }}</span>
          </ng-container>
        </div>
        <div class="eval-date-note-avis">
          <ng-container *ngIf="notation.dateModification">
            <span class="eval-name-date-note">Note Projet</span>
            <div
              *ngIf="notation.grilleEvaluation?.noteProjet !== null && notation.grilleEvaluation?.noteProjet !== undefined; else noNotation"
              class="note-projet"
            >
              <span>{{ notation.grilleEvaluation?.noteProjet | number : '1.1-2' : 'fr' }} </span>
            </div>
            <ng-template #noNotation>
              <div class="avis-projet __orange">
                <span class="avis-projet-text">{{ tagNote }}</span>
              </div>
            </ng-template>
          </ng-container>
        </div>
        <div class="eval-date-note-avis" *ngIf="notation.evaluateurSouhaiteNoter !== false && (notation.avis || notation.avisExpert.avis)">
          <span class="eval-name-date-note">Avis</span>
          <div
            *ngIf="notation.avis ?? notation.avisExpert.avis"
            class="avis-projet"
            [ngClass]="{
              __green: isNoteGreen(),
              __red: isNoteRed(),
              __orange: isNoteOrange(),
              __default: isNoteBlue()
            }"
          >
            <span class="avis-projet-text">
              {{ notation.avis ?? notation.avisExpert.avis
              }}<span class="fw-bold">{{ getNotationType() === EnumNotationTypeNote.NOTATION_NOTE_SUR_20 ? '/20' : '' }}</span>
            </span>
          </div>
        </div>

        <div class="div_tags">
          <span id="role-structure" *ngIf="notation.statutPriseEnCompte?.estIgnore" class="card__tag card__tag--red tag--ignored">
            Notation ignorée
          </span>
        </div>

        <div class="eval-date-note-avis" *ngIf="notation.interventionPI === true">
          <div class="avis-projet __orange">
            <span class="avis-projet-text"> Risque juridique et propriété intellectuelle </span>
          </div>
        </div>

        <div
          class="tag-notation"
          *ngIf="
            sharedFunction.isSiteAdmin() &&
            notation.evaluateur?.utilisateurRole === IntervenantRoles.EXPERT_INDEPENDANT &&
            stepNotation === 'AUDITION'
          "
        >
          <div>
            <span id="role-structure" *ngIf="notation.statut === 'FAITE'" class="card__tag card__tag--green"> Notation envoyée </span>
            <span
              id="role-structure"
              *ngIf="notation.statut !== 'FAITE' && isNotationAuditionCompletedExpertIndep"
              class="card__tag card__tag--blue"
            >
              Notation non envoyée
            </span>
          </div>
          <div>
            <span
              id="role-structure"
              *ngIf="notation.statut !== 'FAITE' && isNotationAuditionCompletedExpertIndep"
              class="card__tag card__tag--blue"
            >
              A confirmer
            </span>
            <span
              id="role-structure"
              *ngIf="notation.statut === 'FAITE' && !notation.statutConfirmation"
              class="card__tag card__tag--orange"
            >
              En cours de confirmation
            </span>
            <span
              id="role-structure"
              *ngIf="notation.statut === 'FAITE' && notation.statutConfirmation === 'CONFIRME'"
              class="card__tag card__tag--green"
            >
              Confirmé
            </span>
            <span
              id="role-structure"
              *ngIf="notation.statut === 'FAITE' && notation.statutConfirmation === 'NON_CONFIRME'"
              class="card__tag card__tag--red"
            >
              Non confirmé
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="synthese-infos" *ngIf="notation?.dateCreation && stepNotation === EnumNotationEtape.EVALUATION">
      <ng-container *ngIf="notation.evaluateurSouhaiteNoter !== false">
        <ng-container>
          <span class="synthese-info-titre">Points Forts</span>
          <ng-container *ngIf="notation?.avisExpert?.pointsForts; else pointsFortsMissing">
            <p class="synthese-info-text">
              {{
                readMorePointsForts
                  ? notation.avisExpert.pointsForts
                  : (notation.avisExpert.pointsForts | slice : 0 : 250) + (isLongPointsForts ? '...' : '')
              }}
            </p>
            <button class="button-voir-plus" *ngIf="isLongPointsForts" (click)="afficherPlusPointsForts(); $event.stopPropagation()">
              {{ showMoreButtonText(readMorePointsForts) }}
            </button>
          </ng-container>
          <ng-template #pointsFortsMissing>
            <p class="synthese-info-text">Non renseignés</p>
          </ng-template>
        </ng-container>

        <ng-container>
          <span class="synthese-info-titre">Points Faibles</span>
          <ng-container *ngIf="notation?.avisExpert?.pointsFaibles; else pointsFaiblesMissing">
            <p class="synthese-info-text">
              {{
                readMorePointsFaibles
                  ? notation.avisExpert.pointsFaibles
                  : (notation.avisExpert.pointsFaibles | slice : 0 : 250) + (isLongPointsFaibles ? '...' : '')
              }}
            </p>
            <button class="button-voir-plus" *ngIf="isLongPointsFaibles" (click)="afficherPlusPointsFaibles(); $event.stopPropagation()">
              {{ showMoreButtonText(readMorePointsFaibles) }}
            </button>
          </ng-container>
          <ng-template #pointsFaiblesMissing>
            <p class="synthese-info-text">Non renseignés</p>
          </ng-template>
        </ng-container>

        <ng-container>
          <span class="synthese-info-titre">Propositions Experts</span>
          <button
            class="button-voir-plus"
            *ngIf="notation?.propositionExpert"
            (click)="showMorePropositionsExperts(); $event.stopPropagation()"
          >
            {{ showMoreButtonText(false) }}
          </button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="notation.evaluateurSouhaiteNoter === false && isNotationEvaluateurExterne()">
        <span class="synthese-info-titre">Commentaire</span>
        <ng-container *ngIf="notation?.commentairePasNote; else commentaireMissing">
          <p class="synthese-info-text">
            {{
              readMoreCommentaire
                ? notation.commentairePasNote
                : (notation.commentairePasNote | slice : 0 : 250) + (isLongCommentaire ? '...' : '')
            }}
          </p>
          <button class="button-voir-plus" *ngIf="isLongCommentaire" (click)="afficherPlusCommentaire(); $event.stopPropagation()">
            {{ showMoreButtonText(readMoreCommentaire) }}
          </button>
        </ng-container>
        <ng-template #commentaireMissing>
          <p class="synthese-info-text">Non renseignés</p>
        </ng-template>
      </ng-container>
    </div>

    <div class="synthese-infos" *ngIf="notation?.dateCreation && stepNotation === EnumNotationEtape.AUDITION">
      <ng-container *ngIf="notation.evaluateurSouhaiteNoter !== false">
        <ng-container>
          <span class="synthese-info-titre">Avis qualitatif post-audition et commentaire pour l'instruction</span>
          <ng-container *ngIf="notation?.avisExpert?.avisPostAudition; else avisMissing">
            <p class="synthese-info-text">
              {{
                readMoreAvis
                  ? notation.avisExpert.avisPostAudition
                  : (notation.avisExpert.avisPostAudition | slice : 0 : 250) + (isLongAvis ? '...' : '')
              }}
            </p>
            <button class="button-voir-plus" *ngIf="isLongAvis" (click)="afficherPlusAvis(); $event.stopPropagation()">
              {{ showMoreButtonText(readMoreAvis) }}
            </button>
          </ng-container>
          <ng-template #avisMissing>
            <p class="synthese-info-text">Non renseignés</p>
          </ng-template>
        </ng-container>

        <ng-container>
          <span class="synthese-info-titre">Motif de refus</span>
          <ng-container *ngIf="notation?.avisExpert?.motifDeRefus; else motifMissing">
            <p class="synthese-info-text">
              {{
                readMoreMotifDeRefus
                  ? notation.avisExpert.motifDeRefus
                  : (notation.avisExpert.motifDeRefus | slice : 0 : 250) + (isLongMotifDeRefus ? '...' : '')
              }}
            </p>
            <button class="button-voir-plus" *ngIf="isLongMotifDeRefus" (click)="afficherPlusMotifDeRefus(); $event.stopPropagation()">
              {{ showMoreButtonText(readMoreMotifDeRefus) }}
            </button>
          </ng-container>
          <ng-template #motifMissing>
            <p class="synthese-info-text">Non renseignés</p>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="this.notation.evaluateur?.utilisateurRole !== IntervenantRoles.EXPERT_INDEPENDANT">
          <span class="synthese-info-titre"
            >Suggestion de réorientation vers un autre dispositif de financement ou vers une Stratégie d'Accélération</span
          >
          <ng-container *ngIf="notation?.avisExpert?.suggestionDeReorientation; else suggestionMissing">
            <p class="synthese-info-text">
              {{
                readMoreSuggestionDeReorientation
                  ? notation.avisExpert.suggestionDeReorientation
                  : (notation.avisExpert.suggestionDeReorientation | slice : 0 : 250) + (isLongSuggestionDeReorientation ? '...' : '')
              }}
            </p>
            <button
              class="button-voir-plus"
              *ngIf="isLongSuggestionDeReorientation"
              (click)="afficherPlusSuggestionDeReorientation(); $event.stopPropagation()"
            >
              {{ showMoreButtonText(readMoreSuggestionDeReorientation) }}
            </button>
          </ng-container>
          <ng-template #suggestionMissing>
            <p class="synthese-info-text">Non renseignés</p>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="notation.evaluateurSouhaiteNoter === false && isNotationEvaluateurExterne()">
        <span class="synthese-info-titre">Commentaire</span>
        <ng-container *ngIf="notation?.commentairePasNote; else commentaireMissing">
          <p class="synthese-info-text">
            {{
              readMoreCommentaire
                ? notation.commentairePasNote
                : (notation.commentairePasNote | slice : 0 : 250) + (isLongCommentaire ? '...' : '')
            }}
          </p>
          <button class="button-voir-plus" *ngIf="isLongCommentaire" (click)="afficherPlusCommentaire(); $event.stopPropagation()">
            {{ showMoreButtonText(readMoreCommentaire) }}
          </button>
        </ng-container>
        <ng-template #commentaireMissing>
          <p class="synthese-info-text">Non renseignés</p>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
