import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CritereReferentiel, CritereReferentielForm, EnumTypeCritere, ReferentielHttpService, ShowToastrService } from '@shared-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CreationReferentielHelperService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(public httpService: ReferentielHttpService, private showToastrService: ShowToastrService, private router: Router) {}

  createForm(): FormGroup<CritereReferentielForm> {
    return new FormGroup<CritereReferentielForm>({
      label: new FormControl('', Validators.required),
      aide: new FormControl(''),
      aideVisible: new FormControl(null, Validators.required),
      noteObligatoire: new FormControl(null, Validators.required),
      noteAide: new FormControl(''),
      noteAideVisible: new FormControl(null, Validators.required),
      noteCommentaire: new FormControl(null, Validators.required),
      noteCommentairePlaceholder: new FormControl(''),
    });
  }

  saveReferentielFromForm(
    form: FormGroup<CritereReferentielForm>,
    type: EnumTypeCritere,
    route: ActivatedRoute,
    isModification: boolean,
    idReferentiel: string
  ) {
    form.markAllAsTouched();

    if (form.invalid) {
      return;
    }

    const referentiel = form.value as CritereReferentiel;
    referentiel.typeCritere = (EnumTypeCritere as any)[type.toString()];
    referentiel.actif = true;

    this.httpService
      .saveReferentiel(referentiel, isModification, idReferentiel)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.showToastrService.success(
            `Votre ${type === EnumTypeCritere.CRITERE_EVALUATION ? 'critère de notation' : "indicateur d'éligibilité"} a été ${
              isModification ? 'modifié' : 'créé'
            } avec succès`
          );
          form.markAsPristine();
          this.goToReferentiel(route, isModification);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  goToReferentiel(route: ActivatedRoute, isModification: boolean): void {
    this.router.navigate([`${isModification ? '../' : ''}../${route.snapshot.data.referentielPath}`], { relativeTo: route });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
