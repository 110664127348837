import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DateTimeUtils, EnumDateType, ShowToastrService } from '@shared-ui';

import { ConfirmModalComponent } from '@shared-evaluateur/components/modals/confirm-modal/confirm-modal.component';
import { ModificationDatesModalComponent } from '@shared-evaluateur/components/modals/modification-dates-modal/modification-dates-modal.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const dsLib: any;

@Component({
  selector: 'periodicity-and-dates',
  templateUrl: './periodicity.component.html',
  styleUrls: ['./periodicity.component.scss'],
})
export class PeriodicityComponent implements OnInit, AfterViewInit {
  @Input() periodicityForm: UntypedFormGroup;
  @Input() stepNumber: number;
  minDate: any = null;

  dayHours = DateTimeUtils.generateTimes(0, 24, 60);

  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['nom', 'type', 'valeur', 'actions', 'delete'];

  otherDates: any[] = [];

  addDateForm: UntypedFormGroup;
  datePublicationJorf = {};
  dateOuverture = {};
  dateFermeture = {};
  date = {};

  constructor(private formBuilder: UntypedFormBuilder, public matDialog: MatDialog, private showToastrService: ShowToastrService) {}

  ngOnInit(): void {
    if (this.periodicityForm.value) {
      this.onChanges(this.periodicityForm.value);
    }

    this.periodicityForm.valueChanges.subscribe(form => {
      this.onChanges(form);
    });

    this.addDateForm = this.formBuilder.group({
      nom: [null, Validators.required],
      type: [EnumDateType.all()[0], Validators.required],
      date: [null, Validators.required],
      heure: [null, Validators.required],
    });

    this.updateTableSource();
  }

  ngAfterViewInit(): void {
    this.datePublicationJorf = new dsLib.InputDate('datePublicationJorf');
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.dateOuverture = new dsLib.InputDate('dateOuverture');
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.dateFermeture = new dsLib.InputDate('dateFermeture');
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.date = new dsLib.InputDate('date');
  }

  updateTableSource(): void {
    const sortedDates = this.sortDates(this.otherDates);
    this.dataSource = new MatTableDataSource(sortedDates);
  }

  sortDates(dates: any[]): any[] {
    return dates.sort((a, b) => new Date(a.valeur).getTime() - new Date(b.valeur).getTime());
  }

  onChanges(form: any): void {
    if (form.dateAutres && this.otherDates !== form.dateAutres) {
      this.otherDates = form.dateAutres;
      this.updateTableSource();
    }

    this.minDate = form.dateOuverture;
    if (form.dateFermeture && form.dateOuverture) {
      if (new Date(form.dateOuverture).getTime() === new Date(form.dateFermeture).getTime()) {
        if (form.heureFermeture <= form.heureOuverture) {
          this.periodicityForm.controls.heureFermeture.setErrors({ incorrect: true });
        }
      }
    }
  }

  addDate(addDateValue: any, formDirective: FormGroupDirective, index: number): void {
    const hours = addDateValue.heure.split(':')[0];
    const minutes = addDateValue.heure.split(':')[1];
    addDateValue.date.setHours(hours, minutes, 0);

    const dateExist = this.dataSource.data.some((element: any) => {
      return new Date(element.valeur).getTime() === addDateValue.date.getTime();
    });

    if (dateExist) {
      this.showToastrService.error('Cette date de relève existe déjà');
    } else {
      if (index !== undefined) {
        this.otherDates.splice(index, 1);
      }

      const newDate = {
        nom: index !== undefined ? addDateValue.nom : this.addDateForm.value.nom,
        type: index !== undefined ? addDateValue.type : this.addDateForm.value.type,
        valeur: index !== undefined ? addDateValue.date : this.addDateForm.value.date,
      };

      this.otherDates.push(newDate);
      this.updateTableSource();

      if (index === undefined) {
        this.addDateForm.reset();
        formDirective.resetForm();
        this.addDateForm.controls.type.setValue(EnumDateType.all()[0]);
        this.periodicityForm.get('dateAutres').setValue(this.otherDates);
      }
    }
  }

  deleteDate(index: number): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Suppression',
        description: `<p>Etes-vous sûr de vouloir supprimer cette date ?</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.otherDates.splice(index, 1);
        this.periodicityForm.get('dateAutres').setValue(this.otherDates);
        this.updateTableSource();
      }
    });
  }

  updatePeriodicity(element: any, index: number) {
    const dialogRef = this.matDialog.open(ModificationDatesModalComponent, {
      data: {
        title: 'Modification dates',
        data: element,
        textGoButton: 'Valider',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.addDate(result.data, result.dataForm, index);
      }
    });
  }

  protected readonly EnumDateType = EnumDateType;
}
