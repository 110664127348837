<div class="sidenav-creation">
  <div class="sidenav-creation__header">
    <a routerLink="/organismes" class="sidenav-creation__back">
      <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back"></lib-pxl-icon>
      Tous les organismes
    </a>

    <h1 id="organisme-title" *ngIf="organisme.id" class="sidenav-creation__title">{{ organisme.nom }}</h1>
    <h1 id="nex-organisme" *ngIf="!organisme.id" class="sidenav-creation__title">Nouvel organisme</h1>
  </div>

  <nav class="sidenav-creation__nav">
    <ol>
      <li>
        <a
          id="information-generale"
          class="sidenav-creation__step"
          [class.sidenav-creation__step--current]="currentSection === 'anchor1'"
          (click)="goToSection('anchor1'); currentSection = 'anchor1'"
        >
          <span class="sidenav-creation__step-number">1</span>
          Informations générales
        </a>
      </li>
    </ol>
  </nav>
</div>
