import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ShowToastrService } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class MultiRadicauxEmailService {
  public constructor(private showToastrService: ShowToastrService) {}

  public findIncorrectRadicauxEmails(form: UntypedFormGroup, value: string): string[] {
    const incorrectList: string[] = [];
    const formValue = form.get(value) as UntypedFormGroup;

    let radicauxEmailsList = formValue.value.split(';');
    radicauxEmailsList = radicauxEmailsList.filter((radicauxEmail: string) => radicauxEmail.trim() !== '');

    // Alimentation de la liste d'emails incorrects
    radicauxEmailsList.forEach((radicauxEmail: string) => {
      const trimmedRadicauxEmail = radicauxEmail.trim();
      if (!/^@[a-z0-9.-]+\.[a-z]{2,}$/.test(trimmedRadicauxEmail)) {
        incorrectList.push(trimmedRadicauxEmail);
      }
    });

    return incorrectList;
  }

  public showRadicauxEmailsError(form: UntypedFormGroup, value: string): void {
    const incorrectList: string[] = this.findIncorrectRadicauxEmails(form, value);

    this.showToastrService.error('Radicaux emails incorrects : ' + incorrectList);
  }
}
