import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CguModalComponent } from './cgu-modal.component';
import { MaterialModule } from '@shared-evaluateur/modules/material.module';
import { FormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [CguModalComponent],
  imports: [FormsModule, CommonModule, MaterialModule, PdfViewerModule, MatCheckboxModule],
})
export class CguModalModule {}
