<section class="form-step">
  <div id="anchor11" class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span>
      Documentation
    </h2>
  </header>

  <form id="documentation" [formGroup]="documentationFormGroup">
    <div class="select-container">
      <label for="ged" class="ds-switch ds-switch--m">
        <input type="checkbox" id="ged" formControlName="gedConfiguree" />
        <span class="slider"></span>
      </label>
      <span id="oui-option" class="ds-body-2--regular label-r ms-2"> Activer le stockage des documents en GED</span>
    </div>
    <table mat-table formArrayName="documentAaps" [dataSource]="dataSource" aria-describedby="tableau de référentiel des documents AAP">
      <ng-container matColumnDef="scope" class="">
        <th id="document-localisation" mat-header-cell *matHeaderCellDef>Scope</th>
        <td id="localisation" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <div>
            <select
              class="mat-select"
              id="scope_list__{{ i }}"
              name="'scope_list__' + i"
              formControlName="scope"
              placeholder="Sélectionnez"
              (change)="onChangeScope(i)"
            >
              <option [ngValue]="null" selected disabled hidden>Sélectionnez</option>
              <option *ngFor="let item of EnumScope.all()" [value]="item">{{ EnumScope.toString(item) }}</option>
            </select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="disponible" class="">
        <th id="document-disponible" mat-header-cell *matHeaderCellDef>Disponibilité des documents sur l'AAP</th>
        <td id="'disponible__' + i" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <mat-checkbox class="d-flex justify-content-center" [checked]="element.disponibiliteAap" formControlName="disponibiliteAap">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="depot" class="">
        <th id="document-depot" mat-header-cell *matHeaderCellDef>Phase</th>
        <td id="depot" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <div class="d-flex justify-content-between align-items-center">
            <mat-select
              class="mat-multi-select d-flex align-items-center"
              id="'etape_list__' + i"
              name="'etape_list__' + i"
              formControlName="etapes"
              placeholder="Sélectionnez"
              multiple
            >
              <option [ngValue]="null" selected disabled hidden>Sélectionnez</option>
              <mat-option *ngFor="let item of etapeDataList" value="{{ item.id }}">{{ item.value }}</mat-option>
            </mat-select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="nom" class="">
        <th id="document-nom" mat-header-cell *matHeaderCellDef>Nom du type de document</th>
        <td id="nomDocument" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <div matTooltip="{{ element.nomDocument ?? element.value.nomDocument }}">
            <input matInput appareance="outline" value="{{ element.nomDocument ?? element.value.nomDocument }}" disabled />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="obligatoire" class="">
        <th id="document-obligatoire" mat-header-cell *matHeaderCellDef>Document obligatoire</th>
        <td id="'obligatoire__' + i" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <mat-checkbox class="d-flex justify-content-center" [checked]="element.obligatoire" formControlName="obligatoire"> </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="habilitationWKF" class="">
        <th id="document-habilitation-WKF" mat-header-cell *matHeaderCellDef>Niveau d'habilit. WKF1/WKF2</th>
        <td id="habilitation-WKF" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <div>
            <select
              class="mat-select"
              id="'WKF_list__' + i"
              name="'WKF_list__' + i"
              formControlName="accesDocument"
              placeholder="Sélectionnez"
            >
              <option [ngValue]="null" selected disabled hidden>Sélectionnez</option>
              <option *ngFor="let item of habilitation" value="{{ item.id }}">{{ item.value }}</option>
            </select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="structureProjet" class="">
        <th id="structure-projet" mat-header-cell *matHeaderCellDef>Structure projet</th>
        <td id="structure" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <div>
            <mat-select
              class="mat-multi-select d-flex align-items-center"
              id="'structure_list__' + i"
              name="'structure_list__' + i"
              formControlName="typePartenaires"
              placeholder="Sélectionnez"
              multiple
            >
              <option [ngValue]="null" selected disabled hidden>Sélectionnez</option>
              <mat-option *ngFor="let item of EnumTypePartenaire.all()" [value]="item">{{ EnumTypePartenaire.toString(item) }}</mat-option>
            </mat-select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="roles" class="">
        <th id="role-projet" mat-header-cell *matHeaderCellDef>Rôles dans le Projet</th>
        <td id="projet" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <div>
            <mat-select
              class="mat-multi-select d-flex align-items-center"
              id="'role_list__' + i"
              name="'role_list__' + i"
              formControlName="roleStructures"
              [placeholder]="placeholder"
              multiple
            >
              <option [ngValue]="null" selected disabled hidden></option>
              <mat-option *ngFor="let item of EnumRoleStructure.all()" [value]="item">{{ EnumRoleStructure.toString(item) }}</mat-option>
            </mat-select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="type" class="">
        <th id="document-type-structure" mat-header-cell *matHeaderCellDef>Type(s) de structure(s)</th>
        <td id="type-structure" mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
          <div>
            <mat-select
              class="mat-multi-select d-flex align-items-center"
              id="'type_structure_list__' + i"
              name="'type_structure_list__' + i"
              formControlName="typeStructures"
              [placeholder]="placeholder"
              multiple
            >
              <option [ngValue]="null" selected disabled hidden></option>
              <mat-option *ngFor="let item of EnumTypeStructure.forPersonneMorale()" [value]="item">{{
                EnumTypeStructure.toString(item)
              }}</mat-option>
            </mat-select>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
    </table>
  </form>
</section>
