<!-- Entête de la page -->
<div class="page-header">
  <a routerLink="/aaps" class="page-header__back">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back"></lib-pxl-icon>
    Tous les AAP
  </a>
  <h2 id="titre_page" class="page-header__title">Recherche projets</h2>
  <div *ngIf="sharedFunction.isSiteAdmin()" class="actions-container">
    <div>
      <button
        id="actions-btn"
        class="ds-btn ds-btn--primary"
        placement="bottom-right"
        [ngbPopover]="popActions"
        *ngIf="sharedFunction.isSiteAdmin()"
      >
        Actions
        <lib-pxl-icon class="icon" icon="icon-arrow-expand-more" aria-hidden="true"></lib-pxl-icon>
      </button>
      <ng-template #popActions>
        <div class="popover_container">
          <ng-container *ngIf="sharedFunction.isSiteAdmin() || sharedFunction.isEvaluateurInstructeurExterne(evaluateur)">
            <span class="popover_title mt1"> Extractions </span>
            <div class="popover_link">
              <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
              <a (click)="downloadPartenaire()">Partenaires</a>
            </div>
            <div class="popover_link">
              <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
              <a (click)="downloadExportProjets()">Projets</a>
            </div>
            <div class="popover_link">
              <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
              <a (click)="downloadPIA()">Reporting de suivi</a>
            </div>
            <div>
              <span class="popover_title mt1"> Documents </span>
              <div class="popover_link">
                <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                <a (click)="downloadFicheCommunication()">Télécharger fiches communication</a>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="content-container">
  <project-search-transverse
    *ngIf="showSearchBar"
    [programmeDataList]="programmesFilter"
    [programmes]="programmes"
    [etapeDataList]="etapeDataList"
    [statutDataList]="statutDataList"
    [typeProjetDataList]="typeProjetDataList"
    (searchEventEmitter)="searchEventCalled($event)"
  ></project-search-transverse>

  <!-- Component de tableau de projets -->
  <div class="tableau-container">
    <pxl-tableau-projet
      [(selection)]="selection"
      [displayedColumns]="displayedColumns"
      [(data)]="projetTableData"
      (elementClicked)="goToProjetInfo($event)"
    >
    </pxl-tableau-projet>
  </div>
</div>
