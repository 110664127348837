import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CritereReferentiel,
  CritereReferentielForm,
  EnumTypeCritere,
  RadioButtonOption,
  ReferentielHttpService,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { CreationReferentielHelperService } from './creation-referentiel.helper.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-creation-referentiel',
  templateUrl: './creation-referentiel.component.html',
  styleUrls: ['./creation-referentiel.component.scss'],
})
export class CreationReferentielComponent extends SubscriptionDestroyerComponent implements OnInit {
  isEvaluation: boolean;
  submitClicked = false;
  referentielName: string;
  form!: FormGroup<CritereReferentielForm>;
  isModification = false;
  idCritereReferentiel: string;

  readonly OPTIONS_YES_NO: RadioButtonOption[] = [
    { key: true, value: 'Oui' },
    { key: false, value: 'Non' },
  ];

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.form.dirty;
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public helperService: CreationReferentielHelperService,
    private referentielService: ReferentielHttpService,
    private showToastrService: ShowToastrService
  ) {
    super();
    this.isEvaluation = this.route.snapshot.data.type === EnumTypeCritere.CRITERE_EVALUATION;
    this.referentielName = this.isEvaluation ? 'Notation critère' : `Indicateur d'éligibilité`;
    this.idCritereReferentiel = this.route.snapshot.params.id;
    this.isModification = this.idCritereReferentiel !== undefined && this.idCritereReferentiel !== null;
  }

  ngOnInit(): void {
    this.form = this.helperService.createForm();
    if (this.isModification) {
      this.getCritere();
    }
  }

  goToReferentiel(): void {
    this.helperService.goToReferentiel(this.route, this.isModification);
  }

  saveReferentiel(): void {
    this.submitClicked = true;

    this.helperService.saveReferentielFromForm(
      this.form,
      this.route.snapshot.data.type as EnumTypeCritere,
      this.route,
      this.isModification,
      this.idCritereReferentiel
    );
  }

  private getCritere() {
    this.referentielService
      .getReferentielCritere(this.idCritereReferentiel)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (critere: CritereReferentiel) => {
          this.patchForm(critere);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.handleError(err);
        },
      });
  }

  private patchForm(critere: CritereReferentiel) {
    this.form.patchValue(critere);
  }

  handleError(err: HttpErrorResponse) {
    this.showToastrService.checkCodeError(err?.error);
  }
}
