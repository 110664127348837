import { enumKeys } from '../../utils/enum-utils';

export enum EnumPhaseComite {
  EVALUATION = 'EVALUATION',
  AUDITION = 'AUDITION',
  SELECTION = 'SELECTION',
}

export namespace EnumPhaseComite {
  export function toString(comite: EnumPhaseComite): string {
    switch (comite) {
      case EnumPhaseComite.EVALUATION:
        return 'Evaluation';
      case EnumPhaseComite.AUDITION:
        return 'Audition';
      case EnumPhaseComite.SELECTION:
        return 'Instruction';
    }
  }

  export function toLowerCaseString(comite: EnumPhaseComite): string {
    switch (comite) {
      case EnumPhaseComite.EVALUATION:
        return 'évaluation';
      case EnumPhaseComite.AUDITION:
        return 'audition';
      case EnumPhaseComite.SELECTION:
        return 'instruction';
    }
  }

  export function all(): EnumPhaseComite[] {
    return enumKeys(EnumPhaseComite) as unknown as EnumPhaseComite[];
  }
}
