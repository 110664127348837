import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { Pole, Projet } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable()
export class PoleService {
  projetUrl = environment.apiUrl + 'projets';
  notifUrl = environment.apiNotif;

  constructor(private httpClient: HttpClient) {}

  getAapPoles(aapId: string): Observable<HttpResponse<Pole[]>> {
    return this.httpClient.get<Pole[]>(environment.apiUrl + 'aaps/' + aapId + '/poles/list', { observe: 'response' });
  }

  assignerPolesProjet(projetId: string, polesIds: string[]): Observable<Projet> {
    return this.httpClient.put<Projet>(`${environment.apiUrl}poles/projets/${projetId}/assign-pole`, polesIds);
  }

  getProjetPoles(projetId: string): Observable<HttpResponse<Pole[]>> {
    return this.httpClient.get<Pole[]>(environment.apiUrl + 'poles/projets/' + projetId + '/list', { observe: 'response' });
  }
}
