import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumRoleIntervenantDC, IntervenantsForm, SubscriptionDestroyerComponent } from '@shared-ui';
import { Observable } from 'rxjs';
import { CreationIntervenantHelperService } from './creation-intervenant.helper.service';
import { v4 as uuid } from 'uuid';

declare const dsLib: any;

@Component({
  selector: 'app-creation-intervenant',
  templateUrl: './creation-intervenant.component.html',
  styleUrls: ['./creation-intervenant.component.scss'],
})
export class CreationIntervenantComponent extends SubscriptionDestroyerComponent implements OnInit {
  submitClicked = false;
  telephoneRequired = false;
  form!: FormGroup<IntervenantsForm>;
  roles = EnumRoleIntervenantDC;
  telephone = {};
  telephoneId: string;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.form.dirty;
  }

  constructor(public router: Router, public route: ActivatedRoute, public helperService: CreationIntervenantHelperService) {
    super();
  }

  roleChangeEvent() {
    const selectedRole = this.form.get('role').getRawValue();
    this.telephoneRequired = this.helperService.isInternalRole(selectedRole);
  }

  ngOnInit(): void {
    this.telephoneId = uuid();
    this.form = this.helperService.createForm();
    this.telephone = new dsLib.InputPhone(this.telephoneId);
  }

  goToIntervenant(): void {
    this.helperService.goToIntervenant(this.route);
  }

  saveIntervenant(): void {
    this.submitClicked = true;

    this.helperService.saveIntervenantFromForm(this.form, this.route);
  }

  onBlur(): void {
    const inputPhone = document.getElementById('input-telephone');
    if (inputPhone?.classList.contains('ds-input-group--error')) {
      this.form.controls['telephone'].setErrors({ incorrect: true });
    }
  }
}
