import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FlechageBudgetaireService } from '@services-evaluateur/flechage-budgetaire.service';
import { ProgramService } from '@services-evaluateur/program.service';
import { UploadDocumentService } from '@services-evaluateur/upload-document.service';
import {
  Dispositif,
  DsMultiselectComponent,
  EnumTypeWorkflow,
  FilterDataInterface,
  Program,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';

@Component({
  selector: 'generals-informations',
  templateUrl: './general.informations.component.html',
  styleUrls: ['./general.informations.component.scss'],
})
export class GeneralInformationsComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() informationsForm: UntypedFormGroup;
  @Input() projetLength: number;
  @Input() stepNumber: number;

  constructor(
    private uploadDocumentService: UploadDocumentService,
    private programService: ProgramService,
    public showToastrService: ShowToastrService,
    public flechageBudgetaireService: FlechageBudgetaireService
  ) {
    super();
  }

  fileToUpload: File = null;
  MAX_DISPOSITIF_LENGTH = 32;

  fileName = '';

  programs: Program[];
  activeDispositifs: Dispositif[] = [];
  shownDispositifs: Dispositif[] = [];
  dispositifsDataList: FilterDataInterface[] = [];

  displayCodeInput = false;
  fileTooBig = false;
  fileNotSupported = false;

  @ViewChild('dispositifsSecondairesComponent') dispositifsSecondaires: DsMultiselectComponent;

  ngOnInit(): void {
    if (this.informationsForm.get('codeComptable').value) {
      this.setTypeValidators(this.informationsForm.get('codeComptable').value);
    }

    this.informationsForm
      .get('codeComptable')
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe(typeComptable => {
        this.setTypeValidators(typeComptable);
      });

    this.programService
      .getPrograms(['CREE', 'OUVERT'])
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<Program[]>) => {
          this.programs = response.body;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  loadDispositifs(): void {
    this.flechageBudgetaireService
      .getDispositifs()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: Dispositif[]) => {
          this.activeDispositifs = this.getActiveDispositifs(response);
          this.setShownDispositifs();
          this.setDispositifsDataList();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  getActiveDispositifs(dispositifs: Dispositif[]): Dispositif[] {
    const currentDate = new Date();
    return [...dispositifs].filter(
      dispositif =>
        dispositif.actif &&
        dispositif.dateDebut &&
        dispositif.dateFin &&
        new Date(dispositif.dateDebut) <= currentDate &&
        new Date(dispositif.dateFin) >= currentDate
    );
  }

  setShownDispositifs(): void {
    this.shownDispositifs = [...this.activeDispositifs];
    const currentDispositifValue = this.informationsForm.get('dispositif').value;
    if (currentDispositifValue && !this.activeDispositifs.find(dispositif => dispositif.code === currentDispositifValue)) {
      this.shownDispositifs.push({ code: currentDispositifValue } as Dispositif);
    }
  }

  setDispositifsDataList(): void {
    const selectedDispositifsCodes = this.informationsForm.get('dispositifsSecondaires').value as string[];
    this.dispositifsDataList = [...this.activeDispositifs].map(dispositif => ({
      id: dispositif.code,
      value: dispositif.code,
      isSelected: false,
    }));

    selectedDispositifsCodes?.forEach(selectedDispositifCode => {
      const dispositif = this.dispositifsDataList.find(dispositif => dispositif.id === selectedDispositifCode);
      if (dispositif) {
        dispositif.isSelected = true;
      } else {
        this.dispositifsDataList.push({
          id: selectedDispositifCode,
          value: selectedDispositifCode,
          isSelected: true,
        });
      }
    });

    this.dispositifsSecondaires.dataList = this.dispositifsDataList;
    this.dispositifsSecondaires.reset(false);
  }

  comparePrg(t1: Program, t2: Program): boolean {
    return t1 && t2 ? t1.id === t2.id : t1 === t2;
  }

  setTypeValidators(typeComptable: string): void {
    const type = this.informationsForm.get('code');

    if (typeComptable === 'Code inconnu') {
      type.setValidators([Validators.required]);
      this.displayCodeInput = true;
    } else {
      type.setValidators(null);
      this.displayCodeInput = false;
      type.setValue(null);
    }

    type.updateValueAndValidity();
  }

  onUpload(files: FileList): void {
    this.fileTooBig = false;
    this.fileNotSupported = false;
    this.fileToUpload = files.item(0);

    if (this.fileToUpload.size >= 500000) {
      this.fileTooBig = true;
    } else if (
      this.fileToUpload.type !== 'application/pdf' &&
      this.fileToUpload.type !== 'image/jpeg' &&
      this.fileToUpload.type !== 'image/png' &&
      this.fileToUpload.type !== 'image/bmp' &&
      this.fileToUpload.type !== 'image/gif'
    ) {
      this.fileNotSupported = true;
    } else {
      this.uploadDocumentService
        .getValueForLogoUpload(this.fileToUpload.name)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (response: HttpResponse<{ url: string }>) => {
            const url = response.body?.url;

            this.uploadDocumentService
              .uploadLogo(url, this.fileToUpload)
              .pipe(this.takeUntilDestroyed())
              .subscribe({
                next: () => {
                  this.fileName = this.fileToUpload.name;
                  this.informationsForm.get('logo').setValue(this.fileName);
                },
                error: (err: HttpErrorResponse) => {
                  this.showToastrService.checkCodeError(err?.error);
                },
              });
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  showErrorLienDoc(): boolean {
    const test = this.informationsForm.get('lienDocumentation').errors?.pattern;
    return test !== undefined;
  }

  onkeypress($event: any): boolean {
    return !($event.keyCode === 47 || $event.keyCode === 92);
  }

  dispositifsSecondairesChanged(): void {
    const dispositifsSecondairesControl = this.informationsForm.get('dispositifsSecondaires');
    dispositifsSecondairesControl.markAsDirty();
    dispositifsSecondairesControl.setValue(this.dispositifsSecondaires.getSelectedValues().map(dispositif => dispositif.id));
  }

  protected readonly EnumTypeWorkFlow = EnumTypeWorkflow;
}
