<section class="form-step">
  <div id="anchor3" class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span
      >Structure du projet
    </h2>
  </header>

  <form [formGroup]="partenaireForm">
    <div class="form-step__section">
      <div class="ds-radio-group">
        <input type="radio" name="type" id="multi" class="ds-radio-group__radio-input" value="MULTI" formControlName="type" />
        <label for="multi" class="ds-radio-group__radio-label">Multi partenaires</label>

        <input type="radio" name="type" id="indiv" class="ds-radio-group__radio-input" value="INDIV" formControlName="type" />
        <label for="indiv" class="ds-radio-group__radio-label">Individuel</label>
      </div>
    </div>

    <div class="form-step__section form-step__slider" *ngIf="partenaireForm.get('type').value === EnumTypePartenaire.MULTI">
      <p class="ds-input-group__label">L’option multi partenaires est obligatoire pour le candidat</p>

      <div class="switch-grp">
        <span id="non-option" class="ds-body-2--regular label-l"> Non</span>
        <label for="obligatoire" class="ds-switch ds-switch--m">
          <input type="checkbox" id="obligatoire" formControlName="obligatoire" />
          <span class="slider"></span>
        </label>
        <span id="oui-option" class="ds-body-2--regular label-r"> Oui</span>
      </div>
    </div>
  </form>
</section>
