import { DOCUMENT } from '@angular/common';
import { Component, Inject, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'pxl-sidenav-creation-aap',
  templateUrl: './sidenav.creation.aap.component.html',
  styleUrls: ['./sidenav.creation.aap.component.scss'],
})
export class SidenavCreationAapComponent implements OnChanges {
  @Output() anchorCall = new EventEmitter<string>();
  @Input() activeAnchor!: string;
  @Input() steps: { featureOn: boolean; label: string; section: string; featureName: string }[];
  currentSection = 'anchor1';

  constructor(@Inject(DOCUMENT) public document: Document) {}

  ngOnChanges(): void {
    this.currentSection = this.activeAnchor;
  }

  goToSection(index: string): void {
    this.anchorCall.emit(index);
  }
}
