import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PhaseCardComponent } from '@features-evaluateur/aap-creation/component/phases/phase-card/phase-card.component';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [PhaseCardComponent],
  imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule, SharedUiModule],
  exports: [PhaseCardComponent],
})
export class PhaseCardModule {}
