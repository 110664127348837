import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frenchNumberFormat',
})
export class FrenchNumberFormatPipe implements PipeTransform {
  transform(value: number | null | undefined): string | null {
    if (value == null) {
      return null;
    }
    if (isNaN(value)) {
      return value.toString();
    }

    value = Math.round(value * 100) / 100;
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 2,
      useGrouping: true,
    }).format(value);
  }
}
