import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComiteService } from '@services-evaluateur/comite.service';
import { Comite, EnumPhaseComite, EnumStatutComite, ShowToastrService, SubscriptionDestroyerComponent } from '@shared-ui';

@Component({
  selector: 'app-assign-projet-comite-modal',
  templateUrl: './assign-projet-comite-modal.component.html',
  styleUrls: ['./assign-projet-comite-modal.component.scss'],
})
export class AssignProjetComiteModalComponent extends SubscriptionDestroyerComponent implements OnInit {
  projetsIds: string[];
  comites: Comite[] = [];
  selectedComiteId: Comite;
  form: FormGroup<FormGroupInterface>;

  constructor(
    public dialogRef: MatDialogRef<AssignProjetComiteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    public comiteService: ComiteService,
    public showToastrService: ShowToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.projetsIds = this.modalData.projetsIds;
    this.createForm();
    this.comiteService
      .getComites()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (result: HttpResponse<Comite[]>) => {
          this.comites = result.body?.filter(comite => comite.statut === EnumStatutComite.CREE);
        },
      });
  }

  createForm(): void {
    this.form = new FormGroup<FormGroupInterface>({
      selectedComiteId: new FormControl(null, Validators.required),
      phase: new FormControl(null, Validators.required),
    });
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const comiteId = this.form.controls.selectedComiteId.value;
    const phase = this.form.controls.phase.value;
    this.comiteService
      .assignProjetsToComite(comiteId, this.modalData.projetsIds, phase)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: (err: HttpErrorResponse) => {
          if (err?.error?.message) {
            this.showToastrService.error(err.error.message);
          } else {
            this.showToastrService.checkCodeError(err?.error);
          }
        },
      });
  }

  closeDialog(): void {
    if (this.form.dirty) {
      const confirmation = window.confirm(`Vous avez demandé d'annuler votre demande. Vos modifications seront perdues.`);
      if (!confirmation) {
        return;
      }
    }
    this.dialogRef.close();
  }

  protected readonly EnumPhaseComite = EnumPhaseComite;
}

interface ModalData {
  projetsIds: string[];
  codeAap: string;
}

interface FormGroupInterface {
  selectedComiteId: FormControl<string>;
  phase: FormControl<EnumPhaseComite>;
}
