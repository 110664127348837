import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CritereReferentielCardComponent } from './critere-referentiel-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [CritereReferentielCardComponent],
  imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule, SharedUiModule],
  exports: [CritereReferentielCardComponent],
})
export class CritereReferentielCardModule {}
