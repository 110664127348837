<section class="form-step">
  <div id="anchor12" class="form-step__anchor"></div>
  <header class="form-step__header">
    <h2 class="form-step__title">
      <span class="form-step__number">{{ stepNumber }}</span
      >Documents dématérialisés
    </h2>
  </header>
  <form id="document-dematerialise-form" [formGroup]="documentationDematerialiseForm">
    <lib-card-info [message]="infoMessage"></lib-card-info>
    <div class="form-step__section section-document-dematerialises">
      <p class="radio-group-title">
        Présence du parcours Dépenses projet (BDD) dématérialisé <span class="ds-input-group__label-asterisk"> *</span>
      </p>
      <div class="ds-radio-group">
        <input
          type="radio"
          name="depensesProjetPresent"
          id="doc-demat-true"
          class="ds-radio-group__radio-input"
          [value]="true"
          formControlName="depensesProjetPresent"
        />
        <label for="doc-demat-true" class="ds-radio-group__radio-label">Oui</label>

        <input
          type="radio"
          name="depensesProjetPresent"
          id="doc-demat-false"
          class="ds-radio-group__radio-input"
          [value]="false"
          formControlName="depensesProjetPresent"
        />
        <label for="doc-demat-false" class="ds-radio-group__radio-label">Non</label>
      </div>
    </div>
    <div
      class="section-document-dematerialises"
      [class.ds-input-group--error]="
        !documentationDematerialiseForm?.controls['ficheComPresent'].valid &&
        documentationDematerialiseForm?.controls['ficheComPresent'].touched
      "
    >
      <p class="radio-group-title">
        Présence du parcours fiche de communication dématérialisée <span class="ds-input-group__label-asterisk"> *</span>
      </p>
      <div class="ds-radio-group">
        <input
          type="radio"
          name="ficheComPresent"
          id="fiche-com-true"
          class="ds-radio-group__radio-input"
          [value]="true"
          formControlName="ficheComPresent"
        />
        <label for="fiche-com-true" class="ds-radio-group__radio-label">Oui</label>

        <input
          type="radio"
          name="ficheComPresent"
          id="fiche-com-false"
          class="ds-radio-group__radio-input"
          [value]="false"
          formControlName="ficheComPresent"
        />
        <label for="fiche-com-false" class="ds-radio-group__radio-label">Non</label>
        <ng-container
          *ngIf="
            !documentationDematerialiseForm?.controls['ficheComPresent'].valid &&
            documentationDematerialiseForm?.controls['ficheComPresent'].touched
          "
        >
          <div class="ds-input-group__feedback">Merci de renseigner si la fiche de communication dématérialisée est présente ou pas</div>
        </ng-container>
      </div>
    </div>

    <div formGroupName="ficheDemandeAidePresent">
      <div
        class="section-fiche-damande-aide"
        [class.ds-input-group--error]="
          !documentationDematerialiseForm?.controls['ficheDemandeAidePresent'].valid &&
          documentationDematerialiseForm?.controls['ficheDemandeAidePresent'].touched
        "
      >
        <p class="radio-group-title">
          Présence du parcours fiche de demande d'aide dématérialisée <span class="ds-input-group__label-asterisk"> *</span>
        </p>
        <div class="ds-radio-group">
          <input
            type="radio"
            name="active"
            id="fiche-com-true"
            class="ds-radio-group__radio-input"
            [value]="true"
            formControlName="active"
          />
          <label for="fiche-demande-aide-true" class="ds-radio-group__radio-label">Oui</label>

          <input
            type="radio"
            name="active"
            id="fiche-com-false"
            class="ds-radio-group__radio-input"
            [value]="false"
            formControlName="active"
          />
          <label for="fiche-demande-aide-false" class="ds-radio-group__radio-label">Non</label>
          <ng-container
            *ngIf="
              !documentationDematerialiseForm?.controls['ficheDemandeAidePresent'].valid &&
              documentationDematerialiseForm?.controls['ficheDemandeAidePresent'].touched
            "
          >
            <div class="ds-input-group__feedback">Merci de renseigner si la fiche de demande d'aide est présente ou pas</div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</section>
