export const codeGed: string[] = [
  'B102',
  'B103',
  'B202',
  'B203',
  'B401',
  'C307',
  'C308',
  'C403',
  'C408',
  'D107',
  'E101',
  'E104',
  'F304',
  'H101',
  'F207',
  'J101',
  'D108',
  'A101',
  'A203',
  'A201',
  'A212',
  'A217',
  'A202',
  'A204',
  'A205',
  'A213',
  'A303',
  'A304',
];
