import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Aap, GrilleImpactHttpService, Kpi, ShowToastrService } from '@shared-ui';

@Component({
  selector: 'pxl-grille-impact',
  templateUrl: './grille-impact.component.html',
  styleUrls: ['./grille-impact.component.scss'],
})
export class GrilleImpactComponent implements OnInit {
  dataSource: MatTableDataSource<Kpi>;
  selection = new SelectionModel<Kpi>(true, []);

  @Input() aap: Aap;
  @Input() kpiFormGroup: UntypedFormGroup;
  @Input() stepNumber: number;

  constructor(private grilleService: GrilleImpactHttpService, private showToastrService: ShowToastrService) {}

  ngOnInit(): void {
    this.selection.changed.subscribe({
      next: () => {
        this.kpiFormGroup.setValue(this.selection.selected);
      },
    });

    this.grilleService.getKpis().subscribe({
      next: kpis => {
        const kpisFiltered = kpis.body.filter(kpi => kpi.actif);
        this.dataSource = new MatTableDataSource<Kpi>(kpisFiltered);
        this.setSelectedCheckboxes();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  setSelectedCheckboxes(): void {
    this.selection.setSelection(
      ...this.dataSource.data.filter(data => this.kpiFormGroup.value.find((kpi: Kpi) => kpi.id === data.id) != null)
    );
  }
}
