import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';

import { EnumAapStatut, Program, ShowToastrService } from '@shared-ui';

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProgramService } from '@services-evaluateur/program.service';

@Component({
  selector: 'pxl-program-list',
  templateUrl: './program.list.component.html',
  styleUrls: ['./program.list.component.scss'],
})
export class ProgramListComponent implements OnInit {
  title = 'pxl_front_admin';
  errorResponse = '';
  programs: Program[];
  displayedColumns: string[] = ['nom', 'typeFront', 'dateCreation', 'aaps', 'statut', 'modifier'];
  dataSource: MatTableDataSource<Program>;

  EnumAapStatut = EnumAapStatut;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private programService: ProgramService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private showToastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'nav-collapsed');

    this.programService.getPrograms().subscribe({
      next: (response: HttpResponse<Program[]>) => {
        this.programs = response.body;
        this.updateTableSource(this.programs);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  updateTableSource(programList: Program[]): void {
    this.dataSource = new MatTableDataSource(
      programList.filter(item => item.statut === 'CREE' || item.statut === 'OUVERT' || item.statut === 'BROUILLON')
    );
    // Indique à la fonction de tri comment acceder au propriétés de l'objet utilisé pour le tri
    // ceci se fait parce que la propriété de l'objet element du html ne correspond pas au nom de la colonne
    this.dataSource.sortingDataAccessor = (item, property) => {
      return typeof (item as any)[property] === 'string' ? (item as any)[property]?.toLocaleLowerCase().trim() : (item as any)[property];
    };

    if (this.sort) {
      this.sort.disableClear = true;
    }

    this.dataSource.sort = this.sort;
  }

  deleteProg(programDeleted: Program, index: number): void {
    this.errorResponse = '';
    this.programService.deleteProgram(programDeleted).subscribe({
      next: () => {
        this.programs.splice(index, 1);
        this.programs = this.programs.filter(item => item.id !== programDeleted.id);
        this.updateTableSource(this.programs);

        this.programs = this.programs.filter(item => item !== programDeleted);

        this.showToastrService.success('Programme supprimé');
      },
      error: (err: HttpErrorResponse) => {
        this.errorResponse = err.statusText;
      },
    });
  }

  updateProg(program: Program): void {
    this.router.navigate(['modifierProgramme/' + program.id]);
  }

  createProg(): void {
    this.router.navigate(['creationProgramme']);
  }
}
