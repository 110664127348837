import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EnumRoleIntervenantDC,
  IntervenantsDc,
  IntervenantsForm,
  ReferentielHttpService,
  ShowToastrService,
  SubscriptionDestroyerComponent,
} from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class CreationIntervenantHelperService extends SubscriptionDestroyerComponent {
  telephone = {};

  constructor(public httpService: ReferentielHttpService, private showToastrService: ShowToastrService, private router: Router) {
    super();
  }

  createForm(): FormGroup<IntervenantsForm> {
    const form: FormGroup<IntervenantsForm> = new FormGroup<IntervenantsForm>({
      nom: new FormControl('', Validators.required),
      prenom: new FormControl('', Validators.required),
      matricule: new FormControl('', Validators.required),
      role: new FormControl(null, Validators.required),
      email: new FormControl('', Validators.required),
      telephone: new FormControl(null),
      fonction: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    });

    this.initFormEvents(form);
    return form;
  }

  initFormEvents(form: FormGroup<IntervenantsForm>): void {
    form.get('role').valueChanges.subscribe(selectedRole => {
      const telephoneControl = form.get('telephone');
      const fonctionControl = form.get('fonction');
      const telephoneValidators = [];
      const fonctionValidators = [Validators.maxLength(100)];
      if (this.isInternalRole(selectedRole)) {
        telephoneValidators.push(Validators.required);
        fonctionValidators.push(Validators.required);
      }
      telephoneControl.setValidators(telephoneValidators);
      telephoneControl.updateValueAndValidity();
      fonctionControl.setValidators(fonctionValidators);
      fonctionControl.updateValueAndValidity();
    });
  }

  isInternalRole(role: EnumRoleIntervenantDC): boolean {
    return role === EnumRoleIntervenantDC.SIGNATAIRE_INTERNE || role === EnumRoleIntervenantDC.SUIVEUR_INTERNE;
  }

  saveIntervenantFromForm(form: FormGroup<IntervenantsForm>, route: ActivatedRoute): IntervenantsDc {
    form.markAllAsTouched();

    if (form.invalid) {
      return;
    }

    const intervenant = form.value as IntervenantsDc;

    this.httpService
      .saveIntervenant(intervenant)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => {
          this.showToastrService.success('Intervenant créé avec succès');
          form.markAsPristine();
          this.goToIntervenant(route);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  goToIntervenant(route: ActivatedRoute): void {
    this.router.navigate([`../${route.snapshot.data.referentielPath}`], { relativeTo: route });
  }
}
