import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomMultiSelectModule } from '@shared-evaluateur/components/multiselect/multiselect.module';
import { SharedModule } from '@shared-evaluateur/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CategoriesComponent } from './categories.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CustomMultiSelectModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    SharedUiModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  declarations: [CategoriesComponent],
  exports: [CategoriesComponent],
  providers: [],
})
export class CategoriesModule {}
