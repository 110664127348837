<div class="page-content page-content--no-scroll list-page">
  <a class="page-header__back" (click)="goToReferentiels()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Référentiels
  </a>
  <lib-card-title [title]="'Paramétrer le référentiel des dépenses projets'"> </lib-card-title>
  <section class="page-section card-create">
    <div class="card card--button">
      <div class="card__title">
        <div>Modification des taux et versements des dépenses projets</div>
      </div>
      <button type="button" class="ds-btn ds-btn--secondary" (click)="goToTauxEtVersements()">
        <lib-pxl-icon class="icon" icon="icon-action-add" aria-hidden="true"></lib-pxl-icon>
        Modification taux et versements
      </button>
    </div>
  </section>

  <pxl-depenses-projet-config
    class="tableau-container"
    [dataSource]="dataSource"
    [modificationPathDepensesProjet]="modificationPathDepensesProjet"
  ></pxl-depenses-projet-config>

  <section class="page-section card-create mt-4">
    <div class="card card--button">
      <div class="card__title">
        <div>Vous pouvez créer un régime d'aide</div>
      </div>
      <button type="button" class="ds-btn ds-btn--secondary" (click)="goToRegimesAides()">
        <lib-pxl-icon class="icon" icon="icon-action-add" aria-hidden="true"></lib-pxl-icon>
        Créer un régime
      </button>
    </div>
  </section>

  <pxl-regimes-aides
    class="tableau-container"
    [dataSource]="dataSource"
    [modificationPathRegimesAides]="modificationPathRegimesAides"
  ></pxl-regimes-aides>
</div>
